const FooterIcon = ({ imgSrc, text, onClick = () => {}, lang = 'en' }) => {
	return (
		<div
			className="flex flex-col justify-center items-center"
			onClick={onClick}
		>
			<div className="w-4.5 h-4.5">
				<img src={imgSrc} alt={text} />
			</div>
			<span
				className={`text-white ${
					lang === 'en'
						? 'text-sm'
						: lang !== 'ka' && lang !== 'te'
						? 'text-sm'
						: 'text-[0.5rem]'
				}`}
			>
				{text}
			</span>
		</div>
	);
};

export default FooterIcon;
