import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	fallbackLng: 'en',
	lng: 'en',
	resources: {
		en: {
			translations: require('./locales/en/translations.json'),
			terms: require('./locales/en/terms.json'),
			winner: require('./locales/en/winner.json'),
			popup_messages: require('./locales/en/popup_messages.json'),
			affiliate: require('./locales/en/affiliate.json'),
			profile: require('./locales/en/profile.json'),
		},
		hi: {
			translations: require('./locales/hi/translations.json'),
			terms: require('./locales/hi/terms.json'),
			winner: require('./locales/hi/winner.json'),
			popup_messages: require('./locales/hi/popup_messages.json'),
			affiliate: require('./locales/hi/affiliate.json'),
			profile: require('./locales/hi/profile.json'),
		},
		ka: {
			translations: require('./locales/ka/translations.json'),
			winner: require('./locales/ka/winner.json'),
			popup_messages: require('./locales/ka/popup_messages.json'),
			affiliate: require('./locales/ka/affiliate.json'),
			profile: require('./locales/ka/profile.json'),
		},
		gu: {
			translations: require('./locales/gu/translations.json'),
			winner: require('./locales/gu/winner.json'),
			popup_messages: require('./locales/gu/popup_messages.json'),
			affiliate: require('./locales/gu/affiliate.json'),
			profile: require('./locales/gu/profile.json'),
		},
		ma: {
			translations: require('./locales/ma/translations.json'),
			winner: require('./locales/ma/winner.json'),
			popup_messages: require('./locales/ma/popup_messages.json'),
			affiliate: require('./locales/ma/affiliate.json'),
			profile: require('./locales/ma/profile.json'),
		},
		od: {
			translations: require('./locales/od/translations.json'),
			terms: require('./locales/od/terms.json'),
			winner: require('./locales/od/winner.json'),
			popup_messages: require('./locales/od/popup_messages.json'),
			affiliate: require('./locales/od/affiliate.json'),
			profile: require('./locales/od/profile.json'),
		},
		bn: {
			translations: require('./locales/bn/translations.json'),
			terms: require('./locales/bn/terms.json'),
			winner: require('./locales/bn/winner.json'),
			popup_messages: require('./locales/bn/popup_messages.json'),
			affiliate: require('./locales/bn/affiliate.json'),
			profile: require('./locales/bn/profile.json'),
		},
		te: {
			translations: require('./locales/te/translations.json'),
			terms: require('./locales/te/terms.json'),
			winner: require('./locales/te/winner.json'),
			popup_messages: require('./locales/te/popup_messages.json'),
			affiliate: require('./locales/te/affiliate.json'),
			profile: require('./locales/te/profile.json'),
		},
	},
	ns: [
		'translations',
		'terms',
		'winner',
		'popup_messages',
		'affiliate',
		'profile',
	],
	defaultNS: 'translations',
});

i18n.languages = ['en', 'hi', 'ka', 'gu', 'ma', 'od', 'bn', 'te'];

export default i18n;
