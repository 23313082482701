{
	"home": "ହୋମ୍\u200C",
	"login": "ଲଗ୍‌ଇନ୍‌ କରନ୍ତୁ",
	"mobile_number": "ମୋବାଇଲ୍ ନମ୍ୱର",
	"mobile_number_label": "ମୋବାଇଲ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
	"get_otp": "Get OTP",
	"mobile_error_message": "ଦୟାକରି ଏକ ବୈଧ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
	"resendOTP": "ପୁଣିଥରେ ଓଟିପି ପଠାନ୍ତୁ",
	"otp_verification": "ଓଟିପି ଯାଞ୍ଚ",
	"otp_verification_sub_text": "ଆମେ +91 {{mobile_number}} କୁ ପଠାଇଥିବା 6-ଅଙ୍କ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
	"try_again_text": "{{time_remaining}} ସେକେଣ୍ଡରେ ପୁଣିଥରେ ଓଟିପି ପଠାନ୍ତୁ ",
	"btn_submit": "ଦାଖଲ କରନ୍ତୁ",
	"sign_up": "ଆପଣ ପହଞ୍ଚି ସାରିଛନ୍ତି!",
	"enter_detail_text": "ପ୍ଲେୟିଂ ସ୍କ୍ୱାର୍ଡରେ ପହଞ୍ଚିବାକୁ ସାଇନ୍\u200C ଅପ୍\u200C କରନ୍ତୁ!",
	"full_name": "ସଂପୂର୍ଣ୍ଣ ନାମ",
	"name": "ନାମ",
	"email_id": "ଇମେଲ୍ ଆଡ୍ରେସ୍\u200C",
	"terms_text": "ମୁଁ ପ୍ରମାଣିତ କରୁଛି ଯେ ମୋର ବୟସ ଅତିକମରେ 18 ବର୍ଷ ବୟସ ହୋଇଛି ଏବଂ <tnc>ନିୟମ ଓ ସର୍ତ୍ତାବଳୀ</tnc> ସହିତ <pp>ଗୋପନୀୟତା ନୀତି</pp> ପଢ଼ିଛି",
	"participate_win": "Participate & Win",
	"winners_list": "ବିଜେତାଙ୍କ ତାଲିକା",
	"faqs": "ବାରମ୍ୱାର ପଚରାଯାଉଥିବା ପ୍ରଶ୍ନଗୁଡିକ",
	"terms_conditions": "ଅପ-ଟୁ-ଡେଟ୍ ନିୟମ ଏବଂ ସର୍ତ୍ତାବଳୀ",
	"privacy_policy": "ଗୋପନୀୟତା ନୀତି",
	"logout": "ଲଗ୍\u200C ଆଉଟ୍\u200C",
	"participate_now": "ବର୍ତ୍ତମାନ ଅଂଶଗ୍ରହଣ କରନ୍ତୁ",
	"winner_board": "Winner Board",
	"enter_unique_code": "ୟୁନିକ୍\u200C କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
	"winners": "Winners",
	"enter_code": "କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
	"profile": "ପ୍ରୋଫାଇଲ୍",
	"get_aboard_text": "ତୁଫାନ୍ ରେ ଆପଣଙ୍କ ସିଟ୍ ଧରି ନିଅନ୍ତୁ",
	"btn_continue": "ଜାରି ରଖନ୍ତୁ",
	"get_aboard_sub_text": "ଜୀବନରେ ଥରେ ଆସୁଥିବା ଏହି ଯାତ୍ରାରେ - ଆମର ସମସ୍ତ ତୁଫାନି ପ୍ରଶଂସକଙ୍କୁ ୱେଷ୍ଟଇଣ୍ଡିଜ୍\u200Cର ଆଇସିସି ଟି -20 ବିଶ୍ୱକପ୍ ଖେଳ ଦେଖିଯିବା ପାଇଁ ବିଶେଷ ଭାବେ ପ୍ରସ୍ତୁତ ଏକ ବିମାନରେ ଏକ ବିଳାସପୂର୍ଣ୍ଣ ଅଭିଜ୍ଞତା ପାଇଁ ଆମ ସହିତ ଯୋଗ ଦିଅନ୍ତୁ।",
	"win_mileage_text": "ସହିତ ଆକ୍ସନ୍\u200Cର ନିକଟତର ହୁଅନ୍ତୁ\nୱେଷ୍ଟଇଣ୍ଡିଜ୍",
	"win_mileage_sub_text": "ପ୍ରତ୍ୟେକ Thums UP®",
	"my_kms": "ମୋର କିମି",
	"follow_us": "ଆମକୁ ଅନୁସରଣ କରନ୍ତୁ",
	"copyright_text": "© 2024 କୋକାକୋଲା କମ୍ପାନୀ। ସମସ୍ତ ଅଧିକାର ସଂରକ୍ଷିତ।",
	"Please enter a valid unique code": "ଦୟାକରି ଏକ ବୈଧ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
	"your_profile": "ମୋର ପ୍ରୋଫାଇଲ୍",
	"profile_page_desc": "ବିଜେତାମାନଙ୍କ ସହିତ ଯୋଗାଯୋଗ କରିବାକୁ ଆମେ ନିମ୍ନରେ ସବିଶେଷ ତଥ୍ୟ ବ୍ୟବହାର କରିବୁ। ଦିଆଯାଇଥିବା ସମସ୍ତ ସୂଚନା ସଠିକ୍ ବୋଲି ଦୟାକରି ସୁନିଶ୍ଚିତ କରନ୍ତୁ।",
	"dob": "ଜନ୍ମ ତିଥି",
	"gender": "ଲିଙ୍ଗ",
	"set_as_default": "ଡିଫଲ୍ଟ ଭାବରେ ସେଟ୍ କରନ୍ତୁ",
	"congratulations": "ଆପଣଙ୍କୁ ଶୁଭେଚ୍ଛା!",
	"flight_ticket_claimed_text": "You’ve claimed your ticket to the West Indies! We will communicate further details via email & SMS shortly.",
	"share": "ସେୟାର୍\u200C କରନ୍ତୁ",
	"claim_form_desc": "ଆପଣଙ୍କର ପୁରସ୍କାର କ୍ଲେମ୍\u200C କରିବାକୁ ଦୟାକରି ନିମ୍ନରେ ସବିଶେଷ ବିବରଣୀ ପ୍ରବେଶ କରନ୍ତୁ!",
	"full_name_claim_form": "ସଂପୂର୍ଣ୍ଣ ନାମ (ସରକାରୀ ID ଅନୁଯାୟୀ)",
	"pan_card": "ପାନ୍ କାର୍ଡ ",
	"passport_no": "ପାସପୋର୍ଟ ନମ୍ୱର",
	"passport_expiry_date": "ପାସପୋର୍ଟର ସମୟସୀମା ସମାପ୍ତି ତାରିଖ",
	"Male": "ପୁରୁଷ",
	"Female": "ମହିଳା",
	"transgender": "Transgender",
	"Other": "ଅନ୍ୟାନ୍ୟ",
	"gift_voucher_claimed_text": "You’ve claimed your gift voucher! We will communicate further details via email & SMS shortly.",
	"claim_now": "ଏବେ କ୍ଲେମ୍\u200C କରନ୍ତୁ",
	"paytm_1000_won_text": "You’ve won yourself a ₹1,000 Paytm voucher!",
	"paytm_1000_win_text": "Win a ₹1000 Paytm Voucher!",
	"paytm_100_won_text": "You’ve won yourself a ₹1,00 Paytm voucher!",
	"paytm_100_win_text": "Win a ₹100 Paytm Voucher!",
	"win_ticket": "Win a Ticket",
	"enter_unique_code_reach_kms": "Enter unique code to reach 14K KMs",
	"enter_name": "ଦୟାକରି ଏକ ବୈଧ ନାମ ପ୍ରବେଶ କରନ୍ତୁ",
	"enter_mobile_number": "Please enter mobile number",
	"enter_email": "ଦୟାକରି ଏକ ବୈଧ ଇମେଲ୍ ID ପ୍ରବେଶ କରନ୍ତୁ",
	"enter_pan": "ଦୟାକରି ଏକ ବୈଧ ପାନ୍ କାର୍ଡ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
	"no_page_found": "ପେଜ୍\u200C ମିଳୁନାହିଁ",
	"go_to_home": "ହୋମ୍\u200Cପେଜ୍\u200Cକୁ ଯାଆନ୍ତୁ",
	"eligible_1000_paytm_voucher": "You are now eligible for ₹1000 Paytm Gift Voucher",
	"win_prize_lucky_draw_daily": "Win Prizes in a lucky Draw Daily",
	"ticket_to_west_indies": "Ticket to West Indies",
	"ticket_to_west_indies_v1": "ଆପଣ ତୁଫାନି ଫ୍ୟାନ୍ କ୍ଲବ୍‌ରେ ନିଜର ସ୍ଥାନ ନିଶ୍ଚିତ କରିଛନ୍ତି!",
	"ticket_to_west_indies_subtext_v1": "ଆପଣ ତୁଫାନରେ ଏକ ସିଟ୍ ଜିତି ନେଇଛନ୍ତି! ୱେଷ୍ଟଇଣ୍ଡିଜ ପାଇଁ ଷ୍ଟାଇଲ୍‌ରେ ଉଡ଼ିବାକୁ ପ୍ରସ୍ତୁତ ହୋଇଯାଆନ୍ତୁ",
	"fan_jersy_heading": "ଜଣେ ପ୍ରୋ ଭଳି ହୁଅନ୍ତୁ",
	"fan_jersy_heading_text": "999 ଟଙ୍କା ମୂଲ୍ୟର ଆପଣଙ୍କର କ୍ରିକେଟ୍ ଜର୍ସି କ୍ଲେମ୍\u200C କରିବାକୁ ଏଠାରେ ଏକ ଫ୍ୟାନକୋଡ୍ ଭାଉଚର୍ ରହିଛି",
	"how_enter_lucky_draw": "How to Enter the Lucky Draw",
	"step_1": "ଷ୍ଟେପ୍\u200C 1",
	"step_2": "ଷ୍ଟେପ୍\u200C 2",
	"step_3": "ଷ୍ଟେପ୍\u200C 3",
	"step_4": "ଷ୍ଟେପ୍\u200C 4",
	"scan_qr_code_bottle": "Scan the QR code from your ThumbsUp bottle",
	"enter_code_kms_achieve_three_code": "Enter the unique code to accrue 14,000kms with 3 codes max",
	"enter_lucky_draw_win_prize": "Enter the lucky draw to win prizes daily",
	"paytm_gift_voucher": "Paytm gift Voucher",
	"faq_heading": "ବାରମ୍ୱାର ପଚରାଯାଇଥିବା ପ୍ରଶ୍ନ",
	"faq_subtext": "ଥୁମ୍ସ ଅପ୍ ବିଷୟରେ ଆପଣ ଙ୍କୁ ସବୁ କିଛି ଜାଣିବା ଦରକାର",
	"terms_heading": "ନିୟମ ଏବଂ ସର୍ତ୍ତାବଳୀ",
	"contest_over": "Contest Over",
	"thank_you_visit_page": "Thank you for visiting our page! This contest is now closed.",
	"participate_other_giveaways": "Please visit (link to ThumbsUp’s Contests page) to participate in other giveaways",
	"winner_list": "ବିଜେତାଙ୍କ ତାଲିକା",
	"full_name_error": "Please enter full name",
	"name_error": "ଦୟାକରି ଏକ ବୈଧ ନାମ ପ୍ରବେଶ କରନ୍ତୁ",
	"dob_error": "ଆପଣଙ୍ଗ ବୟସ ଅତିକମ୍‌ରେ 18 ବର୍ଷ ହୋଇଥିବା ଉଚିତ, ଦୟାକରି ସଠିକ୍ ଜନ୍ମ ତାରିଖ ପ୍ରବେଶ କରନ୍ତୁ।",
	"passport_expiry_date_error_required": "Please enter a valid passport expiry date",
	"passport_expiry_date_error": "Passport expiry date field must be a date after today.",
	"passport_no_error": "Please enter a valid Passport number",
	"terms_agree_error": "ଦୟାକରି ନିୟମ ଏବଂ ସର୍ତ୍ତାବଳୀ ପାଇଁ ସହମତ ହୁଅନ୍ତୁ",
	"email_error": "ଦୟାକରି ଏକ ବୈଧ ଇମେଲ୍ ID ପ୍ରବେଶ କରନ୍ତୁ",
	"how_to_participate": "କିପରି ଅଂଶଗ୍ରହଣ କରିବେ ",
	"toofani_champions": "ତୁଫାନି ଚାମ୍ପିଅନ୍ସ",
	"refer_friend": "ରେଫର୍-ଏ-ଫ୍ରେଣ୍ଡ",
	"have_invite_code": "ଇନଭାଇଟ୍\u200C କୋଡ୍ ଅଛି କି?",
	"enter_collect_kms": "ପ୍ରବେଶ କରନ୍ତୁ ବଂ100 କିଲୋମିଟର ସଂଗ୍ରହ କରନ୍ତୁ",
	"contact_us": "ଆମ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ ",
	"prize_grab": "ଏହି ପୁରସ୍କାର ଜିତିବେ",
	"seat_aboard_toofan": "ତୁଫାନ୍ ରେ ସିଟ୍ କରନ୍ତୁ \nଦୈନିକ 1 ବିଜେତା*",
	"cricket_jersey_999_fancode": "ଫ୍ୟାନକୋଡରୁ ₹ 999 ମୂଲ୍ୟର କ୍ରିକେଟ୍ ଜର୍ସି \nପ୍ରତି ଘଣ୍ଟାରେ 1 ବିଜେତା *\n",
	"toofani_999_fancode": "ଫାନକୋଡରୁ ₹499 ମୂଲ୍ୟର ତୁଫାନି ମର୍ଚ୍ଚେଣ୍ଡାଇଜ୍\u200C\nପ୍ରତି ଘଣ୍ଟାରେ 15 ବିଜେତା *",
	"find_unique_code_pack": "ପ୍ୟାକ୍ ଉପରେ ୟୁନିକ୍\u200C କୋଡ୍ ଖୋଜନ୍ତୁ",
	"enter_code_collect_kms": "କିଲୋମିଟର୍\u200C ସଂଗ୍ରହ କରିବାକୁ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ ",
	"complete_journey_destination": "ଗନ୍ତବ୍ୟ ସ୍ଥଳକୁ ସମ୍ପୂର୍ଣ୍ଣ ଯାତ୍ରା ",
	"get_chance_win": "ଜିତିବାର ସୁଯୋଗ ପାଆନ୍ତୁ *!",
	"refer_friend_collect_kms": "ଅତିରିକ୍ତ କିଲୋମିଟର୍\u200C ସଂଗ୍ରହ କରିବାକୁ ଏକ-ବନ୍ଧୁଙ୍କୁ ରେଫର୍\u200C କରନ୍ତୁ *",
	"get_100km_mate_signup": "ସଫଳତାର ସହ ସାଇନ୍ ଅପ୍ କରୁଥିବା ଏବଂ ଆପଣଙ୍କର ଇନଭାଇଟ୍\u200C କୋଡ୍ ବ୍ୟବହାର କରୁଥିବା ପ୍ରତ୍ୟେକ ସାଥୀ ପାଇଁ 100 କିଲୋମିଟର ପାଆନ୍ତୁ",
	"refer_now": "ଏବେ ରେଫର୍‌ କରନ୍ତୁ",
	"tnc_apply": "ସର୍ତ୍ତାବଳୀ ଏବଂ ସର୍ତ୍ତଗୁଡିକ ଲାଗୁ ହେଉଛି",
	"available": "ଉପଲବ୍ଧ",
	"booked": "ବୁକିଂ ସରିଯାଇଛି",
	"going_soon": "ଅତିଶୀଘ୍ର ସରିବାକୁ ଯାଉଛି",
	"you_made_toofani_fan_club": "ଆପଣ ତୁଫାନି ଫ୍ୟାନ୍ କ୍ଲବ୍‌ରେ ନିଜର ସ୍ଥାନ ନିଶ୍ଚିତ କରିଛନ୍ତି!",
	"won_seat_get_ready": "ଆପଣ ତୁଫାନରେ ଏକ ସିଟ୍ ଜିତି ନେଇଛନ୍ତି! ୱେଷ୍ଟଇଣ୍ଡିଜ ପାଇଁ ଷ୍ଟାଇଲ୍‌ରେ ଉଡ଼ିବାକୁ ପ୍ରସ୍ତୁତ ହୋଇଯାଆନ୍ତୁ",
	"jersey_won_text": "ଜଣେ ପ୍ରୋ ଭଳି ହୁଅନ୍ତୁ",
	"jersey_won_subtext": "999 ଟଙ୍କା ମୂଲ୍ୟର ଆପଣଙ୍କର କ୍ରିକେଟ୍ ଜର୍ସି କ୍ଲେମ୍\u200C କରିବାକୁ ଏଠାରେ ଏକ ଫ୍ୟାନକୋଡ୍ ଭାଉଚର୍ ରହିଛି",
	"redeem_now": "ଏବେ ରିଡିମ୍\u200C କରନ୍ତୁ",
	"voucher_won_text": "ଟିମ୍\u200C ସ୍ପିରିଟ୍\u200C ଆଣନ୍ତୁ",
	"voucher_won_subtext": "499 ଟଙ୍କାର ଆପଣଙ୍କର କ୍ରିକେଟ୍ ମର୍ଚ୍ଚେଣ୍ଡାଇଜ୍\u200Cର କ୍ଲେମ୍ କରିବାକୁ ଏଠାରେ ଏକ ଫ୍ୟାନ୍\u200C କୋଡ୍\u200C ଭାଉଚର୍ ରହିଛି",
	"my_wins": "ମୋର ବିଜୟ",
	"optional": " (ବୈକଳ୍ପିକ)",
	"sign_up_cta": "ସାଇନ୍ ଅପ୍ କରନ୍ତୁ",
	"Please enter a valid OTP": "ଦୟାକରି ଏକ ବୈଧ ଓଟିପି ପ୍ରବେଶ କରନ୍ତୁ",
	"enter_collect_100kms": "ପ୍ରବେଶ କରନ୍ତୁ ବଂ100 କିଲୋମିଟର ସଂଗ୍ରହ କରନ୍ତୁ",
	"unique_code_entry_text": "ୱେଷ୍ଟଇଣ୍ଡିଜରେ ଆଇସିସି ପୁରୁଷ ଟି -20 ବିଶ୍ୱକପ 2024 ଗେମ୍ସ ପାଇଁ ତୁଫାନ୍\u200C ସହିତ ଉଡ଼ିବାର ଏକ ସୁଯୋଗ ପାଆନ୍ତୁ *",
	"unique_code_entry_subtext": "ଏକ ଥମ୍ସ ଅପ୍ ବୋତଲର ଲେବଲ୍ ପଛରେ ୟୁନିକ୍\u200C କୋଡ୍ ଖୋଜନ୍ତୁ",
	"mobile_number_short": "ମୋବାଇଲ୍ ନମ୍ୱର",
	"welcome_player": "ସ୍ୱାଗତ, ଖେଳାଳୀ!",
	"login_start_winning": "ଜିତିବା ଆରମ୍ଭ କରନ୍ତୁ!",
	"trademark_text": "କାର୍ବୋନେଟେଡ୍ ୱାଟର୍\u200C. ଟ୍ରେଡମାର୍କ ମାଲିକ: କୋକା-କୋଲା କମ୍ପାନୀ। ",
	"address": "ଠିକଣା",
	"address_line_1": "ଠିକଣା 1",
	"address_line_2": "ଠିକଣା 2",
	"nearest_landmark": "ନିକଟସ୍ଥ ଲ୍ୟାଣ୍ଡମାର୍କ ",
	"alternative_mobile_number": "ବିକଳ୍ପ ମୋବାଇଲ୍ ନମ୍ବର",
	"pincode": "ପିନ୍\u200Cକୋଡ୍",
	"state": "ରାଜ୍ୟ",
	"city": "ସହର",
	"state_error": "ଦୟାକରି ରାଜ୍ୟ ଚୟନ କରନ୍ତୁ",
	"city_error": "ଦୟାକରି ସହର ଚୟନ କରନ୍ତୁ",
	"pincode_error": "ଦୟାକରି ଏକ ବୈଧ ପିନକୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ ",
	"address_error": "ଦୟାକରି ଏକ ବୈଧ ଠିକଣା ପ୍ରବେଶ କରନ୍ତୁ ",
	"pan_error": "ଦୟାକରି ଏକ ବୈଧ ପାନ୍ କାର୍ଡ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
	"copy_code": "କୋଡ୍ କପି କରନ୍ତୁ",
	"last_date_redeem": "ରିଡିମ୍\u200C କରିବାର ଶେଷ ତାରିଖ",
	"last_date_to_claim": "କ୍ଲେମ୍\u200C କରିବାର ଶେଷ ତାରିଖ",
	"share_with_friends": "ବନ୍ଧୁଙ୍କ ସହିତ ସେୟାର୍\u200C କରନ୍ତୁ",
	"no_internet_message": "କନେକ୍ଟ କରିବାକୁ ଅସମର୍ଥ। ଦୟାକରି ଆପଣଙ୍କର ନେଟୱର୍କ କନେକ୍ସନ୍\u200C ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ!",
	"email_us": "ଆମକୁ ଇମେଲ୍\u200C କରନ୍ତୁ",
	"call_toll_free": "ଟୋଲ୍\u200C ଫ୍ରି ନମ୍ୱର୍\u200Cରେ ଆମକୁ କଲ୍\u200C କରନ୍ତୁ",
	"mon_sat": "ସୋମବାର-ଶନିବାର",
	"sunday": "ରବିବାର",
	"follow_us_now": "ଏବେ ଆମକୁ ଅନୁସରଣ କରନ୍ତୁ",
	"otp_expired_enter_new": "ଆପଣଙ୍କର ଓଟିପିର ସମୟସୀମା ସଂପୂର୍ଣ୍ଣ ହୋଇଛି। ଦୟାକରି ଏକ ନୂତନ ବ୍ୟବହାର କରନ୍ତୁ",
	"email_address": "ଇମେଲ୍ ଆଡ୍ରେସ୍\u200C",
	"Code has already been used, please enter a new code": "କୋଡ୍ ପୂର୍ବରୁ ବ୍ୟବହୃତ ହୋଇସାରିଛି, ଦୟାକରି ଏକ ନୂତନ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
	"first": "ପ୍ରଥମେ",
	"last": "ଶେଷର",
	"add": "ଯୋଡନ୍ତୁ",
	"Seat on Toofan": "ତୁଫାନ୍\u200Cରେ ସିଟ୍\u200C",
	"Toofani Merch": "ତୁଫାନୀ ମର୍ଚ୍ଚେଣ୍ଡାଇଜ୍\u200C",
	"Cricket Jersey": "କ୍ରିକେଟ୍\u200C ଜର୍ସୀ",
	"You are here": "ଆପଣ ଏଇଠି ଅଛନ୍ତି",
	"OTP resent successfully": "ଓଟିପି କୁ ସଫଳତାର ସହିତ ପୁଣିଥରେ ପଠାଗଲା.",
	"No winners till now": "ଏବେ ପର୍ଯ୍ୟନ୍ତ କୌଣସି ବିଜେତା ନାହାଁନ୍ତି",
	"COPIED": "କପି କରାଯାଇଛି",
	"T&C": "ଅପ-ଟୁ-ଡେଟ୍ ସର୍ତ୍ତାବଳୀ ଏବଂ ସର୍ତ୍ତଗୁଡିକ",
	"Email is already in use": "ଇମେଲ ପୂର୍ବରୁ ବ୍ୟବହାର କରାଯାଉଛି",
	"Clear": "ଖାଲି କରନ୍ତୁ",
	"West Indies": "ୱେଷ୍ଟଇଣ୍ଡିଜ୍",
	"India": "ଭାରତ",
	"Reminder send successfully": "ସଫଳତାର ସହ ପ୍ରେରଣ ହୋଇଥିବା ସ୍ମାରକପତ୍ର",
	"KM": "କିମି",
	"KMs": "କିମି",
	"Edit Address": "Edit Address",
	"Please enter a valid code": "ଦୟାକରି ଏକ ବୈଧ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
	"final_day_offer": "ଅଫର ଅବଧିର ଶେଷ ଦିନ କୁ ୩୦ ଏପ୍ରିଲ ୨୦୨୪ରୁ ୧୫ ମେ ୨୦୨୪ ପର୍ଯ୍ୟନ୍ତ ବୃଦ୍ଧି କରାଯାଇଛି । <tnc>ନିୟମ ାବଳୀ ଏବଂ ସର୍ତ୍ତାବଳୀ ପ୍ରଯୁଜ୍ୟ</tnc>"
}
