import { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BottleUniqueCodeIcon from '../../public/bottle_unique_code_v2.svg';
import Input from './Input';
import Button from './Button';
import InputErrorMessage from './InputErrorMessage';
import { useAuthContext } from '../context/auth-context';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import WarningIcon from '../../public/warn_icon.svg';
import PointsFlightIcon from '../../public/points_win_flight_icon.svg';
import SmileIcon from '../../public/smile_icon_emoji.svg';
import FlightWinIcon from '../../public/flight_win_check.svg';
import { useCampaignContext } from '../context/campaign-context';
import Typography from './Typography';
import { useNavigate } from 'react-router-dom';
import BottleAnimation from '../../public/bottle_animation.gif';
import { gaEvents } from '../utils/gaEvents';
import { useUserContext } from '../context/user-context';
import { triggerTrackingPixel, insertFloodlightTag } from '../utils/common';

const UniqueCodeForm = ({ open, handleOpen, type = 'popup' }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [isWinStatus, setIsWinStatus] = useState(false);
	const [myKms, setMyKms] = useState(false);
	const { userData } = useUserContext();
	const { token, isLoggedIn, logout } = useAuthContext();
	const {
		otherStates,
		setOtherStates,
		thresholdPoint,
		setTotalPoints,
		userInfoPopup,
		setUserInfoPopup,
		setPopupInfo,
		checkWinStatus,
		setIsWin,
		giftInfo,
		setFlightMileStone,
		flightMileStone,
		totalPoints,
		setUserUniqueCodeWinPopup,
		userUniqueCodeWinPopup,
		setWinPopupInfo,
		setOpenLoginForm,
		openLoginForm,
	} = useCampaignContext();
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const checkWonUser = () => {
		setUserUniqueCodeWinPopup(!userUniqueCodeWinPopup);
		setWinPopupInfo({
			giftInfo,
			handleOpenHandler: handleOpenHandlerRedirect,
		});
	};
	useEffect(() => {
		let autoPopupClose;
		autoPopupClose = () =>
			setTimeout(() => {
				setUserInfoPopup(false);
				setOtherStates({ ...otherStates, scrollToCloserAction: true });
				navigate('/');
			}, 4000);
		myKms && autoPopupClose();

		isWinStatus &&
			checkWinStatus(
				token,
				`${process.env.API_BASE_URL}win/status`,
				handleOpenHandler,
				handleOpenHandlerRedirect,
				claimNow
			);
		if (
			thresholdPoint > 0 &&
			flightMileStone >= 3 &&
			totalPoints >= thresholdPoint
		) {
			try {
				if (!giftInfo.win_code) {
					handleOpen && handleOpen();
					setUserInfoPopup(!userInfoPopup);
					setPopupInfo({
						type: 'qualified_for_lucky_draw',
						imgSrc: SmileIcon,
						staticMessage: true,
						handleOpenHandler: handleOpenHandlerRedirect,
					});
				} else if (giftInfo.win_code) {
					handleOpen && handleOpen();
					setUserInfoPopup(!userInfoPopup);
					setPopupInfo({
						type: 'already_win',
						imgSrc: WarningIcon,
						staticMessage: true,
						handleOpenHandler: handleOpenHandlerRedirect,
					});
				}
			} catch (e) {
				console.warn(e);
			}
		}

		return () => {
			autoPopupClose && clearTimeout(autoPopupClose);
		};
	}, [isWinStatus, token, myKms]);

	const yupSchema = useCallback(
		yup.object({
			unique_code: yup
				.string()
				.required(t('Please enter a valid unique code'))
				.matches(/^[a-zA-Z0-9]*$/, t('Please enter a valid unique code')),
			// .min(10, t('mobile_error_message')),
		}),
		[]
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	useEffect(() => {
		let enterCodeEvent = false;

		if (watch('unique_code') && !enterCodeEvent) {
			// Floodlight tags

			insertFloodlightTag({
				activityName: 'ThumsUp_ICC24_EnterUnique_Counter',
				imageTag: 'thums043', // Specific image tag for HomePage
			});
			if (typeof fbq !== 'undefined') {
				fbq('trackCustom', 'ICC24Enteruniquecode');
			} else {
				console.warn('FB Pixel not initialized.');
			}
			enterCodeEvent = true;
		}
	}, [watch('unique_code')]);

	const handleOpenHandlerRedirect = () => {
		setUserInfoPopup(false);
		setUserUniqueCodeWinPopup(false);
		navigate('/profile');
	};
	const handleOpenHandler = () => {
		setUserInfoPopup(false);
		navigate('/');
	};
	const claimNow = () => {
		setUserInfoPopup(false);
		navigate('/claim-form');
	};
	const submitHandler = async (data) => {
		//Pixel Code
		triggerTrackingPixel(
			'https://insight.adsrvr.org/track/pxl/?adv=2xop800&ct=0:tf3mqfj&fmt=3'
		);
		// Floodlight tags

		insertFloodlightTag({
			activityName: 'ThumsUp_ICC24_UniqueSubmit_Counter',
			imageTag: 'thums044', // Specific image tag for HomePage
		});
		if (typeof fbq !== 'undefined') {
			fbq('trackCustom', 'ICC24submituniquecode');
		} else {
			console.warn('FB Pixel not initialized.');
		}
		if (
			isLoggedIn &&
			userData &&
			'name' in userData &&
			userData.name === null
		) {
			logout();
			handleOpen && handleOpen();
			setOpenLoginForm(!openLoginForm);
		}
		const { unique_code } = data;
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}unique-code/verify`,
				'POST',
				JSON.stringify({
					unique_code,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response?.success) {
				// GA Event
				window.dataLayer.push(gaEvents['TUICC24_UniqueCode_Submit']);

				handleOpen && handleOpen();
				let currentTotalPoints = response?.data?.totalPoint;
				setTotalPoints(currentTotalPoints);

				setUserInfoPopup(!userInfoPopup);
				setFlightMileStone(response?.data?.milestone || 0);
				let winStatus = response?.data?.isWin,
					type = 'valid_code',
					icon = PointsFlightIcon;
				if (winStatus) {
					type = 'win_check';
					icon = FlightWinIcon;
				} else {
					setMyKms(true);
				}
				setIsWinStatus(winStatus);
				setIsWin(winStatus);
				setPopupInfo({
					type: type,
					dynamicCO: {
						points: response?.data?.totalPoint,
						remaining_km: thresholdPoint - currentTotalPoints,
					},
					imgSrc: icon,
					staticMessage: true,
					hideCrossIcon: true,
				});
				reset();
			} else {
				throw new Error(response?.message);
			}
		} catch (error) {
			console.error(`Unique code API error>>>`, error);
			if (error?.error_type) {
				handleOpen && handleOpen();
				setUserInfoPopup(!userInfoPopup);
				setPopupInfo({
					type: error.error_type,
					imgSrc: WarningIcon,
					staticMessage: true,
					handleOpenHandler: handleOpenHandlerRedirect,
				});
				reset();
			}
		}
	};

	let formView;

	if (type === 'popup') {
		formView = (
			<div className="flex flex-col justify-between items-center mb-10 overflow-y-scroll  h-full no-scrollbar">
				{isLoading && <Loader />}
				<div className="w-22 h-48 mb-2">
					<img src={BottleAnimation} className="h-full w-full" />
				</div>

				{/* <Typography
					variant="h4"
					type="text28"
					text="Get a Chance to Fly Aboard Toofan to the ICC Men’s T20 World Cup 2024 Games in the West Indies*"
					customClasses="text-pageTitleColor text-center mb-1 text-2xxl"
				/> */}
				<h4 className="text-2xxl leading-8 font-bold text-pageTitleColor font-thumbsUp text-center mb-1">
					{`${t('unique_code_entry_text')}*`}
				</h4>
				{/* <Typography
					variant="h4"
					type="text20"
					text="Find the unique code behind the label of a Thums Up® bottle"
					customClasses=" text-center mb-3 font-bold"
				/> */}
				<h4 className="text-xl leading-normal text-black font-thumbsUp lg:text-3xl text-center mb-3 font-bold w-3/4">
					{t('unique_code_entry_subtext')}
				</h4>
				{/*<button onClick={checkWonUser}>Test Fancode Popup</button>*/}

			</div>
		);
	} else {
		formView = (
			<></>
		);
	}

	return (
		<>
			{formView}

		</>
	);
};

export default UniqueCodeForm;
