{
	"winner_list_h1": "तुफानी चॅंपियन्स",
	"avatar": "अवतार",
	"name": "नाव",
	"phone_number": "फोन क्रमांक",
	"prize": "बक्षीस",
	"select_date": "तारीख निवडा",
	"record_not_found": "Record not found",
	"win_1": "वेस्ट इंडीजमधील आयसीसी मेन्स टी20 वर्ल्ड कप 2024 गेम्ससाठीच्या तुफानी जहाजातून उड्डाण करण्याची संधी मिळवा*",
	"win_img_1": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/flight.jpg",
	"win_2": "मी नुकतीच वेस्ट इंडीजमधील आयसीसी मेन्स टी20 वर्ल्ड कप 2024 च्या प्रमोशन मध्ये फॅन कोडकडून एक क्रिकेट जर्सी जिंकली आहे*",
	"win_img_2": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/jercy.jpg",
	"win_3": "मी नुकतीच वेस्ट इंडीजमधील आयसीसी मेन्स टी20 वर्ल्ड कप 2024 च्या प्रमोशन मध्ये फॅन कोडकडून एक क्रिकेट मर्च जिंकली आहे*",
	"win_img_3": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/voucher.jpg",
	"one_more_check": "फक्त आणखी एक तपासणी!",
	"one_more_check_text": "तुम्ही सबमिट केलेल्या तपशीलांची आम्ही पडताळणी करत आहोत आणि 10 व्यावसायिक दिवसांच्या आत आम्ही तुफानातील तुमच्या सफरीच्या पात्रतेबद्दल तुम्हाला या साईटवर वा त्याचबरोबर एसएमएस किंवा व्हॉट्सअपच्या माध्यमातून सूचीत करू. प्रतीक्षा करा!",
	"win_heading": "Congratulations!",
	"win_1_success_verified": "तुफान जहाजामधील तुमचे सीट निश्चित झाले आहे आणि तुम्ही वेस्ट इंडीजला जाणार आहात! आम्ही एसएमएस / व्हॉट्सअप किंवा फोन कॉलच्या माध्यमातून पुढील पायऱ्यांसह तुमच्यापर्यंत पोहोचू. तुमच्या मित्रांना तुमच्या विलक्षण विजयाबद्दल सांगा!",
	"win_1_success": "तुफान जहाजामधील तुमचे सीट निश्चित झाले आहे आणि तुम्ही वेस्ट इंडीजला जाणार आहात! आम्ही एसएमएस / व्हॉट्सअप किंवा फोन कॉलच्या माध्यमातून पुढील पायऱ्यांसह तुमच्यापर्यंत पोहोचू. तुमच्या मित्रांना तुमच्या विलक्षण विजयाबद्दल सांगा!",
	"win_2_success": "You’ve claimed your Cricket Jersey! We will communicate further details via email & SMS shortly.",
	"win_3_success": "You’ve claimed your Toofani Merch! We will communicate further details via email & SMS shortly."
}
