{
	"home": "ಹೋಮ್",
	"login": "ಲಾಗಿನ್ ಮಾಡಿ",
	"mobile_number": "ಮೊಬೈಲ್ ಸಂಖ್ಯೆ",
	"mobile_number_label": "ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
	"get_otp": "Get OTP",
	"mobile_error_message": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
	"resendOTP": "ಒಟಿಪಿ ಅನ್ನು ಮರುಕಳುಹಿಸಿ",
	"otp_verification": "ಒಟಿಪಿ ಪರಿಶೀಲನೆ",
	"otp_verification_sub_text": "ನಾವು +91 {{mobile_number}} ಗೆ ಕಳುಹಿಸಿದ 6-ಅಂಕಿಯ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
	"try_again_text": "{{time_remaining}} ಸೆಕೆಂಡುಗಳಲ್ಲಿ ಒಟಿಪಿ ಅನ್ನು ಮರುಕಳುಹಿಸಿ",
	"btn_submit": "ಸಲ್ಲಿಸಿ",
	"sign_up": "ನೀವು ಬಹುತೇಕ ಹತ್ತಿರದಲ್ಲಿದ್ದೀರಿ!",
	"enter_detail_text": "ಆಡುವ ತಂಡಕ್ಕೆ ಸೇರಲು ಸೈನ್ ಅಪ್ ಮಾಡಿ!",
	"full_name": "ಹೆಸರು",
	"name": "ಹೆಸರು",
	"email_id": "ಇಮೇಲ್ ಐಡಿ",
	"terms_text": "ನಾನು ಕನಿಷ್ಟ 18 ವರ್ಷ ವಯಸ್ಸಿನವನಾಗಿದ್ದೇನೆ ಮತ್ತು <tnc>ನಿಯಮ &ಷರತ್ತುಗಳು</tnc> ಮತ್ತು <pp>ಗೌಪ್ಯತಾ ನೀತಿಯನ್ನು</pp> ಓದಿದ್ದೇನೆ ಎಂದು ನಾನು ಪ್ರಮಾಣೀಕರಿಸುತ್ತೇನೆ.",
	"participate_win": "Participate & Win",
	"winners_list": "Winners List",
	"faqs": "ಫ್ಯಾಕ್‌ಗಳು",
	"terms_conditions": "ನವೀಕೃತ ನಿಯಮ ಮತ್ತು ಷರತ್ತುಗಳು",
	"privacy_policy": "ಗೌಪ್ಯತಾ ನೀತಿ",
	"logout": "ಲಾಗ್‌ ಔಟ್",
	"participate_now": "ಈಗ ಭಾಗವಹಿಸಿ",
	"winner_board": "Winner Board",
	"enter_unique_code": "ಯುನಿಕ್‌ ಕೋಡ್‌ ನಮೂದಿಸಿ",
	"winners": "Winners",
	"enter_code": "ಕೋಡ್‌ ನಮೂದಿಸಿ",
	"profile": "ಪ್ರೊಫೈಲ್",
	"get_aboard_text": "ತೂಫಾನ್ ಮೇಲೆ ನಿಮ್ಮ ಆಸನವನ್ನು ಹಿಡಿದುಕೊಳ್ಳಿ",
	"btn_continue": "ಮುಂದುವರಿಸಿ",
	"get_aboard_sub_text": "ಜೀವನದಲ್ಲಿ ಒಮ್ಮೆಯಾದರೂ-ಈ ಪ್ರಯಾಣದಲ್ಲಿ ನಮ್ಮೊಂದಿಗೆ ಸೇರಿ - ಇದು ವೆಸ್ಟ್ ಇಂಡೀಸ್‌ನಲ್ಲಿ ನಡೆಯಲಿರುವ ಐಸಿಸಿ ಟಿ20 ವಿಶ್ವಕಪ್ ಗೇಮ್‌ಗೆ ನಮ್ಮ ಎಲ್ಲಾ ತೂಫಾನಿ ಅಭಿಮಾನಿಗಳನ್ನು ವಿಶೇಷವಾಗಿ ಕ್ಯುರೇಟೆಡ್ ವಿಮಾನದಲ್ಲಿ ಕರೆದುಕೊಂಡು ಹೋಗಲೆಂದಿರುವ ಐಷಾರಾಮಿ ಅನುಭವವಾಗಿದೆ.",
	"win_mileage_text": "ಕ್ರಿಯೆಗೆ ಹತ್ತಿರವಾಗಿ",
	"win_mileage_sub_text": "ಪ್ರತಿ Thums UP® ಜೊತೆಗೆ",
	"my_kms": "ನನ್ನ ಕೆಎಮ್‌ಗಳು",
	"follow_us": "ನಮ್ಮನ್ನು ಫಾಲೋವ್‌ ಮಾಡಿ",
	"copyright_text": "© 2024 The Coca‑Cola Company. ಎಲ್ಲಾ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ.",
	"Please enter a valid unique code": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
	"your_profile": "ನನ್ನ ಪ್ರೊಫೈಲ್",
	"profile_page_desc": "ವಿಜೇತರನ್ನು ಸಂಪರ್ಕಿಸಲು ನಾವು ಕೆಳಗಿನ ವಿವರಗಳನ್ನು ಬಳಸುತ್ತೇವೆ. ಒದಗಿಸಿದ ಎಲ್ಲಾ ಮಾಹಿತಿಯು ಸರಿಯಾಗಿದೆಯೇ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ",
	"dob": "ಜನ್ಮ ದಿನಾಂಕ",
	"gender": "ಲಿಂಗ",
	"set_as_default": "ಪೂರ್ವನಿಯೋಜಿತವಾಗಿಡು",
	"congratulations": "ಅಭಿನಂದನೆಗಳು",
	"flight_ticket_claimed_text": "You’ve claimed your ticket to the West Indies! We will communicate further details via email & SMS shortly.",
	"share": "ಹಂಚಿಕೊಳ್ಳಿ",
	"claim_form_desc": "ನಿಮ್ಮ ಬಹುಮಾನವನ್ನು ಪಡೆಯಲು ದಯವಿಟ್ಟು ಕೆಳಗಿನ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ!",
	"full_name_claim_form": "ಪೂರ್ಣ ಹೆಸರು (ಸರ್ಕಾರಿ ಐಡಿ ಪ್ರಕಾರ)",
	"pan_card": "ಪ್ಯಾನ್ ಕಾರ್ಡ್",
	"passport_no": "ಪಾಸ್‌ಪೋರ್ಟ್ ಸಂಖ್ಯೆ",
	"passport_expiry_date": "ಪಾಸ್‌ಪೋರ್ಟ್ ಮುಕ್ತಾಯ ದಿನಾಂಕ",
	"Male": "ಪುರುಷ",
	"Female": "ಮಹಿಳೆ",
	"transgender": "Transgender",
	"Other": "ಇತರೆ",
	"gift_voucher_claimed_text": "You’ve claimed your gift voucher! We will communicate further details via email & SMS shortly.",
	"claim_now": "ಈಗ ಕ್ಲೈಮ್ ಮಾಡಿ",
	"paytm_1000_won_text": "You’ve won yourself a ₹1,000 Paytm voucher!",
	"paytm_1000_win_text": "Win a ₹1000 Paytm Voucher!",
	"paytm_100_won_text": "You’ve won yourself a ₹1,00 Paytm voucher!",
	"paytm_100_win_text": "Win a ₹100 Paytm Voucher!",
	"win_ticket": "Win a Ticket",
	"enter_unique_code_reach_kms": "Enter unique code to reach 14K KMs",
	"enter_name": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
	"enter_mobile_number": "Please enter mobile number",
	"enter_email": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಇಮೇಲ್ ಐಡಿಯನ್ನು ನಮೂದಿಸಿ",
	"enter_pan": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ‌ ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
	"no_page_found": "ಯಾವುದೇ ಪುಟ ಕಂಡುಬಂದಿಲ್ಲ",
	"go_to_home": "ಹೋಮ್‌ ಪುಟಕ್ಕೆ ಹೋಗಿ",
	"eligible_1000_paytm_voucher": "You are now eligible for ₹1000 Paytm Gift Voucher",
	"win_prize_lucky_draw_daily": "Win Prizes in a lucky Draw Daily",
	"ticket_to_west_indies": "Ticket to West Indies",
	"ticket_to_west_indies_v1": "ನೀವು ಇದನ್ನು ತೂಫಾನಿ ಫ್ಯಾನ್ ಕ್ಲಬ್‌ಗೆ ಸೇರಿಸಿದ್ದೀರಿ!",
	"ticket_to_west_indies_subtext_v1": "ನೀವು ತೂಫಾನ್‌ನಲ್ಲಿ ಆಸನವನ್ನು ಗೆದ್ದಿದ್ದೀರಿ! ವೆಸ್ಟ್ ಇಂಡೀಸ್‌ಗೆ ಜೋಷ್‌ ಆಗಿ ಹಾರಲು ಸಿದ್ಧರಾಗಿ",
	"fan_jersy_heading": "ಸೂಟ್ ಅಪ್ ಲೈಕ್‌ ಅ ಪ್ರೊ",
	"fan_jersy_heading_text": "999 ರೂ. ಮೌಲ್ಯದ ನಿಮ್ಮ ಕ್ರಿಕೆಟ್ ಜರ್ಸಿಯನ್ನು ಕ್ಲೈಮ್ ಮಾಡಲು ಫ್ಯಾನ್‌ಕೋಡ್ ವೋಚರ್ ಇಲ್ಲಿದೆ.",
	"how_enter_lucky_draw": "How to Enter the Lucky Draw",
	"step_1": "ಹಂತ 1",
	"step_2": "ಹಂತ 2",
	"step_3": "ಹಂತ 3",
	"step_4": "ಹಂತ 4",
	"scan_qr_code_bottle": "Scan the QR code from your ThumbsUp bottle",
	"enter_code_kms_achieve_three_code": "Enter the unique code to accrue 14,000kms with 3 codes max",
	"enter_lucky_draw_win_prize": "Enter the lucky draw to win prizes daily",
	"paytm_gift_voucher": "Paytm gift Voucher",
	"faq_heading": "ಆಗಾಗ್ಗೆ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು",
	"faq_subtext": "ಥಮ್ಸ್ ಅಪ್ ಬಗ್ಗೆ ನೀವು ತಿಳಿದುಕೊಳ್ಳಬೇಕಾದ ಎಲ್ಲವೂ ಇಲ್ಲಿದೆ",
	"terms_heading": "Terms & Conditions",
	"contest_over": "Contest Over",
	"thank_you_visit_page": "Thank you for visiting our page! This contest is now closed.",
	"participate_other_giveaways": "Please visit (link to ThumbsUp’s Contests page) to participate in other giveaways",
	"winner_list": "Winner List",
	"full_name_error": "Please enter full name",
	"name_error": "8ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
	"dob_error": "ನೀವು ಕನಿಷ್ಟ 18 ವರ್ಷ ವಯಸ್ಸಿನವರಾಗಿರಬೇಕು, ದಯವಿಟ್ಟು ಸರಿಯಾದ ಜನ್ಮ ದಿನಾಂಕವನ್ನು ನಮೂದಿಸಿ.",
	"passport_expiry_date_error_required": "Please enter a valid passport expiry date",
	"passport_expiry_date_error": "Passport expiry date field must be a date after today.",
	"passport_no_error": "Please enter a valid Passport number",
	"terms_agree_error": "ದಯವಿಟ್ಟು ನಿಯಮಗಳು & ಷರತ್ತುಗಳಿಗೆ ಒಪ್ಪಿಗೆ ನೀಡಿ",
	"email_error": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಇಮೇಲ್ ಐಡಿಯನ್ನು ನಮೂದಿಸಿ",
	"how_to_participate": "ಹೇಗೆ ಭಾಗವಹಿಸುವುದು",
	"toofani_champions": "ತೂಫಾನಿ ಚಾಂಪಿಯನ್‌ಗಳು",
	"refer_friend": "ಒಬ್ಬ ಸ್ನೇಹಿತನನ್ನು ರೆಫರ್‌ ಮಾಡಿ",
	"have_invite_code": "ಇನ್ವೈಟ್‌ ಕೋಡ್‌ ಹೊಂದಿದ್ದೀರಾ?",
	"enter_collect_kms": "ನಮೂದಿಸಿ & 100 ಕಿ.ಮೀಗಳನ್ನು ಸಂಗ್ರಹಿಸಿ",
	"contact_us": "ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
	"prize_grab": "ಈ ಬಹುಮಾನಗಳನ್ನು ಗೆಲ್ಲಿರಿ",
	"seat_aboard_toofan": "ತೂಫಾನ್ ಹಡಗಿನಲ್ಲಿ ಕುಳಿತುಕೊಳ್ಳಿ \nಪ್ರತಿದಿನ 1 ವಿಜೇತರು*",
	"cricket_jersey_999_fancode": "ಫ್ಯಾನ್‌ಕೋಡ್‌ನಿಂದ ₹999 ಮೌಲ್ಯದ ಕ್ರಿಕೆಟ್‌ ಜೆರ್ಸಿ \nಪ್ರತಿ ಘಂಟೆಗೆ 1 ವಿಜೇತ*",
	"toofani_999_fancode": "ಫ್ಯಾನ್‌ಕೋಡ್‌ನಿಂದ ₹499 ಮೌಲ್ಯದ ತೂಫಾನಿ ಮೆರ್ಚ್ \nಪ್ರತಿ ಘಂಟೆಗೆ 15 ವಿಜೇತರು *",
	"find_unique_code_pack": " ಪ್ಯಾಕ್‌ನಲ್ಲಿ ಯುನಿಕ್ ಕೋಡ್ ಅನ್ನು ಹುಡುಕಿ",
	"enter_code_collect_kms": "ಕೆಎಮ್‌ಗಳನ್ನು ಸಂಗ್ರಹಿಸಲು ಕೋಡ್ ನಮೂದಿಸಿ",
	"complete_journey_destination": "ಗಮ್ಯಸ್ಥಾನಕ್ಕೆ ಸಂಪೂರ್ಣ ಪ್ರಯಾಣ",
	"get_chance_win": "ಗೆಲ್ಲಲು ಅವಕಾಶ ಪಡೆಯಿರಿ*!",
	"refer_friend_collect_kms": "ಹೆಚ್ಚುವರಿ ಕೆಎಮ್‌ಗಳನ್ನು ಸಂಗ್ರಹಿಸಲು ಸ್ನೇಹಿತನನ್ನು ರೆಫರ್‌ ಮಾಡಿ*",
	"get_100km_mate_signup": "ನಿಮ್ಮ ಸ್ನೇಹಿತರು ಯಶಸ್ವಿಯಾಗಿ ಸೈನ್ ಅಪ್ ಮಾಡಿ ಮತ್ತು ನಿಮ್ಮ ಇನ್ವೈಟ್ ಕೋಡ್ ಬಳಸುವ ಮೂಲಕ 100 ಕೆಎಮ್‌ ಪಡೆಯಿರಿ.",
	"refer_now": "ರೆಫರ್‌ ಮಾಡಿ",
	"tnc_apply": "ನಿಯಮ & ಷರತ್ತುಗಳು ಅನ್ವಯ",
	"available": "ಲಭ್ಯವಿದೆ",
	"booked": "ಬುಕ್‌ ಮಾಡಲಾಗಿದೆ",
	"going_soon": "ಶೀಘ್ರದಲ್ಲೇ ಹೋಗುತ್ತಿದೆ",
	"you_made_toofani_fan_club": "ನೀವು ಇದನ್ನು ತೂಫಾನಿ ಫ್ಯಾನ್ ಕ್ಲಬ್‌ಗೆ ಸೇರಿಸಿದ್ದೀರಿ!",
	"won_seat_get_ready": "ನೀವು ತೂಫಾನ್‌ನಲ್ಲಿ ಆಸನವನ್ನು ಗೆದ್ದಿದ್ದೀರಿ! ವೆಸ್ಟ್ ಇಂಡೀಸ್‌ಗೆ ಜೋಷ್‌ ಆಗಿ ಹಾರಲು ಸಿದ್ಧರಾಗಿ",
	"jersey_won_text": "ಸೂಟ್ ಅಪ್ ಲೈಕ್‌ ಅ ಪ್ರೊ",
	"jersey_won_subtext": "999 ರೂ. ಮೌಲ್ಯದ ನಿಮ್ಮ ಕ್ರಿಕೆಟ್ ಜರ್ಸಿಯನ್ನು ಕ್ಲೈಮ್ ಮಾಡಲು ಫ್ಯಾನ್‌ಕೋಡ್ ವೋಚರ್ ಇಲ್ಲಿದೆ.",
	"redeem_now": "ಈಗ ರಿಡೀಮ್ ಮಾಡಿ",
	"voucher_won_text": "ಕೆಲವು ಟೀಮ್ ಸ್ಪಿರಿಟ್ ಅನ್ನು ಎಳೆಯಿರಿ",
	"voucher_won_subtext": "499‌ ರೂ. ಮೌಲ್ಯದ ನಿಮ್ಮ ಕ್ರಿಕೆಟ್ ಮರ್ಚ್ ಅನ್ನು ಕ್ಲೈಮ್ ಮಾಡಲು ಫ್ಯಾನ್‌ಕೋಡ್ ವೋಚರ್ ಇಲ್ಲಿದೆ.",
	"my_wins": "ನನ್ನ ಗೆಲುವುಗಳು",
	"optional": "ಐಚ್ಛಿಕ",
	"sign_up_cta": "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
	"Please enter a valid OTP": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಓಟಿಪಿ ಅನ್ನು ನಮೂದಿಸಿ",
	"enter_collect_100kms": "ನಮೂದಿಸಿ & 100 ಕಿ.ಮೀಗಳನ್ನು ಸಂಗ್ರಹಿಸಿ",
	"unique_code_entry_text": "ವೆಸ್ಟ್ ಇಂಡೀಸ್‌ನಲ್ಲಿ ಐಸಿಸಿ ಪುರುಷರ ಟಿ20 ವಿಶ್ವಕಪ್ 2024 ಗೇಮ್‌ಗಳಿಗೆ ತೂಫಾನ್‌ನಲ್ಲಿ ಹಾರಲು ಅವಕಾಶವನ್ನು ಪಡೆಯಿರಿ",
	"unique_code_entry_subtext": "Thums Up® ಬಾಟಲ್‌ನ ಲೇಬಲ್‌ನ ಹಿಂದೆ ಯುನಿಕ್ ಕೋಡ್ ಅನ್ನು ಹುಡುಕಿ ಯುನಿಕ್ ಕೋಡ್ ನಮೂದಿಸಿ",
	"mobile_number_short": "ಮೊಬೈಲ್ ಸಂಖ್ಯೆ",
	"welcome_player": "ಸ್ವಾಗತ, ಆಟಗಾರ!",
	"login_start_winning": "ಗೆಲ್ಲುವುದನ್ನು ಪ್ರಾರಂಭಿಸಲು ಲಾಗಿನ್ ಮಾಡಿ!",
	"trademark_text": "ಕಾರ್ಬೋನೇಟೆಡ್‌ ನೀರು. ಟ್ರೇಟ್‌ಮಾರ್ಕ್‌‌ ಮಾಲಿಕ: THE COCA-COLA COMPANY.",
	"address": "ವಿಳಾಸ",
	"address_line_1": "ವಿಳಾಸ ಸಾಲು 1",
	"address_line_2": "ವಿಳಾಸ ಸಾಲು 2",
	"nearest_landmark": "ಹತ್ತಿರದ ಹೆಗ್ಗುರುತು",
	"alternative_mobile_number": "ಪರ್ಯಾಯ ಮೊಬೈಲ್ ಸಂಖ್ಯೆ",
	"pincode": "ಪಿನ್‌ಕೋಡ್",
	"state": "ರಾಜ್ಯ",
	"city": "ನಗರ",
	"state_error": "ದಯವಿಟ್ಟು ರಾಜ್ಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
	"city_error": "ದಯವಿಟ್ಟು ನಗರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
	"pincode_error": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಪಿನ್‌ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
	"address_error": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
	"pan_error": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ‌ ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
	"copy_code": "ಕೋಡ್ ನಕಲಿಸಿ",
	"last_date_redeem": "ರಿಡೀಮ್ ಮಾಡಲು ಕೊನೆಯ ದಿನಾಂಕ",
	"last_date_to_claim": "ಕ್ಲೈಮ್ ಮಾಡಲು ಕೊನೆಯ ದಿನಾಂಕ",
	"share_with_friends": "ಸ್ನೇಹಿತರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಿ",
	"no_internet_message": "ಸಂಪರ್ಕಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ನೆಟ್‌ವರ್ಕ್ ಸಂಪರ್ಕವನ್ನು ಪರಿಶೀಲಿಸಿ ಹಾಗೂ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ! ಪುಟವನ್ನು ರಿಫ್ರೆಶ್ ಮಾಡಿ",
	"email_us": "ನಮಗೆ ಇಮೇಲ್ ಮಾಡಿ",
	"call_toll_free": "ನಮಗೆ ಟೋಲ್-ಫ್ರೀ ನಲ್ಲಿ ಕರೆ ಮಾಡಿ",
	"mon_sat": "ಸೋಮವಾರ-ಶನಿವಾರ",
	"sunday": "ರವಿವಾರ",
	"follow_us_now": "ಈಗ ನಮ್ಮನ್ನು ಅನುಸರಿಸಿ",
	"otp_expired_enter_new": "ನಿಮ್ಮ ಒಟಿಪಿ ಅವಧಿ ಮುಗಿದಿದೆ. ದಯವಿಟ್ಟು ಹೊಸದನ್ನು ಬಳಸಿ.",
	"email_address": "ಇಮೇಲ್ ವಿಳಾಸ",
	"Code has already been used, please enter a new code": "ಕೋಡ್ ಅನ್ನು ಈಗಾಗಲೇ ಬಳಸಲಾಗಿದೆ, ದಯವಿಟ್ಟು ಹೊಸ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
	"first": "ಮೊದಲು",
	"last": "ಕೊನೆಯ",
	"add": "ಸೇರಿಸಿ",
	"refresh_page": "ಪುಟವನ್ನು ರಿಫ್ರೆಶ್ ಮಾಡಿ",
	"Seat on Toofan": "ತೂಫಾನ್‌ನಲ್ಲಿ ಆಸನ",
	"Toofani Merch": "ತೂಫಾನಿ ಮೆರ್ಜ್",
	"Cricket Jersey": "ಕ್ರಿಕೆಟ್‌ ಜೆರ್ಸಿ",
	"You are here": "ನೀವು ಇಲ್ಲಿದ್ದೀರಿ",
	"OTP resent successfully": "ಓಟಿಪಿ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ.",
	"No winners till now": "ಇಲ್ಲಿಯವರೆಗೆ ಯಾವುದೇ ವಿಜೇತರಿಲ್ಲ",
	"COPIED": "ನಕಲಿಸಲಾಗಿದೆ",
	"T&C": "ನವೀಕೃತ ನಿಯಮಗಳು & ಷರತ್ತುಗಳು",
	"Email is already in use": "ಇಮೇಲ್ ಈಗಾಗಲೆ ಚಾಲ್ತಿಯಲ್ಲಿದೆ",
	"Clear": "ಕ್ಲಿಯರ್‌ ಮಾಡಿ",
	"West Indies": "ವೆಸ್ಟ್‌ ಇಂಡೀಸ್",
	"India": "ಭಾರತ",
	"Reminder send successfully": "ಜ್ಞಾಪನೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ",
	"KM": "ಕಿಮೀ",
	"KMs": "ಕಿಮೀ",
	"Edit Address": "Edit Address",
	"Please enter a valid code": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
	"final_day_offer": "ಆಫರ್ ಅವಧಿಯ ಕೊನೆಯ ದಿನವನ್ನು ಏಪ್ರಿಲ್ 30, 2024 ರಿಂದ ಮೇ 15, 2024 ರವರೆಗೆ ವಿಸ್ತರಿಸಲಾಗಿದೆ. <tnc>ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು ಅನ್ವಯಿಸುತ್ತವೆ</tnc>"
}
