import { useTranslation } from 'react-i18next';

const Heading = ({ text, additionalClasses }) => {
	const { t } = useTranslation();
	return (
		<h1
			className={`text-pageTitleColor text-2xl lg:text-5xl  ${additionalClasses}`}
		>
			{t(text)}
		</h1>
	);
};

export default Heading;
