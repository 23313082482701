import { useState, useEffect } from 'react';

export const useOnlineStatus = () => {
	const [onlineStatus, setOnlineStatus] = useState(navigator?.onLine);

	useEffect(() => {
		window.addEventListener('offline', () => {
			setOnlineStatus(false);
		});
		window.addEventListener('online', () => {
			setOnlineStatus(true);
		});

		return () => {
			window.removeEventListener('offline', () => {
				setOnlineStatus(false);
			});
			window.removeEventListener('online', () => {
				setOnlineStatus(true);
			});
		};
	}, []);

	return onlineStatus;
};
