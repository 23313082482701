import Dialog from './DialogV1';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import ButtonDefault from './Button';

const MessageDialog = ({ open = true, popupInfo, handleOpen = () => {} }) => {
	/*CO- means dynamic content Object {name:"TH"}*/
	const {
		type,
		imgSrc,
		customHeading,
		customSubHeadingText,
		smallText,
		dynamicCO,
		cstBtnText,
		cstLabelBtnText,
		onClick,
		staticMessage,
		imgSrcClasses = 'rounded-full mb-1.5 bg-headerBg',
		handleOpenHandler,
		hideCrossIcon = false,
		redeemLink,
	} = popupInfo;
	let headingText = customHeading,
		subHeadingText = customSubHeadingText,
		text = smallText,
		btnText = cstBtnText,
		labelBtnText = cstLabelBtnText,
		timerDisplay;
	handleOpen = handleOpenHandler ? handleOpenHandler : handleOpen;
	const { t, i18n } = useTranslation('popup_messages');
	try {
		if (type && staticMessage) {
			headingText = t(type + '.headingText', dynamicCO);
			subHeadingText = t(type + '.subHeadingText', dynamicCO);
			text = t(type + '.text', dynamicCO);
			if (cstBtnText) {
				let existBtnText = t(type + '.btnText', dynamicCO);
				let existLabelBtnText = t(type + '.btnSubText', dynamicCO);
				timerDisplay = t(type + '.timerDisplay', dynamicCO);
				if (existBtnText) {
					btnText = existBtnText;
				}
				if (existLabelBtnText) {
					labelBtnText = existLabelBtnText;
				}
			}
		}
	} catch (e) {
		console.log(`Message Dialog Warning`, e);
	}
	return (
		<Dialog
			hideCrossIcon={hideCrossIcon}
			open={open}
			handleOpen={handleOpen}
			className=" w-full lg:w-3/4"
		>
			<div className="px-8 pt-5 pb-5 flex flex-col justify-start items-center font-thumbsUp">
				{imgSrc && (
					<div
						className={`w-12 h-12  flex justify-center items-center ${imgSrcClasses} mb-3`}
					>
						<img src={imgSrc} alt="icon" />
					</div>
				)}
				<h2
					className={`text-white text-center ${
						i18n.language === 'en' ? 'text-[1.75rem]' : 'text-xl'
					} font-medium leading-8 mb-3`}
				>
					{headingText}
				</h2>
				{subHeadingText && (
					<h3
						className={`${
							i18n.language === 'en' ? 'text-2xl' : 'text-base'
						} text-white font-normal leading-7 mb-1 text-center`}
					>
						{subHeadingText}
					</h3>
				)}
				{text && (
					<h4 className="text-base text-center text-white font-normal leading-7 mb-2">
						{text}
					</h4>
				)}
				{redeemLink ? (
					<div className=" mb-2  mt-4">
						<ButtonDefault
							text={btnText}
							customClasses="text-xl font-normal p-2 lg:text-2xl lg:p-2"
							actionLink=""
							onClick={onClick}
						/>
					</div>
				) : (
					btnText && (
						<div className=" mt-3">
							<Button text={btnText} onClick={onClick} />
						</div>
					)
				)}
				{labelBtnText && (
					<div className="w-2/2  text-center mt-2">
						<span className="text-white">{labelBtnText}</span>{' '}
						{timerDisplay && (
							<span className="text-errorText">{timerDisplay}</span>
						)}
					</div>
				)}
			</div>
		</Dialog>
	);
};

export default MessageDialog;
