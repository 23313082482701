import Dialog from './DialogV1';
import Typography from './Typography';
import MessageIcon from '../../public/contact_message_icon.svg';
import CallIcon from '../../public/contact_call_icon.svg';
import { useTranslation } from 'react-i18next';

const ContactDialog = ({ open, handleOpen }) => {
	const { t, i18n } = useTranslation();
	return (
		<Dialog open={open} handleOpen={handleOpen}>
			<div className="p-5 flex flex-col justify-normal items-center text-center font-thumbsUp">
				{/* <Typography
					variant="h2"
					type="text28"
					text={t('contact_us')}
					customClasses="text-white mb-2"
				/> */}
				<h2 className="text-[1.75rem] leading-8 font-bold text-white mb-2">
					{t('contact_us')}
				</h2>
				<div className="w-10 h-10 mb-1">
					<img src={MessageIcon} className="w-full" />
				</div>
				<Typography
					variant="h3"
					type={i18n.language === 'en' ? 'text24' : 'text16'}
					text={t('email_us')}
					customClasses="text-white"
				/>
				<Typography
					variant="h4"
					type="text20"
					text="indiahelpline@coca-cola.com"
					customClasses="text-white mb-5"
				/>
				<div className="w-10 h-10 mb-2">
					<img src={CallIcon} className="w-full" />
				</div>
				<Typography
					variant="h3"
					type={i18n.language === 'en' ? 'text24' : 'text16'}
					text={t('call_toll_free')}
					customClasses="text-white mb-1"
				/>
				<Typography
					variant="h3"
					type="text20"
					text="1800 208 2653"
					customClasses="text-white mb-1"
				/>
				<Typography
					variant="h3"
					type="text20"
					text={`9:00am-9:00pm (${t('mon_sat')})`}
					customClasses="text-white mb-1"
				/>
				<Typography
					variant="h3"
					type="text20"
					text={`9:00am-6:00pm (${t('sunday')})`}
					customClasses="text-white mb-1"
				/>
			</div>
		</Dialog>
	);
};

export default ContactDialog;
