{
	"home": "Home",
	"login": "Log In",
	"mobile_number": "Mobile Number",
	"mobile_number_label": "Enter mobile number",
	"get_otp": "Get OTP",
	"mobile_error_message": "Please enter a valid number",
	"resendOTP": "Resend OTP",
	"otp_verification": "OTP Verification",
	"otp_verification_sub_text": "Enter the 6-digit code we sent to +91 {{mobile_number}}",
	"try_again_text": "Resend OTP in {{time_remaining}} seconds ",
	"btn_submit": "Submit",
	"sign_up": "You are almost there!",
	"enter_detail_text": "Sign up to make it to the playing squad!",
	"full_name": "Full Name",
	"name": "Name",
	"email_id": "Email ID",
	"terms_text": "I certify that I am at least 18 years old & have read the <tnc>Updated T&C</tnc> and <pp>Privacy Policy</pp>.",
	"participate_win": "Participate & Win",
	"winners_list": "Winners List",
	"faqs": "FAQs",
	"terms_conditions": "Updated Terms & Conditions",
	"privacy_policy": "Privacy Policy",
	"logout": "Sign Out",
	"participate_now": "Participate Now",
	"winner_board": "Winner Board",
	"enter_unique_code": "Enter Unique Code",
	"winners": "Winners",
	"enter_code": "Enter Code",
	"profile": "Profile",
	"get_aboard_text": "Grab Your Seat on Toofan",
	"btn_continue": "Continue",
	"get_aboard_sub_text": "Join us on this once-in-a-lifetime journey – a luxury experience aboard a specially curated aircraft to fly all our Toofani fans to the ICC T20 World Cup 2024 Games in the West Indies.",
	"win_mileage_text": "Get Closer to the Action",
	"win_mileage_sub_text": "with every Thums Up® ",
	"my_kms": "My KMs",
	"follow_us": "Follow Us On",
	"copyright_text": "© 2024 The Coca‑Cola Company. All rights reserved.",
	"Please enter a valid unique code": "Please enter a valid unique code",
	"your_profile": "Your Profile",
	"profile_page_desc": "We will use the details below to contact winners. Please ensure all information provided is correct",
	"dob": "D.O.B",
	"gender": "Gender",
	"set_as_default": "Set as Default",
	"congratulations": "Congratulations",
	"flight_ticket_claimed_text": "You’ve claimed your ticket to the West Indies! We will communicate further details via email & SMS shortly.",
	"share": "Share",
	"claim_form_desc": "Please enter the details below to claim your prize!",
	"full_name_claim_form": "Full Name (As per Govt. ID)",
	"pan_card": "PAN Card",
	"passport_no": "Passport No",
	"passport_expiry_date": "Passport Expiry Date",
	"Male": "Male",
	"Female": "Female",
	"transgender": "Transgender",
	"Other": "Other",
	"gift_voucher_claimed_text": "You’ve claimed your gift voucher! We will communicate further details via email & SMS shortly.",
	"claim_now": "Claim Now",
	"paytm_1000_won_text": "You’ve won yourself a ₹1,000 Paytm voucher!",
	"paytm_1000_win_text": "Win a ₹1000 Paytm Voucher!",
	"paytm_100_won_text": "You’ve won yourself a ₹1,00 Paytm voucher!",
	"paytm_100_win_text": "Win a ₹100 Paytm Voucher!",
	"win_ticket": "Win a Ticket",
	"enter_unique_code_reach_kms": "Enter unique code to reach 14K KMs",
	"enter_name": "Please enter a valid name",
	"enter_mobile_number": "Please enter mobile number",
	"enter_email": "Please enter a valid Email ID",
	"enter_pan": "Please enter a valid PAN Card number",
	"no_page_found": "No Page Found",
	"go_to_home": "Go To Homepage",
	"eligible_1000_paytm_voucher": "You are now eligible for ₹1000 Paytm Gift Voucher",
	"win_prize_lucky_draw_daily": "Win Prizes in a lucky Draw Daily",
	"ticket_to_west_indies": "Ticket to West Indies",
	"ticket_to_west_indies_v1": "You've Made It To The Toofani Fan Club!",
	"ticket_to_west_indies_subtext_v1": "You won a seat aboard Toofan! Get ready to fly in style to the West Indies",
	"fan_jersy_heading": "Suit Up Like a Pro",
	"fan_jersy_heading_text": "Here’s a FanCode voucher to claim your cricket jersey worth Rs. 999",
	"how_enter_lucky_draw": "How to Enter the Lucky Draw",
	"step_1": "Step 1",
	"step_2": "Step 2",
	"step_3": "Step 3",
	"step_4": "Step 4",
	"scan_qr_code_bottle": "Scan the QR code from your ThumbsUp bottle",
	"enter_code_kms_achieve_three_code": "Enter the unique code to accrue 14,000kms with 3 codes max",
	"enter_lucky_draw_win_prize": "Enter the lucky draw to win prizes daily",
	"paytm_gift_voucher": "Paytm gift Voucher",
	"faq_heading": "Frequently Asked Questions",
	"faq_subtext": "Here’s everything you need to know about Thumsup",
	"terms_heading": "Terms & Conditions",
	"contest_over": "Contest Over",
	"thank_you_visit_page": "Thank you for visiting our page! This contest is now closed.",
	"participate_other_giveaways": "Please visit (link to ThumbsUp’s Contests page) to participate in other giveaways",
	"winner_list": "Winner List",
	"full_name_error": "Please enter full name",
	"name_error": "Please enter a valid name",
	"dob_error": "You must be at least 18 years old, please enter correct DOB",
	"passport_expiry_date_error_required": "Please enter a valid passport expiry date",
	"passport_expiry_date_error": "Passport expiry date field must be a date after today.",
	"passport_no_error": "Please enter a valid Passport number",
	"terms_agree_error": "Please agree to terms & conditions",
	"email_error": "Please enter a valid Email ID",
	"how_to_participate": "How to Participate",
	"toofani_champions": "Toofani Champions",
	"refer_friend": "Refer-A-Friend",
	"have_invite_code": "Have an invite code?",
	"enter_collect_kms": "Enter & collect 100kms",
	"contact_us": "Contact Us",
	"prize_grab": "Prizes Up for Grabs",
	"seat_aboard_toofan": "Seat Aboard Toofan \n1 winner daily*",
	"cricket_jersey_999_fancode": "Cricket Jersey worth ₹999 from FanCode \n1 winner every hour*",
	"toofani_999_fancode": "Toofani merch worth ₹499 from FanCode \n15 winners every hour*",
	"find_unique_code_pack": "Find Unique Code on the Pack",
	"enter_code_collect_kms": "Enter Code to collect kms",
	"complete_journey_destination": "Complete journey to destination",
	"get_chance_win": "Get a chance to win*!",
	"refer_friend_collect_kms": "Refer-a-Friend \nto Collect Additional KMs*",
	"get_100km_mate_signup": "Get 100kms for every mate who successfully \nsigns up & uses your invite code.",
	"refer_now": "Refer Now",
	"tnc_apply": "T&C Apply",
	"available": "Available",
	"booked": "Booked",
	"going_soon": "Going Soon",
	"you_made_toofani_fan_club": "You've Made It To The Toofani Fan Club!",
	"won_seat_get_ready": "You won a seat aboard Toofan! Get ready to fly in style to the West Indies",
	"jersey_won_text": "Suit Up Like a Pro",
	"jersey_won_subtext": "Here’s a FanCode voucher to claim your cricket jersey worth Rs. 999",
	"redeem_now": "Redeem Now",
	"voucher_won_text": "Pull On Some Team Spirit",
	"voucher_won_subtext": "Here’s a FanCode voucher to claim your cricket merch worth Rs. 499",
	"my_wins": "My Wins",
	"optional": "Optional",
	"sign_up_cta": "Sign Up",
	"Please enter a valid OTP": "Please enter a valid OTP",
	"enter_collect_100kms": "Enter & collect 100kms",
	"unique_code_entry_text": "Get a Chance to Fly Aboard Toofan to the ICC Men’s T20 World Cup 2024 Games in the West Indies",
	"unique_code_entry_subtext": "Find the unique code behind the label of a Thums Up® bottle",
	"mobile_number_short": "Mobile No.",
	"welcome_player": "Welcome, Player!",
	"login_start_winning": "Login to start winning!",
	"trademark_text": "CARBONATED WATER. TRADEMARK OWNER: THE COCA-COLA COMPANY.",
	"address": "Address",
	"address_line_1": "Address Line 1",
	"address_line_2": "Address Line 2",
	"nearest_landmark": "Nearest Landmark",
	"alternative_mobile_number": "Alternative Mobile Number",
	"pincode": "Pincode",
	"state": "State",
	"city": "City",
	"state_error": "Please select state",
	"city_error": "Please select city",
	"pincode_error": "Please enter a valid pincode",
	"address_error": "Please enter a valid address",
	"pan_error": "Please enter a valid PAN Card number",
	"copy_code": "COPY CODE",
	"last_date_redeem": "Last date to redeem",
	"last_date_to_claim": "Last date to claim",
	"share_with_friends": "Share with friends",
	"no_internet_message": "Unable to connect. Please check your network connection and try again!",
	"email_us": "Email Us",
	"call_toll_free": "Call Us Toll-free at",
	"mon_sat": "Mon-Sat",
	"sunday": "Sun",
	"follow_us_now": "Follow Us Now",
	"otp_expired_enter_new": "Your OTP has expired. Please use a new one.",
	"email_address": "Email Address",
	"Code has already been used, please enter a new code": "Code has already been used, please enter a new code",
	"first": "First",
	"last": "Last",
	"add": "Add",
	"refresh_page": "Refresh Page",
	"Seat on Toofan": "Seat on Toofan",
	"Toofani Merch": "Toofani Merch",
	"Cricket Jersey": "Cricket Jersey",
	"You are here": "You are here",
	"OTP resent successfully": "OTP resent successfully",
	"No winners till now": "No winners till now",
	"COPIED": "COPIED",
	"T&C": "Updated T&C",
	"Email is already in use": "Email is already in use",
	"Clear": "Clear",
	"West Indies": "West Indies",
	"India": "India",
	"Reminder send successfully": "Reminder send successfully",
	"KM": "KM",
	"KMs": "KMs",
	"Edit Address": "Edit Address",
	"final_day_offer": "Final day of Offer Period has been extended from 30th April 2024 to 15th May 2024. <tnc>T&C Apply</tnc>",
	"Offer Period has ended.": "Offer Period has ended."
}
