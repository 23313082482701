import React, { useState, useEffect } from 'react';
import { useCDP } from './useCDP';

export const useNotification = () => {
	try {
		const sendCDPData = useCDP();
		const [notificationPermission, setNotificationPermission] = useState(
			typeof Notification !== 'undefined' ? Notification.permission : 'denied'
		);

		const requestNotificationPermission = async () => {
			if (typeof Notification === 'undefined') {
				console.error('Notifications are not supported in this browser.');
				return;
			}
			try {
				const permission = await Notification.requestPermission();
				console.log(`permission>>>`, permission);
				setNotificationPermission(permission);
			} catch (error) {
				console.error('Error requesting notification permission:', error);
			}
		};

		useEffect(() => {
			if (notificationPermission === 'default') {
				requestNotificationPermission();
			}

			if (notificationPermission === 'granted') {
				// CDP event
				sendCDPData('Click', 'Consent_Push');
			}
		}, [notificationPermission]);
	}catch (e) {
		console.warn(e
		)
	}
};
