{
	"win_1": {
		"headingText": "You’ve made it to the Toofani Fan Club!",
		"subHeadingText": "You won a seat Aboard Toofan to the ICC 2024 Games in the West Indies",
		"text": "",
		"icon": "flight_won_icon",
		"btnText": "હમણાં જ દાવો કરો",
		"btnSubText": "Claim within:",
		"timerDisplay": " {{datetime}}"
	},
	"win_2": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Cricket Jersey worth ₹999 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "અત્યારે જ મેળવો",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_3": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Toofani merch worth ₹499 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "અત્યારે જ મેળવો",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_check": {
		"headingText": "બસ એક વધુ તપાસ!",
		"subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 5 seconds, on this site as well as through SMS or WhatsApp. Sit tight!",
		"text": "",
		"icon": "flight_win_check"
	},
	"win_check_waiting": {
		"headingText": "બસ એક વધુ તપાસ!",
		"subHeadingText": "",
		"text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 3 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
		"icon": "flight_win_check"
	},
	"valid_code": {
		"headingText": "વાહ, તૂફાન!",
		"subHeadingText": "તમે {{points}} કિમી ભેગા કર્યા છે. તમે તુફાન પર તમારી તકથી માત્ર {{remaining_km}} કિમી દૂર છો",
		"text": "",
		"icon": "points_win_flight_icon.svg"
	},
	"total_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "You have entered multiple invalid codes today. Come back tomorrow to try again.",
		"text": "",
		"icon": "warn_icon"
	},
	"already_win": {
		"headingText": "પહેલેથી જીત્યા છે!",
		"subHeadingText": "તમે પહેલાથી જ લકી ડ્રોમાં જીતી ગયા છો",
		"text": "",
		"icon": "warn_icon"
	},
	"valid_code_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "",
		"text": "You have entered multiple invalid codes today. Come back tomorrow to try again."
	},
	"claimed": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your ticket aboard Toofan Air with our fan squad",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"after_claimed": {
		"headingText": "બસ એક વધુ તપાસ!",
		"subHeadingText": "અમે તમે સબમિટ કરેલી વિગતો ચકાસી  રહ્યાં છીએ અને 10 કામકાજી દિવસોમાં, આ સાઇટ પર તેમજ SMS અથવા WhatsApp દ્વારા તમને તૂફાન પર બોર્ડ કરવાની તમારી યોગ્યતા વિશે સૂચિત કરીશું. ધીરજ રાખો!",
		"text": "",
		"btnText": "",
		"icon": "flight_win_check"
	},
	"claimed_reward": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your Toofani Reward",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"claim_now": {
		"headingText": "સમય નીકળી રહ્યો છે!",
		"subHeadingText": "તૂફાન પર તમારી સીટનો દાવો કરવાની અવધિ {{timer}} ના રોજ સમાપ્ત થશે",
		"text": "",
		"btnText": "હમણાં જ દાવો કરો",
		"icon": "timer_icon"
	},
	"claim_expired": {
		"headingText": "તમારો સમય સમાપ્ત થઈ ગયો છે!",
		"subHeadingText": "તમારા પુરસ્કાર પરના તમારા દાવાની સમયસીમા સમાપ્ત થઈ ગઈ છે.",
		"text": "આશા ગુમાવશો નહીં, તમે હજી પણ રમતમાં છો. તમે તૂફાન પર બીજી સીટ જીતી છે કે કેમ તે તપાસવા કાલે ફરી આવો!",
		"icon": "said_icon"
	},
	"not_win_for_the_day": {
		"headingText": "તે મેડન ઓવર છે!",
		"subHeadingText": "આશા ગુમાવશો નહીં, તમે હજી પણ રમતમાં છો. તમે તૂફાન પર બીજી સીટ જીતી છે કે કેમ તે તપાસવા કાલે ફરી આવો!",
		"text": "",
		"icon": "said_icon"
	},
	"exit_claim_popup": {
		"headingText": "તમારા મેડલનો દાવો કરો",
		"subHeadingText": "તમારા ઇનામ પરનો તમારો દાવો {{hours}} કલાકમાં સમાપ્ત થાય છે.",
		"text": "શું તમે ખરેખર બહાર નીકળવા માંગો છો?",
		"icon": "claim_expired_icon",
		"btnTextYes": "હા",
		"btnTextNo": "ના",
		"confirm": true
	},
	"exit_next_win_for_new": {
		"headingText": "ઉભા રહો!",
		"subHeadingText": "તમે આજે આગામી વિજેતા બની શકો છો!",
		"text": "શું તમે ખરેખર બહાર નીકળવા માંગો છો?",
		"icon": "raise_hand_icon",
		"btnTextYes": "હા",
		"btnTextNo": "ના",
		"confirm": true
	},
	"exit_next_win": {
		"headingText": "તમે વિનિંગ સ્કોરને હિટ કરવા જઈ રહ્યા છો!",
		"subHeadingText": "બીજો અનન્ય કોડ દાખલ કરો અને જીતવાની તક મેળવો!",
		"text": "શું તમે ખરેખર બહાર નીકળવા માંગો છો?",
		"icon": "raise_hand_icon",
		"btnTextYes": "હા",
		"btnTextNo": "ના",
		"confirm": true
	},
	"affiliate_signup_success": {
		"headingText": "શાબાશ કેપ્ટન",
		"subHeadingText": "",
		"text": "તમે 100kms મેળવી લીધા છે",
		"icon": "right_bat"
	},
	"default": {
		"headingText": "Error!",
		"subHeadingText": "Not found!",
		"text": ""
	},
	"qualified_for_lucky_draw": {
		"headingText": "Already qualified!",
		"subHeadingText": "You have already qualified for the lucky draw",
		"text": ""
	}
}
