{
	"heading_h2": "భాగస్వామి అంటే ఇలా ఉండాలి!",
	"heading_h3": "పాల్గొనడానికి మీ స్నేహితులని పిలవండి",
	"invite_code_text": "మీ స్నేహితుడు సైన్ అప్ అయ్యి మీ ఇన్వైట్ కోడ్ ని వాడిన వెంటనే 100 కిలోమీటర్లు పొందండి",
	"friend_mobile_number": "మీ స్నేహితుని మొబైల్ నంబర్ ఎంటర్ చెయ్యండి",
	"friend_email": "మీ స్నేహితుడి ఈమెయిల్(ఆప్షనల్) ని ఎంటర్ చెయ్యండి",
	"send_invite_btn": "సబ్మిట్ చెయ్యండి",
	"privacy_policy": "ప్రైవసీ పొలసీ",
	"icon": "right_bat",
	"mobile_required": "సరైన నంబర్ ని ఎంటర్ చెయ్యండి",
	"email_optional": "దయచేసి సరైన ఈమెయిల్ ని ఎంటర్ చెయ్యండి",
	"Number already in use, Please refer another": "సంఖ్య అప్పటికే వాడుకలో ఉంది. దయచేసి ఇంకొక దాన్ని రిఫర్ చెయ్యండి",
	"thank_you": "ధన్యవాదాలు!",
	"thank_you_h3": "కోడ్ తో పాటు మీ స్నేహితుడికి ఒక ఇన్వైట్ కోడ్ పంపబడింది",
	"thank_you_info_text": "మీ స్నేహితుడు సైన్ అప్ అయ్యి తన ఇన్వైట్ కోడ్ ని వాడగానే మీకు 100kms లభిస్తాయి.",
	"enter_invite_code": "ఇన్వైట్ కోడ్ ని ఎంటర్ చేయండి",
	"affiliate_signup_h3": "మీ స్నేహితుని యొక్క 6- అంకెల ఇన్వైట్ కోడ్ ని ఎంటర్ చేసి 100kms ని సేకరించండి",
	"affiliate_signup_btn": "సబ్మిట్ చెయ్యండి",
	"Please enter a valid code": "దయచేసి సరైన కోడ్ ని ఎంటర్ చెయ్యండి",
	"my_referrals": "నా రిఫరెల్స్",
	"accepted": "ఆమోదించబడింది",
	"pending": "పెండింగ్",
	"users": "వినియోగదారులు",
	"status": "స్తితి",
	"send_reminder": "సూచన ని పంపండి",
	"refer_another": "ఇంకొకటి చూడండి",
	"Referral not found": "రిఫరల్ కనుగొనబడలేదు"
}
