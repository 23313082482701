import { useTranslation } from 'react-i18next';
import VoucherWonIcon from '../../public/voucher_won_icon.svg';
import SmileIcon from '../../public/smile_con.svg';
import { useUserContext } from '../context/user-context';
import { useAuthContext } from '../context/auth-context';
import { useApiCall } from '../utils/useApiCall';
import Pagination from '../components/Pagination';
import Loader from '../components/Loader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCampaignContext } from '../context/campaign-context';

const Notifications = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [pageError, setPageError] = useState('');
	const [notifications, setNotifications] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const { openLoginForm, setOpenLoginForm } = useCampaignContext();
	const { notificationCount, setNotificationCount } = useUserContext();
	const { isLoggedIn, token } = useAuthContext();
	useEffect(() => {
		// Fetch referrals when the component mounts and when startDate or currentPage changes
		isLoggedIn && fetchNotifications();
		if (!isLoggedIn) {
			navigate('/');
		}
	}, [isLoggedIn, currentPage]);
	const markReadNotification = async (id, status) => {
		try {
			if (status === '1') {
				return false;
			}
			const response = await sendRequest(
				`${process.env.API_BASE_URL}notification/${id}/mark-read`,
				'get',
				JSON.stringify({ status: '1' }),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				// Update the state to reflect the change
				const updatedNotifications = notifications.map((notification) => {
					if (notification.id === id) {
						return { ...notification, status: '1' }; // Assuming '0' means read, adjust as needed
					}
					return notification;
				});
				setNotifications(updatedNotifications);
				setNotificationCount(response.data.notificationCount);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const fetchNotifications = async () => {
		let params = {
			page: currentPage,
		};

		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}notifications`,
				'POST',
				JSON.stringify(params),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				setNotifications(response.data.notifications); // Adjust according to your actual API response structure
				setTotalPages(response.data.total);
				setNotificationCount(response.data.notificationCount);
			}
		} catch (error) {
			setPageError(error?.data?.message || error?.message);
			setNotifications([]); // Adjust according to your actual API response structure
			setTotalPages(0);
			if (!isLoggedIn) {
				setOpenLoginForm(!openLoginForm);
			}
		}
	};
	const eventWiseIcon = {
		sign_up: SmileIcon,
		next_day_pool: SmileIcon,
		lucky_draw_winner: VoucherWonIcon,
	};
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		// Optionally, scroll to the top of the page or a specific element after changing the page
	};
	return (
		<div className="p-6">
			{isLoading && <Loader />}
			{notifications.map((notification, indexKey) => (
				<div key={indexKey} className="py-6 border-b ">
					<NotificationItem
						text={notification.head_line}
						subText={notification.content}
						type={notification.event_type}
						onClickHandler={markReadNotification}
						timeAgo={notification.time_ago}
						notificationId={notification.id}
						status={notification.status}
						imgSrc={
							eventWiseIcon[notification.event_type]
								? eventWiseIcon[notification.event_type]
								: SmileIcon
						}
					/>
				</div>
			))}
			{/* Pagination Controls */}
			{totalPages > 1 ? (
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			) : (
				pageError && (
					<div className="mt-4 space-y-2">
						<div className="flex justify-center bg-inputBg px-4 py-2 h-39">
							{pageError}
						</div>
					</div>
				)
			)}
		</div>
	);
};

const NotificationItem = ({
	text,
	subText,
	timeAgo,
	imgSrc,
	notificationId,
	status,
	onClickHandler,
}) => {
	const { t } = useTranslation();
	return (
		<div
			className="flex justify-between items-center"
			onClick={() => onClickHandler(notificationId, status)}
		>
			<div className="flex justify-start items-center gap-2">
				<div className="w-10 h-10 bg-headerBg rounded-lg flex justify-center items-center">
					<div className="w-6 h-6">
						<img src={imgSrc} />
					</div>
				</div>
				<div>
					<p className="text-pageTitleColor text-base font-bold leading-5">
						{text}
					</p>
					<p className="text-sm text-black font-bold leading-4">{subText}</p>
				</div>
			</div>
			<div className="flex flex-col justify-between items-end gap-2">
				<p className="text-sm font-normal text-black leading-4">{timeAgo}</p>
				{/*<p className="text-xs text-subTextColor font-normal leading-3">2 h</p>*/}
			</div>
		</div>
	);
};

export default Notifications;
