import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import { useState, useEffect } from 'react';
import Header from './components/Header';
import { AuthContext } from './context/auth-context';
import { UserContextProvider } from './context/user-context';
import ErrorBoundary from './components/ErrorBoundary';
import { useAuth } from './utils/useAuth';
import { CampaignContextProvider } from './context/campaign-context';
import { useOnlineStatus } from './utils/useOnlineStatus';
import NoInternet from './pages/NoInternet';
import FacebookPixel from './utils/FacebookPixel';

const App = () => {
	const { login, logout, userId, token, queryUser, setQueryUser } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();

	const onlineStatus = useOnlineStatus();

	if (!onlineStatus) {
		return <NoInternet />;
	}

	useEffect(() => {
		if (
			location.pathname !== '/contest-over' &&
			location.pathname !== '/winner-list'
		) {
			navigate('/contest-over');
		}
	}, [location]);

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: !!token,
				token,
				userId,
				login,
				logout,
				queryUser,
				setQueryUser,
			}}
		>
			<FacebookPixel pixelId="1372821139805728" />
			<UserContextProvider>
				<CampaignContextProvider>
					<Header />
					<main className="flex-grow">
						<Outlet />
					</main>
					<Footer />
				</CampaignContextProvider>
			</UserContextProvider>
		</AuthContext.Provider>
	);
};

export default App;
