import { useState, useEffect } from 'react';
import Dialog from './DialogV1';
import AffiliateInviteForm from './AffiliateInviteForm';
import AffiliateForm from './AffiliateForm';

const AffiliateJourneyDialog = ({ open, handleOpen,formType }) => {

	let AffiliateForms = formType?AffiliateForm:AffiliateInviteForm;

	return (
		<Dialog open={open} handleOpen={handleOpen} className="w-full lg:w-2/5">
			<AffiliateForms handleOpen={handleOpen} />
		</Dialog>
	);
};

export default AffiliateJourneyDialog;
