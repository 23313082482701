// YourComponent.js
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../public/calendar_icon.svg';
// import CalendarIcon from './CalendarIcon';

const DatePickerInput = (props) => {
	const [selectedDate, setSelectedDate] = useState(null);

	return (
		<div className="bg-inputBg lg:bg-white placeholder-placeHolderColor p-4 rounded-lg w-full  flex justify-between items-center">
			<DatePicker
				{...props}
				// selected={selectedDate}
				// onChange={(date) => setSelectedDate(date)}
				dateFormat="dd/MM/yyyy"
				placeholderText={
					props.placeholder ? props.placeholder : 'Select a date'
				}
				className="text-xl lg:text-2xl w-full"
				scrollableYearDropdown
				showYearDropdown
				// startDate={new Date('1920-01-02')}
				// maxDate={new Date()} // To prevent selecting future dates
				dropdownMode="select"
				locale="en"
				// icon={<CalendarIcon />}
				id="dob_picker"
				name="dob"
			/>
			<div className="w-4.5 h-4.5">
				<img
					src={CalendarIcon}
					onClick={() => document.getElementById('dob_picker').click()}
				/>
			</div>

			<style>
				{`
        .react-datepicker-wrapper {
            width: 100%;
        }
		.react-datepicker__input-container {
			position: initial;
		}
		`}
			</style>
		</div>
	);
};

export default DatePickerInput;
