import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';

const NoInternet = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="flex flex-col justify-center items-center h-screen px-10 ">
			<h1 className="text-4xl text-black mb-3 leading-10">No Internet</h1>
			<p className="text-xl text-black font-light mb-3 text-center">
				{t('no_internet_message')}
			</p>
			<div className="">
				<Button
					text={t('refresh_page')}
					customClasses="text-xl font-normal p-2"
					onClick={() => navigate('/')}
				/>
			</div>
		</div>
	);
};

export default NoInternet;
