{
	"winner_list_h1": "ତୁଫାନୀ ଚାମ୍ପିଅନ୍\u200Cସ୍\u200C",
	"avatar": "ଅବତାର",
	"name": "ନାମ",
	"phone_number": "ଫୋନ୍ ନମ୍ବର",
	"prize": "ପୁରସ୍କାର",
	"select_date": "ତାରିଖ ଚୟନ କରନ୍ତୁ",
	"record_not_found": "Record not found",
	"win_1": "ମୁଁ ଏବେ ୱେଷ୍ଟ ଇଣ୍ଡିଜ୍\u200Cରେ ଆଇସିସି ପୁରୁଷ ଟି -20 ବିଶ୍ୱକପ 2024 ଖେଳ ପାଇଁ ତୁଫାନ୍\u200Cରେଏକ ସିଟ୍\u200C ଜିତିଛି।",
	"win_img_1": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/flight.jpg",
	"win_2": "ମୁଁ ଏବେ ଥମ୍ସ ଅପ୍® ଆଇସିସି ପୁରୁଷ ଟି -20 ବିଶ୍ୱକପ 2024 ପ୍ରମୋସନ୍\u200Cରେ ଫ୍ୟାନକୋଡରୁ ଏକ କ୍ରିକେଟ୍ ଜର୍ସି ଜିତିଛି।",
	"win_img_2": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/jercy.jpg",
	"win_3": "ମୁଁ ଏବେ ଥମ୍ସ ଅପ୍® ଆଇସିସି ପୁରୁଷ ଟି -20 ବିଶ୍ୱକପ 2024 ପ୍ରମୋସନ୍\u200Cରେ ଫ୍ୟାନକୋଡରୁ ଏକ କ୍ରିକେଟ୍ ମର୍ଚ୍ଚେଣ୍ଡାଇଜ୍\u200C ଜିତିଛି।",
	"win_img_3": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/voucher.jpg",
	"one_more_check": "କେବଳ ଆଉ ଏକ ଯାଞ୍ଚ!",
	"one_more_check_text": "ଆପଣ ଦାଖଲ କରିଥିବା ସବିଶେଷ ତଥ୍ୟ ଆମେ ଯାଞ୍ଚ କରୁଛୁ ଏବଂ 10 ଟି ବ୍ୟବସାୟ ଦିନ ମଧ୍ୟରେ, ଏହି ସାଇଟରେ ତଥା SMS କିମ୍ବା ହ୍ୱାଟସ୍ ଆପ୍ ମାଧ୍ୟମରେ ତୁଫାନ୍ ବୋର୍ଡରେ ଆପଣଙ୍କର ଯୋଗ୍ୟତା ବିଷୟରେ ଆପଣଙ୍କୁ ଜଣାଇବୁ | ଧୈର୍ଯ୍ୟର ସହ ଅପେକ୍ଷା କରନ୍ତୁ!",
	"win_heading": "ଆପଣଙ୍କୁ ଶୁଭେଚ୍ଛା!",
	"win_1_success_verified": "ତୁଫାନରେ ଆପଣଙ୍କର ସିଟ୍ ନିଶ୍ଚିତ ହୋଇଛି ଏବଂ ଆପଣ ୱେଷ୍ଟଇଣ୍ଡିଜ୍ ଯାଉଛନ୍ତି!\nଆମେ ଆମର ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ସହିତ ଶୀଘ୍ର ହିଁ SMS / ହ୍ୱାଟସ୍ ଆପ୍ କିମ୍ବା ଏକ ଫୋନ୍ କଲ୍ ମାଧ୍ୟମରେ ଆପଣଙ୍କ ନିକଟରେ ପହଞ୍ଚିବୁ। ନିଜର ଚମତ୍କାର ବିଜୟ ବିଷୟରେ ବନ୍ଧୁମାନଙ୍କୁ କୁହନ୍ତୁ!",
	"win_1_success": "ତୁଫାନରେ ଆପଣଙ୍କର ସିଟ୍ ନିଶ୍ଚିତ ହୋଇଛି ଏବଂ ଆପଣ ୱେଷ୍ଟଇଣ୍ଡିଜ୍ ଯାଉଛନ୍ତି!\nଆମେ ଆମର ପରବର୍ତ୍ତୀ ପଦକ୍ଷେପ ସହିତ ଶୀଘ୍ର ହିଁ SMS / ହ୍ୱାଟସ୍ ଆପ୍ କିମ୍ବା ଏକ ଫୋନ୍ କଲ୍ ମାଧ୍ୟମରେ ଆପଣଙ୍କ ନିକଟରେ ପହଞ୍ଚିବୁ। ନିଜର ଚମତ୍କାର ବିଜୟ ବିଷୟରେ ବନ୍ଧୁମାନଙ୍କୁ କୁହନ୍ତୁ!",
	"win_2_success": "You’ve claimed your Cricket Jersey! We will communicate further details via email & SMS shortly.",
	"win_3_success": "You’ve claimed your Toofani Merch! We will communicate further details via email & SMS shortly."
}
