import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import Typography from './Typography';
import DownArrowIcon from '../../public/down_arrow_icon.svg';
import RightTickIcon from '../../public/right_tick_icon.svg';
import { useAuthContext } from '../context/auth-context';
import { useCDP } from '../utils/useCDP';
import { useApiCall } from '../utils/useApiCall';
import { useUserContext } from '../context/user-context';

const LanguageDropDown = () => {
	const { i18n } = useTranslation();
	const [openList, setOpenList] = useState(false);

	const { isLoggedIn, token } = useAuthContext();
	const { isLoading, error: apiError, sendRequest } = useApiCall();

	const sendCDPData = useCDP();

	const { setLangSelected, setSelectedLangCode, selectedLangCode } =
		useUserContext();

	useEffect(() => {
		sessionStorage.getItem('lang') &&
			setSelectedLangCode(sessionStorage.getItem('lang'));
		sessionStorage.getItem('lang') &&
			i18n.changeLanguage(sessionStorage.getItem('lang'));
	}, []);

	const languageData = [
		{
			key: 'en',
			value: 'English',
			engValue: 'English',
			class: 'TUICC24English',
		},
		{ key: 'hi', value: 'हिंदी', engValue: 'Hindi', tuClass: 'TUICC24Hindi' },
		{
			key: 'te',
			value: 'తెలుగు',
			engValue: 'Telugu',
			tuClass: 'TUICC24Telugu',
		},
		{
			key: 'bn',
			value: 'বাংলা',
			engValue: 'Bangla',
			tuClass: 'TUICC24Bengali',
		},
		{
			key: 'gu',
			value: 'ગુજરાતી',
			engValue: 'Gujarati',
			tuClass: 'TUICC24Gujrati',
		},
		{
			key: 'ka',
			value: 'ಕನ್ನಡ',
			engValue: 'Kannada',
			tuClass: 'TUICC24Kannada',
		},
		{
			key: 'ma',
			value: 'मराठी',
			engValue: 'Marathi',
			tuClass: 'TUICC24Marathi',
		},
		{ key: 'od', value: 'ଓଡ଼ିଆ', engValue: 'Odia', tuClass: 'TUICC24Odia' },
	];

	const languageSelectHandler = async (code, value) => {
		// i18n.changeLanguage(code);
		// setSelectedLangCode(code);
		setOpenList(!openList);

		setLangSelected(value);

		// Set language selection in session storage
		sessionStorage.setItem('lang', code);
		sessionStorage.setItem('langValue', value);

		// CDP event
		if (isLoggedIn) {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}user/language/${value}`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					}
				);

				if (response.success) {
				} else {
					throw new Error(response.message);
				}
			} catch (error) {
				console.log(`User language API error>>>`, error);
			}
		} else {
			sendCDPData('Select', 'Change_Language', value);
		}
		window.location.reload();
	};

	return (
		<>
			<div
				className="flex justify-start items-center gap-1 lg:gap-1.5"
				onClick={() => setOpenList(!openList)}
			>
				<Typography
					variant="h2"
					type="text20"
					text={
						selectedLangCode.charAt(0).toUpperCase() + selectedLangCode.slice(1)
					}
					customClasses="text-white lg:text-2xxl "
				/>
				<div className="w-2 h-1 lg:w-3 lg:h-1.5">
					<img src={DownArrowIcon} className="w-full" />
				</div>
			</div>

			{openList && (
				<div className=" rounded-lg bg-white py-1 absolute top-12 right-5 z-10 flex flex-col justify-normal items-normal gap-1 lg:top-16 lg:right-48 lg:py-3 lg:gap-3">
					{languageData.map(({ key, value, engValue, tuClass }, index) => (
						<button
							className={`p-1 flex justify-between items-center gap-1 ${
								key === selectedLangCode ? 'bg-footerBg' : ''
							} ${tuClass}`}
							key={key}
							onClick={() => languageSelectHandler(key, engValue)}
						>
							<Typography
								variant="h2"
								type="text14"
								text={value}
								customClasses={`${
									key === selectedLangCode
										? 'text-white lg:text-xl'
										: 'text-pageTitleColor lg:text-base'
								} `}
							/>
							{key === selectedLangCode && (
								<div className="w-1.5 h-1.5 lg:w-2 lg:h-2">
									<img src={RightTickIcon} className="w-full" />
								</div>
							)}
						</button>
					))}
				</div>
			)}
		</>
	);
};

export default LanguageDropDown;
