import { useState, useCallback, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import AddressBox from '../components/AddressBox';
import Button from '../components/Button';
import Heading from '../components/Heading';
import InputWithLabel from '../components/InputWithLabel';
import InputErrorMessage from '../components/InputErrorMessage';
import { useUserContext } from '../context/user-context';
import { AuthContext, useAuthContext } from '../context/auth-context';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import FlightWinCheck from '../../public/flight_win_check.svg';
import AddAddressDialog from '../components/AddAddressDialog';
import { formatDate } from '../utils/common';
import DatePicker from '../components/DatePicker';
import { useCampaignContext } from '../context/campaign-context';
import { gaEvents } from '../utils/gaEvents';

const ClaimForm = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [openAddressForm, setOpenAddressForm] = useState(false);

	const { userData } = useUserContext();
	const { token, isLoggedIn } = useAuthContext();
	useEffect(() => {
		if (!isLoggedIn) {
			navigate('/');
		}
	}, [isLoggedIn]);
	const { userInfoPopup, setUserInfoPopup, setPopupInfo, setIsWin, popupInfo } =
		useCampaignContext();
	// const [apiErrorMessage, setApiErrorMessage] = useState("");

	const { isLoading, error: apiError, sendRequest } = useApiCall();

	const yupSchema = useCallback(
		yup.object({
			full_name: yup.string().required(t('enter_name')),
			mobile_number: yup
				.string()
				.matches(/^\d{10}$/, t('mobile_error_message'))
				.min(10, t('mobile_error_message')),
			email_id: yup.string().nullable().email(t('email_error')),
			pan_number: yup
				.string()
				.matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, t('enter_pan'))
				.required(t('enter_pan')),
			gender: yup.string().required('Please select a gender'),
			passport_expiry_date: yup
				.date()
				.required(t('passport_expiry_date_error_required')) // Assuming you have a specific error message for this
				.typeError(t('passport_expiry_date_error_required')), // And one for type error
			// .min(new Date(), t('passport_expiry_date_error')), // Ensure the date is after today,
			passport_no: yup
				.string()
				.matches(/^[A-Za-z0-9]{6,9}$/, t('passport_no_error'))
				.required(t('passport_no_error')),
		}),
		[]
	);
	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		register,
	} = useForm({ resolver: yupResolver(yupSchema) });

	const submitHandler = async (data) => {
		const {
			full_name,
			email_id,
			mobile_number,
			gender,
			pan_number,
			passport_no,
			passport_expiry_date,
		} = data;

		try {
			const formattedDate = formatDate(passport_expiry_date);
			const response = await sendRequest(
				`${process.env.API_BASE_URL}claim-form`,
				'POST',
				JSON.stringify({
					full_name: userData.name || full_name,
					email: email_id || undefined,
					mobile_no: userData.mobile_no || mobile_number,
					gender,
					passport_no,
					passport_expiry_date: formattedDate,
					pan_card: pan_number,
					winner_id: userData?.id,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				// GA Event
				window.dataLayer.push(
					gaEvents['TUICC24_SeatToofaniAir_ClaimFormSubmit']
				);

				setUserInfoPopup(true);
				setIsWin(true);
				setPopupInfo({
					type: 'after_claimed',
					imgSrc: FlightWinCheck,
					staticMessage: true,
					handleOpenHandler,
				});
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			// setApiErrorMessage(error);
			console.error(`Claim form API error>>>`, error);
		}
	};
	const handleOpenHandler = () => {
		setUserInfoPopup(false);
		navigate('/profile');
	};
	return (
		<div className="pt-4 pb-5 px-4">
			<Heading text={t('claim_form_desc')} additionalClasses="mb-4" />
			<form onSubmit={handleSubmit(submitHandler)}>
				{isLoading && <Loader />}
				<div className="mb-4">
					<Controller
						name="full_name"
						control={control}
						defaultValue={userData.name}
						render={({ field: { ref, ...rest } }) => (
							<InputWithLabel
								{...rest}
								isValid={!errors?.full_name}
								label={`${t('full_name_claim_form')}*`}
								placeholder={t('full_name_claim_form')}
								disabled={!!userData.name}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.full_name?.message} />
				</div>
				<div className="mb-4">
					<Controller
						name="mobile_number"
						control={control}
						defaultValue={userData.mobile_no}
						render={({ field: { ref, ...rest } }) => (
							<InputWithLabel
								{...rest}
								isValid={!errors?.mobile_number}
								label={`${t('mobile_number')}*`}
								placeholder={`${t('mobile_number')}`}
								disabled={!!userData.mobile_no}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.mobile_number?.message} />
				</div>
				<div className="mb-4">
					<label className="text-black text-xl font-normal leading-6 mb-1.5">
						{t('gender')}*
					</label>
					<select
						{...register('gender', { required: true })}
						className="bg-inputBg border-collapse p-4 rounded-lg w-full text-xl"
					>
						<option value="Male">{t('Male')}</option>
						<option value="Female">{t('Female')}</option>
						{/* <option value="Transgender">{t('transgender')}</option> */}
						<option value="Other">{t('Other')}</option>
					</select>
				</div>
				<div className="mb-4">
					<Controller
						name="email_id"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<InputWithLabel
								{...rest}
								isValid={!errors?.email_id}
								label={`${t('email_id')}`}
								placeholder={`${t('email_id')}`}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.email_id?.message} />
				</div>
				<div className="mb-4">
					<Controller
						name="pan_number"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<InputWithLabel
								{...rest}
								isValid={!errors?.pan_number}
								label={`${t('pan_card')}*`}
								placeholder={`${t('pan_card')}`}
								tooltip={true}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.pan_number?.message} />
				</div>
				<div className="mb-4">
					<Controller
						name="passport_no"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<InputWithLabel
								{...rest}
								isValid={!errors?.passport_no}
								label={`${t('passport_no')}*`}
								placeholder={`${t('passport_no')}`}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.passport_no?.message} />
				</div>
				<div className="mb-6">
					<label className="text-black text-xl font-normal leading-6 mb-1.5">
						{`${t('passport_expiry_date')}*`}
					</label>
					<Controller
						name="passport_expiry_date"
						control={control}
						defaultValue={''}
						render={({ field: { ref, value, onChange, ...rest } }) => (
							<DatePicker
								{...rest}
								isValid={!errors?.passport_expiry_date}
								placeholder={t('passport_expiry_date')}
								selected={value || null}
								onChange={(date) => {
									onChange(date);
								}}
								type="date"
								startDate={new Date()}
								minDate={new Date()}
								// startDate={new Date().setDate(new Date().getDate() + 1)}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.dob?.message} />
				</div>
				<section className="mb-5">
					<AddressBox
						address={userData?.address || []}
						onAdd={() => setOpenAddressForm(!openAddressForm)}
						onEdit={() => {}}
						onDelete={() => {}}
						btnClasses="text-bannerRedText"
					/>
				</section>
				{apiError && <InputErrorMessage text={t(apiError)} />}
				<Button text={t('btn_submit')} />
			</form>

			<AddAddressDialog
				open={openAddressForm}
				handleOpen={() => setOpenAddressForm(!openAddressForm)}
			/>
		</div>
	);
};

export default ClaimForm;
