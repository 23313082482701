import Dialog from './DialogV1';
import UniqueCodeBanner from "./UniqueCodeBanner";
import {useCampaignContext} from "../context/campaign-context";
import {useAuthContext} from "../context/auth-context";
import {useUserContext} from "../context/user-context";

const UniqueCodeBannerDialog = ({ open, handleOpen }) => {
	const {
		openUniqueCodeForm,
		setOpenUniqueCodeForm,
		openLoginForm,
		setOpenLoginForm,
	} = useCampaignContext();
	const { isLoggedIn } = useAuthContext();
	const { historyBackOption,setHistoryBackOption } = useUserContext();
	const participateHandler = () => {
		handleOpen()
		if (isLoggedIn) {
			setOpenUniqueCodeForm(!openUniqueCodeForm);
		} else {
			setOpenLoginForm(!openLoginForm);
			setHistoryBackOption({...historyBackOption,isDialogOpen: true,redirectLink:()=>{
					setOpenUniqueCodeForm(!openUniqueCodeForm)
				}})
		}
	};
	return (
		<Dialog open={open} handleOpen={handleOpen} className="w-full lg:w-2/5">
			<UniqueCodeBanner onParticipateClick={participateHandler} handleOpen={handleOpen} />
		</Dialog>
	);
};

export default UniqueCodeBannerDialog;
