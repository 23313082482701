import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Error from './components/Error';
import Profile from './pages/Profile';
import Homepage from './pages/Homepage';
import Login from './pages/Login';
import Signup from './pages/Signup';
import WinnerList from './pages/WinnerList';
import OtpVerification from './pages/OtpVerification';
import ClaimForm from './pages/ClaimForm';
import NotFound from './pages/NotFound';
import Notifications from './pages/Notifications';
import ParticipateWin from './pages/ParticipateWin';
import Faq from './pages/Faq';
import Terms from './pages/Terms';
import ContestOver from './pages/ContestOver';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NoInternet from './pages/NoInternet';
import FlightMove from './pages/FlightMove';
import EditProfile from "./pages/EditProfile";

export const appRouter = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '/',
				element: <Homepage />,
			},
			{
				path: '/profile',
				element: <Profile />,
			},
			{
				path: '/update-profile',
				element: <EditProfile />,
			},
			// {
			// 	path: '/login',
			// 	element: <Login />,
			// },
			// {
			// 	path: '/signup',
			// 	element: <Signup />,
			// },
			/*{
				path: '/winner-list',
				element: <WinnerList />,
			},*/
			// {
			// 	path: '/otp-verification',
			// 	element: <OtpVerification />,
			// },
			{
				path: '/claim-form',
				element: <ClaimForm />,
			},
			{
				path: '/notifications',
				element: <Notifications />,
			},
			{
				path: '/participate-and-win',
				element: <ParticipateWin />,
			},
			{
				path: '/faqs',
				element: <Faq />,
			},
			{
				path: '/terms-and-conditions',
				element: <Terms />,
			},
			{
				path: '/privacy-policy',
				element: <PrivacyPolicy />,
			},
			{
				path: '/contest-over',
				element: <ContestOver />,
			},
			{ path: '/offline', element: <NoInternet /> },
			{
				path: 'flight',
				element: <FlightMove />,
			},
			{
				path: '*',
				element: <NotFound />,
			},
		],
		errorElement: <Error />,
	},
]);
