import SeatAvailableIcon from '../../public/seat_available_body.svg';
import SeatHighlightIcon from '../../public/seat_highlight_body.svg';
import SeatBookedIcon from '../../public/seat_booked_body.svg';
import FlightBody from '../../public/flight_body_icon.svg';
import FlightBodyFull from '../../public/flight_body_icon_full.svg';
import { useIsMobile } from '../utils/useIsMobile';

const FlightSeatFilling = ({ seatBookeds }) => {
	let seatBooked = 76;
	const emptyArray = new Array(75).fill(undefined);

	const isMobile = useIsMobile();

	let assignMarginIndex;
	return (
		// <div className="bg-flight-body-bg lg:bg-flight-body-full-bg bg-cover bg-no-repeat bg-center h-[12.5rem] w-full flex justify-center items-center lg:h-[36rem] lg:bg-left ">
		<div className="h-auto w-full flex justify-center items-center lg:h-[36rem] lg:bg-left relative ">
			<img
				src={isMobile ? FlightBody : FlightBodyFull}
				className="w-full h-full"
			/>
			<div className="absolute m-auto left-0 right-0 top-0 bottom-0 w-full h-full flex justify-center items-center">
				<div className="flex justify-center items-start w-[15rem] lg:w-[48.75rem] lg:pt-12 lg:pr-[6rem] ">
					<div className="w-[4%] h-auto mr-0.5 lg:w-8 lg:h-8 ">
						<img
							src={seatBooked > 0 ? SeatBookedIcon : SeatHighlightIcon}
							className="w-full h-full"
						/>
					</div>
					<div className="flex flex-col-reverse h-[4.5rem]  flex-wrap justify-end items-start  gap-0.5 lg:h-[12.188rem] w-full">
						{emptyArray.map((item, index) => {
							let imgSrc;
							let marginTop = '';
							if (index + 1 <= seatBooked - 1) {
								imgSrc = SeatBookedIcon;
							} else if (index + 1 === seatBooked) {
								imgSrc = SeatHighlightIcon;
							} else {
								imgSrc = SeatAvailableIcon;
							}

							if (assignMarginIndex) {
								if (index % 2 !== 0 && index - assignMarginIndex === 4) {
									marginTop = 'mt-4 lg:mt-8';
									assignMarginIndex = index;
								}
							} else {
								if (index % 2 !== 0) {
									marginTop = 'mt-4 lg:mt-8';
								}

								assignMarginIndex = index;
							}

							return (
								<div
									className={`w-[4%] h-auto lg:w-8 lg:h-8 ${marginTop}`}
									key={index}
								>
									<img src={imgSrc} className="w-full h-full" />
								</div>
							);
						})}
						<div className="w-[4%] h-auto lg:w-8 lg:h-8 ">
							<img
								src={SeatAvailableIcon}
								className="w-full h-full invisible"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FlightSeatFilling;
