import LoaderIcon from '../../public/loader.svg';

const Loader = (props) => {
	return (
		<div className="h-full w-full absolute top-0 left-0 flex justify-center items-center bg-white opacity-90">
			<div className="w-16 h-16">
				<img src={LoaderIcon} />
			</div>
		</div>
	);
};

export default Loader;
