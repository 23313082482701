import { createContext, useState, useContext } from 'react';

const initialState = {
	userData: {
		name: '',
		email: '',
		mobile_no: '',
		profile: { dob: '', gender: '', my_kms: 0 },
		isWin: false,
	},
	notificationCount: 0,
	setNotificationCount: () => {},
	historyBackOption: {
		isDialogOpen: false,
		isPageOpen: false,
		redirectLink: () => {},
	},
	setHistoryBackOption: () => {},
	langSelected: 'English',
	setLangSelected: () => {},
	selectedLangCode: 'en',
	setSelectedLangCode: () => {},
};

const UserContext = createContext(initialState);

export const UserContextProvider = ({ children }) => {
	const [userData, setUserData] = useState({});
	const [historyBackOption, setHistoryBackOption] = useState({
		isDialogOpen: 0,
		isPageOpen: 0,
		redirectLink: () => {},
	});
	const [notificationCount, setNotificationCount] = useState(0);

	const [langSelected, setLangSelected] = useState('English');
	const [selectedLangCode, setSelectedLangCode] = useState('en');

	return (
		<UserContext.Provider
			value={{
				userData,
				setUserData,
				notificationCount,
				setNotificationCount,
				historyBackOption,
				setHistoryBackOption,
				langSelected,
				setLangSelected,
				selectedLangCode,
				setSelectedLangCode,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const useUserContext = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext must be used within a UserContextProvider');
	}
	return context;
};
