export const floodLightTags = {
	ThumsUp_ICC24_HomePage_CounterTest: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums04u+unique',
	},
	ThumsUp_ICC24_HomePage_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03s+unique',
	},
	ThumsUp_ICC24_ContactUs_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums042+unique',
	},
	ThumsUp_ICC24_EnterUnique_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums043+unique',
	},
	ThumsUp_ICC24_Facebook_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums045+unique',
	},
	ThumsUp_ICC24_FAQs_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums04+unique',
	},
	ThumsUp_ICC24_GetOTP_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03u+unique',
	},
	ThumsUp_ICC24_HaveAnInviteCode_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03_+unique',
	},
	ThumsUp_ICC24_HowToParticipate_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03x+unique',
	},
	ThumsUp_ICC24_Instagram_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums046+unique',
	},
	ThumsUp_ICC24_Login_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03t+unique',
	},
	ThumsUp_ICC24_PrivacyPolicy_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums041+unique',
	},
	ThumsUp_ICC24_ReferAFriend_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03z+unique',
	},
	ThumsUp_ICC24_SignUp_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03w+unique',
	},
	ThumsUp_ICC24_TnC_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums040+unique',
	},
	ThumsUp_ICC24_ToofaniChampions_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03y+unique',
	},
	ThumsUp_ICC24_UniqueSubmit_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums044+unique',
	},
	ThumsUp_ICC24_VerifyOTP_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums03v+unique',
	},
	ThumsUp_ICC24_YouTube_Counter: {
		allow_custom_scripts: true,
		send_to: 'DC-12665261/thums0/thums047+unique',
	},
};
