import Dialog from './Dialog';
import EditAddressForm from './EditAddressForm';

const EditAddressDialog = ({ open, handleOpen, addressObj = {} }) => {
	return (
		<Dialog
			open={open}
			handleOpen={handleOpen}
			className="w-full lg:w-2/5 "
			bodyHeight="h-screen"
		>
			<EditAddressForm handleOpen={handleOpen} addressObj={addressObj} />
		</Dialog>
	);
};

export default EditAddressDialog;
