import { useRouteError } from 'react-router-dom';

const Error = () => {
	console.log(`useRouteError()>>>`, useRouteError());
	return (
		<div className="error">
			<h1>Oops! Something went wrong. error</h1>
		</div>
	);
};

export default Error;
