import ProfilePic from '../../public/profile_pic_1.svg';

const ProfilePicUpload = ({ items, onClick }) => {
	return (
		<div className="px-3 py-2 border-2 border-profileBorder rounded-xl w-[10rem] flex justify-start items-center gap-3 flex-wrap bg-white">
			{items.map(({ key, value }) => (
				<div
					className="w-[2.188rem] h-[2.375rem]"
					onClick={() => onClick(key, value)}
					key={key}
				>
					<img src={value} />
				</div>
			))}
			{/* <div className="w-[2.188rem] h-[2.375rem]">
				<img src={ProfilePic} />
			</div>
			<div className="w-[2.188rem] h-[2.375rem]">
				<img src={ProfilePic} />
			</div>
			<div className="w-[2.188rem] h-[2.375rem]">
				<img src={ProfilePic} />
			</div>
			<div className="w-[2.188rem] h-[2.375rem]">
				<img src={ProfilePic} />
			</div>
			<div className="w-[2.188rem] h-[2.375rem]">
				<img src={ProfilePic} />
			</div>
			<div className="w-[2.188rem] h-[2.375rem]">
				<img src={ProfilePic} />
			</div>
			<div className="w-[2.188rem] h-[2.375rem]">
				<img src={ProfilePic} />
			</div> */}
		</div>
	);
};

export default ProfilePicUpload;
