import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Heading from '../components/Heading';
import OTPInput from '../components/OTPInput';
import SubHeading from '../components/SubHeading';
import Button from '../components/Button';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import InputErrorMessage from '../components/InputErrorMessage';
import { AuthContext } from '../context/auth-context';
import { useSendOtp } from '../utils/useSendOtp';

const OtpVerification = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [otp, setOtp] = useState(0);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [timer, setTimer] = useState(60);
	const [isResendDisabled, setIsResendDisabled] = useState(true);
	const [resendOtpSuccessMsg, setResendOtpSuccessMsg] = useState(false);
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const {
		sendOtpRequest,
		isLoading: sendOtpLoading,
		apiError: sendOtpError,
	} = useSendOtp();

	const { login, setQueryUser, isLoggedIn } = useContext(AuthContext);

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/');
		}
	}, [isLoggedIn]);

	useEffect(() => {
		let interval;

		if (timer > 0 && isResendDisabled) {
			interval = setInterval(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);
		} else {
			setIsResendDisabled(false);
		}

		return () => clearInterval(interval);
	}, [timer, isResendDisabled]);

	const handleResend = async () => {
		setTimer(60);
		setIsResendDisabled(true);
		try {
			await sendOtpRequest(location?.state?.mobile_number);
			setResendOtpSuccessMsg(true);
		} catch (error) {
			console.error(`resend otp error>>>`, error);
		}
	};

	const otpChangHandler = (value) => {
		setOtp(value);
		if (value.length === 6) {
			setBtnDisabled(false);
		}
	};

	const submitHandler = async () => {
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}login/verify-otp`,
				'POST',
				JSON.stringify({
					mobile_no: +location.state.mobile_number,
					isd_code: 91,
					otp,
				}),
				{
					'Content-Type': 'application/json',
					'x-auth-key': process.env.AUTH_KEY,
				}
			);
			// const response = {
			// 	success: true,
			// 	data: {
			// 		token:
			// 			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjQxNDIvYXBpL3YxL2xvZ2luL3ZlcmlmeS1vdHAiLCJpYXQiOjE3MDY2MTY0MzEsImV4cCI6MTcwNjYyMDAzMSwibmJmIjoxNzA2NjE2NDMxLCJqdGkiOiI4TnBrbXNhV1hhZWVyOHU1Iiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjciLCJpZCI6MSwibmFtZSI6bnVsbH0.KXvN6zcwwfCXyDWL7nrLnqyHmfDsCQ_KPQzhP9F0Tbo',
			// 		isFirstLogin: true,
			// 	},
			// 	message: 'Login successfully',
			// };

			login(response?.data?.token);
			if (response?.data?.isFirstLogin) {
				navigate('/signup', {
					state: { mobile_number: location.state.mobile_number },
				});
			} else {
				// setQueryUser(true);
				navigate('/');
			}
		} catch (error) {
			console.error(`Login API error>>>`, error);
		}
	};

	return (
		<>
			<div className="mt-7 mb-4 px-4  lg:flex lg:justify-center">
				<div className="text-center lg:w-1/4 lg:bg-desktopFormBg lg:h-3/5 lg:py-10 lg:px-4 relative">
					{(isLoading || sendOtpLoading) && <Loader />}
					<div className="mb-7 text-center">
						<Heading
							text={t('otp_verification')}
							additionalClasses="lg:text-3xxl"
						/>
						<SubHeading
							text={t('otp_verification_sub_text')}
							customClasses="lg:text-xl"
						/>
					</div>
					<div className="mb-9 text-center">
						<OTPInput length={6} onChange={otpChangHandler} />
					</div>
					<div className="mb-5">
						{isResendDisabled && (
							<SubHeading
								text={`${t('try_again_text')} 00:${timer}`}
								customClasses="lg:text-xl"
							/>
						)}
						{!isResendDisabled && (
							<button
								onClick={handleResend}
								disabled={isResendDisabled}
								className="text-pageSubHead text-base leading-5 lg:text-xl lg:leading-6"
							>
								{t('resendOTP')}
							</button>
						)}
					</div>
					{(apiError || sendOtpError) && <InputErrorMessage text={apiError} />}
					{resendOtpSuccessMsg && (
						<p className="text-sm text-green-900 font-normal lg:text-lg">
							OTP resent successfully.
						</p>
					)}
					<Button
						text={t('btn_submit')}
						disabled={btnDisabled}
						onClick={submitHandler}
						customClasses="lg:text-3xxl p-3"
					/>
				</div>
			</div>
		</>
	);
};

export default OtpVerification;
