{
	"home": "होम",
	"login": "लॉगिन करें",
	"mobile_number": "मोबाइल नंबर",
	"mobile_number_label": "मोबाइल नंबर दर्ज करें",
	"get_otp": "Get OTP",
	"mobile_error_message": "कृपया एक मान्य नंबर दर्ज करें ",
	"resendOTP": "ओटीपी दोबारा भेजें",
	"otp_verification": "ओटीपी वेरीफिकेशन ",
	"otp_verification_sub_text": "+91 {{mobile_number}} पर भेजा गया 6 अंकों वाला कोड दर्ज करें",
	"try_again_text": "{{time_remaining}} सेकेंड में ओटीपी दोबारा भेजें",
	"btn_submit": "सबमिट करें",
	"sign_up": "आप मंजिल के करीब हैं!",
	"enter_detail_text": "इसे प्लेइंग स्क्वाड से जुडने के लिए साइन अप करें!",
	"full_name": "पूरा नाम",
	"name": "नाम",
	"email_id": "ईमेल पता",
	"terms_text": "मेरी आयु कम से कम 18 वर्ष है और मुझे <tnc>नियम और शर्त</tnc> और <pp>गोपनीयता नीति</pp> मान्य हैं। ",
	"participate_win": "भाग लो और जीतो",
	"winners_list": "Winners List",
	"faqs": "अक्सर पूछे जाने वाले प्रश्न",
	"terms_conditions": "अद्यतन नियम और शर्ते ",
	"privacy_policy": "गोपनीयता नीति ",
	"logout": "लॉग आउट करें",
	"participate_now": "अभी भाग लें",
	"winner_board": "Winner Board",
	"enter_unique_code": "कोड दर्ज करें",
	"winners": "विजेताओं",
	"enter_code": "कोड दर्ज करें",
	"profile": "प्रोफाइल",
	"get_aboard_text": "तूफान में अपनी सीट को जीते",
	"btn_continue": "जारी रखें",
	"get_aboard_sub_text": "हमारे सभी तूफानी फैन्स के लिए  वेस्ट इंडीज में होने वाले आईसीसी टी20 विश्व कप के लिए विशेष रूप से तैयार किए गए लक्जरी अनुभव वाला हवाई सफ़र की इस कभी न भूलने वाले वन्स-इन-अ-लाइफ-टाइम यात्रा में हमसे जुड़े। ",
	"win_mileage_text": "",
	"win_mileage_sub_text": "हर एक Thums UP® के साथ इस एक्शन के नजदीक पहुंचें ",
	"my_kms": "मेरे किलोमीटर",
	"follow_us": "हमें फॉलो करें",
	"copyright_text": "© 2024 The Coca-Cola Company। सर्वाधिकार सुरक्षित ",
	"Please enter a valid unique code": "कृपया एक मान्य कोड दर्ज करें",
	"your_profile": "मेरी प्रोफाइल",
	"profile_page_desc": "विजेताओं से संपर्क करने के लिए हम नीचे के विवरणों का उपयोग करेंगे। कृपया सुनिश्चित करें कि दी गई सभी जानकारियां सही हैं",
	"dob": "जन्म तारीख",
	"gender": "लिंग",
	"set_as_default": "डिफाल्ट रूप से सेट",
	"congratulations": "बधाई हो!",
	"flight_ticket_claimed_text": "You’ve claimed your ticket to the West Indies! We will communicate further details via email & SMS shortly.",
	"share": "साझा करें",
	"claim_form_desc": "कृपया अपने प्राइज क्लेम करने के लिए नीचे विवरण दर्ज करें!",
	"full_name_claim_form": "पूरा नाम (सरकारी आईडी के अनुसार)",
	"pan_card": "पैन कार्ड",
	"passport_no": "पासपोर्ट नंब.",
	"passport_expiry_date": "पासपोर्ट की समय अवधि समाप्त होने की तारीख",
	"Male": "पुरुष",
	"Female": "महिला",
	"transgender": "ट्रांसजेंडर",
	"Other": "अन्य",
	"gift_voucher_claimed_text": "You’ve claimed your gift voucher! We will communicate further details via email & SMS shortly.",
	"claim_now": "अभी क्लेम करें",
	"paytm_1000_won_text": "You’ve won yourself a ₹1,000 Paytm voucher!",
	"paytm_1000_win_text": "Win a ₹1000 Paytm Voucher!",
	"paytm_100_won_text": "You’ve won yourself a ₹1,00 Paytm voucher!",
	"paytm_100_win_text": "Win a ₹100 Paytm Voucher!",
	"win_ticket": "Win a Ticket",
	"enter_unique_code_reach_kms": "Enter unique code to reach 14K KMs",
	"enter_name": "कृपया एक मान्य नाम दर्ज करें",
	"enter_mobile_number": "Please enter mobile number",
	"enter_email": "कृपया एक मान्य ईमेल आईडी दर्ज करें",
	"enter_pan": "कृपया एक मान्य पैन कार्ड दर्ज करें",
	"no_page_found": "पेज नहीं मिला",
	"go_to_home": "होमपेज पर जाएं",
	"eligible_1000_paytm_voucher": "You are now eligible for ₹1000 Paytm Gift Voucher",
	"win_prize_lucky_draw_daily": "Win Prizes in a lucky Draw Daily",
	"ticket_to_west_indies": "Ticket to West Indies",
	"ticket_to_west_indies_v1": "आपने तूफ़ानी फैन क्लब में जगह बना ली है!",
	"ticket_to_west_indies_subtext_v1": "आपने तूफ़ान में एक सीट जीत ली! वेस्ट इंडीज जाने के लिए शानदार उड़ान हेतु तैयार हो जाएं",
	"fan_jersy_heading": "प्रोफेशनल की तरह सूट करें",
	"fan_jersy_heading_text": "999 रुपये मूल्य की अपनी क्रिकेट जर्सी क्लेम करने के लिए यह एक FanCode वाउचर है ",
	"how_enter_lucky_draw": "How to Enter the Lucky Draw",
	"step_1": "स्टेप 1",
	"step_2": "स्टेप 2",
	"step_3": "स्टेप 3",
	"step_4": "स्टेप 4",
	"scan_qr_code_bottle": "Scan the QR code from your ThumbsUp bottle",
	"enter_code_kms_achieve_three_code": "Enter the unique code to accrue 14,000kms with 3 codes max",
	"enter_lucky_draw_win_prize": "Enter the lucky draw to win prizes daily",
	"paytm_gift_voucher": "Paytm gift Voucher",
	"faq_heading": "अक्सर पूछे जाने वाले प्रश्नों",
	"faq_subtext": "यहां आपको थम्स अप के बारे में जानने की जरूरत है",
	"terms_heading": "नियम और शर्ते ",
	"contest_over": "Contest Over",
	"thank_you_visit_page": "Thank you for visiting our page! This contest is now closed.",
	"participate_other_giveaways": "Please visit (link to ThumbsUp’s Contests page) to participate in other giveaways",
	"winner_list": "विजेता सूची",
	"full_name_error": "Please enter full name",
	"name_error": "कृपया एक मान्य नाम दर्ज करें",
	"dob_error": "आपकी आयु कम से कम 18 वर्ष होनी चाहिए, कृपया सही जन्मतिथि दर्ज करें",
	"passport_expiry_date_error_required": "Please enter a valid passport expiry date",
	"passport_expiry_date_error": "Passport expiry date field must be a date after today.",
	"passport_no_error": "Please enter a valid Passport number",
	"terms_agree_error": "कृपया नियम एवं शर्तों से सहमत हों",
	"email_error": "कृपया मान्य ईमेल पता दर्ज करें",
	"how_to_participate": "कैसे भाग लें",
	"toofani_champions": "तूफानी चैंपियंस",
	"refer_friend": "दोस्त को रेफर करें",
	"have_invite_code": "क्या आपके पास इनवाइट कोड है ?",
	"enter_collect_kms": "दर्ज करें और 100किलोमीटर एकत्र करें",
	"contact_us": "हमसे संपर्क करें",
	"prize_grab": "जीतें ये प्राइजेज",
	"seat_aboard_toofan": "तूफान में सीट लें \nहर दिन 1 विजेता*",
	"cricket_jersey_999_fancode": "FanCode की ओर से ₹999 की क्रिकेट जर्सी  \nहर घंटा 1 विजेता*",
	"toofani_999_fancode": "FanCode की ओर ₹499 मूल्य का तूफानी मर्च  \nहर घंटा 15 विजेता*",
	"find_unique_code_pack": "पैक पर यूनिक कोड पाएं",
	"enter_code_collect_kms": "किलोमीटर एकत्र करने के लिए कोड दर्ज करें ",
	"complete_journey_destination": "मंजिल तक की यात्रा पूरी करें",
	"get_chance_win": "जीतने का अवसर पाएं *!",
	"refer_friend_collect_kms": "अतिरिक्त किलोमीटर एकत्र करने के लिए दोस्त को रेफर करें*",
	"get_100km_mate_signup": "सफलतापूर्वक साइन अप करने वाले और आपके इनवाइट कोड का उपयोग करने वाले हर एक दोस्त पर 100 किलोमीटर पाएं।",
	"refer_now": "रेफर करें",
	"tnc_apply": "नियम और शर्त लागू",
	"available": "उपलब्ध",
	"booked": "बुक हो चुके",
	"going_soon": "जल्द जाने वाले ",
	"you_made_toofani_fan_club": "आपने तूफ़ानी फैन क्लब में जगह बना ली है!",
	"won_seat_get_ready": "आपने तूफ़ान में एक सीट जीत ली! वेस्ट इंडीज जाने के लिए शानदार उड़ान हेतु तैयार हो जाएं\nअभी क्लेम करें",
	"jersey_won_text": "प्रोफेशनल की तरह सूट करें",
	"jersey_won_subtext": "999 रुपये मूल्य की अपनी क्रिकेट जर्सी क्लेम करने के लिए यह एक FanCode वाउचर है ",
	"redeem_now": "अभी रिदीम करें",
	"voucher_won_text": "कुछ टीम भावना जगाएं",
	"voucher_won_subtext": "499 रुपये मूल्य का अपने क्रिकेट मर्च क्लेम करने के लिए यह एक FanCode वाउचर है",
	"my_wins": "मेरी जीत",
	"optional": "(वैकल्पिक)",
	"sign_up_cta": "साइन अप करें",
	"Please enter a valid OTP": "कृपया एक वैध ओटीपी दर्ज करें",
	"enter_collect_100kms": "दर्ज करें और 100किलोमीटर एकत्र करें ",
	"unique_code_entry_text": "वेस्ट इंडीज में आईसीसी मैन्स टी20 विश्व कप 2024 के लिए तूफ़ान की उड़ान भरने का अवसर पाएं",
	"unique_code_entry_subtext": "Thums Up® बोतल के लेबल के पीछे यूनिक कोड पाएं",
	"mobile_number_short": "मोबाइल नंबर",
	"welcome_player": "स्वागत है, खिलाड़ी!",
	"login_start_winning": "लॉगिन करें और जीतना शुरू करें!",
	"trademark_text": "कार्बोनेटेड वाटर। ट्रेडमार्क: The Coca-Cola Company",
	"address": "पता",
	"address_line_1": "पता पंक्ति 1",
	"address_line_2": "पता पंक्ति 2",
	"nearest_landmark": "निकट का लैंडमार्क",
	"alternative_mobile_number": "वैकल्पिक मोबाइल नंबर",
	"pincode": "पिन कोड",
	"state": "राज्य",
	"city": "शहर",
	"state_error": "कृपया राज्य चुनें",
	"city_error": "कृपया शहर चुनें",
	"pincode_error": "कृपया एक मान्य पिनकोड दर्ज करें",
	"address_error": "कृपया एक मान्य पता दर्ज करें",
	"pan_error": "कृपया एक मान्य पैन कार्ड नंबर दर्ज करें",
	"copy_code": "कोड कॉपी करें",
	"last_date_redeem": "रिदीम करने की अंतिम तारीख",
	"last_date_to_claim": "क्लेम करने की अंतिम तारीख",
	"share_with_friends": "दोस्तों के साथ साझा करें",
	"no_internet_message": "कनेक्ट करने में असमर्थ। कृपया अपना नेटवर्क कनेक्शन जांचें और दोबारा प्रयास करें! \nपेज को रीफ्रेश करें ",
	"email_us": "पर हमें ईमेल करें ",
	"call_toll_free": "हमें इस टॉल फ्री नंबर पर कॉल करें ",
	"mon_sat": "सुबह 9:00बजे से शाम 9:00बजे तक(सोमवार से शनिवार)",
	"sunday": "सुबह 9:00बजे से शाम 6:00 बजे तक (रविवार)",
	"follow_us_now": "अभी हमें फॉलो करें",
	"otp_expired_enter_new": "आपके ओटीपी की अवधि समाप्त हो गई। कृपया नया ओटीपी उपयोग करें।",
	"email_address": "ईमेल पता",
	"Code has already been used, please enter a new code": "इस कोड का उपयोग किया जा चुका है, कृपया एक नया कोड दर्ज करें",
	"first": "प्रथम",
	"last": "अंतिम",
	"add": "दर्ज करें",
	"Seat on Toofan": "तूफान में सीट ",
	"Toofani Merch": "तूफानी मर्च ",
	"Cricket Jersey": "क्रिकेट जर्सी ",
	"You are here": "आप यहाँ आ गए",
	"OTP resent successfully": "ओटीपी सफलतापूर्वक पुनः भेजा गया",
	"No winners till now": "अब तक कोई विनर नहीं हैं",
	"COPIED": "कॉपी किया गया",
	"T&C": "अद्यतन नियम एवं शर्तें",
	"Email is already in use": "ईमेल पहले से ही उपयोग में है",
	"Clear": "क्लियर करें",
	"West Indies": "वेस्ट इंडीज",
	"India": "भारत",
	"Reminder send successfully": "अनुस्मारक सफलतापूर्वक भेजा गया",
	"KM": "कि.मी.",
	"KMs": "कि.मी.",
	"Edit Address": "Edit Address",
	"Please enter a valid code": "कृपया एक मान्य कोड दर्ज करें",
	"final_day_offer": "ऑफर अवधि का अंतिम दिन 30 अप्रैल 2024 से बढ़ाकर 15 मई 2024 कर दिया गया है. <tnc>नियम और शर्त लागू</tnc>"
}
