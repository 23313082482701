import { useState, useEffect } from 'react';
import Dialog from './Dialog';
import LoginForm from './LoginForm';
import OtpVerificationForm from './OtpVerificationForm';
import SignupForm from './SignupForm';
import { useAuthContext } from '../context/auth-context';

const LoginJourneyDialog = ({ open, handleOpen }) => {
	const [loginStep, setLoginStep] = useState(1);
	const [mobileNumber, setMobileNumber] = useState('');

	const { queryUser } = useAuthContext();

	useEffect(() => {
		open && setLoginStep(1);
	}, [open]);

	let LoginComp;
	switch (loginStep) {
		case 1:
			LoginComp = LoginForm;
			break;
		case 2:
			LoginComp = OtpVerificationForm;
			break;
		case 3:
			LoginComp = SignupForm;
			break;
		default:
			break;
	}

	const handleOpenHandler = () => {
		if (loginStep === 3 && !queryUser) {
			return false;
		} else {
			handleOpen();
		}
	};

	return (
		<Dialog
			open={open}
			handleOpen={handleOpenHandler}
			className="w-full lg:w-2/5"
		>
			<LoginComp
				setLoginStep={setLoginStep}
				setMobileNumber={setMobileNumber}
				mobileNumber={mobileNumber}
			/>
		</Dialog>
	);
};

export default LoginJourneyDialog;
