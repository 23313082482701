import ButtonDefault from './Button';
import { Button } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import FancodeIcon from '../../public/fancode_icon.svg';
import Fancode499Icon from '../../public/fancode_499_icon.svg';
import Typography from './Typography';
import BottomBorderIcon from '../../public/bottom_border_icon.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SocialShareButton from './share/SocialShareButton';
import { gaEvents } from '../utils/gaEvents';
import { useCDP } from '../utils/useCDP';

const Gift = ({
	imgSrc,
	text,
	subText,
	btnText,
	btnOnClick,
	labelBtnText,
	share,
	expiry,
	fanCodeIcon,
	couponCode,
	redeemLink,
	referFriendHanlder,
	remaining_expiry_hours,
	expiry_date,
	giftClasses = 'bg-giftBg',
	winMessage,
	winImage,
	winCode,
	redeemEvent,
	shareBtnTxt = '',
}) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [copyTxt, setCopyTxt] = useState(t('copy_code'));
	const sendCDPData = useCDP();

	const copyCouponCode = (str) => {
		navigator.clipboard
			.writeText(str)
			.then(() => {
				setCopyTxt(t('COPIED'));
				setTimeout(() => setCopyTxt(t('copy_code')), 1000);
				// Optionally, you can show a success message to the user
			})
			.catch((err) => {
				console.error('Failed to copy text:', err);
				// Optionally, you can show an error message to the user
			});
	};

	const onRedeemClick = () => {
		// GA Event
		window.dataLayer.push(gaEvents[redeemEvent]);

		if (winCode === 2) {
			sendCDPData('Click', 'Redeem_Fancode999');
		} else {
			sendCDPData('Click', 'Redeem_Fancode499');
		}
	};

	return (
		<div className="rounded-lg bg-inputBg">
			<div
				className={`rounded-t-lg ${giftClasses}  flex flex-col justify-between items-center mb-3 w-full ${
					winCode === 1 ? 'h-32' : ''
				} lg:h-[21.25rem] relative lg:mb-6`}
			>
				<div
					className={`w-[11.5rem] h-[8.68rem] lg:w-[28.25rem] flex-1 flex justify-center ${
						winCode === 1 ? 'items-center' : 'items-end'
					}`}
				>
					<img
						src={imgSrc}
						className={`rounded-lg w-full ${winCode === 2 ? 'lg:h-full' : ''}`}
					/>
				</div>
				{fanCodeIcon && (
					<div className="absolute top-2.5 left-2.5 w-[4.438rem] h-6 lg:w-[18.31rem] lg:h-[5.68rem]">
						<img src={FancodeIcon} alt="fancode499" className="w-full h-full" />
					</div>
				)}
				<div className="w-full h-2 bg-border-bottom-bg bg-cover bg-no-repeat bg-center"></div>
			</div>
			<div className="flex flex-col justify-normal items-center">
				<Typography
					variant="h3"
					type="text24"
					text={text}
					customClasses="mb-1 lg:text-5xl lg:leading-[3.25rem] lg:mb-3 text-center"
				/>
				<h3 className="text-center font-medium text-voucherSubText mb-4 lg:text-4xl !leading-normal">
					{subText}
				</h3>

				<div className="flex flex-col justify-normal items-center lg:flex-row lg:justify-center lg:items-baseline lg:gap-3 w-full lg:mb-4 ">
					{expiry !== 'Expired' && couponCode && (
						<div className="border-2 pl-4 border-dashed border-otherTextColor  font-thumbsUp w-3/4 mb-5 flex justify-between items-center gap-3 rounded-lg lg:w-auto">
							<div className="py-2 lg:py-5">
								<Typography variant="h3" type="text24" text={couponCode} />
							</div>
							<div
								className="py-2 lg:py-5 px-4 bg-otherTextColor rounded-lg"
								onClick={() => copyCouponCode(couponCode)}
							>
								<Typography
									variant="h3"
									type={
										i18n.language === 'en'
											? 'text20'
											: i18n.language !== 'ka' && i18n.language !== 'te'
											? 'text16'
											: 'text12'
									}
									text={copyTxt}
									customClasses="text-white"
								/>
							</div>
						</div>
					)}
					{expiry !== 'Expired' &&
						(redeemLink ? (
							<div className=" mb-2  ">
								<ButtonDefault
									text={btnText}
									customClasses="text-xl font-normal p-2 lg:text-3xxl lg:p-5"
									actionLink={redeemLink}
									onClick={onRedeemClick}
								/>
							</div>
						) : (
							<div className="  pb-3  ">
								<ButtonDefault
									text={btnText}
									customClasses="text-xl font-normal p-2 lg:text-3xxl lg:p-5"
									onClick={btnOnClick}
								/>
							</div>
						))}
				</div>

				{expiry === 'Expired' ? (
					<div className="  pb-3  ">
						<ButtonDefault
							text={expiry}
							disabled="true"
							customClasses="text-xl font-normal p-2 lg:text-3xxl lg:p-5"
							onClick={() => {}}
						/>
					</div>
				) : (
					labelBtnText && (
						<div className="w-2/3  text-center mt-2 pb-2">
							<span
								className={`text-gray ${
									i18n.language === 'en' ? 'text-[1rem]' : 'text-xs'
								} `}
							>
								{labelBtnText}
							</span>
							<span
								className={`text-errorText ${
									i18n.language === 'en' ? 'text-[1rem]' : 'text-xs'
								}`}
							>
								{' '}
								{expiry}
							</span>
						</div>
					)
				)}

				{expiry !== 'Expired' && redeemLink && referFriendHanlder && (
					<SocialShareButton
						handleShare={referFriendHanlder}
						title={winMessage}
						text=""
						btnText={shareBtnTxt}
						url=""
						file={winImage}
						winCode={winCode}
						sendCDPData={sendCDPData}
					/>
				)}
			</div>
		</div>
	);
};

export default Gift;
