import { useState, useEffect, useCallback } from 'react';
import { useUserContext } from '../context/user-context';

let logoutTimer;
import { useNavigate } from 'react-router-dom';
export const useAuth = () => {
	const [token, setToken] = useState(
		(sessionStorage.getItem('userData') &&
			JSON.parse(sessionStorage.getItem('userData'))?.token) ||
			null
	);
	const navigate = useNavigate();
	// const [userId, setUserId] = useState(null);
	const [tokenExpirationDate, setTokenExpirationDate] = useState(null);
	const [queryUser, setQueryUser] = useState(false);
	const { notificationCount, setNotificationCount } = useUserContext();
	const login = useCallback((token, expirationDate = null) => {
		setToken(token);
		// setUserId(uid);
		const tokenExpirationDate =
			expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
		setTokenExpirationDate(tokenExpirationDate);
		sessionStorage.setItem(
			'userData',
			JSON.stringify({
				// userId: uid,
				token,
				expiration: tokenExpirationDate.toISOString(),
			})
		);
	}, []);
	const logout = useCallback(() => {
		setToken(null);
		// setUserId(null);
		setQueryUser(false);
		sessionStorage.removeItem('userData');
		sessionStorage.removeItem('hasSeenWinPopup');
		setTokenExpirationDate(null);
		setNotificationCount(0);
		navigate('/');
	}, []);

	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime =
				tokenExpirationDate.getTime() - new Date().getTime();
			logoutTimer = setTimeout(logout, remainingTime);
		} else {
			clearTimeout(logoutTimer);
		}
	}, [token, tokenExpirationDate, logout]);

	useEffect(() => {
		const storedData = JSON.parse(sessionStorage.getItem('userData'));
		if (
			storedData &&
			storedData.token &&
			new Date(storedData.expiration) > new Date()
		) {
			login(
				// storedData.userId,
				storedData.token,
				new Date(storedData.expiration)
			);
		}
	}, [login]);

	return {
		login,
		logout,
		// userId,
		token,
		queryUser,
		setQueryUser,
	};
};
