import {
	Dialog,
	DialogHeader,
	DialogBody,
	DialogFooter,
} from '@material-tailwind/react';
import CloseIcon from '../../public/cross_icon.svg';

const DialogDefault = ({
	hideCrossIcon,
	open,
	handleOpen,
	children,
	bodyHeight = '',
	classesNames="bg-popup-bg bg-cover bg-no-repeat bg-center px-4 py-6"
}) => {
	return (
		<Dialog
			open={open}
			handler={handleOpen}
			className={classesNames}
		>
			{!hideCrossIcon && (
				<div className="w-3.5 h-3.5 absolute top-4 right-3.5 z-10">
					<img src={CloseIcon} onClick={handleOpen} />
				</div>
			)}
			<DialogBody
				className={` md:overflow-visible sm:overflow-visible ${bodyHeight}`}
			>
				{children}
			</DialogBody>
		</Dialog>
	);
};

export default DialogDefault;
