import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import Button from '../components/Button';
import Input from '../components/Input';
import Heading from '../components/Heading';
import Checkbox from '../components/Checkbox';
import DatePicker from '../components/DatePicker';
import InputErrorMessage from '../components/InputErrorMessage';
import Loader from '../components/Loader';
import { useApiCall } from '../utils/useApiCall';
import { formatDate } from '../utils/common';
import { AuthContext } from '../context/auth-context';
import ProfilePicUploadIcon from '../../public/profile_pic_upload.svg';
import ProfilePicUpload from '../components/ProfilePicUpload';
import Typography from './Typography';
import { useCampaignContext } from '../context/campaign-context';
import { gaEvents } from '../utils/gaEvents';
import { insertFloodlightTag } from '../utils/common';

const SignupForm = ({ mobileNumber }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { token, setQueryUser } = useContext(AuthContext);
	const { setOpenLoginForm, openLoginForm } = useCampaignContext();
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const [openProfileSelection, setOpenProfileSelection] = useState(false);
	const [avatars, setAvatars] = useState([]);
	const [profilePicName, setProfilePicName] = useState();
	const [defaultProfilePic, setDefaultProfilePic] =
		useState(ProfilePicUploadIcon);

	useEffect(() => {
		const getAvatars = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}avatars`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
					}
				);

				if (response?.success) {
					const avatarObj = response?.data;
					const avatarArr = Object.keys(avatarObj).map((key) => ({
						key,
						value: avatarObj[key],
					}));
					setAvatars(avatarArr);
				} else {
					throw new Error(response?.message);
				}
			} catch (error) {
				console.log(`GET avatar API error>>>`, error.message);
			}
		};

		getAvatars();
	}, []);

	const yupSchema = useCallback(
		yup.object({
			full_name: yup
				.string()
				.required(t('name_error'))
				.matches(/^[a-zA-Z\s]+$/, t('name_error')),
			email_id: yup.string().nullable().email(t('email_error')),
			agree_terms: yup.boolean().oneOf([true], t('terms_agree_error')),
		}),
		[['email_id', 'email_id']]
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	useEffect(() => {
		setValue('full_name', watch('full_name')?.replace(/[^A-Za-z\s]/g, ''));
	}, [watch('full_name')]);

	const submitHandler = async (data) => {
		// Floodlight tags

		insertFloodlightTag({
			activityName: 'ThumsUp_ICC24_SignUp_Counter',
			imageTag: 'thums03w', // Specific image tag for HomePage
		});
		if (typeof fbq !== 'undefined') {
			fbq('trackCustom', 'ICC24SignUp');
		} else {
			console.warn('FB Pixel not initialized.');
		}
		const { full_name, email_id, mobile_number, dob } = data;

		// Get IP details
		let ip_detail = {};
		try {
			ip_detail = await sendRequest(
				process.env.GET_IP_DETAIL_URL,
				'GET',
				null,
				{ headers: { 'Content-Type': 'application/json' } }
			);
		} catch (error) {
			console.log(`IP details fetch error>>>`, error);
		}

		// Convert date object to yyyy-mm-dd
		const formattedDob = formatDate(dob);

		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}user/signup`,
				'POST',
				JSON.stringify({
					name: full_name || undefined,
					email: email_id || undefined,
					ip_detail,
					profile_pic: profilePicName,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				setQueryUser(true);
				setOpenLoginForm(!openLoginForm);
				// Track the signup event with GTM
				window.dataLayer.push(gaEvents['TUICC24_SignUp']);
				navigate('/');
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			console.error(`Signup API error>>>`, error);
		}
	};

	const onPicClickHandler = (picName, picUrl) => {
		setProfilePicName(picName);
		setDefaultProfilePic(picUrl);
		setOpenProfileSelection(!openProfileSelection);
	};

	return (
		<div className="flex flex-col justify-between items-center font-thumbsUp">
			{isLoading && <Loader />}
			<div className="mb-5 text-center flex flex-col items-center justify-normal">
				{/* <Heading text={t('sign_up')} additionalClasses="lg:text-3xxl" /> */}

				{/* <h3 className="text-pageSubHead text-base lg:text-xl mb-2">
						{t('enter_detail_text')}
					</h3> */}
				<Typography variant="h2" type="text24" text={t('sign_up')} />
				<Typography variant="h3" type="text16" text={t('enter_detail_text')} />
				<div
					className="w-[3.313rem] h-[3.438rem]"
					onClick={() => setOpenProfileSelection(!openProfileSelection)}
				>
					<img src={defaultProfilePic} className="w-full" />
				</div>
			</div>
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="mb-6">
					<Controller
						name="full_name"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.full_name}
								placeholder={`${t('name')}*`}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.full_name?.message} />
				</div>

				<div className="mb-6">
					<Controller
						name="email_id"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.email_id}
								placeholder={`${t('email_id')} (${t('optional')})`}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.email_id?.message} />
				</div>

				<div className="flex items-center mb-4 gap-2">
					<Controller
						name="agree_terms"
						control={control}
						defaultValue={false}
						render={({ field: { ref, ...rest } }) => (
							<Checkbox {...rest} id="agreeTerms" />
						)}
					/>

					<label
						htmlFor="agreeTerms"
						className="text-black text-xs lg:text-base lg:leading-5"
					>
						{/* {t('terms_text')}I certify that I am at least 18 years old &
						have read the{' '}
						<Link
							to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/tnc/ICC24-TnCs.pdf"
							target="_blank"
							className="text-textLink"
						>
							T&C{' '}
						</Link>
						and{' '}
						<Link
							to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
							target="_blank"
							className="text-textLink"
						>
							{t('privacy_policy')}
						</Link>
						. */}
						<Trans
							i18nKey="terms_text"
							defaults="I certify that I am at least 18 years old & have read the <tnc>T&C</tnc> and <pp>Privacy Policy</pp>."
							components={{
								tnc: (
									<a
										href="https://017533-content-dev.s3.ap-south-1.amazonaws.com/tnc/ICC24-TnCs.pdf"
										target="_blank"
										style={{ color: '#1985D3' }}
									/>
								),
								pp: (
									<a
										href="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
										target="_blank"
										style={{ color: '#1985D3' }}
									/>
								),
							}}
						/>
					</label>
				</div>
				<InputErrorMessage text={errors?.agree_terms?.message} />
				{apiError && <InputErrorMessage text={t(apiError)} />}
				<Button text={t('sign_up_cta')} customClasses="lg:text-3xxl p-3" />
			</form>
			{openProfileSelection && (
				<div className="absolute top-32 left-1/2 transform -translate-x-1/2">
					<ProfilePicUpload items={avatars} onClick={onPicClickHandler} />
				</div>
			)}

		</div>
	);
};

export default SignupForm;
