import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import IconTextVertical from './IconTextVertical';
import Typography from './Typography';
import FlightJourney from '../../public/flight_right_icon.svg';
import JerseyIcon from '../../public/jersey_icon.svg';
import FancodeIcon from '../../public/fancode_banner_icon.svg';
import BottleUniquecodeIcon from '../../public/bottle_unique_code_v2.svg';
import CodeIcon from '../../public/unique_code_white.svg';
import DestinationIcon from '../../public/destination_icon.svg';
import FlightSeatIcon from '../../public/flight_seat_icon.svg';
import ReferFriendIcon from '../../public/refer_friend_popup_icon.svg';
import BannerFlightIcon from '../../public/banner_flight_icon.svg';
import WCT20Icon from '../../public/wc_t20_icon_tu.svg';
import ThumbsupPiyoIcon from '../../public/thumbs_up_piyo.svg';
import Button from './Button';

const UniqueCodeBanner = ({ onParticipateClick = () => {} }) => {
	const { t, i18n } = useTranslation();

	let bannerContent;

	bannerContent = (
		<>
			<Typography
				variant="h2"
				type={i18n.language === 'en' ? 'text24' : 'text16'}
				text={t('how_to_participate')}
				customClasses={`${
					i18n.language === 'en' ? 'mb-4' : 'mb-2'
				} font-normal text-center text-white lg:text-[4.25rem] lg:leading-normal`}
			/>
			<div
				className={`flex justify-between items-center ${
					i18n.language === 'en' ? 'gap-5 lg:gap-16' : 'gap-1 lg:gap-8'
				}  mb-3`}
			>
				<IconTextVertical
					imgSrc={BottleUniquecodeIcon}
					text={t('find_unique_code_pack')}
					subText={t('step_1')}
					bottle={true}
					lang={i18n.language}
				/>
				<IconTextVertical
					imgSrc={CodeIcon}
					text={t('enter_code_collect_kms')}
					subText={t('step_2')}
					lang={i18n.language}
				/>
				<IconTextVertical
					imgSrc={DestinationIcon}
					text={t('complete_journey_destination')}
					subText={t('step_3')}
					lang={i18n.language}
				/>
				<IconTextVertical
					imgSrc={FlightSeatIcon}
					text={t('get_chance_win')}
					subText={t('step_4')}
					lang={i18n.language}
				/>
			</div>
			<div className="">
				<Button
					text={t('enter_unique_code')}
					customClasses="p-2 text-base lg:p-7 lg:text-[2.5rem] mb-1 font-thumbsUp"
					onClick={onParticipateClick}
				/>
			</div>
		</>
	);

	return (
		<div className="relative w-full h-[16.875rem] lg:h-[37.25rem] flex flex-col">
			<div className="flex flex-col justify-center items-center flex-1">
				{bannerContent}
			</div>
			<Typography
				variant="h4"
				type="text12"
				text={`*${t('tnc_apply')}`}
				customClasses="text-right pr-2 pb-2 text-white font-normal"
			/>
		</div>
	);
};

export default UniqueCodeBanner;
