export const getProfileResponse = {
	success: true,
	data: {
		id: 5,
		name: 'THUP User',
		email: 'thump1@gmail.com',
		isd_code: 91,
		mobile_no: 76454534498,
		profile: {
			user_id: 5,
			dob: '1994-12-21',
			gender: 'Male',

			profile: null,
			my_kms: 5000,
		},
		address: [],
	},
	message: 'ok',
};
