import UserIcon from "../../public/user_icon.svg";
import RightTick from "../../public/right_green_tick.svg";
import {useTranslation} from "react-i18next";
import FlightIcon from "../../public/flight_takeoff_icon.svg";
import {useEffect} from "react";

const Referrals = ({referrals,setEnableSendReminder}) => {
    const { t } = useTranslation("affiliate");
    // Inside your component
    useEffect(() => {
        // This will check all referrals and update the state accordingly
        // but only once when the component is rendered
        const shouldSetReminder = referrals.some(referral => referral.isOlderThanWeek);
        shouldSetReminder && setEnableSendReminder(true);

    }, [referrals, setEnableSendReminder]);
  return (
      <div className="mt-4 space-y-2">
          <div className="flex justify-center items-center px-4 py-2 h-39 bg-otherTextColor rounded text-white">

              <div className="w-1/2 flex-shrink-0">{t("users")}</div>
              <div className="w-1/3 ml-4 text-center">{t("status")}</div>
          </div>
          {referrals.map((referral, index) => (
              <div key={index} className="flex items-center bg-inputBg px-4 py-2 h-39 text-base font-medium">
                  <div className="flex items-center w-1/2 ml-4 text-center">
                      <img src={referral.profile ? referral.profile : UserIcon} alt="Avatar"
                           className="w-8 h-8 rounded-full"/>
                      <span className="ml-2">{referral.mobile}</span>
                  </div>

                  {/* Adjust width as needed */}
                  <div className="w-1/2 ml-4 flex justify-end text-center">{referral.affiliate_id !== 0 && <img src={RightTick} alt="tick" className="w-3 h-3"/>}</div>
                  <div className="w-1/3 flex justify-start px-2"> {/* Adjust width as needed */}
                      {referral.affiliate_id !== 0 ? (<div className="font-medium text-base">{t(referral.status)}</div>) :
                          <div className="font-medium text-base text-gray-700">{t(referral.status)}</div>}
                  </div>
              </div>
          ))}


      </div>
  );
}
export default Referrals;