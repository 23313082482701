const DrawerMenuItem = ({
	imgSrc,
	text,
	subText,
	onClick = () => {},
	lang = 'en',
}) => {
	return (
		<div
			className="flex justify-start gap-3 items-center cursor-pointer"
			onClick={onClick}
		>
			<div className="w-4.5 h-4.5">
				<img src={imgSrc} alt={text} />
			</div>
			<div>
				<h3
					className={`text-black ${
						lang === 'en' ? 'text-2xl' : 'text-base'
					} font-light`}
				>
					{text}
				</h3>
				{subText && (
					<h3 className="text-black text-base font-normal">{subText}</h3>
				)}
			</div>
		</div>
	);
};

export default DrawerMenuItem;
