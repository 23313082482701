import clsx from 'clsx';

const Select = ({
	isValid = true,
	customClasses = '',
	disabled = false,
	selectHeading,
	items,
	// onChange,
	...rest
}) => {
	return (
		<select
			{...rest}
			disabled={disabled}
			className={clsx(
				`bg-inputBg lg:bg-white placeholder-placeHolderColor p-4 rounded-lg w-full text-xl lg:text-2xl ${customClasses}`,
				{
					'border border-errorText': !isValid,
					'text-disabled': disabled,
				}
			)}
			// onChange={onChange}
		>
			<option>{selectHeading}</option>
			{items.map((item) => (
				<option value={item.id} key={item.name}>
					{item.name}
				</option>
			))}
		</select>
	);
};

export default Select;
