import {
	useState,
	useContext,
	useLayoutEffect,
	useRef,
	useEffect,
} from 'react';
import { Carousel } from '@material-tailwind/react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import CarouselImage1 from '../../public/carousel_1.svg';
import SeatAvailableIcon from '../../public/seat_available.svg';
import SeatBookedIcon from '../../public/seat_booked.svg';
import SeatHighlightIcon from '../../public/seat_highlight.svg';
import SeatGuideItem from '../components/SeatGuideItem';
import FlightWithBookings from '../../public/flight_with_bookings.svg';
import FlightHorizontal from '../../public/flight_horizontal.svg';
import UserJourney from '../../public/user_journey.svg';
import FacebookIcon from '../../public/facebook.svg';
import InstagramIcon from '../../public/instagram.svg';
import TwitterIcon from '../../public/twitter.svg';
import Button from '../components/Button';
import { useAuthContext } from '../context/auth-context';
import { useCampaignContext } from '../context/campaign-context';
import HomePageBanner from '../components/HomepageBanner';
import Typography from '../components/Typography';
import FlightSeatFilling from '../components/FlightSeatFilling';
import FlightMapCurveSvg from '../components/FlightMapCurveSvg';
import IndiaIcon from '../../public/india_icon.svg';
import WestIndiesIcon from '../../public/west_indies_icon.svg';
import FlightAnimation from '../components/FlightAnimation';
import FlightRedIcon from '../../public/flight_red_icon.svg';
import { useIsMobile } from '../utils/useIsMobile';
import { useCDP } from '../utils/useCDP';
import HomePageCarousel from '../components/HomePageCarousel';
import YoutubeIcon from '../../public/youtube_mobile_icon.svg';
import { useUserContext } from '../context/user-context';
import BoxArrow from '../../public/box_arrow.svg';
import { triggerTrackingPixel, insertFloodlightTag } from '../utils/common';
import CloseIcon from '../../public/cross_icon.svg';
// import TrackingScript from "../utils/TrackingScript";

const Homepage = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const isMobile = useIsMobile();

	const { isLoggedIn } = useAuthContext();
	const { historyBackOption, setHistoryBackOption, userData } =
		useUserContext();
	const sendCDPData = useCDP();

	const {
		openUniqueCodeForm,
		setOpenUniqueCodeForm,
		openLoginForm,
		setOpenLoginForm,
		seatBooked,
		flightMileStone,
		setOpenAffiliateForm,
		otherStates,
		setOtherStates,
		totalPoints,
		thresholdPoint,
	} = useCampaignContext();
	// Create a ref for the target element
	const targetRef = useRef(null);

	const [displayMessage, setDisplayMessage] = useState(true);

	let kmMessagePos = 'top-[28%] right-[25%] lg:right-[24%]';

	if (flightMileStone === 2) {
		kmMessagePos = 'top-[60%] right-[50%] lg:right-[49%]';
	} else if (flightMileStone === 3) {
		kmMessagePos = 'top-[35%] right-[76%] lg:right-[75%]';
	}

	useEffect(() => {
		sendCDPData('Page_view', 'Homepage_load');
	}, []);
	useEffect(() => {
		if (
			isLoggedIn &&
			userData.name &&
			userData.name.length > 1 &&
			(historyBackOption.isDialogOpen || historyBackOption.isPageOpen)
		) {
			historyBackOption.redirectLink();
			setHistoryBackOption({
				...historyBackOption,
				isDialogOpen: false,
				isPageOpen: false,
				redirectLink: () => {},
			});
		}
	}, [isLoggedIn, historyBackOption, userData]);

	/*useEffect(() => {
		setTimeout(() => {
			insertFloodlightTag({
				activityName: 'ThumsUp_ICC24_HomePage_CounterTest',
				imageTag: 'thums04u', // Specific image tag for HomePage
			});
			console.log("after 5 sec run ThumsUp_ICC24_HomePage_CounterTest")
		}, 5000); // 5000 milliseconds = 5 seconds
	}, []);*/
	useEffect(() => {
		setTimeout(() => {
			insertFloodlightTag({
				activityName: 'ThumsUp_ICC24_HomePage_Counter',
				imageTag: 'thums03s', // Specific image tag for HomePage
			});
			console.log('after 5 sec run ThumsUp_ICC24_HomePage_Counter');
		}, 5000); // 5000 milliseconds = 5 seconds
	}, []);
	// Use useLayoutEffect to scroll to the element after the component mounts
	useLayoutEffect(() => {
		// Check if the element is currently in view
		let scrollToCloserActionInterval;
		if (otherStates.scrollToCloserAction) {
			if (targetRef.current) {
				// Scroll the element into view
				targetRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
			scrollToCloserActionInterval = () =>
				setTimeout(() => {
					setOtherStates({ ...otherStates, scrollToCloserAction: false });
				}, 1000);
		}

		return () => {
			scrollToCloserActionInterval &&
				clearTimeout(scrollToCloserActionInterval);
		};
	}, [otherStates]);
	const participateHandler = () => {
		// Image Pixel
		triggerTrackingPixel(
			'https://insight.adsrvr.org/track/pxl/?adv=2xop800&ct=0:7hrz99b&fmt=3'
		);
		if (typeof fbq !== 'undefined') {
			fbq('trackCustom', 'ICC24Participatenow');
		} else {
			console.warn('FB Pixel not initialized.');
		}
		if (isLoggedIn) {
			setOpenUniqueCodeForm(!openUniqueCodeForm);
		} else {
			setOpenLoginForm(!openLoginForm);
			setHistoryBackOption({
				...historyBackOption,
				isDialogOpen: true,
				redirectLink: () => {
					setOpenUniqueCodeForm(!openUniqueCodeForm);
				},
			});
		}
	};

	const myKmHandler = () => {
		if (isLoggedIn) {
			// setOpenUniqueCodeForm(!openUniqueCodeForm);
			navigate('/profile');
		} else {
			navigate('/login');
		}
	};

	return (
		<>
			{displayMessage && (
				<div className="py-2 px-4 lg:py-3  flex justify-between items-center bg-btnbg gap-2">
					<p
						className={`${
							i18n.language === 'en'
								? 'text-xs lg:text-lg'
								: 'text-[0.5rem] lg:text-sm'
						}   text-white leading-normal font-thumbsUp font-normal`}
					>
						<Trans
							i18nKey="Offer Period has ended."
							defaults="Offer Period has ended."
						/>
					</p>

				</div>
			)}
			<div className="bg-page-bg-image bg-cover bg-no-repeat bg-center   pt-2 pb-5  lg:pt-9 lg:pb-20">
				{/*{<TrackingScript />}*/}

				<section className="px-4 lg:px-36">
					<HomePageCarousel
						participateHandler={participateHandler}
						referClickHandler={() => {
							// Image Pixel
							triggerTrackingPixel(
								'https://insight.adsrvr.org/track/pxl/?adv=2xop800&ct=0:5g32ofe&fmt=3'
							);
							// Floodlight tags

							insertFloodlightTag({
								activityName: 'ThumsUp_ICC24_ReferAFriend_Counter',
								imageTag: 'thums03z', // Specific image tag for HomePage
							});
							if (typeof fbq !== 'undefined') {
								fbq('trackCustom', 'ICC24Refernow');
							} else {
								console.warn('FB Pixel not initialized.');
							}
							setOpenAffiliateForm(true);
						}}
					/>
				</section>
				<section className="flex flex-col items-center px-4 lg:px-36">
					<Typography
						variant="h2"
						type="text24"
						text={t('get_aboard_text')}
						customClasses="text-pageTitleColor mb-2 lg:text-5xl lg:leading-[3.25rem] text-center"
					/>
					<Typography
						variant="h3"
						type="text14"
						text={t('get_aboard_sub_text')}
						customClasses="text-center lg:text-[2.5rem] lg:leading-[2.75rem] mb-4 lg:mb-8"
					/>

				</section>

				<section>
					<div className="mb-4 w-full lg:mb-8">
						<FlightSeatFilling seatBooked={seatBooked} />
					</div>
				</section>
				<section className="px-4 lg:px-36 flex flex-col items-center">
					<div className="flex justify-center  items-center gap-4 mb-3 lg:gap-[7rem] lg:mb-11">
						<SeatGuideItem
							imgSrc={SeatAvailableIcon}
							text={t('available')}
							lang={i18n.language}
						/>
						<SeatGuideItem
							imgSrc={SeatBookedIcon}
							text={t('booked')}
							lang={i18n.language}
						/>
						<SeatGuideItem
							imgSrc={SeatHighlightIcon}
							text={t('going_soon')}
							lang={i18n.language}
						/>
					</div>
				</section>
				<section className="flex flex-col items-center px-4 lg:px-36 relative">
					<Typography
						variant="h2"
						type="text24"
						text={t('win_mileage_text')}
						customClasses="text-pageTitleColor lg:text-5xl lg:leading-[3.25rem] lg:mb-2 text-center"
					/>
					<Typography
						variant="h3"
						type="text14"
						text={t('win_mileage_sub_text')}
						customClasses="lg:text-[2.5rem] lg:leading-[2.75rem] mb-4 lg:mb-12"
					/>
					<div className="flex justify-between items-center  w-full mb-10 relative">
						<FlightAnimation milestone={flightMileStone} lang={i18n.language} />
						{!flightMileStone && (
							<div className="w-[6%] h-auto absolute  top-[45%] right-[15%]">
								<img src={FlightRedIcon} className="w-full" />
							</div>
						)}
						{flightMileStone > 0 && (
							<div
								className={`bg-pageTitleColor p-1 absolute ${kmMessagePos} text-white rounded-lg lg:p-3`}
							>
								<div className="relative">
									<div className="w-1.5 absolute -top-2 left-1/2 transform -translate-x-1/2 lg:w-4 lg:-top-5">
										<img src={BoxArrow} className="w-full" />
									</div>
									<p className="text-[0.563rem] lg:text-2xl  font-medium text-center">
										{`${t('You are here')}`}
										<br />
										{`${
											totalPoints <= thresholdPoint
												? totalPoints
												: thresholdPoint
										}${t('KM')}`}
									</p>
								</div>
							</div>
						)}
						{/* <span
						className={`absolute  right-[13%] ${
							i18n.language === 'en'
								? 'bottom-[10%] text-base lg:text-[2.75rem] lg:right-[12%] lg:bottom-[14%]'
								: 'bottom-[13%] text-xs lg:text-[1.75rem] lg:right-[12%] lg:bottom-[15%]'
						} font-bold text-white tracking-widest`}
					>
						{t('India')}
					</span>
					<span
						className={`absolute bottom-[10%] left-[8%] ${
							i18n.language === 'en'
								? 'bottom-[9%] text-base lg:text-[2.75rem] lg:left-[8%] lg:bottom-[14%]'
								: 'bottom-[13%] text-xs lg:text-[1.75rem] lg:left-[8%] lg:bottom-[15%]'
						}  font-bold text-white tracking-widest`}
					>
						{t('West Indies')}
					</span> */}
					</div>
				</section>
				<section
					ref={targetRef}
					id="getToCloserAction"
					className="flex flex-col items-center lg:hidden px-4 lg:px-36"
				>
					<h3 className="text-sm text-pageTitleColor font-medium leading-4.5 mb-1">
						{t('follow_us')}
					</h3>
					<div className="flex justify-center gap-6 mb-0.5">
						<div
							onClick={() => {
								sendCDPData('Click', 'Follow_FB');
								// Floodlight tags

								insertFloodlightTag({
									activityName: 'ThumsUp_ICC24_Facebook_Counter',
									imageTag: 'thums045', // Specific image tag for HomePage
								});
							}}
						>
							<Link
								to="https://www.facebook.com/thumsupofficial"
								target="_blank"
							>
								<img src={FacebookIcon} alt="Facebook" />
							</Link>
						</div>
						<div
							onClick={() => {
								sendCDPData('Click', 'Follow_Insta');
								// Floodlight tags

								insertFloodlightTag({
									activityName: 'ThumsUp_ICC24_Instagram_Counter',
									imageTag: 'thums046', // Specific image tag for HomePage
								});
							}}
						>
							<Link
								to="https://www.instagram.com/thumsupofficial"
								target="_blank"
							>
								<img src={InstagramIcon} alt="Instagram" />
							</Link>
						</div>
						<div
							onClick={() => {
								// Floodlight tags

								insertFloodlightTag({
									activityName: 'ThumsUp_ICC24_YouTube_Counter',
									imageTag: 'thums047', // Specific image tag for HomePage
								});
							}}
						>
							<Link
								to="https://www.youtube.com/user/ThumsUpOfficial"
								target="_blank"
							>
								<img src={YoutubeIcon} alt="Youtube" />
							</Link>
						</div>
						{/* <div onClick={() => sendCDPData('Click', 'Follow_Twitter')}>
						<Link to="https://twitter.com/ThumsUpOfficial" target="_blank">
							<img src={TwitterIcon} alt="Twitter" />
						</Link>
					</div> */}
					</div>
					<h4 className="text-xxs text-pageTitleColor font-light leading-4.5">
						{t('copyright_text')}
					</h4>
				</section>
			</div>
		</>
	);
};

export default Homepage;
