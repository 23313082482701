import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const Input = ({
	isValid = true,
	customClasses = '',
	disabled = false,
	inline = false,
	...rest
}) => {
	const { t } = useTranslation();
	return (
		<input
			{...rest}
			disabled={disabled}
			className={clsx(` font-thumbsUp  ${customClasses}`, {
				'border border-errorText': !isValid,
				'text-disabled': disabled,
				'bg-inputBg  p-3 placeholder-placeHolderColor  rounded-lg w-full text-xl lg:text-2xl':
					!inline,
				'lg:bg-headerBg text-white placeholder-white-500 p-1': inline,
			})}
		/>
	);
};

export default Input;
