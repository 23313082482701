{
	"win_1": {
		"headingText": "You’ve made it to the Toofani Fan Club!",
		"subHeadingText": "You won a seat Aboard Toofan to the ICC 2024 Games in the West Indies",
		"text": "",
		"icon": "flight_won_icon",
		"btnText": "ଏବେ କ୍ଲେମ୍‌ କରନ୍ତୁ",
		"btnSubText": "Claim within:",
		"timerDisplay": " {{datetime}}"
	},
	"win_2": {
		"headingText": "ଆପଣଙ୍କୁ ଶୁଭେଚ୍ଛା!",
		"subHeadingText": "You have won Cricket Jersey worth ₹999 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "ଏବେ ରିଡିମ୍‌ କରନ୍ତୁ",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_3": {
		"headingText": "ଆପଣଙ୍କୁ ଶୁଭେଚ୍ଛା!",
		"subHeadingText": "You have won Toofani merch worth ₹499 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "ଏବେ ରିଡିମ୍‌ କରନ୍ତୁ",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_check": {
		"headingText": "କେବଳ ଆଉ ଏକ ଯାଞ୍ଚ!",
		"subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 5 seconds, on this site as well as through SMS or WhatsApp. Sit tight!",
		"text": "",
		"icon": "flight_win_check"
	},
	"win_check_waiting": {
		"headingText": "କେବଳ ଆଉ ଏକ ଯାଞ୍ଚ!",
		"subHeadingText": "",
		"text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 3 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
		"icon": "flight_win_check"
	},
	"valid_code": {
		"headingText": "ୱା, ତୁଫାନ୍!",
		"subHeadingText": "ଆପଣ {{points}} କିମି ସଂଗ୍ରହ କରିଛନ୍ତି। ଆପଣ ତୁଫାନରେ ବସିବାର ସୁଯୋଗ ଠାରୁ ମାତ୍ର {{remaining_km}} କିଲୋମିଟର ଦୂରରେ ଅଛନ୍ତି!",
		"text": "",
		"icon": "points_win_flight_icon.svg"
	},
	"total_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "You have entered multiple invalid codes today. Come back tomorrow to try again.",
		"text": "",
		"icon": "warn_icon"
	},
	"already_win": {
		"headingText": "ପୂର୍ବରୁ ଜିତିସାରିଛନ୍ତି!",
		"subHeadingText": "ଆପଣ ଲକି ଡ୍ରରେ ଜିତି ସାରିଛନ୍ତି",
		"text": "",
		"icon": "warn_icon"
	},
	"valid_code_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "",
		"text": "You have entered multiple invalid codes today. Come back tomorrow to try again."
	},
	"claimed": {
		"headingText": "ଆପଣଙ୍କୁ ଶୁଭେଚ୍ଛା!",
		"subHeadingText": "You’ve successfully claimed your ticket aboard Toofan Air with our fan squad",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"after_claimed": {
		"headingText": "କେବଳ ଆଉ ଏକ ଯାଞ୍ଚ!",
		"subHeadingText": "ଆପଣ ଦାଖଲ କରିଥିବା ସବିଶେଷ ତଥ୍ୟ ଆମେ ଯାଞ୍ଚ କରୁଛୁ ଏବଂ 10 ଟି ବ୍ୟବସାୟ ଦିନ ମଧ୍ୟରେ, ଏହି ସାଇଟରେ ତଥା SMS କିମ୍ବା ହ୍ୱାଟସ୍ ଆପ୍ ମାଧ୍ୟମରେ ତୁଫାନ୍ ବୋର୍ଡରେ ଆପଣଙ୍କର ଯୋଗ୍ୟତା ବିଷୟରେ ଆପଣଙ୍କୁ ଜଣାଇବୁ | ଧୈର୍ଯ୍ୟର ସହ ଅପେକ୍ଷା କରନ୍ତୁ!",
		"text": "",
		"btnText": "",
		"icon": "flight_win_check"
	},
	"claimed_reward": {
		"headingText": "ଆପଣଙ୍କୁ ଶୁଭେଚ୍ଛା!",
		"subHeadingText": "You’ve successfully claimed your Toofani Reward",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"claim_now": {
		"headingText": "ସମୟ ସରିଯାଉଛି!",
		"subHeadingText": "ତୁଫାନରେ ଆପଣଙ୍କର ସିଟ୍ କ୍ଲେମ୍‌ କରିବାର ଅବଧି {{timer}} ରେ ସମାପ୍ତ ହେବ",
		"text": "",
		"btnText": "ଏବେ କ୍ଲେମ୍‌ କରନ୍ତୁ",
		"icon": "timer_icon"
	},
	"claim_expired": {
		"headingText": "ଆପଣ ରନ୍‌ ଆଉଟ୍‌ ହୋଇଯାଇଛନ୍ତି!",
		"subHeadingText": "ପୁରସ୍କାରରେ ଆପଣଙ୍କର କ୍ଲେମ୍‌ ସମୟସୀମା ସମାପ୍ତ ହୋଇଛି।",
		"text": "ଆଶା ହରାନ୍ତୁ ନାହିଁ, ଆପଣ ଏବେ ମଧ୍ୟ ଗେମ୍‌ରେ ଅଛନ୍ତି। ତୁଫାନ୍‌ରେ ପରବର୍ତ୍ତୀ ସିଟ୍ ଜିତିଛନ୍ତି ନା ନାହିଁ ତାହା ଯାଞ୍ଚ କରିବାକୁ ଆସନ୍ତାକାଲି ପୁନର୍ବାର ଫେରି ଆସନ୍ତୁ!",
		"icon": "said_icon"
	},
	"not_win_for_the_day": {
		"headingText": "ୟେ ହେଉଛି ଏକ ମେଡେନ୍\u200C ଓଭର୍\u200C!",
		"subHeadingText": "ଆଶା ହରାନ୍ତୁ ନାହିଁ, ଆପଣ ଏବେ ମଧ୍ୟ ଗେମ୍\u200Cରେ ଅଛନ୍ତି। ତୁଫାନ୍\u200Cରେ ପରବର୍ତ୍ତୀ ସିଟ୍ ଜିତିଛନ୍ତି ନା ନାହିଁ ତାହା ଯାଞ୍ଚ କରିବାକୁ ଆସନ୍ତାକାଲି ପୁନର୍ବାର ଫେରି ଆସନ୍ତୁ!",
		"text": "",
		"icon": "said_icon"
	},
	"exit_claim_popup": {
		"headingText": "ଆପଣଙ୍କର ମେଡାଲ୍‌ କ୍ଲେମ୍‌ କରନ୍ତୁ",
		"subHeadingText": "{{hours}} ଘଣ୍ଟା ଭିତରେ ପୁରସ୍କାର ଉପରେ ଆପଣଙ୍କର କ୍ଲେମ୍‌ର ସମୟସୀମା ଅବଧି ଶେଷ ହେବାକୁ ଯାଉଛି।",
		"text": "ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ବାହାରକୁ ଯିବାକୁ ଚାହୁଁଛନ୍ତି?",
		"icon": "claim_expired_icon",
		"btnTextYes": "ହଁ",
		"btnTextNo": "ନା",
		"confirm": true
	},
	"exit_next_win_for_new": {
		"headingText": "ଅଟକି ଯାଆନ୍ତୁ!",
		"subHeadingText": "ଆପଣ ଆଜିର ପରବର୍ତ୍ତୀ ବିଜେତା ହୋଇପାରନ୍ତି!",
		"text": "ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ବାହାରକୁ ଯିବାକୁ ଚାହୁଁଛନ୍ତି?",
		"icon": "raise_hand_icon",
		"btnTextYes": "ହଁ",
		"btnTextNo": "ନା",
		"confirm": true
	},
	"exit_next_win": {
		"headingText": "ଆପଣ ବିଜୟ ସ୍କୋରକୁ ହାସଲ କରିବାକୁ ଯାଉଛନ୍ତି!",
		"subHeadingText": "ଅନ୍ୟ ଏକ ୟୁନିକ୍‌ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ ଏବଂ ଜିତିବାର ସୁଯୋଗ ପାଆନ୍ତୁ!",
		"text": "ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ବାହାରକୁ ଯିବାକୁ ଚାହୁଁଛନ୍ତି?",
		"icon": "raise_hand_icon",
		"btnTextYes": "ହଁ",
		"btnTextNo": "ନା",
		"confirm": true
	},
	"affiliate_signup_success": {
		"headingText": "ବହୁତ ବଢ଼ିଆ ସ୍କିପର୍\u200C",
		"subHeadingText": "",
		"text": "ଆପଣ 100 କିଲୋମିଟର୍\u200C ସଂଗ୍ରହ କରିଛନ୍ତି",
		"icon": "right_bat"
	},
	"default": {
		"headingText": "Error!",
		"subHeadingText": "Not found!",
		"text": ""
	},
	"qualified_for_lucky_draw": {
		"headingText": "Already qualified!",
		"subHeadingText": "You have already qualified for the lucky draw",
		"text": ""
	}
}
