{
	"home": "హోమ్",
	"login": "లాగిన్ చెయ్యండి",
	"mobile_number": "మొబైల్ నంబర్",
	"mobile_number_label": "మొబైల్ నంబర్‌ను నమోదు చెయ్యండి",
	"get_otp": "Get OTP",
	"mobile_error_message": "దయచేసి సరైన నంబర్‌ను నమోదు",
	"resendOTP": "ఓ టి పి ను మళ్ళీ పంపండి",
	"otp_verification": "ఓ టి పి ధృవీకరణ",
	"otp_verification_sub_text": "మేము +91 {{mobile_number}} కి పంపిన 6-అంకెల కోడ్‌ను నమోదు చెయ్యండి",
	"try_again_text": "{{time_remaining}} సెకన్లలో ఓ టి పి ని మళ్ళీ పంపండి",
	"btn_submit": "సమర్పించండి",
	"sign_up": "మీరు దాదాపు అక్కడే ఉన్నారు!",
	"enter_detail_text": "ప్లేయింగ్ స్క్వాడ్‌లో చేరడానికి సైన్ అప్ చెయ్యండి!",
	"full_name": "పేరు",
	"name": "పేరు",
	"email_id": "ఇమెయిల్ చిరునామా",
	"terms_text": "నాకు కనీసం 18 సంవత్సరాలు ఉన్నాయి మరియు <tnc>షరతులు & నిబంధనలు</tnc> మరియు <pp>గోప్యతా విధానాన్ని</pp> చదివానని ధృవీకరిస్తున్నాను.",
	"participate_win": "Participate & Win",
	"winners_list": "Winners List",
	"faqs": "తరచుగా అడిగే ప్రశ్నలు",
	"terms_conditions": "అప్ టు డేట్ షరతులు మరియు నిబంధనలు వర్తిస్తాయి",
	"privacy_policy": "ప్రైవసీ పొలసీ",
	"logout": "లాగ్ ఔట్",
	"participate_now": "ఇప్పుడే పాల్గొనండి",
	"winner_board": "Winner Board",
	"enter_unique_code": "విభిన్నమైన కోడ్‌ ని నమోదు చెయ్యండి",
	"winners": "Winners",
	"enter_code": "కోడ్ ను ఎంటర్ చెయ్యండి",
	"profile": "ప్రొఫైల్",
	"get_aboard_text": "తూఫాన్ లో మీ సీట్ ని సంపాదించండి",
	"btn_continue": "కొనసాగించు",
	"get_aboard_sub_text": "జీవితంలో ఒక్కసారైనా జరిగే ఈ ప్రయాణంలో మాతో చేరండి - వెస్టిండీస్‌లో జరిగే ఐ సి సి టి 20 ప్రపంచ కప్ గేమ్‌కు మా టూఫానీ అభిమానులందరినీ ఎగరవేయడానికి ప్రత్యేకంగా క్యూరేటెడ్ విమానంలో విలాసవంతమైన అనుభవం.",
	"win_mileage_text": "నటన కి మరింత దెగ్గర అవ్వండి",
	"win_mileage_sub_text": "ప్రతి Thums UP® తో ",
	"my_kms": "నా కిలోమీటర్లు",
	"follow_us": "లో మమ్మల్ని అనుసరించండి",
	"copyright_text": "© 2024 కోకా-కోలా కంపెనీ. సర్వ హక్కులు ప్రత్యేకించబడినవి.",
	"Please enter a valid unique code": "దయచేసి సరైన కోడ్‌ని నమోదు చెయ్యండి",
	"your_profile": "నా ప్రొఫైల్",
	"profile_page_desc": "మేము విజేతలను సంప్రదించడానికి కింద ఉన్న సమాచారాన్ని వాడతాము. దయచేసి క్రింద ఇచ్చిన సంచారం సరైనదా కాదా అని చూడండి",
	"dob": "పుట్టినరోజు",
	"gender": "లింగం",
	"set_as_default": "డీఫాల్ట్ కి సెట్ చేయబడినది",
	"congratulations": "శుభాభినంధనలు",
	"flight_ticket_claimed_text": "You’ve claimed your ticket to the West Indies! We will communicate further details via email & SMS shortly.",
	"share": "పంచుకోండి",
	"claim_form_desc": "మీ బహుమతిని గెలుచుకోవడానికి దయచేసి మీ సమాచారాన్ని క్రింద పొందుపరచండి!",
	"full_name_claim_form": "పూర్తి పేరు (ప్రభుత్వ ఐ డి ప్రకారం)",
	"pan_card": "ప్యాన్ కార్డ్",
	"passport_no": "పాస్పోర్ట్ నంబర్",
	"passport_expiry_date": "పాస్పోర్ట్ ఎక్స్పైరీ తేదీ",
	"Male": "మగ",
	"Female": "ఆడ",
	"transgender": "Transgender",
	"Other": "ఇతరులు",
	"gift_voucher_claimed_text": "You’ve claimed your gift voucher! We will communicate further details via email & SMS shortly.",
	"claim_now": "ఇప్పుడు క్లైమ్ చేసుకోండి",
	"paytm_1000_won_text": "You’ve won yourself a ₹1,000 Paytm voucher!",
	"paytm_1000_win_text": "Win a ₹1000 Paytm Voucher!",
	"paytm_100_won_text": "You’ve won yourself a ₹1,00 Paytm voucher!",
	"paytm_100_win_text": "Win a ₹100 Paytm Voucher!",
	"win_ticket": "Win a Ticket",
	"enter_unique_code_reach_kms": "Enter unique code to reach 14K KMs",
	"enter_name": "దయచేసి సరైన పేరును నమోదు చెయ్యండి.",
	"enter_mobile_number": "మొబైల్ నంబర్‌ను నమోదు చెయ్యండి",
	"enter_email": "దయచేసి సరైన ఇమెయిల్ ఐ డి ని నమోదు చెయ్యండి.",
	"enter_pan": "దయచేసి సరైన ప్యాన్ కార్డ్ నంబర్ ని ఎంటర్ చెయ్యండి",
	"no_page_found": "పేజీ లభించలేదు",
	"go_to_home": "హోమ్ పేజ్ కి వెళ్ళండి",
	"eligible_1000_paytm_voucher": "You are now eligible for ₹1000 Paytm Gift Voucher",
	"win_prize_lucky_draw_daily": "Win Prizes in a lucky Draw Daily",
	"ticket_to_west_indies": "Ticket to West Indies",
	"ticket_to_west_indies_v1": "మీరు తూఫానీ ఫ్యాన్ క్లబ్ కోసం చేసారు",
	"ticket_to_west_indies_subtext_v1": "You won a seat aboard Toofan! Get ready to fly in style to the West Indies",
	"fan_jersy_heading": "ఒక ప్రో లాగ సూట్ అప్ అవ్వండి",
	"fan_jersy_heading_text": "999 రూపాయల విలువగల మీ క్రికెట్ జెర్సీ వోచర్ ని క్లైమ్ చేయడానికి ఇది మీ ఫ్యాన్ కోడ్",
	"how_enter_lucky_draw": "How to Enter the Lucky Draw",
	"step_1": "దశ 1",
	"step_2": "దశ 2",
	"step_3": "దశ 3",
	"step_4": "దశ 4",
	"scan_qr_code_bottle": "Scan the QR code from your ThumbsUp bottle",
	"enter_code_kms_achieve_three_code": "Enter the unique code to accrue 14,000kms with 3 codes max",
	"enter_lucky_draw_win_prize": "Enter the lucky draw to win prizes daily",
	"paytm_gift_voucher": "Paytm gift Voucher",
	"faq_heading": "తరచుగా అడిగే ప్రశ్నలు",
	"faq_subtext": "థమ్స్ అప్ గురించి మీరు తెలుసుకోవలసిన ప్రతిదీ ఇక్కడ ఉంది",
	"terms_heading": "Terms & Conditions",
	"contest_over": "Contest Over",
	"thank_you_visit_page": "Thank you for visiting our page! This contest is now closed.",
	"participate_other_giveaways": "Please visit (link to ThumbsUp’s Contests page) to participate in other giveaways",
	"winner_list": "Winner List",
	"full_name_error": "Please enter full name",
	"name_error": "దయచేసి సరైన పేరును నమోదు చెయ్యండి",
	"dob_error": "మీకు కనీసం 18 సంవత్సరాల వయసు ఉండాలి.దయచేసి సరైన పుట్టిన తేదీ ను ఎంటర్ చెయ్యండి",
	"passport_expiry_date_error_required": "Please enter a valid passport expiry date",
	"passport_expiry_date_error": "Passport expiry date field must be a date after today.",
	"passport_no_error": "Please enter a valid Passport number",
	"terms_agree_error": "దయచేసి షరతులు మరియు నిబంధనలను ఆమోదించింది",
	"email_error": "దయచేసి సరైన ఇమెయిల్ ఐ డి ని నమోదు చెయ్యండి",
	"how_to_participate": "ఎలా పాల్గొనాలి",
	"toofani_champions": "తూఫానీ విజేతలు",
	"refer_friend": "ఒక స్నేహితుడిని రిఫర్ చెయ్యండి",
	"have_invite_code": "మీ దెగ్గర ఇన్వైట్ ఉందా?",
	"enter_collect_kms": "ఎంటర్ అవ్వండి 100kms ని పొందండి",
	"contact_us": "మమ్మల్ని సంప్రదించండి",
	"prize_grab": "ఈ బహుమతులను గెలుచుకోండి",
	"seat_aboard_toofan": "తూఫాన్ ఆన్ బోర్డ్ చెయ్యండి \nప్రతి రోజు ఒక విజేత *",
	"cricket_jersey_999_fancode": "ఫ్యాన్ కోడ్ నుంచి 999 రూపాయల విలువ గల క్రికెట్ జెర్సీ \nప్రతి గంటకి ఒక విజేత*",
	"toofani_999_fancode": "ఫ్యాన్ కోడ్ నుంచి 499 రూపాయల విలువ గల తూఫానీ మెర్ఛ్ \nప్రతి గంటకి 15 మంది విజేతలు*",
	"find_unique_code_pack": "ప్యాకెట్ మీద ఉన్న విభిన్నమైన కోడ్ ని చూడండి",
	"enter_code_collect_kms": "కిలోమీటర్లను స్కరిచడానికి క్రింద ఉన్న కోడ్ ని ఎంటర్ చెయ్యండి",
	"complete_journey_destination": "గమ్యానికి పూర్తి ప్రయాణం",
	"get_chance_win": "గెలవడానికి ఒక అవకాశాన్ని పొందండి*!",
	"refer_friend_collect_kms": "అదనపు కీలవమేటర్ లను సేకరించడానికి ఒక స్నేహితుడిని రిఫర్ చెయ్యండి*",
	"get_100km_mate_signup": "విజయవంతంగా సైన్ అప్ చేసి మీ కోడ్ ని వాడిన ప్రతి ఒక్కరూ 100 కిలోమీటర్లను పొందుతారు",
	"refer_now": "ఇప్పుడే రిఫర్ చెయ్యండి",
	"tnc_apply": "షరతులు మరియు నిబంధనలు వర్తిస్తాయి",
	"available": "అందుబాటులో ఉంది",
	"booked": "నమోదు చేయబడినది",
	"going_soon": "త్వరలో వెళ్తుంది",
	"you_made_toofani_fan_club": "మీరు తూఫాన్ ఫ్యాన్ క్లబ్ కోసం చేసారు!",
	"won_seat_get_ready": "మీరు తూఫాన్ లో సీటు ని గెలుచుకున్నారు! వెస్ట్ ఇండీస్ కి ఎగరడానికి సిద్దంగా ఉండండి",
	"jersey_won_text": "ఒక ప్రో లాగ సూట్ అప్ అవ్వండి",
	"jersey_won_subtext": "999 రూపాయల విలువగల మీ క్రికెట్ జెర్సీ వోచర్ ని క్లైమ్ చేయడానికి ఇది మీ ఫ్యాన్ కోడ్",
	"redeem_now": "ఇప్పుడే రిడీమ్ చెయ్యండి",
	"voucher_won_text": "కొంత టీం స్పిరిట్ ని తెచ్చుకోండి",
	"voucher_won_subtext": "మీ 499 రూపాయల క్రికెట్ మెర్ఛ్ ని క్లైమ్ చేయడానికి ఇది మీ ఫ్యాన్ కోడ్ వోచర్",
	"my_wins": "నా విజయాలు",
	"optional": "ఐచ్ఛికం",
	"sign_up_cta": "సైన్ అప్",
	"Please enter a valid OTP": "దయచేసి సరైన OTP ను ఎంటర్ చెయ్యండి",
	"enter_collect_100kms": "ఎంటర్ అవ్వండి 100kms ని పొందండి",
	"unique_code_entry_text": "వెస్టిండీస్‌లో జరిగే ఐ సి సి పురుషుల టి 20 ప్రపంచ కప్ 2024 ఆటలకు టూఫాన్‌లో ప్రయాణించే అవకాశాన్ని పొందండి*",
	"unique_code_entry_subtext": "Thums up ® బాటిల్ లేబుల్ వెనుక ఉన్న విభిన్న కోడ్‌ను కనుగొనండి",
	"mobile_number_short": "మొబైల్ నంబర్.",
	"welcome_player": "ఆటగాళ్ళకి స్వాగతం!",
	"login_start_winning": "గెలుపొందడం ప్రారంభించడానికి లాగిన్ చెయ్యండి!",
	"trademark_text": "కార్బొనేటెడ్ నీరు, ముద్ర ఉన్న యజమాని: ధ కోకో కోలా కంపెనీ.",
	"address": "చిరునామా",
	"address_line_1": "చిరునామా మొదటి వరుస",
	"address_line_2": "చిరునామా రెండవ వరుస",
	"nearest_landmark": "దెగ్గరలో ఉన్న సరిహద్దు గుర్తు",
	"alternative_mobile_number": "ప్రత్యామ్నాయ మొబైల్ నంబర్",
	"pincode": "పిన కోడ్",
	"state": "రాష్ట్రం",
	"city": "నగరం",
	"state_error": "దయచేసి సరైన నగరాన్ని ఎంచుకోండి",
	"city_error": "దయచేసి సరైన రాష్ట్రాన్ని ఎంచుకోండి",
	"pincode_error": "దయచేసి సరైన పిన్ కోడ్ ని ఎంటర్ చెయ్యండి",
	"address_error": "దయచేసి సరైన చిరునామా ని ఎంటర్ చెయ్యండి",
	"pan_error": "దయచేసి సరైన ప్యాన్ కార్డ్ నంబర్ ని ఎంటర్ చెయ్యండి",
	"copy_code": "కోడ్ ని కాపీ చెయ్యండి",
	"last_date_redeem": "రిడీమ్ చేసుకోవడానికి చివరి తేదీ",
	"last_date_to_claim": "Last date to claim",
	"share_with_friends": "మీ స్నేహితులతో పంచుకోండి",
	"no_internet_message": "కనెక్ట్ అవ్వలేక పోయాను. దయచేసి మీ నెట్వర్క్ కనెక్షన్ ని పరీక్షించి మరలా ప్రయత్నించండి!",
	"email_us": "కి మాకు ఈమెయిల్ చెయ్యండి",
	"call_toll_free": "టోల్ ఫ్రీ సంఖ్య కి కాల్ చెయ్యండి",
	"mon_sat": "సోమవారం- శనివారం",
	"sunday": "ఆదివారం",
	"follow_us_now": "ఇప్పుడే మమ్మల్ని అనుసరించండి",
	"otp_expired_enter_new": "మీ ఓ టి పి గడువు ముగిసింది. దయచేసి కొత్తదాన్ని ఉపయోగించండి.",
	"email_address": "ఇమెయిల్ చిరునామా",
	"Code has already been used, please enter a new code": "కోడ్ ఉపయోగించబడింది, దయచేసి కొత్త కోడ్‌ని నమోదు చెయ్యండి.",
	"first": "మొదటి",
	"last": "చివర",
	"add": "జోడించండి",
	"refresh_page": "Refresh Page",
	"Seat on Toofan": "తూఫాన్ మీద సీట్",
	"Toofani Merch": "తూఫానీ మెర్ఛ్",
	"Cricket Jersey": "క్రికెట్ జెర్సీ",
	"You are here": "మీరు ఇక్కడ ఉన్నారు",
	"OTP resent successfully": "OTP మరలా విజయవంతంగా పంపబడింది.",
	"No winners till now": "ఇప్పటివరకు విజేతలు లేరు",
	"COPIED": "కాపీ చెయ్యబడ్డాయి",
	"T&C": "అప్ టు డేట్ షరతులు మరియు నిబంధనలు వర్తిస్తాయి",
	"Email is already in use": "ఈమెయిల్ వాడుక లో ఉంది",
	"Clear": "శుభ్రం చెయ్యండి",
	"West Indies": "వెస్ట్ ఇండీస్",
	"India": "ఇండియా",
	"Reminder send successfully": "రిమైండర్ విజయవంతంగా పంపబడింది",
	"KM": "కిమీ",
	"KMs": "కిమీ",
	"Edit Address": "Edit Address",
	"Please enter a valid code": "దయచేసి సరైన కోడ్ ని ఎంటర్ చెయ్యండి",
	"final_day_offer": "ఆఫర్ గడువును 2024 ఏప్రిల్ 30 నుంచి 2024 మే 15 వరకు పొడిగించింది. <tnc>నిబంధనలు మరియు షరతులు వర్తిస్తాయి</tnc>"
}
