const SeatGuideItem = ({ imgSrc, text, lang }) => {
	return (
		<div className="flex justify-start gap-1 items-center">
			<div className="w-4.5 h-4.5 lg:w-11 lg:h-10">
				<img src={imgSrc} alt={text} className="w-full" />
			</div>
			<span
				className={`text-pageTitleColor  font-normal ${
					lang === 'en'
						? 'text-xs lg:text-4xxl'
						: 'text-[0.625rem] lg:text-[1.5rem]'
				} lg-leading[3rem]`}
			>
				{text}
			</span>
		</div>
	);
};

export default SeatGuideItem;
