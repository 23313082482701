import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import Heading from '../components/Heading';
import CalenderIcon from '../../public/calender_ico.svg';
import { formatDate, formattedDate } from '../utils/common';
import { useApiCall } from '../utils/useApiCall';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader'; // Ensure this path is correct
import Pagination from '../components/Pagination'; // Ensure this path is correct
import Winners from '../components/Winners';
import { useAuthContext } from '../context/auth-context';
import { format, parseISO } from 'date-fns'; // Import necessary functions

const WinnerList = () => {
	const navigate = useNavigate();
	const { token, isLoggedIn } = useAuthContext();
	const [startDate, setStartDate] = useState(
		parseISO(format(new Date(), 'yyyy-MM-dd'))
	);
	const [pageError, setPageError] = useState('');
	const [winners, setWinners] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const { t } = useTranslation(['translations', 'winner']);
	const { isLoading, error: apiError, sendRequest } = useApiCall();

	const minDate = new Date('2024-03-01');
	const maxDate = new Date('2024-05-15');

	useEffect(() => {
		// Fetch winners when the component mounts and when startDate or currentPage changes
		fetchWinners();
	}, [isLoggedIn, startDate, currentPage]);

	useEffect(() => {
		if (startDate && (startDate < minDate || startDate > maxDate)) {
			setStartDate(null); // Clear the selected date if it is out of range
		}
	}, [startDate]);

	const fetchWinners = async () => {
		let params = {
			page: +currentPage,
			start_date: startDate ? format(startDate, 'yyyy-MM-dd') : '',
			end_date: '',
		};
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}winners`,
				'POST',
				JSON.stringify(params),
				{
					'Content-Type': 'application/json'
				}
			);

			if (response.success) {
				if (response.data.isClientWin === true) {
					navigate('/contest-over');
				}
				setWinners(response.data.winners); // Adjust according to your actual API response structure
				setTotalPages(response.data.total);
			}
		} catch (error) {
			setPageError(error?.data?.message || error?.message);
			setWinners([]); // Adjust according to your actual API response structure
			setTotalPages(0);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		// Optionally, scroll to the top of the page or a specific element after changing the page
	};

	// Custom input component for the DatePicker
	const CustomInput = React.forwardRef(
		({ value, onClick, placeholder }, ref) => (
			<div
				onClick={onClick}
				ref={ref}
				className="relative mt-[16px] sm:max-w-md cursor-pointer flex items-center"
			>
				<div className="flex absolute inset-y-0 left-0 items-center pl-3">
					<img
						src={CalenderIcon}
						alt="Calendar"
						className="w-5 h-5 text-gray-500"
					/>
				</div>
				<input
					readOnly
					value={value ? value : ''}
					className="border-2 font-bold rounded border-gray-400 text-black text-sm text-[16px] focus:ring-blue-500 focus:border-secondaryText block w-full pl-10 p-2.5 appearance-none focus:outline-none"
					placeholder={t('winner:select_date')}
				/>
				{value && (
					<button
						onClick={(e) => {
							e.stopPropagation(); // Prevent DatePicker from opening
							setStartDate(null); // Clear the selected date
						}}
						className="absolute right-3 text-gray-500 hover:text-gray-700"
					>
						{t('Clear')}
					</button>
				)}
			</div>
		)
	);

	return (
		<div className="px-4 pt-4 pb-7 lg:px-36 lg:pt-9 lg:pb-20">
			<Heading
				text={t('winner:winner_list_h1')}
				additionalClasses="lg:mb-2 font-bold text-[28px]"
			/>
			<DatePicker
				selected={startDate}
				onChange={(date) => {
					setStartDate(date);
					setCurrentPage(1); // Optionally reset the page when the date changes
				}}
				dateFormat="dd-MM-yyyy"
				customInput={<CustomInput />}
				shouldCloseOnSelect={true}
				minDate={minDate}
				maxDate={maxDate}
			/>

			<style>
				{`
        .react-datepicker-wrapper {
            width: 100%;
            display: block
        }
		.react-datepicker__input-container {
			position: initial;
		}
		`}
			</style>
			{isLoading && <Loader />}
			{/* Render winners list and pagination controls here */}
			<Winners winners={winners} />

			{/* Pagination Controls */}
			{totalPages > 0 ? (
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			) : (
				<div className="mt-4 space-y-2">
					<div className="flex justify-center bg-inputBg px-4 py-2 h-39">
						{t(pageError)}
					</div>
				</div>
			)}
		</div>
	);
};

export default WinnerList;
