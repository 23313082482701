{
	"heading_h2": "ಪಾರ್ಟ್‌ನರ್‌ಷಿಪ್‌ ಅಂದರೆ ಹೀಗಿರಬೇಕು!",
	"heading_h3": "ಭಾಗವಹಿಸಲು ನಿಮ್ಮ ಸ್ನೇಹಿತರನ್ನು ಇನ್ವೈಟ್‌ ಮಾಡಿ",
	"invite_code_text": "ನಿಮ್ಮ ಸ್ನೇಹಿತರು ಸೈನ್ ಅಪ್ ಮಾಡಿದಾಗ ಮತ್ತು ನಿಮ್ಮ ಇನ್ವೈಟ್ ಕೋಡ್ ಅನ್ನು ಬಳಸಿದಾಗ 100 ಕೆಎಮ್‌ ಪಡೆಯಿರಿ",
	"friend_mobile_number": "ನಿಮ್ಮ ಸ್ನೇಹಿತರ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
	"friend_email": "ನಿಮ್ಮ ಸ್ನೇಹಿತರ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ಐಚ್ಛಿಕ)",
	"send_invite_btn": "ಸಲ್ಲಿಸಿ",
	"privacy_policy": "ಗೌಪ್ಯತಾ ನೀತಿ",
	"icon": "right_bat",
	"mobile_required": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
	"email_optional": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ",
	"Number already in use, Please refer another": "ಈಗಾಗಲೇ ಬಳಕೆಯಲ್ಲಿರುವ ಸಂಖ್ಯೆ, ದಯವಿಟ್ಟು ಇನ್ನೊಂದನ್ನು ರೆಫರ್‌ ಮಾಡಿ",
	"thank_you": "ಧನ್ಯವಾದಗಳು!",
	"thank_you_h3": "ಕೋಡ್‌ನೊಂದಿಗೆ ನಿಮ್ಮ ಸ್ನೇಹಿತರಿಗೆ ಇನ್ವೈಟ್ ಕಳುಹಿಸಲಾಗಿದೆ",
	"thank_you_info_text": "ನಿಮ್ಮ ಸ್ನೇಹಿತರು ಸೈನ್ ಅಪ್ ಮಾಡಿದ ನಂತರ ಮತ್ತು ಅವರ‌ ಯುನಿಕ್‌ ಇನ್ವೈಟ್ ಕೋಡ್ ಅನ್ನು ಬಳಸಿದ ನಂತರ ನೀವು 100 ಕೆಮಿಗಳನ್ನು ಪಡೆಯುತ್ತೀರಿ.",
	"enter_invite_code": "ಇನ್ವೈಟ್ ಕೋಡ್ ನಮೂದಿಸಿ",
	"affiliate_signup_h3": "ನಿಮ್ಮ ಸ್ನೇಹಿತರ 6-ಅಂಕಿಯ ಇನ್ವೈಟ್ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ ಮತ್ತು 100 ಕೆಮಿ ಸಂಗ್ರಹಿಸಿ",
	"affiliate_signup_btn": "ಸಲ್ಲಿಸಿ",
	"Please enter a valid code": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
	"my_referrals": "ನನ್ನ ರೆಫರಲ್‌ಗಳು",
	"accepted": "ಸ್ವೀಕರಿಸಲಾಗಿದೆ",
	"pending": "ಬಾಕಿ ಉಳಿದಿದೆ",
	"users": "ಬಳಕೆದಾರರು",
	"status": "ಸ್ಥಿತಿ",
	"send_reminder": "ಜ್ಞಾಪನೆಯನ್ನು ಕಳುಹಿಸಿ",
	"refer_another": "ಇನ್ನೊಬ್ಬರನ್ನು ರೆಫರ್‌ ಮಾಡಿ",
	"Referral not found": "ಉಲ್ಲೇಖ ಸಿಗಲಿಲ್ಲ"
}
