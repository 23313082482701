import { useApiCall } from '../utils/useApiCall';
import { useAuthContext } from '../context/auth-context';
import { v4 as uuidv4 } from 'uuid';
export const useCDP = () => {
	try {
		const { isLoading, error: apiError, sendRequest } = useApiCall();
		const { token } = useAuthContext();

		const sendCDPData = async (eventType, eventSubType, languageCode = '') => {
			try {
				if (!token) {
					CdpRegistration({
						SendCdpEvent: true,
						eventType,
						eventSubType,
						languageCode,
						HfSession: generateOrRetrieveUserId(),
					});
					// console.log("CDP -cahnges", {
					// 	SendCdpEvent: true,
					// 	eventType,
					// 	eventSubType,
					// 	languageCode,
					// 	HfSession: generateOrRetrieveUserId()
					// })
					return;
				}
				const response = await sendRequest(
					`${process.env.API_BASE_URL}${token ? 'user/' : ''}cdp/event`,
					'POST',
					JSON.stringify({
						event_type: eventType,
						event_sub_type: eventSubType,
						language_code: languageCode,
					}),
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
						Authorization: `Bearer ${token}`,
					}
				);
			} catch (error) {
				console.error(`CDP error>>>`, error);
			}
		};
		const generateOrRetrieveUserId = () => {
			// Attempt to retrieve an existing ID
			let userId = localStorage.getItem('userId');
			// If it doesn't exist, create a new one and store it
			if (!userId) {
				userId = uuidv4(); // Use a function like uuidv4 to generate a unique ID
				localStorage.setItem('userId', userId);
			}
			return userId;
		};
		const GetGeoField = async () => {
			try {
				const response = await fetch(process.env.GET_IP_DETAIL_URL, {
					method: 'GET', // The method is GET
					headers: { 'Content-Type': 'application/json' }, // Set the headers
				});

				// Check if the response is ok (status in the range 200-299)
				if (response.ok) {
					return await response.json(); // Parse JSON body of the response
				} else {
					// If response is not ok, throw an error with the status
					console.error('Network response was not ok: ', response.statusText);
				}
			} catch (error) {
				console.error(`IP details fetch error:`, error);
			}
		};
		const CdpRegistration = async (model) => {
			if (model.SendCdpEvent) {
				let GeoField;
				if (model.eventSubType === 'Homepage_load') {
					GeoField = await GetGeoField();
				}
				const url = process.env.CDP_URL; // Ensure CdpUrl is defined

				try {
					const user_identifiers = [
						{
							user_identifier_type: 'COKE_COOKIE',
							user_identifier_sub_type: 'CDS_PIXEL_COOKIE',
							user_identifier: model.HfSession,
						},
					];

					let data = {
						event_type: model.eventType,
						event_sub_type: model.eventSubType,
						client_id: process.env.CDP_CLIENT_ID, // Ensure CdsTrackingClientId is defined
						user_identifiers,
						geo_state_province_code: '',
						brand_name: 'Thums Up',
						geo_country_code: '',
						geo_city_name: '',
						geo_postal_code: '',
						language_code: model.languageCode,
					};
					if (GeoField) {
						data = {
							...data,
							geo_state_province_code: GeoField?.region,
							geo_country_code: GeoField?.countryCode,
							geo_city_name: GeoField?.city,
							geo_postal_code: GeoField?.zip,
							ip_address: GeoField?.query,
						};
					}

					// Remove any undefined properties
					Object.keys(data).forEach(
						(key) => data[key] === undefined && delete data[key]
					);

					const response = await fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'x-api-key': process.env.CDP_API_KEY, // Ensure CdpApiKey is defined
						},
						body: JSON.stringify(data),
					});

					const responseData = await response.json(); // Assuming response is JSON
					console.error('CDP Registration success:', responseData);
					// Process response data here
				} catch (ex) {
					console.error('CDP Registration error:', ex);
					// Handle exceptions, e.g., set an error state, show a message, etc.
				}
			}
		};

		return sendCDPData;
	} catch (e) {
		console.warn(e);
	}
};
