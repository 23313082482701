{
	"winner_list_h1": "ತೂಫಾನಿ ಚಾಂಪಿಯನ್‌ಗಳು",
	"avatar": "ಅವತಾರ",
	"name": "ಹೆಸರು",
	"phone_number": "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
	"prize": "ಬಹುಮಾನ",
	"select_date": "ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
	"record_not_found": "Record not found",
	"win_1": "ವೆಸ್ಟ್ ಇಂಡೀಸ್‌ನಲ್ಲಿ ನಡೆಯುವ ಐಸಿಸಿ ಪುರುಷರ ಟಿ20 ವಿಶ್ವಕಪ್ 2024 ಗೇಮ್‌ಗಳಿಗಾಗಿ ನಾನು ತೂಫಾನ್‌ನಲ್ಲಿ ಆಸನವನ್ನು ಗೆದ್ದಿದ್ದೇನೆ*",
	"win_img_1": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/flight.jpg",
	"win_2": "ನಾನು Thums Up ® ಐಸಿಸಿ ಪುರುಷರ ಟಿ20 ವಿಶ್ವಕಪ್ 2024 ಪ್ರೊಮೋಶನ್‌ನಲ್ಲಿ ಫ್ಯಾನ್‌ಕೋಡ್‌ನಿಂದ ಕ್ರಿಕೆಟ್ ಜರ್ಸಿಯನ್ನು ಗೆದ್ದಿದ್ದೇನೆ ನೀವೂ ಗೆಲ್ಲಬಹುದು!",
	"win_img_2": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/jercy.jpg",
	"win_3": "ನಾನು ಈಗಷ್ಟೇ Thums Up ® ಐಸಿಸಿ ಪುರುಷರ ಟಿ20 ವಿಶ್ವಕಪ್ 2024 ಪ್ರೊಮೋಶನ್‌ನಲ್ಲಿ ಫ್ಯಾನ್‌ಕೋಡ್‌ನಿಂದ ಕ್ರಿಕೆಟ್ ಮೆರ್ಚ್ ಗೆದ್ದಿದ್ದೇನೆ ನೀವೂ ಗೆಲ್ಲಬಹುದು!",
	"win_img_3": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/voucher.jpg",
	"one_more_check": "ಕೇವಲ ಒಂದು ಪರಿಶೀಲನೆ ಬಾಕಿ ಇದೆ!",
	"one_more_check_text": "ನೀವು ಸಲ್ಲಿಸಿದ ವಿವರಗಳನ್ನು ನಾವು ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇವೆ ಮತ್ತು ಈ ಸೈಟ್‌ನಲ್ಲಿ ಹಾಗೂ ಸಂದೇಶ ಅಥವಾ ವಾಟ್ಸ್‌ಅಪ್ ಮೂಲಕ 10 ವ್ಯವಹಾರಿಕ ದಿನಗಳಲ್ಲಿ ತೂಫಾನ್ ಹತ್ತಲು ನಿಮ್ಮ ಅರ್ಹತೆಯ ಕುರಿತು ನಿಮಗೆ ತಿಳಿಸುತ್ತೇವೆ. ಕಾತುರದಿಂದ ಕುಳಿತುಕೊಳ್ಳಿ!",
	"win_heading": "Congratulations!",
	"win_1_success_verified": "ತೂಫಾನ್‌ನಲ್ಲಿರುವ ನಿಮ್ಮ ಆಸನವನ್ನು ಖಚಿತಪಡಿಸಲಾಗಿದೆ ಮತ್ತು ನೀವು ವೆಸ್ಟ್ ಇಂಡೀಸ್‌ಗೆ ಹೋಗುತ್ತಿದ್ದೀರಿ! ಸಂದೇಶ/ ವಾಟ್ಸ್‌ಅಪ್ ಅಥವಾ ಫೋನ್ ಕರೆ ಮೂಲಕ ನಾವು ಮುಂದಿನ ಹಂತಗಳೊಂದಿಗೆ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸುತ್ತೇವೆ. ನಿಮ್ಮ ಅಸಾಧಾರಣ ಗೆಲುವಿನ ಬಗ್ಗೆ ನಿಮ್ಮ ಸ್ನೇಹಿತರಿಗೆ ತಿಳಿಸಿ!",
	"win_1_success": "ತೂಫಾನ್‌ನಲ್ಲಿರುವ ನಿಮ್ಮ ಆಸನವನ್ನು ಖಚಿತಪಡಿಸಲಾಗಿದೆ ಮತ್ತು ನೀವು ವೆಸ್ಟ್ ಇಂಡೀಸ್‌ಗೆ ಹೋಗುತ್ತಿದ್ದೀರಿ! ಸಂದೇಶ/ ವಾಟ್ಸ್‌ಅಪ್ ಅಥವಾ ಫೋನ್ ಕರೆ ಮೂಲಕ ನಾವು ಮುಂದಿನ ಹಂತಗಳೊಂದಿಗೆ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸುತ್ತೇವೆ. ನಿಮ್ಮ ಅಸಾಧಾರಣ ಗೆಲುವಿನ ಬಗ್ಗೆ ನಿಮ್ಮ ಸ್ನೇಹಿತರಿಗೆ ತಿಳಿಸಿ!",
	"win_2_success": "You’ve claimed your Cricket Jersey! We will communicate further details via email & SMS shortly.",
	"win_3_success": "You’ve claimed your Toofani Merch! We will communicate further details via email & SMS shortly."
}
