{
	"heading_h2": "Partnership Ho Toh Aisi!",
	"heading_h3": "Invite your friends to participate",
	"invite_code_text": "Get 100kms when your friend signs up and uses your invite code",
	"friend_mobile_number": "Enter your friend’s mobile number",
	"friend_email": "Enter your friend’s email (optional)",
	"send_invite_btn": "Submit",
	"privacy_policy": "Privacy Policy",
	"icon": "right_bat",
	"mobile_required": "Please enter a valid number",
	"email_optional": "Please enter a valid email",
	"Number already in use, Please refer another": "Number already in use, Please refer another",
	"thank_you": "Thank You!",
	"thank_you_h3": "An invite has been sent to your friend with code",
	"thank_you_info_text": "You will get 100kms once your friend signs up and uses their unique invite code.",
	"enter_invite_code": "Enter Invite Code",
	"affiliate_signup_h3": "Enter your friend’s 6-digit code & collect 100kms",
	"affiliate_signup_btn": "Submit",
	"Please enter a valid code": "Please enter a valid code",
	"my_referrals": "My Referrals",
	"accepted": "Accepted",
	"pending": "Pending",
	"users": "Users",
	"status": "Status",
	"send_reminder": "Send Reminder",
	"refer_another": "Refer Another",
	"Referral not found": "Referral not found"
}
