import { useEffect, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '../components/Button';
import Input from '../components/Input';
import Heading from '../components/Heading';
import InputErrorMessage from '../components/InputErrorMessage';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import { useAuthContext } from '../context/auth-context';
import Typography from './Typography';
import { triggerTrackingPixel, insertFloodlightTag } from '../utils/common';

const LoginForm = ({ setLoginStep, setMobileNumber }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { isLoggedIn } = useAuthContext();

	const recaptchaRef = useRef();

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/');
		}
	}, [isLoggedIn]);

	const { isLoading, error: apiError, sendRequest } = useApiCall();

	const yupSchema = useCallback(
		yup.object({
			mobile_number: yup
				.string()
				.matches(/^(?!(\d)\1{9})[6,7,8,9]\d{9}$/, t('mobile_error_message'))
				.min(10, t('mobile_error_message')),
		}),
		[]
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		watch,
		setValue,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	useEffect(() => {
		setValue(
			'mobile_number',
			watch('mobile_number')?.replace(/\D/g, '').slice(0, 10)
		);
	}, [watch('mobile_number')]);

	const submitHandler = async (data) => {
		//Image pixel
		triggerTrackingPixel(
			'https://insight.adsrvr.org/track/pxl/?adv=2xop800&ct=0:246aaqm&fmt=3'
		);
		// Floodlight tags

		insertFloodlightTag({
			activityName: 'ThumsUp_ICC24_GetOTP_Counter',
			imageTag: 'thums03u', // Specific image tag for HomePage
		});

		const { mobile_number } = data;
		// const reCaptchaToken = await recaptchaRef.current.executeAsync();
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}login/send-otp`,
				'POST',
				JSON.stringify({
					mobile_no: +mobile_number,
					isd_code: 91,
				}),
				{
					'Content-Type': 'application/json',
					'x-auth-key': process.env.AUTH_KEY,
				}
			);
			setMobileNumber(mobile_number);
			setLoginStep(2);
		} catch (error) {
			console.error(`Login API error>>>`, error);
		}
	};

	return (
		<div className="flex flex-col justify-between items-center font-thumbsUp">
			{isLoading && <Loader />}
			<div className="mb-4 text-center ">
				<Typography variant="h2" type="text24" text={t('welcome_player')} />
				<Typography
					variant="h3"
					type="text16"
					text={t('login_start_winning')}
				/>
			</div>
			<form onSubmit={handleSubmit(submitHandler)} className="w-full">
				<div className={errors?.mobile_number?.message ? 'mb-1.5' : 'mb-6'}>
					<Controller
						name="mobile_number"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.mobile_number}
								placeholder={`${t('mobile_number_label')}*`}
								customClasses={`${
									i18n.language === 'ka' || i18n.language === 'te'
										? 'placeholder:text-sm'
										: ''
								} `}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.mobile_number?.message} />
				</div>
				{apiError && <InputErrorMessage text={t(apiError)} />}
				{/*{<ReCAPTCHA
					sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
					size="invisible"
					ref={recaptchaRef}
				/>}*/}
				<Button
					text={t('btn_continue')}
					customClasses="lg:text-3xxl p-3 TUICC24GetOTP"
					id="TUICC24GetOTP"
				/>
			</form>


		</div>
	);
};

export default LoginForm;
