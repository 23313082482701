import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import IconTextVertical from './IconTextVertical';
import Typography from './Typography';
import FlightJourney from '../../public/flight_banner.svg';
import JerseyIcon from '../../public/jersey_icon.svg';
import FancodeIcon from '../../public/fancode_banner_icon.svg';

const MyWinsDefault = () => {
	const { t, i18n } = useTranslation();

	return (
		<>
			<Typography
				variant="h2"
				type="text24"
				text={t('prize_grab')}
				customClasses={`mb-4 text-center text-white  ${
					i18n.language !== 'ka' && i18n.language !== 'te'
						? 'lg:text-[4.25rem]'
						: 'lg:text-[1.75rem]'
				} lg:leading-normal`}
			/>
			<div className="flex justify-between items-start gap-5 lg:gap-11">
				<div className="flex-1">
					<IconTextVertical
						imgSrc={FlightJourney}
						text={t('seat_aboard_toofan')
							.split('\n')
							.map((line, index) => (
								<Fragment key={index}>
									{line}
									<br />
								</Fragment>
							))}
						imgClass="w-[4.063rem] "
					/>
				</div>
				<div className="flex-1">
					<IconTextVertical
						imgSrc={JerseyIcon}
						text={t('cricket_jersey_999_fancode')
							.split('\n')
							.map((line, index) => (
								<Fragment key={index}>
									{line}
									<br />
								</Fragment>
							))}
					/>
				</div>
				<div className="flex-1">
					<IconTextVertical
						imgSrc={FancodeIcon}
						text={t('toofani_999_fancode')
							.split('\n')
							.map((line, index) => (
								<Fragment key={index}>
									{line}
									<br />
								</Fragment>
							))}
					/>
				</div>
			</div>
		</>
	);
};

export default MyWinsDefault;
