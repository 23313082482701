import { useApiCall } from './useApiCall';

export const useSendOtp = () => {
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const sendOtpRequest = async (mobile_number) => {
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}login/send-otp`,
				'POST',
				JSON.stringify({
					mobile_no: +mobile_number,
					isd_code: 91,
				}),
				{
					'Content-Type': 'application/json',
					'x-auth-key': process.env.AUTH_KEY,
				}
			);

			if (response?.success) {
				// navigate('/otp-verification', { state: { mobile_number } });
			} else {
				throw new Error(response?.message);
			}
		} catch (error) {
			throw new Error(error?.message);
		}
	};

	return {
		sendOtpRequest,
		isLoading,
		apiError,
	};
};
