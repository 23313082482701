import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProfileIcon from '../../public/profile_icon.svg';
import Polygon from '../../public/polygon.svg';
import LogoutIcon from '../../public/logout_icon.svg';
import { useAuthContext } from '../context/auth-context';
import { useCampaignContext } from '../context/campaign-context';
import Button from './Button';

const ProfileMenuItem = ({ name, icon, onClick }) => {
	return (
		<div
			className="flex justify-between items-center p-5 border-b border-borderBtm"
			onClick={onClick}
		>
			<span className="text-xl text-secondaryText ">{name}</span>
			<div className="w-5 h-5">
				<img src={icon} />
			</div>
		</div>
	);
};

const ProfileMenu = ({ setOpenMenu }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { logout, isLoggedIn } = useAuthContext();

	const { openLoginForm, setOpenLoginForm } = useCampaignContext();

	const logoutHandler = () => {
		logout();
		navigate('/');
		setOpenMenu(false);
	};
	return (
		<div className="border border-borderBtm bg-white ">
			<div className="absolute -top-5 -right-8 w-24">
				<img src={Polygon} />
			</div>
			{!isLoggedIn && (
				// <Button
				// 	text="Login"
				// 	customClasses="p-2 mb-8 relative"
				// 	onClick={() => {
				// 		// navigate('/login');
				// 		setOpenLoginForm(!openLoginForm);
				// 		setOpenMenu(false);
				// 	}}
				// />
				<ProfileMenuItem
					name={t('login')}
					icon={ProfileIcon}
					onClick={() => {
						// navigate('/login');
						setOpenLoginForm(!openLoginForm);
						setOpenMenu(false);
					}}
				/>
			)}
			{isLoggedIn && (
				<ProfileMenuItem
					name={t('profile')}
					icon={ProfileIcon}
					onClick={() => {
						setOpenMenu(false);
						isLoggedIn
							? navigate('/profile')
							: setOpenLoginForm(!openLoginForm);
					}}
				/>
			)}
			{isLoggedIn && (
				<ProfileMenuItem
					name={t('logout')}
					icon={LogoutIcon}
					onClick={logoutHandler}
				/>
			)}
		</div>
	);
};

export default ProfileMenu;
