import FlightJourney from '../../public/flight_right_icon.svg';
import Typography from './Typography';

const IconTextVertical = ({
	imgSrc,
	text,
	subText,
	imgClass = '',
	bottle = false,
	flightJourney = false,
	lang = 'en',
}) => {
	let imgContainerSize = 'w-9  lg:w-20 lg:h-20';

	if (bottle) {
		imgContainerSize = 'w-[2.813rem]  lg:w-20 lg:h-20';
	}

	if (flightJourney) {
		imgContainerSize = 'w-9  lg:w-[10rem] lg:h-[6rem]';
	}
	return (
		<div className="flex flex-col justify-start items-center text-center">
			<div
				className={`${imgClass} ${imgContainerSize}   lg:mb-5 flex justify-center items-center `}
			>
				<img src={imgSrc} className={`w-full ${bottle ? '' : 'h-full'}`} />
			</div>
			{subText && (
				// <Typography
				// 	variant="h3"
				// 	type="text14"
				// 	text={subText}
				// 	customClasses="text-bannerRedText  lg:text-[2.125rem] lg:leading-normal"
				// />
				<p
					className={`${
						lang === 'en'
							? 'text-sm leading-[1.125rem] lg:text-[2.125rem] lg:leading-normal'
							: 'text-[0.625rem] leading-normal lg:text-[2rem] '
					}   font-thumbsUp text-bannerRedText  `}
				>
					{subText}
				</p>
			)}
			{/* <Typography
				variant="h3"
				type="text14"
				text={text}
				customClasses="text-white lg:text-[2.375rem] lg:leading-normal"
			/> */}
			<p
				className={`${
					lang === 'en'
						? 'text-[0.625rem] leading-normal lg:text-[2.375rem] lg:leading-normal'
						: 'text-[0.625rem] leading-normal lg:text-[2rem] '
				}   font-thumbsUp text-white  `}
			>
				{text}
			</p>
		</div>
	);
};

export default IconTextVertical;
