import { Drawer } from '@material-tailwind/react';
import { Avatar } from '@material-tailwind/react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import HomeIcon from '../../public/home_icon.svg';
import ProfileMenuIcon from '../../public/user_icon.svg';
import ParticipateIcon from '../../public/how_to_icon.svg';
import PrivacyIcon from '../../public/privacy.svg';
import FaqIcon from '../../public/faqs.svg';
import TermsIcon from '../../public/terms.svg';
import WinnerIcon from '../../public/winner_drawer.svg';
import RightArrow from '../../public/profile_right_arrow.svg';
import ChampionIcon from '../../public/champion_icon.svg';
import ReferFriendIcon from '../../public/refer_friend_icon.svg';
import MessageIcon from '../../public/message_icon.svg';
import ContactIcon from '../../public/contact_icon.svg';
import DrawerMenuItem from './DrawerMenuItem';
import { useUserContext } from '../context/user-context';
import { useAuthContext } from '../context/auth-context';
import Button from './Button';
import { useCampaignContext } from '../context/campaign-context';
import { insertFloodlightTag } from '../utils/common';

const DrawerDefault = ({ open, closeDrawer, setOpenContact }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const { isLoggedIn, logout } = useAuthContext();

	const { userData, historyBackOption, setHistoryBackOption } =
		useUserContext();
	const {
		openUniqueCodeForm,
		setOpenUniqueCodeForm,
		openUniqueCodeBanner,
		setOpenUniqueCodeBanner,
		openLoginForm,
		setOpenLoginForm,
		setOpenAffiliateForm,
		setIsAffiliateForm,
		openAffiliateForm,
		totalPoints,
		thresholdPoint,
	} = useCampaignContext();

	const logoutHandler = () => {
		logout();
		navigate('/');
		closeDrawer();
	};

	let topSection;
	if (isLoggedIn) {
		topSection = (
			<div className="mb-8 bg-otpBg p-3 flex justify-between items-center rounded-lg">
				<div className="flex justify-start gap-1">
					<Avatar
						src={
							userData?.profile?.profile_cdn
								? userData?.profile?.profile_cdn
								: ProfileMenuIcon
						}
					/>
					<div>
						{' '}
						<h3 className="text-black text-base font-normal">
							{userData?.name}
						</h3>
						<h3 className="text-subTextColor text-sm font-normal">
							{t('my_kms')}{' '}
							{totalPoints <= thresholdPoint ? totalPoints : thresholdPoint}
						</h3>
					</div>
				</div>
				<img
					src={RightArrow}
					onClick={() => {
						navigate('/profile');
						closeDrawer();
					}}
					className="cursor-pointer"
				/>
			</div>
		);
	} else {
		topSection = (
			<Button
				text={t('login')}
				customClasses="p-2 mb-8"
				onClick={() => {
					// Floodlight tags
					insertFloodlightTag({
						activityName: 'ThumsUp_ICC24_Login_Counter',
						imageTag: 'thums03t', // Specific image tag for HomePage
					});
					// navigate('/login');
					setOpenLoginForm(!openLoginForm);
					closeDrawer();
				}}
			/>
		);
	}

	return (
		<Drawer
			open={open}
			onClose={closeDrawer}
			className="p-4 bg-white overflow-auto"
		>
			{topSection}

			<div className="h-screen">
				<div className="mb-4">
					<Link to="/" onClick={closeDrawer}>
						<DrawerMenuItem
							imgSrc={HomeIcon}
							text={t('home')}
							lang={i18n.language}
						/>
					</Link>
				</div>
				<div className="mb-4">
					<DrawerMenuItem
						imgSrc={WinnerIcon}
						text={t('toofani_champions')}
						onClick={() => {
							// Floodlight tags

							insertFloodlightTag({
								activityName: 'ThumsUp_ICC24_ToofaniChampions_Counter',
								imageTag: 'thums03y', // Specific image tag for HomePage
							});

							if (isLoggedIn) {
								navigate('/winner-list');
							} else {
								setOpenLoginForm(!openLoginForm);
							}
							closeDrawer();
						}}
						lang={i18n.language}
					/>
				</div>

				<div className="mb-4">
					<Link to="/faqs" onClick={closeDrawer}>
						<DrawerMenuItem
							imgSrc={FaqIcon}
							text={t('faqs')}
							lang={i18n.language}
						/>
					</Link>
				</div>
				<div
					className="mb-4"
					onClick={() => {
						// Floodlight tags

						insertFloodlightTag({
							activityName: 'ThumsUp_ICC24_TnC_Counter',
							imageTag: 'thums040', // Specific image tag for HomePage
						});
					}}
				>
					<Link
						to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/tnc/ICC24-TnCs.pdf"
						target="_blank"
						onClick={closeDrawer}
					>
						<DrawerMenuItem
							imgSrc={TermsIcon}
							text={t('terms_conditions')}
							lang={i18n.language}
						/>
					</Link>
				</div>
				<div
					className="mb-4"
					onClick={() => {
						// Floodlight tags

						insertFloodlightTag({
							activityName: 'ThumsUp_ICC24_PrivacyPolicy_Counter',
							imageTag: 'thums041', // Specific image tag for HomePage
						});
					}}
				>
					<Link
						to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
						target="_blank"
						onClick={closeDrawer}
					>
						<DrawerMenuItem
							imgSrc={PrivacyIcon}
							text={t('privacy_policy')}
							lang={i18n.language}
						/>
					</Link>
				</div>
				<div
					className="mb-14"
					onClick={() => {
						// Floodlight tags

						insertFloodlightTag({
							activityName: 'ThumsUp_ICC24_ContactUs_Counter',
							imageTag: 'thums042', // Specific image tag for HomePage
						});
						setOpenContact(true);
						closeDrawer();
					}}
				>
					<DrawerMenuItem
						imgSrc={ContactIcon}
						text={t('contact_us')}
						lang={i18n.language}
					/>
				</div>
				{isLoggedIn && (
					<button
						className="text-3xl text-footerBg mb-[6rem]"
						onClick={logoutHandler}
					>
						{t('logout')}
					</button>
				)}
			</div>

		</Drawer>
	);
};

export default DrawerDefault;
