import { useState, useEffect } from 'react';
import { useApiCall } from '../utils/useApiCall';

export const usePincode = () => {
	const { isLoading, error, sendRequest } = useApiCall();

	const [isValid, setIsValid] = useState(true);
	const [cityName, setCityName] = useState('');
	const [stateName, setStateName] = useState('');

	const getPinCodeData = async (pinCode) => {
		try {
			const response = await sendRequest(
				`https://api.postalpincode.in/pincode/${pinCode}`
			);

			if (response[0]?.Status === 'Success') {
				setIsValid(true);
				const postOfficeArr = response[0]?.PostOffice;
				setCityName(postOfficeArr[0]?.Block);
				setStateName(postOfficeArr[0]?.State);
			} else {
				throw new Error(response[0]?.Status);
			}
		} catch (error) {
			setIsValid(false);
		}
	};

	return {
		isValid,
		stateName,
		cityName,
		postCodeLoading: isLoading,
		getPinCodeData,
	};
};
