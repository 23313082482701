import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MilestoneIcon from '../../public/red_circle.svg';
import Typography from './Typography';

const ProgressBar = ({ totalPoints, thresholdPoint }) => {
	const { t, i18n } = useTranslation();
	const [progressPercent, setProgressPercent] = useState(0);

	useEffect(() => {
		const progressPercent = Math.ceil((totalPoints / thresholdPoint) * 100);
		setProgressPercent(progressPercent);
	}, [totalPoints, thresholdPoint]);

	let progressIndicator = null;

	if (progressPercent) {
		progressIndicator = (
			<div
				className={`h-full bg-headerBg`}
				style={{ width: `${progressPercent}%` }}
			></div>
		);
	}

	return (
		<div className="py-1 lg:py-3  relative">
			<div className="h-1 lg:h-3 w-[99%] bg-progressBg rounded-lg ">
				{progressIndicator}
				<div className="w-2.5 h-2.5 absolute top-[5%] left-0 lg:w-8 lg:h-8">
					<div className="flex flex-col items-start ">
						<div className="w-2.5 h-2.5 mb-3 lg:mb-5 lg:w-8 lg:h-8">
							<img src={MilestoneIcon} className="mb-2 w-full h-full" />
						</div>
						<Typography
							variant="h3"
							type={`${i18n.language === 'en' ? 'text16' : 'text12'}`}
							text={`0${t('KM')}`}
							customClasses="lg:text-4xl lg:leading-10 opacity-60"
						/>
					</div>
				</div>
				<div className="absolute top-[5%] right-0">
					<div className="flex flex-col items-end ">
						<div className="w-2.5 h-2.5 mb-3 lg:mb-5 lg:w-8 lg:h-8">
							<img src={MilestoneIcon} className="mb-2 w-full h-full" />
						</div>
						<Typography
							variant="h3"
							type={`${i18n.language === 'en' ? 'text16' : 'text12'}`}
							text={`14000${t('KMs')}`}
							customClasses="lg:text-4xl lg:leading-10 opacity-60"
						/>
					</div>
				</div>
				{/*{totalPoints > 0 && progressPercent !== 100 && (
					<div
						className="absolute top-[5%] left-[16%]"
						style={{
							left: `${progressPercent - 5}%`,
						}}
					>
						<div className="flex flex-col items-center ">
							<div className="w-2.5 h-2.5 mb-3 lg:mb-5 lg:w-8 lg:h-8">
								<img src={MilestoneIcon} className="mb-2 w-full h-full" />
							</div>
							<Typography
								variant="h3"
								type="text16"
								text={`${totalPoints}KMs`}
								customClasses="lg:text-4xl lg:leading-10 opacity-60"
							/>
						</div>
					</div>
				)}*/}
				<div className="absolute top-[5%] left-[32%]">
					<div className="flex flex-col items-center ">
						<div className="w-2.5 h-2.5 mb-3 lg:mb-5 lg:w-8 lg:h-8">
							<img src={MilestoneIcon} className="mb-2 w-full h-full" />
						</div>
						<Typography
							variant="h3"
							type={`${i18n.language === 'en' ? 'text16' : 'text12'}`}
							text={`5000${t('KMs')}`}
							customClasses="lg:text-4xl lg:leading-10 opacity-60"
						/>
					</div>
				</div>
				{
					<div className="absolute top-[5%] left-[64%]">
						<div className="flex flex-col items-center ">
							<div className="w-2.5 h-2.5 mb-3 lg:mb-5 lg:w-8 lg:h-8">
								<img src={MilestoneIcon} className="mb-2 w-full h-full" />
							</div>
							<Typography
								variant="h3"
								type={`${i18n.language === 'en' ? 'text16' : 'text12'}`}
								text={`10000${t('KMs')}`}
								customClasses="lg:text-4xl lg:leading-10 opacity-60"
							/>
						</div>
					</div>
				}
			</div>
		</div>
	);
};

export default ProgressBar;
