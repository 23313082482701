import Dialog from './Dialog';
import AddAddressForm from './AddAddressForm';

const AddAddressDialog = ({ open, handleOpen }) => {
	return (
		<Dialog
			open={open}
			handleOpen={handleOpen}
			className="w-full lg:w-2/5 "
			bodyHeight="h-screen"
		>
			<AddAddressForm handleOpen={handleOpen} />
		</Dialog>
	);
};

export default AddAddressDialog;
