{
	"winner_list_h1": "तूफानी चैंपियंस",
	"avatar": "अवतार",
	"name": "नाम",
	"phone_number": "फोन नंबर",
	"prize": "प्राइज",
	"select_date": "तारीख़ चुनें",
	"record_not_found": "रिकॉर्ड नहीं मिला",
	"win_1": "मैंने वेस्टइंडीज में होने वाले आईसीसी मेन्स टी20 विश्व कप 2024 के लिए तूफान की सवारी की एक सीट जीत ली है* ",
	"win_img_1": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/flight.jpg",
	"win_2": "मैंने वेस्टइंडीज में होने वाले आईसीसी मेन्स टी20 विश्व कप 2024 प्रमोशन के लिए FanCode की ओर से एक क्रिकेट जर्सी जीत ली है*",
	"win_img_2": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/jercy.jpg",
	"win_3": "मैंने वेस्टइंडीज में होने वाले आईसीसी मेन्स टी20 विश्व कप 2024 प्रमोशन के लिए FanCode की ओर से एक क्रिकेट मर्च जीत ली है*",
	"win_img_3": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/voucher.jpg",
	"one_more_check": "बस एक जांच और!",
	"one_more_check_text": "हम आपके द्वारा जमा किए गए विवरणों का वेरिफिकेशन कर रहे हैं और आपको इस साइट के साथ ही एसएमएस या व्हाट्सएप के द्वारा 10 कारोबारी दिवस के भीतर तूफान में शामिल होने की आपकी पात्रता के बारे में सूचित करेंगे। तैयार रहें!",
	"win_heading": "बधाई हो!",
	"win_1_success_verified": "तूफ़ान में आपकी सीट पक्की हो गई है और आप वेस्ट इंडीज़ रवाना होने वाले हैं!\nहम अगले स्टेप में शीघ्र ही एसएमएस/व्हाट्सएप या फोन कॉल के द्वारा आपसे संपर्क करेंगे। अपने दोस्तों को अपनी शानदार जीत के बारे में बताएं!\nसाझा करें",
	"win_1_success": "तूफ़ान में आपकी सीट पक्की हो गई है और आप वेस्ट इंडीज़ रवाना होने वाले हैं!\nहम अगले स्टेप में शीघ्र ही एसएमएस/व्हाट्सएप या फोन कॉल के द्वारा आपसे संपर्क करेंगे। अपने दोस्तों को अपनी शानदार जीत के बारे में बताएं!",
	"win_2_success": "You’ve claimed your Cricket Jersey! We will communicate further details via email & SMS shortly.",
	"win_3_success": "You’ve claimed your Toofani Merch! We will communicate further details via email & SMS shortly."
}
