import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LanguageDropDown from './LanguageDropDown';
import Logo from '../../public/logo.svg';
import HamburgerIcon from '../../public/hamburger.svg';
import NotificationIcon from '../../public/notification_bell_icon.svg';
import Drawer from './Drawer';
import { useAuthContext } from '../context/auth-context';
import { useCampaignContext } from '../context/campaign-context';
import { useUserContext } from '../context/user-context';
import BackArrow from '../../public/back_arrow.svg';

const MobileHeader = ({ setOpenContact }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [isNavOpen, setIsNavOpen] = useState(false);
	const { isLoggedIn } = useAuthContext();
	const { openLoginForm, setOpenLoginForm } = useCampaignContext();
	const { notificationCount } = useUserContext();

	return (
		<>
			{/* <nav>
				<div
					className="HAMBURGER-ICON space-y-2 w-5"
					onClick={() => setIsNavOpen((prev) => !prev)}
				>
					<img src={HamburgerIcon} alt="Hamburger Icon" className="w-full" />
				</div>
				<Drawer
					open={isNavOpen}
					closeDrawer={() => setIsNavOpen(false)}
					setOpenContact={setOpenContact}
				/>
			</nav> */}
			{/* <div className="w-[14rem] h-[3.5rem]">
				<img
					src={Logo}
					alt="LOGO"
					onClick={() => navigate('/')}
					className="w-full h-full"
				/>
			</div> */}
			{location.pathname === '/winner-list' ? (
				<div className="w-6" onClick={() => navigate('/contest-over')}>
					<img src={BackArrow} className="w-full" />
				</div>
			) : (
				<div></div>
			)}
			<div className="w-[3.063rem] ">
				<img src={Logo} alt="LOGO" onClick={() => {}} className="w-full" />
			</div>
			<div></div>

			{/* <div className="flex justify-between items-center gap-5">
				<LanguageDropDown />
				{notificationCount > 0 && (
					<div className="relative">
						<img
							src={NotificationIcon}
							alt="Notification Icon"
							onClick={() => {
								if (isLoggedIn) {
									navigate('/notifications');
								} else {
									setOpenLoginForm(!openLoginForm);
								}
							}}
							className="cursor-pointer"
						/>
						{notificationCount > 1 && (
							<div className="text-btnbg  rounded-full font-bold pb-1 border-2 text-center text-xs absolute -top-2.5 -end-1 w-4 h-4">
								{notificationCount - 1}
								<div
									className={`absolute top-0 start-0 rounded-full -z-10  border-1`}
								></div>
							</div>
						)}
					</div>
				)}
			</div> */}
		</>
	);
};

export default MobileHeader;
