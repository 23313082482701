import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import IconTextVertical from './IconTextVertical';
import Typography from './Typography';
import FlightJourney from '../../public/flight_banner.svg';
import JerseyIcon from '../../public/jersey_icon.svg';
import FancodeIcon from '../../public/fancode_banner_icon.svg';
import BottleUniquecodeIcon from '../../public/bottle_unique_code_v2.svg';
import CodeIcon from '../../public/unique_code_white.svg';
import DestinationIcon from '../../public/destination_icon.svg';
import FlightSeatIcon from '../../public/flight_seat_icon.svg';
import ReferFriendIcon from '../../public/refer_friend_popup_icon.svg';
import BannerFlightIcon from '../../public/banner_flight_icon.svg';
import WCT20Icon from '../../public/wc_t20_icon_tu.svg';
import ThumbsupPiyoIcon from '../../public/thumbs_up_piyo.svg';
import ThumbsupPiyoIconBn from '../../public/thumbs_up_piyo_bn.svg';
import ThumbsupPiyoIconGu from '../../public/thumbs_up_piyo_gu.svg';
import ThumbsupPiyoIconHi from '../../public/thumbs_up_piyo_hi.svg';
import ThumbsupPiyoIconKa from '../../public/thumbs_up_piyo_ka.svg';
import ThumbsupPiyoIconMa from '../../public/thumbs_up_piyo_ma.svg';
import ThumbsupPiyoIconOd from '../../public/thumbs_up_piyo_od.svg';
import ThumbsupPiyoIconTe from '../../public/thumbs_up_piyo_te.svg';

import Button from './Button';
import { useIsMobile } from '../utils/useIsMobile';

const HomePageBanner = ({
	bannerNum,
	onParticipateClick = () => {},
	onReferClick = () => {},
}) => {
	const { t, i18n } = useTranslation();

	let thumsUpPiyoIcon = ThumbsupPiyoIcon;
	switch (i18n.language) {
		case 'bn':
			thumsUpPiyoIcon = ThumbsupPiyoIconBn;
			break;
		case 'gu':
			thumsUpPiyoIcon = ThumbsupPiyoIconGu;
			break;
		case 'hi':
			thumsUpPiyoIcon = ThumbsupPiyoIconHi;
			break;
		case 'ka':
			thumsUpPiyoIcon = ThumbsupPiyoIconKa;
			break;
		case 'ma':
			thumsUpPiyoIcon = ThumbsupPiyoIconMa;
			break;
		case 'od':
			thumsUpPiyoIcon = ThumbsupPiyoIconOd;
			break;
		case 'te':
			thumsUpPiyoIcon = ThumbsupPiyoIconTe;
			break;

		default:
			break;
	}

	const isMobile = useIsMobile();

	let bannerContent;

	if (bannerNum === 1) {
		bannerContent = (
			<>
				<div className="w-[12.813rem]  lg:w-[47.68rem] lg:h-[22.68rem]">
					<img src={BannerFlightIcon} className="w-full" />
				</div>
				<div className="w-[3rem]  absolute top-2.5 right-2 lg:w-[6.063rem] lg:h-[2.875rem] lg:top-5 lg:right-5">
					<img src={WCT20Icon} className="w-full " />
				</div>
				<div className="w-[9rem]  absolute top-[5rem] left-1/2 transform -translate-x-1/2 lg:w-[32.62rem] lg:h-[9.93rem] lg:top-[21rem]">
					<img src={thumsUpPiyoIcon} className="w-full " />
				</div>
			</>
		);
	} else if (bannerNum === 2) {
		bannerContent = (
			<>
				<Typography
					variant="h2"
					type={i18n.language === 'en' ? 'text18' : 'text16'}
					text={t('prize_grab')}
					customClasses="lg:mb-4 text-center font-normal text-white lg:text-[4.25rem] lg:leading-normal"
				/>
				<div
					className={`flex justify-between items-start ${
						i18n.language === 'en' ? 'gap-5 lg:gap-11' : 'gap-2 lg:gap-6'
					} `}
				>
					<div className="flex-1">
						<IconTextVertical
							imgSrc={FlightJourney}
							text={t('seat_aboard_toofan')
								.split('\n')
								.map((line, index) => (
									<Fragment key={index}>
										{line}
										<br />
									</Fragment>
								))}
							imgClass="w-[4.063rem] !mb-[0.125rem]"
							flightJourney={true}
							lang={i18n.language}
						/>
					</div>
					<div className="flex-1">
						<IconTextVertical
							imgSrc={JerseyIcon}
							text={t('cricket_jersey_999_fancode')
								.split('\n')
								.map((line, index) => (
									<Fragment key={index}>
										{line}
										<br />
									</Fragment>
								))}
							lang={i18n.language}
							imgClass={`${isMobile ? '!w-5 !mb-[0.125rem]' : ''}`}
						/>
					</div>
					<div className="flex-1">
						<IconTextVertical
							imgSrc={FancodeIcon}
							text={t('toofani_999_fancode')
								.split('\n')
								.map((line, index) => (
									<Fragment key={index}>
										{line}
										<br />
									</Fragment>
								))}
							lang={i18n.language}
							imgClass={`${isMobile ? '!w-5 !mb-[0.125rem]' : ''}`}
						/>
					</div>
				</div>
			</>
		);
	} else if (bannerNum === 3) {
		bannerContent = (
			<>
				<Typography
					variant="h2"
					type={i18n.language === 'en' ? 'text18' : 'text10'}
					text={t('how_to_participate')}
					customClasses={`${
						i18n.language === 'en' ? '' : 'mb-2'
					} font-normal text-center text-white lg:text-[4.25rem] lg:leading-normal`}
				/>
				<div
					className={`flex justify-between items-center ${
						i18n.language === 'en' ? 'gap-5 lg:gap-16 ' : 'gap-1 lg:gap-8 mb-1'
					}  `}
				>
					<IconTextVertical
						imgSrc={BottleUniquecodeIcon}
						text={t('find_unique_code_pack')}
						subText={t('step_1')}
						bottle={true}
						lang={i18n.language}
						imgClass={`${isMobile ? '!w-3.5 !mb-[0.125rem]' : ''}`}
					/>
					<IconTextVertical
						imgSrc={CodeIcon}
						text={t('enter_code_collect_kms')}
						subText={t('step_2')}
						lang={i18n.language}
						imgClass={`${isMobile ? '!w-6 !mb-[0.125rem]' : ''}`}
					/>
					<IconTextVertical
						imgSrc={DestinationIcon}
						text={t('complete_journey_destination')}
						subText={t('step_3')}
						lang={i18n.language}
						imgClass={`${isMobile ? '!w-6 !mb-[0.125rem]' : ''}`}
					/>
					<IconTextVertical
						imgSrc={FlightSeatIcon}
						text={t('get_chance_win')}
						subText={t('step_4')}
						lang={i18n.language}
						imgClass={`${isMobile ? '!w-6 !mb-[0.125rem]' : ''}`}
					/>
				</div>
				<div className="">
					<Button
						text={t('enter_unique_code')}
						customClasses="p-1 text-base lg:p-7 lg:text-[2.5rem]"
						onClick={onParticipateClick}
					/>
				</div>
			</>
		);
	} else if (bannerNum === 4) {
		let referFriendText = t('refer_friend_collect_kms');

		if (true || !isMobile) {
			referFriendText = referFriendText.replace('\n', '');
		}

		bannerContent = (
			<>
				<div className="w-6 h-6 rounded-full mb-1.5 bg-white flex justify-center items-center lg:w-[6.563rem] lg:h-[6.563rem]">
					<img src={ReferFriendIcon} />
				</div>
				<Typography
					variant="h2"
					type={i18n.language === 'en' ? 'text18' : 'text10'}
					text={referFriendText.split('\n').map((line, index) => (
						<Fragment key={index}>
							{line}
							{<br />}
						</Fragment>
					))}
					customClasses={`text-center font-normal text-white ${
						i18n.language === 'en' ? 'lg:text-[4.25rem]' : 'lg:text-[2.5rem]'
					} lg:leading-normal w-[75%] lg:w-[80%]`}
				/>
				<Typography
					variant="h2"
					type={i18n.language === 'en' ? 'text10' : 'text10'}
					text={t('get_100km_mate_signup')
						.split('\n')
						.map((line, index) => (
							<Fragment key={index}>
								{line}
								<br />
							</Fragment>
						))}
					customClasses={`text-center font-normal text-white mb-[0.125rem] ${
						i18n.language === 'en' ? 'lg:text-[2.5rem]' : 'lg:text-[2rem]'
					}  lg:leading-normal w-[75m%] lg:w-[62%] lg:mb-5`}
				/>

				<div className="">
					<Button
						text={t('refer_now')}
						customClasses="p-1 text-base lg:p-7 lg:text-[2.5rem]"
						onClick={onReferClick}
					/>
				</div>
			</>
		);
	} else {
		bannerContent = (
			<Typography
				variant="h2"
				type="text40"
				customClasses="font-normal"
				text="TVC Banner"
			/>
		);
	}

	return (
		<div className="relative bg-popup-bg bg-cover bg-no-repeat bg-center w-full h-[9.375rem] lg:h-[37.25rem] flex flex-col overflow-y-hidden">
			<div className="p-2 lg:p-5 flex flex-col justify-center items-center flex-1">
				{bannerContent}
			</div>
			<Typography
				variant="h4"
				type="text12"
				text={`*${t('tnc_apply')}`}
				customClasses={`text-right pr-2 pb-2 text-white font-normal ${
					i18n.language !== 'en' ? 'text-[0.5rem]' : ''
				}`}
			/>
		</div>
	);
};

export default HomePageBanner;
