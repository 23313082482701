import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FaqItem from '../components/FaqItem';
import Heading from '../components/Heading';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import InputErrorMessage from '../components/InputErrorMessage';
import { useUserContext } from '../context/user-context';
import { insertFloodlightTag } from '../utils/common';

const Faq = () => {
	const { t } = useTranslation();

	const [showIndex, setShowIndex] = useState(0);

	const [faqs, setFaqs] = useState([]);

	const { isLoading, error: apiError, sendRequest } = useApiCall();

	// const { langSelected } = useUserContext();

	const langSelected = sessionStorage.getItem('langValue') || 'English';

	useEffect(() => {
		const getFaqs = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}faq/${langSelected}`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
					}
				);
				if (response?.success) {
					setFaqs(response?.data);
				} else {
					throw new Error(response?.message);
				}
			} catch (error) {
				console.log('Terms API error>>>', error.message);
			}
		};
		getFaqs();
	}, [langSelected]);

	useEffect(() => {
		setTimeout(() => {
			insertFloodlightTag({
				activityName: 'ThumsUp_ICC24_FAQs_Counter',
				imageTag: 'thums04', // Specific image tag for HomePage
			});
			console.log("after 5 sec run ThumsUp_ICC24_FAQs_Counter")
		}, 5000); // 5000 milliseconds = 5 seconds
	}, []);
	return (
		<div className="px-4 py-5 lg:px-36 lg:pt-16 lg:pb-20">
			<Heading text={t('faq_heading')} additionalClasses="lg:mb-7" />
			<h3 className="text-xl text-paragraphColor leading-6 font-normal mb-3 lg:text-2xxl lg:leading-normal lg:mb-7">
				{t('faq_subtext')}
			</h3>
			{isLoading && <Loader />}
			{apiError && <InputErrorMessage text={apiError} />}
			{faqs.map((item, index) => (
				<FaqItem
					showItems={index === showIndex}
					setShowIndex={() => setShowIndex(index)}
					item={item}
					key={item.id}
				/>
			))}

		</div>
	);
};

export default Faq;
