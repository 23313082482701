import {
	createContext,
	useState,
	useContext,
	useCallback,
	useEffect,
} from 'react';
import { useAuthContext } from './auth-context';
import { useApiCall } from '../utils/useApiCall';
import FlightWonIcon from '../../public/flight_won_icon.svg';
import WinIcon from '../../public/win_icon.svg';
import SaidIcon from '../../public/said_icon.svg';

const initialState = {
	openUniqueCodeForm: false,
	setOpenUniqueCodeForm: () => {},
	openUniqueCodeBanner: false,
	setOpenUniqueCodeBanner: () => {},
	totalPoints: 0,
	setTotalPoints: () => {},
	openLoginForm: false,
	setOpenLoginForm: () => {},
	openAffiliateForm: false,
	setOpenAffiliateForm: () => {},
	isAffiliateForm: false,
	setIsAffiliateForm: () => {},
	userInfoPopup: false,
	setUserInfoPopup: () => {},
	popupInfo: { type: 'default' },
	setPopupInfo: () => {},
	winPopupInfo:  {},
	setWinPopupInfo: () => {},
	thresholdPoint: 14000,
	setThresholdPoint: () => {},
	checkWinStatus: () => {},
	isWin: false,
	setIsWin: () => {},
	giftInfo: {},
	setGiftInfo: () => {},
	seatBooked: 0,
	setSeatBooked: () => {},
	flightMileStone: 0,
	setFlightMileStone: () => {},
	otherStates: {},
	setOtherStates: () => {},
	userUniqueCodeWinPopup: false,
	setUserUniqueCodeWinPopup: () => {},
};

const CampaignContext = createContext(initialState);

export const CampaignContextProvider = ({ children }) => {
	const [openUniqueCodeForm, setOpenUniqueCodeForm] = useState(false);
	const [openUniqueCodeBanner, setOpenUniqueCodeBanner] = useState(false);
	const [openLoginForm, setOpenLoginForm] = useState(false);
	const [openAffiliateForm, setOpenAffiliateForm] = useState(false);
	const [isAffiliateForm, setIsAffiliateForm] = useState(false);
	const [userInfoPopup, setUserInfoPopup] = useState(false);
	const [userUniqueCodeWinPopup, setUserUniqueCodeWinPopup] = useState(false);
	const [totalPoints, setTotalPoints] = useState(0);
	const [popupInfo, setPopupInfo] = useState({});
	const [winPopupInfo, setWinPopupInfo] = useState({});
	const [isWinStatus, setIsWinStatus] = useState(false);
	const [thresholdPoint, setThresholdPoint] = useState(14000);
	const [flightMileStone, setFlightMileStone] = useState(0);
	const [otherStates, setOtherStates] = useState({});

	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const { token } = useAuthContext();
	const checkWinStatus = useCallback(
		async (token, winUrl, handleOpenHandler,handleOpenHandlerRedirect, claimNow) => {
			try {
				const response = await sendRequest(winUrl, 'GET', null, {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				});

				if (response?.success) {

					if (response?.data?.win_code && response?.data?.isWinToday) {
						setUserUniqueCodeWinPopup(!userUniqueCodeWinPopup)
						let winType = response.data.win_code;
						if (winType === 1) {
							setWinPopupInfo({
								giftInfo: response?.data,
								handleOpenHandler:handleOpenHandlerRedirect
							})
							/*setPopupInfo({
								type: 'win_' + winType,
								dynamicCO: { datetime: response?.data?.remaining_expiry_hours },
								imgSrc: FlightWonIcon,
								staticMessage: true,
								cstBtnText: true,
								onClick: claimNow,
								handleOpenHandler,
							});*/
						} else {
							setWinPopupInfo({
								giftInfo: response?.data,
								handleOpenHandler:handleOpenHandlerRedirect
							})
							/*setPopupInfo({
								type: 'win_' + winType,
								dynamicCO: { datetime: response?.data?.expiry_date },
								imgSrc: WinIcon,
								staticMessage: true,
								cstBtnText: true,
								onClick: handleOpenHandlerRedirect,
								redeemLink:'/profile'
							});*/
						}
					} else {
						setUserInfoPopup(!userInfoPopup);
						setPopupInfo({
							type: 'not_win_for_the_day',
							dynamicCO: {},
							imgSrc: SaidIcon,
							staticMessage: true,
							handleOpenHandler,
						});
					}
				} else {
					throw new Error(response?.message);
				}
			} catch (error) {
				console.error(`Win API error>>>`, error);
			}
		},
		[]
	);
	const [isWin, setIsWin] = useState(false);
	const [giftInfo, setGiftInfo] = useState({});
	const [seatBooked, setSeatBooked] = useState(0);

	useEffect(() => {
		const getWinStatus = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}win/status`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					}
				);

				if (response?.success) {
					setGiftInfo(response?.data);
					try{
						let winType = response?.data?.win_code;
						if (winType===1 && response.data.seat_seen==="0") {
							let hasSeenWinPopup = localStorage.getItem('hasSeenWinPopup');
							if (!hasSeenWinPopup) {
								setUserUniqueCodeWinPopup(!userUniqueCodeWinPopup)
								setWinPopupInfo({
									giftInfo: response?.data,
									handleOpenHandler: null
								})
								localStorage.setItem('hasSeenWinPopup', 'true');
								const responseSeat = await sendRequest(`${process.env.API_BASE_URL}win/seat-seen`, 'GET', null, {
									'Content-Type': 'application/json',
									Authorization: `Bearer ${token}`,
								});
								console.log("win-seat",responseSeat);
							}


						}
					}catch (e) {
						console.log("win-status",e);
					}
				} else {
					throw new Error(response?.message);
				}
			} catch (error) {
				console.log('Win status API error>>>', error.message);
			}
		};

		isWin && getWinStatus();
	}, [isWin]);
	return (
		<CampaignContext.Provider
			value={{
				openUniqueCodeForm,
				setOpenUniqueCodeForm,
				openUniqueCodeBanner,
				setOpenUniqueCodeBanner,
				totalPoints,
				setTotalPoints,
				openLoginForm,
				setOpenLoginForm,
				openAffiliateForm,
				setOpenAffiliateForm,
				isAffiliateForm,
				setIsAffiliateForm,
				userInfoPopup,
				setUserInfoPopup,
				setPopupInfo,
				popupInfo,
				thresholdPoint,
				setThresholdPoint,
				checkWinStatus,
				isWin,
				setIsWin,
				giftInfo,
				setGiftInfo,
				seatBooked,
				setSeatBooked,
				flightMileStone,
				setFlightMileStone,
				otherStates,
				setOtherStates,
				setUserUniqueCodeWinPopup,
				userUniqueCodeWinPopup,
				setWinPopupInfo,
				winPopupInfo
			}}
		>
			{children}
		</CampaignContext.Provider>
	);
};

export const useCampaignContext = () => {
	const context = useContext(CampaignContext);
	if (!context) {
		throw new Error(
			'useCampaignContext must be used within a CampaignContextProvider'
		);
	}
	return context;
};
