import {
	Dialog,
	DialogHeader,
	DialogBody,
	DialogFooter,
} from '@material-tailwind/react';
import CloseIcon from '../../public/close.svg';

const DialogDefault = ({ open, handleOpen, children, bodyHeight = '' }) => {
	return (
		<Dialog open={open} handler={handleOpen} className="px-4 py-6">
			<div
				className="w-3.5 h-3.5 absolute top-4 right-3.5"
				onClick={handleOpen}
			>
				<img src={CloseIcon} />
			</div>
			<DialogBody
				className={` lg:overflow-hidden md:overflow-visible sm:overflow-visible  ${bodyHeight}`}
			>
				{children}
			</DialogBody>
		</Dialog>
	);
};

export default DialogDefault;
