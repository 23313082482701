{
	"home": "হোম",
	"login": "লগইন করুন",
	"mobile_number": "মোবাইল নম্বর",
	"mobile_number_label": "মোবাইল নম্বর লিখুন",
	"get_otp": "Get OTP",
	"mobile_error_message": "একটি বৈধ নম্বর লিখুন",
	"resendOTP": "ওটিপি আবার পাঠান",
	"otp_verification": "ওটিপি যাচাইকরণ",
	"otp_verification_sub_text": "6 সংখ্যার কোডটি লিখুন যা আমরা +91 {{mobile_number}} এ পাঠিয়েছি",
	"try_again_text": "{{time_remaining}} সেকেন্ডের মধ্যে ওটিপি আবার পাঠান",
	"btn_submit": "জমা করুন",
	"sign_up": "আপনি বেশিরভাগ ওখানেই থাকেন!",
	"enter_detail_text": "খেলার দলে আনতে সাইন আপ করুন!",
	"full_name": "নাম*",
	"name": "নাম*",
	"email_id": "ইমেল ঠিকানা",
	"terms_text": "আমি প্রত্যয়িত করছি যে আমি 18 বছরের কম বয়সী নই এবং <tnc>নিয়ম ও শর্তাবলী</tnc> এবং <pp>গোপনীয়তা নীতি</pp> পড়েছি।",
	"participate_win": "Participate & Win",
	"winners_list": "Winners List",
	"faqs": "প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী",
	"terms_conditions": "আপ টু ডেট শর্তাবলী",
	"privacy_policy": "গোপনীয়তা নীতি",
	"logout": "সাইন আউট করুন ",
	"participate_now": "এখনই অংশগ্রহণ করুন",
	"winner_board": "Winner Board",
	"enter_unique_code": "ইউনিক কোড লিখুন",
	"winners": "Winners",
	"enter_code": "কোড লিখুন",
	"profile": "প্রোফাইল",
	"get_aboard_text": "তুফানে আপনার আসন দখল করুন",
	"btn_continue": "অব্যাহত রাখুন",
	"get_aboard_sub_text": "ওয়ানস-ইন-এ-লাইফটাইম/কখনও না ভুলতে পারা যাত্রায় আমাদের সাথে যোগ দিন - ওয়েস্ট ইন্ডিজে আইসিসি টি-টোয়েন্টি বিশ্বকাপ উপভোগ করার জন্য আমাদের তুফানি ফ্যানদের ওয়েস্ট ইন্ডিজে নিয়ে যাওয়ার জন্য একটি বিশেষভাবে ডিজাইন করা বিমানে ভ্রমণ করা একটি চমৎকার অভিজ্ঞতা।",
	"win_mileage_text": "এর সাথে অ্যাকশনের কাছাকাছি যান",
	"win_mileage_sub_text": "প্রতিটি Thums Up® ",
	"my_kms": "আমার কিলোমিটার",
	"follow_us": "আমাদেরকে এতে অনুসরণ করুন",
	"copyright_text": "© 2024 The Coca-Cola Company। সমস্ত অধিকার সংরক্ষিত",
	"Please enter a valid unique code": "অনুগ্রহ করে একটি বৈধ কোড লিখুন",
	"your_profile": "আমার প্রোফাইল",
	"profile_page_desc": "আমরা বিজয়ীদের সাথে যোগাযোগ করতে নীচের বিবরণ ব্যবহার করব। অনুগ্রহ করে নিশ্চিত করুন যে প্রদত্ত সমস্ত তথ্য সঠিক",
	"dob": "জন্ম তারিখ",
	"gender": "লিঙ্গ",
	"set_as_default": "ডিফল্ট হিসাবে সেট করুন",
	"congratulations": "অভিনন্দন",
	"flight_ticket_claimed_text": "You’ve claimed your ticket to the West Indies! We will communicate further details via email & SMS shortly.",
	"share": "শেয়ার করুন",
	"claim_form_desc": "আপনার পুরস্কার ক্লেম করতে আপনার বিশদ বিবরণ লিখুন",
	"full_name_claim_form": "পুরো নাম (সরকারি পরিচয়পত্র অনুযায়ী)",
	"pan_card": "প্যান কার্ড",
	"passport_no": "পাসপোর্ট মেয়াদ শেষ হওয়ার তারিখ",
	"passport_expiry_date": "Passport Expiry Date",
	"Male": "পুরুষ",
	"Female": "মহিলা",
	"transgender": "Transgender",
	"Other": "অন্যান্য",
	"gift_voucher_claimed_text": "You’ve claimed your gift voucher! We will communicate further details via email & SMS shortly.",
	"claim_now": "এখন ক্লেম করুন ",
	"paytm_1000_won_text": "You’ve won yourself a ₹1,000 Paytm voucher!",
	"paytm_1000_win_text": "Win a ₹1000 Paytm Voucher!",
	"paytm_100_won_text": "You’ve won yourself a ₹1,00 Paytm voucher!",
	"paytm_100_win_text": "Win a ₹100 Paytm Voucher!",
	"win_ticket": "Win a Ticket",
	"enter_unique_code_reach_kms": "Enter unique code to reach 14K KMs",
	"enter_name": "একটি বৈধ নাম লিখুন",
	"enter_mobile_number": "মোবাইল নম্বর লিখুন",
	"enter_email": "একটি বৈধ ইমেল আইডি লিখুন",
	"enter_pan": "অনুগ্রহ করে একটি বৈধ প্যান কার্ড নম্বর লিখুন",
	"no_page_found": "পৃষ্ঠা খুঁজে পাওয়া যায়নি",
	"go_to_home": "হোমপেজে যান",
	"eligible_1000_paytm_voucher": "You are now eligible for ₹1000 Paytm Gift Voucher",
	"win_prize_lucky_draw_daily": "Win Prizes in a lucky Draw Daily",
	"ticket_to_west_indies": "Ticket to West Indies",
	"ticket_to_west_indies_v1": "“আপনি তুফানি ফ্যান ক্লাবে আপনার স্থান অর্জন করেছেন!",
	"ticket_to_west_indies_subtext_v1": "আপনি বিদেশে একটি তুফান সিট জিতেছেন! ওয়েস্ট ইন্ডিজে একটি দর্শনীয় ফ্লাইটের জন্য প্রস্তুত হন",
	"fan_jersy_heading": "একজন প্রো এর মত স্যুট পরিধান করুন",
	"fan_jersy_heading_text": "আপনার 999 টাকার ক্রিকেট জার্সি ক্লেম করার জন্য এটি একটি FanCode ভাউচার",
	"how_enter_lucky_draw": "How to Enter the Lucky Draw",
	"step_1": "ধাপ 1",
	"step_2": "ধাপ 2",
	"step_3": "ধাপ 3",
	"step_4": "ধাপ 4",
	"scan_qr_code_bottle": "Scan the QR code from your ThumbsUp bottle",
	"enter_code_kms_achieve_three_code": "Enter the unique code to accrue 14,000kms with 3 codes max",
	"enter_lucky_draw_win_prize": "Enter the lucky draw to win prizes daily",
	"paytm_gift_voucher": "Paytm gift Voucher",
	"faq_heading": "প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী",
	"faq_subtext": "থামস আপ সম্পর্কে আপনার যা জানা দরকার তা এখানে",
	"terms_heading": "Terms & Conditions",
	"contest_over": "Contest Over",
	"thank_you_visit_page": "Thank you for visiting our page! This contest is now closed.",
	"participate_other_giveaways": "Please visit (link to ThumbsUp’s Contests page) to participate in other giveaways",
	"winner_list": "Winner List",
	"full_name_error": "Please enter full name",
	"name_error": "একটি বৈধ নাম লিখুন",
	"dob_error": "আপনার বয়স কমপক্ষে 18 বছর হতে হবে, অনুগ্রহ করে সঠিক জন্ম তারিখ লিখুন।",
	"passport_expiry_date_error_required": "Please enter a valid passport expiry date",
	"passport_expiry_date_error": "Passport expiry date field must be a date after today.",
	"passport_no_error": "Please enter a valid Passport number",
	"terms_agree_error": "অনুগ্রহ করে নিয়ম এবং শর্তাবলীতে সম্মত হন",
	"email_error": "একটি বৈধ ইমেল আইডি লিখুন",
	"how_to_participate": "কিভাবে অংশগ্রহণ করবেন",
	"toofani_champions": "স্টর্ম চ্যাম্পিয়নস",
	"refer_friend": "একজন বন্ধুকে রেফার করুন",
	"have_invite_code": "আপনার কি কোন ইনভাইট কোড আছে?",
	"enter_collect_kms": "প্রবিষ্ট করুন এবং 100 কিমি সংগ্রহ করুন",
	"contact_us": "আমাদের সাথে যোগাযোগ করুন",
	"prize_grab": "জিতে নিন এই পুরস্কারগুলো",
	"seat_aboard_toofan": "তুফান জাহাজে আসন \nপ্রতিদিন ১ জন বিজয়ী*",
	"cricket_jersey_999_fancode": "ফ্যান কোড থেকে ₹999 মূল্যের ক্রিকেট জার্সি \nপ্রতি ঘন্টায় 1 জন বিজয়ী*",
	"toofani_999_fancode": "ফ্যান কোডের জন্য ₹499 মূল্যের তুফানি মার্চ \nপ্রতি ঘন্টায় 15 জন বিজয়ী*",
	"find_unique_code_pack": "প্যাকে ইউনিক কোড পান",
	"enter_code_collect_kms": " কিলোমিটার সংগ্রহ করতে কোড লিখুন",
	"complete_journey_destination": "গন্তব্যে সমস্ত পথ ভ্রমণ করুন",
	"get_chance_win": "আপনার জয়ের সুযোগ পান*!",
	"refer_friend_collect_kms": "অতিরিক্ত কিলোমিটার সংগ্রহ করতে একজন বন্ধুকে রেফার করুন*",
	"get_100km_mate_signup": "সফলভাবে সাইন আপ করে এবং আপনার ইনভাইট কোড ব্যবহার করে এমন প্রতিটি অংশীদারের জন্য 100 KM উপার্জন করুন।”",
	"refer_now": "এখন রেফার করুন",
	"tnc_apply": "নিয়ম এবং শর্তাবলী প্রযোজ্য",
	"available": "উপলভ্য সিট",
	"booked": "বুক করা সিট",
	"going_soon": "শীঘ্রই যাচ্ছে",
	"you_made_toofani_fan_club": "আপনি তুফানি ফ্যান ক্লাবে আপনার স্থান অর্জন করেছেন!",
	"won_seat_get_ready": "আপনি বিদেশে একটি তুফান সিট জিতেছেন! ওয়েস্ট ইন্ডিজে একটি দর্শনীয় ফ্লাইটের জন্য প্রস্তুত হন",
	"jersey_won_text": "একজন প্রো এর মত স্যুট পরিধান করুন",
	"jersey_won_subtext": "আপনার 999 টাকার ক্রিকেট জার্সি ক্লেম করার জন্য এটি একটি FanCode ভাউচার",
	"redeem_now": "রিডিম করুন",
	"voucher_won_text": "অল্প টীম স্পিরিট জাগিয়ে তুলুন",
	"voucher_won_subtext": "এটি একটি ফ্যানকোড ভাউচার যা আপনার 499 টাকার ক্রিকেট মার্চ ক্লেম করতে পারে",
	"my_wins": "আমার WINগুলি",
	"optional": "ঐচ্ছিক",
	"sign_up_cta": "নিবন্ধন করুন",
	"Please enter a valid OTP": "একটি বৈধ ওটিপি প্রবিষ্ট করুন",
	"enter_collect_100kms": "প্রবিষ্ট করুন এবং 100 কিমি সংগ্রহ করুন",
	"unique_code_entry_text": "ওয়েস্ট ইন্ডিজে ICC পুরুষদের T20 বিশ্বকাপ 2024-এর জন্য তুফান নিয়ে আসার সুযোগ পান*",
	"unique_code_entry_subtext": "Thums Up® বোতল লেবেলের পিছনে ইউনিক কোডটি খুঁজুন",
	"mobile_number_short": "মোবাইল নম্বর",
	"welcome_player": "স্বাগতম, প্লেয়ার!",
	"login_start_winning": "জয় শুরু করতে লগইন করুন!",
	"trademark_text": "কার্বনেটেড জল. ট্রেডমার্কের মালিক: THE COCA-COLA COMPANY.",
	"address": "ঠিকানা",
	"address_line_1": "ঠিকানা লাইন 1",
	"address_line_2": "ঠিকানা লাইন নম্বর 2",
	"nearest_landmark": "কাছাকাছি ল্যান্ডমার্ক",
	"alternative_mobile_number": "বিকল্প মোবাইল নম্বর",
	"pincode": "পিনকোড",
	"state": "অবস্থা",
	"city": "শহর",
	"state_error": "রাজ্য নির্বাচন করুন",
	"city_error": "অনুগ্রহ করে শহর নির্বাচন করুন",
	"pincode_error": "একটি বৈধ পিনকোড লিখুন",
	"address_error": "অনুগ্রহ করে একটি বৈধ ঠিকানা লিখুন৷",
	"pan_error": "অনুগ্রহ করে একটি বৈধ প্যান কার্ড নম্বর লিখুন",
	"copy_code": "কোড কপি করুন",
	"last_date_redeem": "রিডিম করার শেষ তারিখ",
	"last_date_to_claim": "ক্লেম করার শেষ তারিখ",
	"share_with_friends": "বন্ধুদের সাথে শেয়ার করুন",
	"no_internet_message": "সংযোগ করতে অক্ষম। অনুগ্রহ করে আপনার নেটওয়ার্ক সংযোগ পরীক্ষা করুন এবং আবার চেষ্টা করুন!",
	"email_us": "আমাদের ইমেইল করুন",
	"call_toll_free": "আমাদের এই টোল ফ্রি নম্বরে কল করুন",
	"mon_sat": "সোম থেকে শনিবার",
	"sunday": "রবিবার",
	"follow_us_now": "এখন আমাদের অনুসরণ করুন",
	"otp_expired_enter_new": "আপনার ওটিপির মেয়াদ শেষ হয়ে গেছে। অনুগ্রহ করে নতুন ওটিপি ব্যবহার করুন।",
	"email_address": "ইমেল ঠিকানা",
	"Code has already been used, please enter a new code": "কোড ইতিমধ্যে ব্যবহার করা হয়েছে, অনুগ্রহ করে একটি নতুন কোড লিখুন",
	"first": "প্রথম",
	"last": "শেষ",
	"add": "যোগ করুন",
	"refresh_page": "পৃষ্ঠাটি রিফ্রেশ করুন",
	"Seat on Toofan": "তুফানে সিট",
	"Toofani Merch": "তুফানী মার্চ",
	"Cricket Jersey": "ক্রিকেট জার্সি",
	"You are here": "আপনি এখানে পৌঁছে গেছেন",
	"OTP resent successfully": "ওটিপি সফলভাবে পুনরায় পাঠানো হয়েছে.",
	"No winners till now": "এখন পর্যন্ত কোন বিজয়ী নেই",
	"COPIED": "কপি করা হয়েছে",
	"T&C": "আপ টু ডেট নিয়ম এবং শর্তাবলী",
	"Email is already in use": "ইমেইল ঠিকানাটি ইতোমধ্যেই ব্যবহারে রয়েছে",
	"Clear": "পরিষ্কার করুন",
	"West Indies": "ওয়েস্ট ইন্ডিজ",
	"India": "ভারত",
	"Reminder send successfully": "রিমাইন্ডার সফলভাবে পাঠানো হয়েছে",
	"KM": "কিমি",
	"KMs": "কিমি",
	"Edit Address": "Edit Address",
	"Please enter a valid code": "অনুগ্রহ করে একটি বৈধ কোড লিখুন",
	"final_day_offer": "অফারের মেয়াদের শেষ দিন ৩০ এপ্রিল ২০২৪ থেকে বাড়িয়ে ১৫ মে ২০২৪ করা হয়েছে। <tnc>বিধি ও শর্তাবলী প্রযোজ্য</tnc>",
	"Offer Period has ended.": "Offer Period has ended."
}
