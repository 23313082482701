import { useState, useEffect } from 'react';
import Heading from '../components/Heading';
import { useTranslation } from 'react-i18next';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import InputErrorMessage from '../components/InputErrorMessage';

const PrivacyPolicy = () => {
	const [content, setContent] = useState('');

	const { isLoading, error: apiError, sendRequest } = useApiCall();

	useEffect(() => {
		const getContent = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}privacy-policy/english`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
					}
				);
				if (response?.success) {
					setContent(response?.data?.content);
				} else {
					throw new Error(response?.message);
				}
			} catch (error) {
				console.log('Terms API error>>>', error.message);
			}
		};
		getContent();
	}, []);

	const { t } = useTranslation(['translations', 'terms']);
	return (
		<div className="mb-5 px-4 py-5  lg:px-36 lg:pt-16 lg:pb-20">
			<Heading text={t('privacy_policy')} additionalClasses="mb-3 lg:mb-7" />
			{isLoading && <Loader />}
			{apiError && <InputErrorMessage text={apiError} />}
			{content && (
				<div
					dangerouslySetInnerHTML={{ __html: content }}
					className="text-sm leading-5 lg:text-2xl lg:leading-8"
				/>
			)}
		</div>
	);
};

export default PrivacyPolicy;
