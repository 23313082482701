import { useTranslation } from 'react-i18next';
import Heading from '../components/Heading';
import Voucher from '../components/Voucher';
import BottleUniqueCode from '../../public/bottle_unique_code_v2.svg';
import EnterCode from '../../public/enter_code_icon.svg';
import FlightIcon from '../../public/flight_right_icon.svg';
import FlightJourney from '../../public/flight_journey.svg';
import { useAuthContext } from '../context/auth-context';
import { useCampaignContext } from '../context/campaign-context';
import JerseyIcon from '../../public/jersey_shirt_icon.svg';
import FancodeJerseyIcon from '../../public/fancode_jersey_icon.svg';

const ParticipateStep = ({ imgSrc, text, subText }) => {
	return (
		<div className="flex justify-start items-center gap-6 mb-10 lg:gap-14">
			<div className="w-11 lg:w-[6rem]">
				<img src={imgSrc} className="w-full" />
			</div>
			<div className="text-left">
				<p className="text-base text-footerBg font-normal lg:text-2xl">
					{text}
				</p>
				<p className="text-xl text-pageTitleColor font-normal lg:text-3xl">
					{subText}
				</p>
			</div>
		</div>
	);
};

const ParticipateWin = () => {
	const { t } = useTranslation();

	const { isLoggedIn } = useAuthContext();
	const {
		openUniqueCodeForm,
		setOpenUniqueCodeForm,
		setOpenLoginForm,
		openLoginForm,
	} = useCampaignContext();
	const participateHandler = () => {
		if (isLoggedIn) {
			setOpenUniqueCodeForm(!openUniqueCodeForm);
		} else {
			setOpenLoginForm(!openLoginForm);
		}
	};

	return (
		<div className="bg-participate-now bg-no-repeat bg-cover py-5 px-6 text-center">
			<Heading
				text={t('win_prize_lucky_draw_daily')}
				additionalClasses="mb-1"
			/>
			<Heading
				text={t('how_enter_lucky_draw')}
				additionalClasses="mb-3 text-2xxl"
			/>
			<ParticipateStep
				imgSrc={BottleUniqueCode}
				text={t('step_1')}
				subText={t('Scan the QR code from your ThumbsUp bottle')}
			/>
			{/* <div className="flex justify-start items-center gap-6 mb-10 lg:gap-14">
				<div className="w-11 lg:w-[6rem]">
					<img src={BottleUniqueCode} className="w-full"/>
				</div>
				<div className="text-left">
					<p className="text-base text-footerBg font-normal lg:text-2xl">{t('step_1')}</p>
					<p className="text-xl text-pageTitleColor font-normal lg:text-3xl">
						{t('Scan the QR code from your ThumbsUp bottle')}
					</p>
				</div>
			</div> */}
			<ParticipateStep
				imgSrc={EnterCode}
				text={t('step_2')}
				subText={t('enter_code_kms_achieve_three_code')}
			/>
			{/* <div className="flex justify-start items-center gap-6 mb-10 lg:gap-14">
				<div className="w-11 lg:w-[6rem]">
					<img src={EnterCode} />
				</div>
				<div className="text-left">
					<p className="text-base text-footerBg font-normal lg:text-2xl">{t('step_2')}</p>
					<p className="text-xl text-pageTitleColor font-normal lg:text-3xl">
						{t('enter_code_kms_achieve_three_code')}
					</p>
				</div>
			</div> */}
			<ParticipateStep
				imgSrc={FlightIcon}
				text={t('step_3')}
				subText={`${t('enter_lucky_draw_win_prize')}*`}
			/>
			{/* <div className="flex justify-start items-center gap-6 mb-5">
				<div className="w-11 lg:w-[6rem]">
					<img src={FlightIcon} />
				</div>
				<div className="text-left">
					<p className="text-base text-footerBg font-normal">{t('step_3')}</p>
					<p className="text-xl text-pageTitleColor font-normal ">
						{t('enter_lucky_draw_win_prize')}*
					</p>
				</div>
			</div> */}
		</div>
	);
};

export default ParticipateWin;
