{
	"win_1": {
		"headingText": "You’ve made it to the Toofani Fan Club!",
		"subHeadingText": "You won a seat Aboard Toofan to the ICC 2024 Games in the West Indies",
		"text": "",
		"icon": "flight_won_icon",
		"btnText": "ಈಗ ಕ್ಲೈಮ್ ಮಾಡಿ",
		"btnSubText": "Claim within:",
		"timerDisplay": " {{datetime}}"
	},
	"win_2": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Cricket Jersey worth ₹999 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "ಈಗ ರಿಡೀಮ್ ಮಾಡಿ",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_3": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Toofani merch worth ₹499 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "ಈಗ ರಿಡೀಮ್ ಮಾಡಿ",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_check": {
		"headingText": "ಕೇವಲ ಒಂದು ಪರಿಶೀಲನೆ ಬಾಕಿ ಇದೆ!",
		"subHeadingText": "ನೀವು ಸಲ್ಲಿಸಿದ ವಿವರಗಳನ್ನು ನಾವು ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇವೆ ಮತ್ತು ಈ ಸೈಟ್‌ನಲ್ಲಿ ಹಾಗೂ ಸಂದೇಶ ಅಥವಾ ವಾಟ್ಸ್‌ಅಪ್ ಮೂಲಕ 10 ವ್ಯವಹಾರಿಕ ದಿನಗಳಲ್ಲಿ ತೂಫಾನ್ ಹತ್ತಲು ನಿಮ್ಮ ಅರ್ಹತೆಯ ಕುರಿತು ನಿಮಗೆ ತಿಳಿಸುತ್ತೇವೆ. ಕಾತುರದಿಂದ ಕುಳಿತುಕೊಳ್ಳಿ!",
		"text": "",
		"icon": "flight_win_check"
	},
	"win_check_waiting": {
		"headingText": "ಕೇವಲ ಒಂದು ಪರಿಶೀಲನೆ ಬಾಕಿ ಇದೆ!",
		"subHeadingText": "",
		"text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 3 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
		"icon": "flight_win_check"
	},
	"valid_code": {
		"headingText": "ವಾಹ್, ತೂಫಾನ್!",
		"subHeadingText": "ನೀವು {{points}} ಕಿಮೀ ಸಂಗ್ರಹಿಸಿದ್ದೀರಿ. ತೂಫಾನ್‌ನಲ್ಲಿನ ಅವಕಾಶಕ್ಕಾಗಿ ನೀವು ಕೇವಲ {{remaining_km}} ಕಿಮೀ ದೂರದಲ್ಲಿದ್ದೀರಿ!",
		"text": "",
		"icon": "points_win_flight_icon.svg"
	},
	"total_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "You have entered multiple invalid codes today. Come back tomorrow to try again.",
		"text": "",
		"icon": "warn_icon"
	},
	"already_win": {
		"headingText": "ಈಗಾಗಲೇ ಗೆಲುವು ಸಾಧಿಸಿದ್ದೀರಿ!",
		"subHeadingText": "ಲಕ್ಕಿ ಡ್ರಾದಲ್ಲಿ ನೀವು ಈಗಾಗಲೇ ಗೆದ್ದಿದ್ದೀರಿ",
		"text": "",
		"icon": "warn_icon"
	},
	"valid_code_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "",
		"text": "You have entered multiple invalid codes today. Come back tomorrow to try again."
	},
	"claimed": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your ticket aboard Toofan Air with our fan squad",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"after_claimed": {
		"headingText": "ಕೇವಲ ಒಂದು ಪರಿಶೀಲನೆ ಬಾಕಿ ಇದೆ!",
		"subHeadingText": "ನೀವು ಸಲ್ಲಿಸಿದ ವಿವರಗಳನ್ನು ನಾವು ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇವೆ ಮತ್ತು ಈ ಸೈಟ್‌ನಲ್ಲಿ ಹಾಗೂ ಸಂದೇಶ ಅಥವಾ ವಾಟ್ಸ್‌ಅಪ್ ಮೂಲಕ 10 ವ್ಯವಹಾರಿಕ ದಿನಗಳಲ್ಲಿ ತೂಫಾನ್ ಹತ್ತಲು ನಿಮ್ಮ ಅರ್ಹತೆಯ ಕುರಿತು ನಿಮಗೆ ತಿಳಿಸುತ್ತೇವೆ. ಕಾತುರದಿಂದ ಕುಳಿತುಕೊಳ್ಳಿ!",
		"text": "",
		"btnText": "",
		"icon": "flight_win_check"
	},
	"claimed_reward": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your Toofani Reward",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"claim_now": {
		"headingText": "ಸಮಯ ಮೀರುತ್ತಿದೆ!",
		"subHeadingText": "ತೂಫಾನ್‌ನಲ್ಲಿ ನಿಮ್ಮ‌ ಆಸನವನ್ನು ಕ್ಲೈಮ್ ಮಾಡುವ ವಿಂಡೋ {{timer}} ರಂದು ಮುಕ್ತಾಯವಾಗುತ್ತದೆ",
		"text": "",
		"btnText": "ಈಗ ಕ್ಲೈಮ್ ಮಾಡಿ",
		"icon": "timer_icon"
	},
	"claim_expired": {
		"headingText": "ನೀವು ರನ್ ಔಟ್ ಆಗಿದ್ದೀರಿ!",
		"subHeadingText": "ನಿಮ್ಮ ಬಹುಮಾನವನ್ನು ಕ್ಲೈಮ್‌ ಮಾಡುವ ಅವಧಿ ಮುಗಿದಿದೆ. ಭಾಗವಹಿಸಿದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು.",
		"text": "Don’t lose hope, you are still in the game.\nCome back again tomorrow to check if you have won the next seat on Toofan!",
		"icon": "said_icon"
	},
	"not_win_for_the_day": {
		"headingText": "ಅದು ಮೇಡನ್ ಓವರ್!",
		"subHeadingText": "ಭರವಸೆ ಕಳೆದುಕೊಳ್ಳಬೇಡಿ, ನೀವು ಇನ್ನೂ ಆಟದಲ್ಲಿದ್ದೀರಿ. ನೀವು ತೂಫಾನ್‌ನಲ್ಲಿ ಮುಂದಿನ ಆಸನವನ್ನು ಗೆದ್ದಿದ್ದೀರಾ ಎಂದು ಪರಿಶೀಲಿಸಲು ನಾಳೆ ಮತ್ತೆ ಬನ್ನಿ!",
		"text": "",
		"icon": "said_icon"
	},
	"exit_claim_popup": {
		"headingText": "ನಿಮ್ಮ ಪದಕವನ್ನು ಪಡೆದುಕೊಳ್ಳಿ",
		"subHeadingText": "ನಿಮ್ಮ ಬಹುಮಾನದ ಮೇಲಿನ ನಿಮ್ಮ ಕ್ಲೈಮ್ {{hours}} ಗಂಟೆಗಳಲ್ಲಿ ಮುಕ್ತಾಯವಾಗುತ್ತದೆ.",
		"text": "ನೀನು ನಿಜವಾಗಲೂ ಹೊರಗೆ ಹೋಗಬಯಸುತ್ತೀರಾ?",
		"icon": "claim_expired_icon",
		"btnTextYes": "ಹೌದು",
		"btnTextNo": "ಇಲ್ಲ",
		"confirm": true
	},
	"exit_next_win_for_new": {
		"headingText": "ನಿಲ್ಲಿಸಿ!",
		"subHeadingText": "ನೀವು ಇಂದು ಮುಂದಿನ ವಿಜೇತರಾಗಬಹುದು!",
		"text": "ನೀನು ನಿಜವಾಗಲೂ ಹೊರಗೆ ಹೂಗಬಯಸುತ್ತೀರಾ?",
		"icon": "raise_hand_icon",
		"btnTextYes": "ಹೌದು",
		"btnTextNo": "ಇಲ್ಲ",
		"confirm": true
	},
	"exit_next_win": {
		"headingText": "ನೀವು ಗೆಲುವಿನ ಸ್ಕೋರ್ ಅನ್ನು ಹಿಮ್ಮೆಟ್ಟಿಸಲಿದ್ದೀರಿ!",
		"subHeadingText": "ಮತ್ತೊಂದು ಯುನಿಕ್ ಕೋಡ್ ನಮೂದಿಸಿ ಮತ್ತು ಗೆಲ್ಲುವ ಅವಕಾಶವನ್ನು ಪಡೆಯಿರಿ!",
		"text": "ನೀನು ನಿಜವಾಗಲೂ ಹೊರಗೆ ಹೋಗಬಯಸುತ್ತೀರಾ?",
		"icon": "raise_hand_icon",
		"btnTextYes": "ಹೌದು",
		"btnTextNo": "ಇಲ್ಲ",
		"confirm": true
	},
	"affiliate_signup_success": {
		"headingText": "ವೆಲ್ ಡನ್ ಸ್ಕಿಪ್ಪರ್",
		"subHeadingText": "",
		"text": "ನೀವು 100 ಕೆಮಿ ಸಂಗ್ರಹಿಸಿದ್ದೀರಿ",
		"icon": "right_bat"
	},
	"default": {
		"headingText": "Error!",
		"subHeadingText": "Not found!",
		"text": ""
	},
	"qualified_for_lucky_draw": {
		"headingText": "Already qualified!",
		"subHeadingText": "You have already qualified for the lucky draw",
		"text": ""
	}
}
