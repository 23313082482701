import { useTranslation } from 'react-i18next';

import Typography from './Typography';
import FlightWinCheck from '../../public/flight_win_check.svg';

const MyWinsClaimed = ({ giftInfo }) => {
	const { t } = useTranslation('winner');

	return (
		<>
			<div className="flex flex-col justify-start items-center text-center">
				<div className="w-9 h-9 mb-2 lg:w-20 lg:h-20 lg:mb-5">
					<img src={FlightWinCheck} className="w-full h-full" />
				</div>
				<Typography
					variant="h2"
					type="text24"
					text={t('one_more_check')}
					customClasses="mb-4 text-center  lg:text-[4.25rem] lg:leading-normal"
				/>
				<h3 className="text-center text-sm  leading-[1.125rem] font-thumbsUp  lg:text-[2.375rem] lg:leading-normal">
					{t('one_more_check_text')}
				</h3>
			</div>
		</>
	);
};

export default MyWinsClaimed;
