import { useEffect, useContext } from 'react';
import { useApiCall } from '../utils/useApiCall';
import { useUserContext } from '../context/user-context';
import { getProfileResponse } from './mockProfile';
import { useAuthContext } from '../context/auth-context';
import { useCampaignContext } from '../context/campaign-context';

export const useUserDetail = () => {
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const { setUserData, setNotificationCount } = useUserContext();

	const { token, queryUser, isLoggedIn, setQueryUser, logout } =
		useAuthContext();
	const {
		setTotalPoints,
		thresholdPoint,
		setThresholdPoint,
		setSeatBooked,
		setIsWin,
		setFlightMileStone,
	} = useCampaignContext();

	useEffect(() => {
		const getUserDetail = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}user/profile`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					}
				);

				if (response.success) {
					// isLoggedIn && !response?.data?.name && logout();

					setTotalPoints(response.data?.profile?.my_kms || 0);
					setUserData(response.data);
					setIsWin(response?.data?.isWin);
					setFlightMileStone(response?.data?.milestone);
					setQueryUser(false);
					// setFlightMileStone(2);
				} else {
					throw new Error(response.message);
				}
			} catch (error) {
				console.log(`GET user profile API error>>>`, error);
			}
		};

		(queryUser || isLoggedIn) && getUserDetail();
	}, [queryUser, isLoggedIn]);

	// Call default settings API
	useEffect(() => {
		const getDefaultData = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}default`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
						Authorization: `Bearer ${token}`,
					}
				);

				if (response?.success) {
					setTotalPoints(response?.data?.points);
					response?.data?.totalPoint &&
						setThresholdPoint(response?.data?.totalPoint);
					response?.data?.notificationCount &&
						setNotificationCount(response?.data?.notificationCount);
				} else {
					throw new Error(response?.message);
				}
			} catch (error) {
				console.log('Default API error>>>', error.message);
			}
		};

		// getDefaultData();
	}, [isLoggedIn]);

	useEffect(() => {
		const getSeatData = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}win/seats`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
						Authorization: `Bearer ${token}`,
					}
				);

				if (response?.success) {
					setSeatBooked(response?.data?.flight_booked_ticket || 0);
				} else {
					throw new Error(response?.message);
				}
			} catch (error) {
				console.log('Flight seat book API error>>>', error.message);
			}
		};

		// getSeatData();
	}, [isLoggedIn]);
};
