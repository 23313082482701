import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Heading from '../components/Heading';
import ProfilePicIcon from '../../public/user_icon.svg';
import EditIcon from '../../public/edit_icon.svg';
import ProgressBar from '../components/ProgressBar';
import { useUserContext } from '../context/user-context';
import { useAuthContext } from '../context/auth-context';
import AddressBox from '../components/AddressBox';
import AddAddressDialog from '../components/AddAddressDialog';
import { useCampaignContext } from '../context/campaign-context';
import MyWinsDefault from '../components/MyWinsDefault';
import JerseyIcon from '../../public/jersey_shirt_icon.svg';
// import FlightWonIcon from '../../public/flight_win_icon.svg';
import FlightWonIcon from '../../public/flight_win_v1.svg';
import FancodeIcon from '../../public/fancode_icon.svg';
import Fancode499Icon from '../../public/fancode_499_icon.svg';
import Gift from '../components/Gift';
import ReferralList from './ReferralList';
import EditAddressDialog from '../components/EditAddressDialog';
import MyWinsClaimed from '../components/MyWinsClaimed';
import ClaimRedeemSuccess from '../components/ClaimRedeemSuccess';
import ClaimRedeemFail from '../components/ClaimRedeemFail';
const Profile = () => {
	const { t } = useTranslation(['translations', 'winner']);
	const navigate = useNavigate();

	const [openAddressForm, setOpenAddressForm] = useState(false);
	const [openEditAddressForm, setOpenEditAddressForm] = useState(false);
	const [editAddressObj, setEditAddressObj] = useState({});

	const { userData } = useUserContext();
	const { isLoggedIn, logout } = useAuthContext();

	const {
		openUniqueCodeForm,
		setOpenUniqueCodeForm,
		totalPoints,
		thresholdPoint,
		setOpenAffiliateForm,
		setIsAffiliateForm,
		isWin,
		giftInfo,
	} = useCampaignContext();

	useEffect(() => {
		if (!isLoggedIn) {
			console.log('navigating to home>>>');
			navigate('/');
		}
		if (userData && 'name' in userData && userData.name === null) {
			logout();
		}
	}, [isLoggedIn]);

	const myWinsInfo = {
		1: {
			imgSrc: FlightWonIcon,
			text: t('you_made_toofani_fan_club'),
			subText: t('won_seat_get_ready'),
			btnText: t('claim_now'),
			shareBtnTxt: t('share_with_friends'),
			btnOnClick: () => navigate('/claim-form'),
			share: false,
			labelBtnText: `${t('last_date_to_claim')}: `,
			expiry: '5th May, 2024',
			fanCodeIcon: false,
			giftClasses: 'bg-bgCard',
			shareEvent: 'TUICC24_SeatToofaniAir_Share',
			winCode: giftInfo?.win_code,
			winImage: t('winner:win_img_1'),
		},
		2: {
			imgSrc: JerseyIcon,
			text: t('jersey_won_text'),
			subText: t('jersey_won_subtext'),
			btnText: t('redeem_now'),
			shareBtnTxt: t('share_with_friends'),
			btnOnClick: () => {},
			share: true,
			expiry: '30th June, 2024',
			labelBtnText: `${t('last_date_redeem')}:`,
			fanCodeIcon: true,
			shareEvent: 'TUICC24_Jersery_Share',
			redeemEvent: 'TUICC24_Jersery_RedeemFormSubmit',
			winCode: giftInfo?.win_code,
			winImage: t('winner:win_img_2'),
		},
		3: {
			imgSrc: Fancode499Icon,
			text: t('voucher_won_text'),
			subText: t('voucher_won_subtext'),
			btnText: t('redeem_now'),
			shareBtnTxt: t('share_with_friends'),
			btnOnClick: () => {},
			share: true,
			fanCodeIcon: true,
			expiry: '30th June, 2024',
			labelBtnText: `${t('last_date_redeem')}:`,
			shareEvent: 'TUICC24_Toofani_Merch_Share',
			redeemEvent: 'TUICC24_ToofaniMerch_RedeemFormSubmit',
			winCode: giftInfo?.win_code,
			winImage: t('winner:win_img_3'),
		},
	};

	const addAddressHandler = () => {};

	const editAddressHandler = (addressObj) => {
		setEditAddressObj(addressObj);
		setOpenEditAddressForm(!openEditAddressForm);
	};

	const deleteAddressHandler = () => {};

	let myWinView;
	const referFriend = () => {
		setIsAffiliateForm(false);
		setOpenAffiliateForm(true);
	};

	if (isWin && giftInfo) {
		const myWinData = myWinsInfo[giftInfo?.win_code];
		let winMessage = '';
		if (giftInfo?.win_code) {
			winMessage = t('winner:win_' + giftInfo.win_code);
		}
		if (giftInfo.claimStatus && giftInfo.claimStatus === '3') {
			myWinView = (
				<div className="bg-inputBg bg-cover bg-no-repeat bg-center w-full h-[14.875rem] lg:h-[37.25rem] flex flex-col rounded-lg">
					<div className="px-5 flex flex-col justify-center items-center flex-1">
						<ClaimRedeemFail  />
					</div>
				</div>
			);
		}else if(!giftInfo.isClaim) {
		/*if (!giftInfo.isClaim) {*/
			myWinView = (
				<Gift
					{...myWinData}
					expiry={giftInfo.expiry_date}
					couponCode={giftInfo.couponCode}
					redeemLink={giftInfo.redeemLink}
					winMessage={winMessage}
					referFriendHanlder={referFriend}
					winCode={giftInfo?.win_code}
				/>
			);
		} else if (giftInfo.isClaim) {
			myWinView = (
				<div className="bg-inputBg bg-cover bg-no-repeat bg-center w-full h-[14.875rem] lg:h-[37.25rem] flex flex-col rounded-lg">
					<div className="px-5 flex flex-col justify-center items-center flex-1">
						{giftInfo.claimStatus && giftInfo.claimStatus === '2' ? (
							<ClaimRedeemSuccess {...myWinData} />
						) : (
							<MyWinsClaimed giftInfo={giftInfo} />
						)}
					</div>
				</div>
			);
		}
	} else {
		myWinView = (
			<div className="bg-popup-bg bg-cover bg-no-repeat bg-center w-full h-[16.875rem] lg:h-[37.25rem] flex flex-col rounded-lg">
				<div className="p-5 flex flex-col justify-center items-center flex-1">
					<MyWinsDefault />
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="px-4 pt-4 pb-7 lg:px-36 lg:pt-9 lg:pb-20">
				<Heading text={t('your_profile')} additionalClasses="lg:mb-2" />
				<h2 className="text-subHeadingColor text-medium lg:text-[2.5rem] font-normal leading-normal mb-6 lg:mb-8 ">
					{t('profile_page_desc')}
				</h2>
				<section className="flex justify-between items-start mb-5 lg:mb-16">
					<div className="flex justify-between items-start">
						<div className="flex flex-col justify-center items-center mr-3">
							<div className="w-16 h-16 lg:w-[8.938rem] lg:h-[8.938rem] mb-1 lg:mb-4">
								<img
									src={
										userData?.profile?.profile_cdn
											? userData?.profile?.profile_cdn
											: ProfilePicIcon
									}
									className="w-full"
								/>
							</div>
							<h3 className="text-pageTitleColor text-sm font-medium lg:text-2xl">
								{userData?.name}
							</h3>
						</div>
						<div>
							<h3 className="text-sm text-black leading-4 font-normal lg:text-2xxl lg:leading-8">
								{t('mobile_number_short')}:{' '}
								{`+${userData?.isd_code}-${userData?.mobile_no}`}
							</h3>
							<h3 className="text-sm text-black leading-4 font-normal lg:text-2xxl lg:leading-8">
								{t('email_id')}: {userData?.email}
							</h3>
							<h3 className="text-sm text-black leading-4 font-normal lg:text-2xxl lg:leading-8">
								{t('dob')}:{' '}
								{userData?.profile?.dob &&
									new Date(userData?.profile?.dob)
										.toLocaleDateString('en-GB', {
											day: '2-digit',
											month: 'short',
											year: 'numeric',
										})
										.replace(/ /g, '/')}
							</h3>
							<h3 className="text-sm text-black leading-4 font-normal lg:text-2xxl lg:leading-8">
								{t('gender')}:{' '}
								{userData?.profile?.gender && t(userData?.profile?.gender)}
							</h3>
						</div>
					</div>
					<div className="w-3 h-3.5 lg:w-[1.125rem] lg:h-[1.125rem]">
						<button onClick={() => navigate('/update-profile')}>
							<img src={EditIcon} alt="edit" />
						</button>
					</div>
				</section>
				<section className="mb-5 lg:mb-16">
					<AddressBox
						address={userData?.address || []}
						onAdd={() => setOpenAddressForm(!openAddressForm)}
						onEdit={editAddressHandler}
						onDelete={deleteAddressHandler}
					/>
				</section>
				<section className="mb-12 lg:mb-24">
					<Heading text={t('my_kms')} additionalClasses="mb-4" />
					<ProgressBar
						totalPoints={
							totalPoints <= thresholdPoint ? totalPoints : thresholdPoint
						}
						thresholdPoint={thresholdPoint}
					/>
				</section>
				<section className="mb-8 lg:mb-16">
					<Heading text={t('my_wins')} additionalClasses="mb-1 lg:mb-4" />
					{myWinView}
				</section>
				<section className="mb-5">
					<ReferralList referFriendHandler={referFriend} />
				</section>
			</div>
			<AddAddressDialog
				open={openAddressForm}
				handleOpen={() => setOpenAddressForm(!openAddressForm)}
			/>
			<EditAddressDialog
				open={openEditAddressForm}
				handleOpen={() => setOpenEditAddressForm(!openEditAddressForm)}
				addressObj={editAddressObj}
			/>
		</>
	);
};

export default Profile;
