{
	"heading_h2": "ପାର୍ଟନରସିପ୍\u200C ହେଉ ତ ପରି ହଉ!",
	"heading_h3": "ଆପଣଙ୍କର ସାଙ୍ଗମାନଙ୍କୁ ଅଂଶଗ୍ରହଣ କରିବାକୁ ଇନଭାଇଟ୍\u200C କରନ୍ତୁ",
	"invite_code_text": "ଆପଣଙ୍କର ବନ୍ଧୁ ସାଇନ୍ ଅପ୍ କରିବା ପରେ ଏବଂ ଆପଣଙ୍କର ଇନଭାଇଟ୍\u200C କୋଡ୍ ବ୍ୟବହାର କରିବା ପରେ ଆପଣ 100 କିଲୋମିଟର ପାଇବେ।",
	"friend_mobile_number": "ଆପଣଙ୍କର ବନ୍ଧୁଙ୍କ ମୋବାଇଲ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
	"friend_email": "ଆପଣଙ୍କର ବନ୍ଧୁଙ୍କ ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ (ବୈକଳ୍ପିକ)",
	"send_invite_btn": "ଦାଖଲ କରନ୍ତୁ",
	"privacy_policy": "ଗୋପନୀୟତା ନୀତି",
	"icon": "right_bat",
	"mobile_required": "ଦୟାକରି ଏକ ବୈଧ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
	"email_optional": "ଦୟାକରି ଏକ ବୈଧ ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ",
	"Number already in use, Please refer another": "ସଂଖ୍ୟା ପୂର୍ବରୁ ବ୍ୟବହାରରେ ଅଛି, ଦୟାକରି ଆଉ ଏକ ନମ୍ୱର ଦେଖନ୍ତୁ",
	"thank_you": "ଧନ୍ୟବାଦ!",
	"thank_you_h3": "କୋଡ୍ ସହିତ ଆପଣଙ୍କ ବନ୍ଧୁଙ୍କ ନିକଟକୁ ଏକ କୋଡ୍\u200C ସହିତ ଇନଭାଇଟ୍\u200C ପଠାଯାଇଛି",
	"thank_you_info_text": "ଆପଣଙ୍କର ବନ୍ଧୁ ସାଇନ୍ ଅପ୍ କରିବା ପରେ ଏବଂ ତାଙ୍କର ୟୁନିକ୍\u200C ଇନଭାଇଟ୍\u200C କୋଡ୍ ବ୍ୟବହାର କରିବା ପରେ ଆପଣ 100 କିଲୋମିଟର ପାଇବେ।",
	"enter_invite_code": "ଇନଭାଇଟ୍\u200C କୋଡ୍\u200C ପ୍ରବେଶ କରନ୍ତୁ",
	"affiliate_signup_h3": "ଆପଣଙ୍କ ବନ୍ଧୁଙ୍କ 6 ଅଙ୍କ ବିଶିଷ୍ଟ ଇନ୍\u200Cଭାଇଟ୍\u200C କୋଡ୍\u200C ପ୍ରବେଶ କରନ୍ତୁ ଏବଂ 100 କିଲୋମିଟର୍\u200C ସଂଗ୍ରହ କରନ୍ତୁ",
	"affiliate_signup_btn": "ଦାଖଲ କରନ୍ତୁ",
	"Please enter a valid code": "ଦୟାକରି ଏକ ବୈଧ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
	"my_referrals": "ମୋର ରେଫରାଲ୍",
	"accepted": "ଗ୍ରହଣ କରାଯାଇଛି",
	"pending": "ବିଚାରାଧୀନ ଅଛି",
	"users": "ବ୍ୟବହାରକାରୀ",
	"status": "ସ୍ଥିତି",
	"send_reminder": "ରିମାଇଣ୍ଡର ପଠାନ୍ତୁ",
	"refer_another": "ଅନ୍ୟକୁ ରେଫର୍\u200C କରନ୍ତୁ",
	"Referral not found": "ରେଫରାଲ ମିଳିଲା ନାହିଁ"
}
