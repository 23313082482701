// OTPInput.js
import { useState, useRef, useEffect } from 'react';

const OTPInput = ({ length, onChange }) => {
	const [otp, setOtp] = useState(Array(length).fill(''));
	const inputRefs = useRef([]);

	useEffect(() => {
		inputRefs.current[0].focus();
	}, []);

	const handleChange = (e, index) => {
		const value = e.target.value;

		if (value.length === 1 && index < length - 1) {
			inputRefs.current[index + 1].focus();
		}

		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);

		onChange(newOtp.join(''));
	};

	const handleKeyDown = (e, index) => {
		if (e.key === 'Backspace' && index > 0) {
			// inputRefs.current[index - 1].focus();
		}
	};

	return (
		<div>
			{otp.map((digit, index) => (
				<input
					key={index}
					type="text"
					maxLength="6"
					value={digit}
					onChange={(e) => handleChange(e, index)}
					onKeyDown={(e) => handleKeyDown(e, index)}
					ref={(ref) => (inputRefs.current[index] = ref)}
					className="w-11 h-11 rounded bg-otpBg mr-4 text-xl text-center text-inputTextColor lg:text-2xl"
				/>
			))}
		</div>
	);
};

export default OTPInput;
