import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const Button = ({
	text,
	disabled,
	target = '_blank',
	// textSize,
	customClasses = 'py-3 ',
	onClick = () => {},
	actionLink,
	id = '',
}) => {
	const { t } = useTranslation();
	return (
		<>
			{actionLink ? (
				<Link
					target={target}
					to={actionLink}
					className={clsx(
						`bg-btnbg btn rounded-lg w-full text-2xl text-white ${customClasses}`,
						{
							'bg-disabled': disabled,
							// 'text-base': textSize === 'base',
						}
					)}
					disabled={disabled}
					onClick={onClick}
				>
					{t(text)}
				</Link>
			) : (
				<button
					className={clsx(
						`bg-btnbg  rounded-lg  w-full text-2xl text-white px-8 ${customClasses}`,
						{
							'bg-disabled': disabled,
							// 'text-base': textSize === 'base',
						}
					)}
					disabled={disabled}
					onClick={onClick}
					id={id}
				>
					{t(text)}
				</button>
			)}
		</>
	);
};

export default Button;
