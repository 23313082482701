import Dialog from './Dialog';
import UniqueCodeForm from './UniqueCodeForm';

const UniqueCodeDialog = ({ open, handleOpen }) => {
	return (
		<Dialog
			open={open}
			handleOpen={handleOpen}
			className="w-full lg:w-2/5"
			// bodyHeight="h-screen"
		>
			<UniqueCodeForm handleOpen={handleOpen} />
		</Dialog>
	);
};

export default UniqueCodeDialog;
