import { Typography } from '@material-tailwind/react';

const TypographyComp = ({ variant, type, text, customClasses }) => {
	const typographyTypes = {
		text24:
			'text-pageTitleColor text-2xl leading-7 font-bold font-thumbsUp lg:text-[2.375rem] ',
		text18:
			'text-pageTitleColor text-lg leading-normal font-bold font-thumbsUp lg:text-2xl ',
		text16: 'text-base leading-5 font-bold text-black font-thumbsUp lg:text-xl',
		text20:
			'text-xl leading-normal font-medium text-black font-thumbsUp lg:text-3xl',
		text12: 'text-xs font-bold leading-4 text-black font-thumbsUp',
		text14: 'text-sm font-bold leading-[1.125rem] text-black font-thumbsUp',
		text10:
			'text-[0.625rem] font-bold leading-[1.125rem] text-pageTitleColor font-thumbsUp',
		text8:
			'text-[0.5rem] text-black leading-[0.625rem] font-bold font-thumbsUp',
		text28: 'text-2xxl leading-8 font-bold text-pageTitleColor font-thumbsUp',
		text40:
			'text-[2.5rem] leading-normal font-bold text-white font-thumbsUp lg:text-[6.25rem]',
		text8:
			'text-[0.5rem] font-bold leading-[1.125rem] text-pageTitleColor font-thumbsUp',
		text6:
			'text-[0.375rem] font-bold leading-normal text-pageTitleColor font-thumbsUp',
	};
	return (
		<Typography
			variant={variant}
			className={`${typographyTypes[type]} ${customClasses}`}
		>
			{text}
		</Typography>
	);
};

export default TypographyComp;
