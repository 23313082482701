import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageDropDown from './LanguageDropDown';
import Logo from '../../public/logo.svg';
import NotificationIcon from '../../public/notification.svg';
import { useState } from 'react';
import ProfileMenuIcon from '../../public/profile_menu_icon.svg';
import Button from './Button';
import ProfileMenu from './ProfileMenu';
import { useAuthContext } from '../context/auth-context';
import UniqueCodeForm from './UniqueCodeForm';
import { useCampaignContext } from '../context/campaign-context';
import { useUserContext } from '../context/user-context';
import { triggerTrackingPixel } from '../utils/common';

const DesktopHeader = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { isLoggedIn } = useAuthContext();

	const {
		openUniqueCodeForm,
		setOpenUniqueCodeForm,
		openLoginForm,
		setOpenLoginForm,
	} = useCampaignContext();

	const [openMenu, setOpenMenu] = useState(false);

	const { userData, historyBackOption, setHistoryBackOption } =
		useUserContext();

	return (
		<>

			<div className="w-[3.688rem] h-[4.063rem]">
				<img
					src={Logo}
					alt="LOGO"
					// width="49"
					// height="49"
					onClick={() => navigate('/')}
					className="w-full h-full"
				/>
			</div>

			{/* <nav
				className={`flex justify-center items-center gap-12 ${
					i18n.language === 'en' ? 'text-2xxl' : 'text-base'
				}`}
			>

				{isLoggedIn ? (
					<Link to="/winner-list" className=" font-light leading-4 text-white">
						{t('toofani_champions')}
					</Link>
				) : (
					<div
						onClick={() => {
							setOpenLoginForm(!openLoginForm);
							setHistoryBackOption({
								...historyBackOption,
								isDialogOpen: false,
								isPageOpen: true,
								redirectLink: () => {
									navigate('/winner-list');
								},
							});
						}}
						className=" font-light leading-4 text-white"
					>
						{t('toofani_champions')}
					</div>
				)}
				<Link to="/faqs" className=" font-light leading-4 text-white">
					{t('faqs')}
				</Link>
			</nav> */}
			{/* {isLoggedIn && <UniqueCodeForm type="inline" />} */}
			{/* <div className="flex justify-between gap-5">
				<LanguageDropDown />
				<div className="w-7 h-7">
					<img
						src={
							userData?.profile?.profile_cdn && isLoggedIn
								? userData?.profile?.profile_cdn
								: ProfileMenuIcon
						}
						alt="Profile menu"
						onClick={() => setOpenMenu(!openMenu)}
						className="w-full"
					/>
				</div>
			</div> */}
			{/* {openMenu && (
				<div className="w-48 absolute top-[5.25rem] right-[8.25rem] z-10">
					<ProfileMenu setOpenMenu={setOpenMenu} />
				</div>
			)} */}
		</>
	);
};

export default DesktopHeader;
