import PlusIcon from '../../public/plus_icon.svg';

const FaqItem = ({ item, showItems, setShowIndex = () => {} }) => {
	const handleClick = () => {
		setShowIndex();
	};
	return (
		<div className="mx-auto my-4 border-b border-borderBtm mb-4">
			<div
				className="flex justify-between cursor-pointer mb-4"
				onClick={handleClick}
			>
				<span className="text-faqText text-xl font-normal leading-normal lg:text-2xxl lg:leading-normal">
					{item?.question}
				</span>
				<div className="w-3.5 h-3.5">
					<img src={PlusIcon} />
				</div>
			</div>
			<p className="text-base leading-normal lg:text-2xl">
				{showItems && item?.answer}
			</p>
		</div>
	);
};

export default FaqItem;
