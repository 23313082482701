import Dialog from './DialogV1';
import { useTranslation } from 'react-i18next';
import FlightWonIcon from "../../public/flight_win_v1.svg";
import JerseyIcon from "../../public/jersey_shirt_icon.svg";
import Fancode499Icon from "../../public/fancode_499_icon.svg";
import PopupGift from "./PopupGift";
import {useNavigate} from "react-router-dom";

const UniqueCodeWinnerDialog = ({ open = true, winPopupInfo, handleOpen = () => {} }) => {
	/*CO- means dynamic content Object {name:"TH"}*/
	if (!winPopupInfo) {
		return null; // Return null for React components instead of undefined
	}
	const {
		giftInfo,
		handleOpenHandler,
		hideCrossIcon=false
	} = winPopupInfo;
	handleOpen = handleOpenHandler ? handleOpenHandler : handleOpen;
	const { t, i18n } = useTranslation(['translations','winner']);
	const referFriend = () => {	};
	const navigate = useNavigate();
	const myWinsInfo = {
		1: {
			imgSrc: FlightWonIcon,
			text: t('you_made_toofani_fan_club'),
			subText: t('won_seat_get_ready'),
			btnText: t('claim_now'),
			shareBtnTxt: t('share_with_friends'),
			btnOnClick: () => {
				handleOpen && handleOpen()
				navigate('/claim-form')
			},
			share: false,
			labelBtnText: `${t('last_date_to_claim')}: `,
			expiry: giftInfo?.expiry_date,
			fanCodeIcon: false,
			giftClasses: 'bg-bgCard',
			shareEvent: 'TUICC24_SeatToofaniAir_Share',
			winCode: giftInfo?.win_code,
			winImage: t('winner:win_img_1'),
		},
		2: {
			imgSrc: JerseyIcon,
			text: t('jersey_won_text'),
			subText: t('jersey_won_subtext'),
			btnText: t('redeem_now'),
			shareBtnTxt: t('share_with_friends'),
			btnOnClick: () => {},
			share: true,
			expiry: giftInfo?.expiry_date,
			labelBtnText: `${t('last_date_redeem')}:`,
			fanCodeIcon: true,
			shareEvent: 'TUICC24_Jersery_Share',
			redeemEvent: 'TUICC24_Jersery_RedeemFormSubmit',
			winCode: giftInfo?.win_code,
			winImage: t('winner:win_img_2'),
		},
		3: {
			imgSrc: Fancode499Icon,
			text: t('voucher_won_text'),
			subText: t('voucher_won_subtext'),
			btnText: t('redeem_now'),
			shareBtnTxt: t('share_with_friends'),
			btnOnClick: () => {},
			share: true,
			fanCodeIcon: true,
			expiry: giftInfo?.expiry_date,
			labelBtnText: `${t('last_date_redeem')}:`,
			shareEvent: 'TUICC24_Toofani_Merch_Share',
			redeemEvent: 'TUICC24_ToofaniMerch_RedeemFormSubmit',
			winCode: giftInfo?.win_code,
			winImage: t('winner:win_img_3'),
		},
	};
	const myWinData = myWinsInfo[giftInfo?.win_code];
	let winMessage = '';
	try {
		if (giftInfo?.win_code) {
			winMessage = t('winner:win_' + giftInfo.win_code);
		}
	} catch (e) {
		console.log(`Message Dialog Warning`, e);
	}
	return (
		<Dialog bodyHeight="p-0" hideCrossIcon={hideCrossIcon} open={open} handleOpen={handleOpen} classesNames="" className=" w-full lg:w-3/4">
			<PopupGift
				{...myWinData}
				couponCode={giftInfo?.couponCode}
				redeemLink={giftInfo?.redeemLink}
				winMessage={winMessage}
				referFriendHanlder={referFriend}
				winCode={giftInfo?.win_code}
				btnOnClickHandler={handleOpenHandler}
				copyTextTrans={t('copy_code')}
			/>
		</Dialog>
	);
};

export default UniqueCodeWinnerDialog;
