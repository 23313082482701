{
	"home": "હોમ",
	"login": "લૉગિન કરો",
	"mobile_number": "મોબાઇલ નંબર",
	"mobile_number_label": "મોબાઇલ નંબર દાખલ કરો",
	"get_otp": "Get OTP",
	"mobile_error_message": "કૃપા કરીને માન્ય નંબર દાખલ કરો",
	"resendOTP": "OTP ફરીથી મોકલો",
	"otp_verification": "OTP ચકાસણી",
	"otp_verification_sub_text": "અમારા દ્વારા +91 {{mobile_number}} પર મોકલેલ 6-અંકનો કોડ દાખલ કરો",
	"try_again_text": "{{time_remaining}} સેકન્ડમાં OTP ફરીથી મોકલો",
	"btn_submit": "સબમિટ",
	"sign_up": "તમે લગભગ પહોંચી ગયા છો!",
	"enter_detail_text": "રમવાની ટીમમાં આવવા માટે સાઇન અપ કરો!",
	"full_name": "પૂરું નામ",
	"name": "નામ",
	"email_id": "ઈમેલ આઈડી",
	"terms_text": "હું પ્રમાણિત કરું છું કે મારી ઉંમર ઓછામાં ઓછી 18 વર્ષ છે અને મેં <tnc>નિયમો અને શરતો</tnc> અને <pp>ગોપનીયતા નીતિ</pp> વાંચી છે.",
	"participate_win": "Participate & Win",
	"winners_list": "Winners List",
	"faqs": "વારંવાર પૂછાતા પ્રશ્નો",
	"terms_conditions": "અપ-ટુ-ડેટ નિયમો અને શર્તો",
	"privacy_policy": "ગોપનીયતા નિતી",
	"logout": "સાઈન આઉટ",
	"participate_now": "હમણાં જ સહભાગી બનો",
	"winner_board": "Winner Board",
	"enter_unique_code": "અનન્ય કોડ દાખલ કરો",
	"winners": "Winners",
	"enter_code": "કોડ દાખલ કરો",
	"profile": "પ્રોફાઈલ",
	"get_aboard_text": "તૂફાન પર તમારી સીટ મેળવો",
	"btn_continue": "ચાલુ રાખો",
	"get_aboard_sub_text": "જીવનભરની આ સફરમાં અમારી સાથે જોડાઓ - અમારા તમામ તૂફાની ચાહકોને વેસ્ટ ઈન્ડિઝમાં ICC T20 વર્લ્ડ કપની રમતમાં જવા માટે ખાસ ક્યુરેટેડ એરક્રાફ્ટમાં સવારીનો વૈભવી અનુભવ.",
	"win_mileage_text": "એક્શનની નજીક જાઓ",
	"win_mileage_sub_text": "દરેક થ્મસ અપની સાથે®",
	"my_kms": "મારું કીમી",
	"follow_us": "અમને ફોલો કરો",
	"copyright_text": "© 2024 કોકા કોલા કંપની. સર્વાધિકાર આરક્ષિત.",
	"Please enter a valid unique code": "કૃપા કરીને માન્ય કોડ દાખલ કરો",
	"your_profile": "મારી પ્રોફાઈલ",
	"profile_page_desc": "અમે વિજેતાઓનો સંપર્ક કરવા માટે નીચેની વિગતોનો ઉપયોગ કરીશું. કૃપા કરીને ખાતરી કરો કે આપેલી બધી માહિતી સાચી છે",
	"dob": "જન્મ તારીખ",
	"gender": "જાતિ",
	"set_as_default": "ડિફૉલ્ટ તરીકે સેટ કરો",
	"congratulations": "બસ એક વધુ તપાસ!",
	"flight_ticket_claimed_text": "You’ve claimed your ticket to the West Indies! We will communicate further details via email & SMS shortly.",
	"share": "શેર કરો",
	"claim_form_desc": "કૃપા કરીને તમારી વિગતો તમારા પુરસ્કારોનો દાવો કરવા માટે દાખલ કરો",
	"full_name_claim_form": "પૂરું નામ (સરકારી ID મુજબ)",
	"pan_card": "પાન કાર્ડ",
	"passport_no": "પાસપોર્ટ નંબર",
	"passport_expiry_date": "પાસપોર્ટ સમાપ્તિની તારીખ",
	"Male": "પુરૂષ",
	"Female": "સ્ત્રી",
	"transgender": "Transgender",
	"Other": "અન્ય",
	"gift_voucher_claimed_text": "You’ve claimed your gift voucher! We will communicate further details via email & SMS shortly.",
	"claim_now": "હમણાં જ દાવો કરો",
	"paytm_1000_won_text": "You’ve won yourself a ₹1,000 Paytm voucher!",
	"paytm_1000_win_text": "Win a ₹1000 Paytm Voucher!",
	"paytm_100_won_text": "You’ve won yourself a ₹1,00 Paytm voucher!",
	"paytm_100_win_text": "Win a ₹100 Paytm Voucher!",
	"win_ticket": "Win a Ticket",
	"enter_unique_code_reach_kms": "Enter unique code to reach 14K KMs",
	"enter_name": "કૃપા કરીને માન્ય નામ દાખલ કરો",
	"enter_mobile_number": "Please enter mobile number",
	"enter_email": "કૃપા કરીને માન્ય ઇમેઇલ ID દાખલ કરો",
	"enter_pan": "કૃપા કરીને માન્ય PAN કાર્ડ નંબર દાખલ કરો",
	"no_page_found": "કોઈ પેજ મળ્યું નથી",
	"go_to_home": "હોમપેજ પર જાઓ",
	"eligible_1000_paytm_voucher": "You are now eligible for ₹1000 Paytm Gift Voucher",
	"win_prize_lucky_draw_daily": "Win Prizes in a lucky Draw Daily",
	"ticket_to_west_indies": "Ticket to West Indies",
	"ticket_to_west_indies_v1": "તમે તૂફાની ફેન ક્લબમાં પહોંચી ગયા છો!",
	"ticket_to_west_indies_subtext_v1": "તમે તૂફાન પરની સીટ જીતી લીધી! વેસ્ટ ઈન્ડિઝ માટે શાનદાન ઉડાન ભરવા તૈયાર થઈજાઓ હમણાં જ દાવો કરો",
	"fan_jersy_heading": "પ્રો જેમ કપડાં પહેરો",
	"fan_jersy_heading_text": "તમારી 999 રૂપિયાની ક્રિકેટ જર્સીનો દાવો કરવા માટે અહીં એક ફેનકોડ વાઉચર છે",
	"how_enter_lucky_draw": "How to Enter the Lucky Draw",
	"step_1": "પગલું 1",
	"step_2": "પગલું 2",
	"step_3": "પગલું 3",
	"step_4": "પગલું 4",
	"scan_qr_code_bottle": "Scan the QR code from your ThumbsUp bottle",
	"enter_code_kms_achieve_three_code": "Enter the unique code to accrue 14,000kms with 3 codes max",
	"enter_lucky_draw_win_prize": "Enter the lucky draw to win prizes daily",
	"paytm_gift_voucher": "Paytm gift Voucher",
	"faq_heading": "વારંવાર પૂછાતા પ્રશ્નો",
	"faq_subtext": "થમ્સ અપ વિશે તમારે જે જાણવાની જરૂર છે તે અહીં છે",
	"terms_heading": "Terms & Conditions",
	"contest_over": "Contest Over",
	"thank_you_visit_page": "Thank you for visiting our page! This contest is now closed.",
	"participate_other_giveaways": "Please visit (link to ThumbsUp’s Contests page) to participate in other giveaways",
	"winner_list": "વિજેતા સૂચિ",
	"full_name_error": "કૃપા કરીને માન્ય નામ દાખલ કરો",
	"name_error": "કૃપા કરીને માન્ય નામ દાખલ કરો",
	"dob_error": "તમારી ઉંમર ઓછામાં ઓછી 18 વર્ષની હોવી જોઈએ, કૃપા કરીને સાચી જન્મ તારીખ દાખલ કરો.",
	"passport_expiry_date_error_required": "Please enter a valid passport expiry date",
	"passport_expiry_date_error": "Passport expiry date field must be a date after today.",
	"passport_no_error": "Please enter a valid Passport number",
	"terms_agree_error": "કૃપા કરી નિયમો અને શરતો માટે સંમત થાવ",
	"email_error": "કૃપા કરીને માન્ય ઈમેલ આઈડી દાખલ કરો",
	"how_to_participate": "કેવી રીતે ભાગ લેવો",
	"toofani_champions": "તૂફાની ચેમ્પિયન્સ",
	"refer_friend": "મિત્રને રિફર કરો",
	"have_invite_code": "ઇન્વાઈટ કોડ છે?",
	"enter_collect_kms": "દાખલ કરો અને 100kms મેળવો",
	"contact_us": "અમારો સંપર્ક કરો",
	"prize_grab": "આ ઇનામો જીતો",
	"seat_aboard_toofan": "તૂફાન પર સીટ \nદરરોજ 1 વિજેતા*",
	"cricket_jersey_999_fancode": "ફેનકોડ તરફથી ₹999ની કિંમતની ક્રિકેટ જર્સી \nદર કલાકે 1 વિજેતા*",
	"toofani_999_fancode": "FanCode તરફથી ₹499 ની કિંમતનો તૂફાની સામાન \nદર કલાકે 15 વિજેતા*",
	"find_unique_code_pack": "પેક પર અનન્ય કોડ મેળવો",
	"enter_code_collect_kms": "કિલોમીટર મેળવવા કોડ દાખલ કરો",
	"complete_journey_destination": "ડેસ્ટિનેશન સુધીની સંપૂર્ણ યાત્રા",
	"get_chance_win": "જીતવાની તક મળે છે*!",
	"refer_friend_collect_kms": "વધારાના કિમી એકત્રિત કરવા માટે મિત્રને સંદર્ભિત કરો*",
	"get_100km_mate_signup": "દરેક સાથી જે સફળતાપૂર્વક સાઇન અપ કરે છે અને તમારા ઈન્વાઈટ કોડનો ઉપયોગ કરે છે તેમને માટે  100 કિમી મેળવો.",
	"refer_now": "હવે રિફર કરો",
	"tnc_apply": "નિયમો અને શરતો લાગુ",
	"available": "ઉપલબ્ધ",
	"booked": "બુક થઈ ગઈ",
	"going_soon": "જલ્દી જઈ રહી છે",
	"you_made_toofani_fan_club": "તમે તૂફાની ફેન ક્લબમાં પહોંચી ગયા છો!",
	"won_seat_get_ready": "તમે તૂફાન પરની સીટ જીતી લીધી! વેસ્ટ ઈન્ડિઝ માટે શાનદાન ઉડાન ભરવા તૈયાર થઈજાઓ",
	"jersey_won_text": "પ્રો જેમ કપડાં પહેરો",
	"jersey_won_subtext": "તમારી 999 રૂપિયાની ક્રિકેટ જર્સીનો દાવો કરવા માટે અહીં એક ફેનકોડ વાઉચર છે",
	"redeem_now": "અત્યારે જ મેળવો",
	"voucher_won_text": "કેટલીક ટીમની ભાવનાઓ વિકસાવો",
	"voucher_won_subtext": "499 રૂપિયાના તમારા ક્રિકેટ સામાનનો દાવો કરવા માટે અહીં એક ફેનકોડ વાઉચર છે",
	"my_wins": "મારી જીતો",
	"optional": "વૈકલ્પિક",
	"sign_up_cta": "સાઈન અપ",
	"Please enter a valid OTP": "કૃપા કરીને માન્ય ઓટીપી દાખલ કરો",
	"enter_collect_100kms": "દાખલ કરો અને 100kms મેળવો",
	"unique_code_entry_text": "વેસ્ટ ઈન્ડિઝમાં ICC મેન્સ T20 વર્લ્ડ કપ 2024 ગેમ્સમાં તૂફાન પર સવારી કરી ઉડાન માટેની તક મેળવો*",
	"unique_code_entry_subtext": "થમ્સ અપ® બોટલના લેબલ પાછળનો અનન્ય કોડ શોધો",
	"mobile_number_short": "મોબાઈલ નંબર",
	"welcome_player": "वेलकम, प्लेयर!",
	"login_start_winning": "જીતવાનું શરૂ કરવા માટે લૉગિન કરો!",
	"trademark_text": "કાર્બોનેટેડ પાણી. ટ્રેડમાર્ક માલિક :કોકા કોલા કંપની.",
	"address": "સરનામું",
	"address_line_1": "સરનામું લાઇન 1",
	"address_line_2": "સરનામાની લાઇન નં 2",
	"nearest_landmark": "નજીકનું લેન્ડમાર્ક",
	"alternative_mobile_number": "વૈકલ્પિક મોબાઇલ નંબર",
	"pincode": "પીન કોડ",
	"state": "રાજ્ય",
	"city": "શહેર",
	"state_error": "કૃપા કરીને રાજ્ય પસંદ કરો",
	"city_error": "કૃપા કરીને શહેર પસંદ કરો",
	"pincode_error": "કૃપા કરીને માન્ય પિનકોડ દાખલ કરો",
	"address_error": "કૃપા કરીને માન્ય સરનામું દાખલ કરો",
	"pan_error": "કૃપા કરીને માન્ય PAN કાર્ડ નંબર દાખલ કરો",
	"copy_code": "કોડ કોપિ કરો",
	"last_date_redeem": "રિડીમ કરવાની છેલ્લી તારીખ",
	"last_date_to_claim": "દાવો કરવાની છેલ્લી તારીખ",
	"share_with_friends": "मित्रांसोबत शेअर करा",
	"no_internet_message": "જોડાણ કરવામાં અસમર્થ. કૃપા કરીને તમારું નેટવર્ક કનેક્શન તપાસો અને ફરી પ્રયાસ કરો!",
	"email_us": "ઈમેઈલ કરો",
	"call_toll_free": "અમને ટોલ ફ્રી પર કોલ કરો",
	"mon_sat": "સોમ-શનિ",
	"sunday": "રવિ",
	"follow_us_now": "હવે અમને ફોલો કરો",
	"otp_expired_enter_new": "તમારા OTPની સમયમર્યાદા સમાપ્ત  થઈ ગઈ છે. કૃપા કરીને એક નવો ઉપયોગ કરો.",
	"email_address": "ઈમેઈલ એડ્રેસ",
	"Code has already been used, please enter a new code": "કોડનો ઉપયોગ પહેલેથી જ કરવામાં આવ્યો છે, કૃપા કરીને નવો કોડ દાખલ કરો.",
	"first": "પ્રથમ",
	"last": "शेवटचे",
	"add": "ઉમેરો",
	"refresh_page": "પેજ રિફ્રેશ કરો",
	"Seat on Toofan": "તૂફાન પર બેસો",
	"Toofani Merch": "તૂફાની સામાન",
	"Cricket Jersey": "ક્રિકેટ જર્સી",
	"You are here": "તમે અહીં છો",
	"OTP resent successfully": "ઓટીપી ફરીથી સફળતાપૂર્વક મોકલવામાં આવ્યો છે.",
	"No winners till now": "હજી સુધી કોઈ વિજેતાઓ નથી",
	"COPIED": "કોપી થયું",
	"T&C": "અપ-ટુ-ડેટ નિયમો અને શરતો",
	"Email is already in use": "ઈમેઈલ પહેલેથી જ ઉપયોગમાં છે",
	"Clear": "સાફ કરો",
	"West Indies": "વેસ્ટઈન્ડિઝ",
	"India": "ભારત",
	"Reminder send successfully": "યાદ અપાવનાર સફળતાપૂર્વક મોકલાઈ ગયુ",
	"KM": "કીમી",
	"KMs": "કીમી",
	"Edit Address": "Edit Address",
	"Please enter a valid code": "કૃપા કરીને માન્ય કોડ દાખલ કરો",
	"final_day_offer": "ઓફર અવધિનો છેલ્લો દિવસ 30 એપ્રિલ 2024 થી વધારીને 15 મે 2024 કરવામાં આવ્યો છે. <tnc>નિયમો અને શરતો લાગુ</tnc>"
}
