import React, { ErrorBoundary } from 'react';

const ErrorBoundaryComp = ({ children }) => {
	const handleError = (error, info) => {
		console.error('Error caught by error boundary:', error);
		console.error('Error boundary info:', info);
	};

	return <ErrorBoundary onError={handleError}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryComp;
