import { useState, useCallback, useRef, useEffect } from 'react';

export const useApiCall = () => {
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const activeHttpRequests = useRef([]);

	useEffect(() => {
		let timer;

		if (error) {
			timer = setTimeout(() => setError(null), 3000);
		}

		return () => {
			timer && clearTimeout(timer);
		};
	}, [error]);

	const sendRequest = useCallback(
		async (url, method = 'GET', body = null, headers = {}) => {
			setLoading(true);
			const abortCtrl = new AbortController();
			activeHttpRequests.current.push(abortCtrl);
			try {
				const response = await fetch(url, {
					method,
					body,
					headers,
					signal: abortCtrl.signal,
				});

				const result = await response.json();

				activeHttpRequests.current = activeHttpRequests.current.filter(
					(reqCtrl) => reqCtrl !== abortCtrl
				);

				if (!response.ok) {
					throw result;
				}
				setLoading(false);
				return result;
			} catch (error) {
				console.log(`error>>>`, error);
				setError(error.message);
				setLoading(false);
				throw error;
			}
		},
		[]
	);

	const clearError = () => {
		setError(null);
	};

	useEffect(() => {
		return () => {
			activeHttpRequests.current.forEach((abortControl) =>
				abortControl.abort()
			);
		};
	}, []);

	return {
		isLoading,
		error,
		sendRequest,
		clearError,
	};
};
