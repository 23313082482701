import { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import Dialog from './Dialog';
import Input from './Input';
import Button from './Button';
import InputErrorMessage from './InputErrorMessage';
import Heading from './Heading';
import Checkbox from './Checkbox';
import Select from './Select';
import { useAuthContext } from '../context/auth-context';
import { useApiCall } from '../utils/useApiCall';
import Loader from './Loader';
import { useUserContext } from '../context/user-context';
import { addressObj } from '../utils/mockAddAddress';
import { usePincode } from '../utils/usePincode';

const EditAddressForm = ({ open, handleOpen, addressObj = {} }) => {
	const { t } = useTranslation();

	const { token, setQueryUser } = useAuthContext();

	const { isLoading, error: apiError, sendRequest } = useApiCall();

	const { userData, setUserData } = useUserContext();

	const {
		isValid: isPinCodeValid,
		stateName,
		cityName,
		postCodeLoading,
		getPinCodeData,
	} = usePincode();

	const [cityList, setCityList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [stateId, setStateId] = useState(addressObj?.state_id);

	const {
		address1,
		address2,
		alt_mobile_no,
		city,
		country,
		id,
		is_default,
		landmark,
		pin_code,
		state,
	} = addressObj;

	useEffect(() => {
		const getStates = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}states`,
					'POST',
					JSON.stringify({
						country_id: 101,
					}),
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
					}
				);

				if (response?.success) {
					setStateList(response?.data);
				}
			} catch (error) {
				console.log(`States API error>>>`, error.message);
			}
		};

		getStates();
	}, []);

	const yupSchema = useCallback(
		yup.object({
			address_1: yup.string().required(t('address_error')),
			address_2: yup.string().optional(),
			landmark: yup.string().optional(),
			pincode: yup
				.string()
				.matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, t('pincode_error'))
				.required(t('pincode_error')),
			state: yup.string().required(t('state_error')),
			city: yup.string().required(t('city_error')),
		}),
		[]
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		register,
		watch,
		setValue,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	useEffect(() => {
		if (watch('pincode')?.length === 6) {
			getPinCodeData(watch('pincode'));
		}
	}, [watch('pincode')]);

	useEffect(() => {
		if (pin_code) {
			getPinCodeData(pin_code);
		}
	}, [pin_code]);

	useEffect(() => {
		if (stateName) {
			const stateId = stateList?.find((item) => item.name === stateName)?.id;
			setStateId(stateId);
			if (stateId) {
				setValue('state', stateId);
			} else {
				setValue('state', '');
			}
		}
	}, [stateName]);

	useEffect(() => {
		if (cityList.length > 0) {
			const cityId = cityList?.find((item) => item.name === cityName)?.id;
			// setStateId(stateId);
			if (cityId) {
				setValue('city', cityId);
			} else {
				setValue('city', '');
			}
		}
	}, [cityList]);

	useEffect(() => {
		const getCities = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}cities`,
					'POST',
					JSON.stringify({
						state_id: stateId,
					}),
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
					}
				);

				if (response?.success) {
					setCityList(response?.data);
				}
			} catch (error) {
				console.log(`Cities API error>>>`, error.message);
			}
		};

		stateId && getCities();
	}, [stateId]);

	const submitHandler = async (data) => {
		if (!isPinCodeValid) {
			return false;
		}
		const {
			address_1,
			address_2,
			landmark,
			alternative_mobile_number,
			city,
			pincode,
			set_as_default,
			state,
		} = data;
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}address/update`,
				'POST',
				JSON.stringify({
					id: addressObj?.id,
					address1: address_1,
					address2: address_2,
					landmark,
					mobile_no: alternative_mobile_number && +alternative_mobile_number,
					state: +state,
					city: +city,
					pin_code: pincode,
					country: 101,
					is_default: set_as_default ? 1 : 0,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			// const response = addressObj;
			if (response.success) {
				setQueryUser(true);
				handleOpen();
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			console.error(`Add address API error>>>`, error);
		}
	};

	const onStateChangeHandler = (e) => {
		setStateId(e.target.value);
	};

	return (
		<div className="flex flex-col justify-between items-start font-thumbsUp mb-10 overflow-y-scroll  h-full lg:pt-4">
			<Heading text={`${t('Edit Address')}`} additionalClasses="mb-4" />
			<form
				onSubmit={handleSubmit(submitHandler)}
				className="w-full mb-2 lg:mb-4"
			>
				{isLoading && <Loader />}
				<div className="mb-5 w-full">
					<Controller
						name="address_1"
						control={control}
						defaultValue={address1}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.address_1}
								placeholder={`${t('address_line_1')}*`}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.address_1?.message} />
				</div>
				<div className="mb-5 w-full">
					<Controller
						name="address_2"
						control={control}
						defaultValue={address2}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.address_2}
								placeholder={`${t('address_line_2')}`}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.address_2?.message} />
				</div>
				<div className="mb-5 w-full">
					<Controller
						name="landmark"
						control={control}
						defaultValue={landmark}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.landmark}
								placeholder={t('nearest_landmark')}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.landmark?.message} />
				</div>
				<div className="mb-5 w-full">
					<Controller
						name="alternative_mobile_number"
						control={control}
						defaultValue={alt_mobile_no}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.alternative_mobile_number}
								placeholder={t('alternative_mobile_number')}
							/>
						)}
					/>
					<InputErrorMessage
						text={errors?.alternative_mobile_number?.message}
					/>
				</div>
				<div className="mb-5 w-full">
					<Controller
						name="pincode"
						control={control}
						defaultValue={pin_code}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.pincode && isPinCodeValid}
								placeholder={`${t('pincode')}*`}
							/>
						)}
					/>
					{(errors?.pincode || !isPinCodeValid) && (
						<InputErrorMessage text={t('pincode_error')} />
					)}
				</div>
				<div className="mb-4">
					<Controller
						name="state"
						control={control}
						defaultValue={addressObj?.state_id || ''}
						render={({ field: { ref, ...rest } }) => (
							<Select
								{...rest}
								isValid={!errors?.state}
								selectHeading={`${t('state')}*`}
								items={stateList}
								// onChange={onStateChangeHandler}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.state?.message} />
				</div>
				<div className="mb-4">
					<Controller
						name="city"
						control={control}
						defaultValue={addressObj?.city_id || ''}
						render={({ field: { ref, ...rest } }) => (
							<Select
								{...rest}
								isValid={!errors?.city}
								selectHeading={`${t('city')}*`}
								items={cityList}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.city?.message} />
				</div>
				<div className="flex items-end gap-2 mb-5">
					<Controller
						name="set_as_default"
						control={control}
						defaultValue={!!is_default}
						render={({ field: { ref, value, ...rest } }) => (
							<Checkbox
								{...rest}
								value={value}
								isValid={!errors?.set_as_default}
								id="set_as_default"
								checked={value}
							/>
						)}
					/>
					<label htmlFor="set_as_default" className="text-black text-xs ">
						{t('set_as_default')}
					</label>
				</div>

				<div className="w-full ">
					{apiError && <InputErrorMessage text={t(apiError)} />}
					<Button
						text={t('btn_submit')}
						customClasses="font-thumbsUp p-3  lg:p-7"
					/>
				</div>
			</form>
			<Link
				to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
				target="_blank"
			>
				<button className="mb-4 lg:mb-7">{t('privacy_policy')}</button>
			</Link>
		</div>
	);
};

export default EditAddressForm;
