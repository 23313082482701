import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../components/Button';
import Input from '../components/Input';
import Heading from '../components/Heading';
import Checkbox from '../components/Checkbox';
import DatePicker from '../components/DatePicker';
import InputErrorMessage from '../components/InputErrorMessage';
import Loader from '../components/Loader';
import { useApiCall } from '../utils/useApiCall';
import { formatDate } from '../utils/common';
import { AuthContext } from '../context/auth-context';
import ProfilePicUploadIcon from '../../public/profile_pic_upload.svg';
import ProfilePicUpload from '../components/ProfilePicUpload';

const Signup = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const location = useLocation();

	const { token, setQueryUser, isLoggedIn } = useContext(AuthContext);
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const [openProfileSelection, setOpenProfileSelection] = useState(false);
	const [avatars, setAvatars] = useState([]);
	const [profilePicName, setProfilePicName] = useState();
	const [defaultProfilePic, setDefaultProfilePic] =
		useState(ProfilePicUploadIcon);

	useEffect(() => {
		// !location?.state?.mobile_number && isLoggedIn && navigate('/');

		if (!location?.state?.mobile_number) {
			if (isLoggedIn) {
				navigate('/');
			} else {
				navigate('/login');
			}
		}
	}, [isLoggedIn, location?.state?.mobile_number]);

	useEffect(() => {
		const getAvatars = async () => {
			try {
				const response = await sendRequest(
					`${process.env.API_BASE_URL}avatars`,
					'GET',
					null,
					{
						'Content-Type': 'application/json',
						'x-auth-key': process.env.AUTH_KEY,
					}
				);

				if (response?.success) {
					const avatarObj = response?.data;
					const avatarArr = Object.keys(avatarObj).map((key) => ({
						key,
						value: avatarObj[key],
					}));
					setAvatars(avatarArr);
				} else {
					throw new Error(response?.message);
				}
			} catch (error) {
				console.log(`GET avatar API error>>>`, error.message);
			}
		};

		getAvatars();
	}, []);

	const yupSchema = useCallback(
		yup.object({
			full_name: yup.string().required(t('full_name_error')),
			mobile_number: yup
				.string()
				.matches(/^\d{10}$/, t('mobile_error_message'))
				.min(10, t('mobile_error_message')),
			email_id: yup.string().required(t('email_error')).email(t('email_error')),
			dob: yup
				.date(t('dob_error'))
				.required(t('dob_error'))
				.typeError(t('dob_error'))
				.test('is-over-18', t('dob_error'), function (value) {
					const eighteenYearsAgo = new Date();
					eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

					return value <= eighteenYearsAgo;
				}),
			agree_terms: yup.boolean().oneOf([true], t('terms_agree_error')),
		}),
		[]
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	const submitHandler = async (data) => {
		const { full_name, email_id, mobile_number, dob } = data;

		// Get IP details
		let ip_detail = {};
		try {
			ip_detail = await sendRequest(
				process.env.GET_IP_DETAIL_URL,
				'GET',
				null,
				{ headers: { 'Content-Type': 'application/json' } }
			);
		} catch (error) {
			console.log(`IP details fetch error>>>`, error);
		}

		// Convert date object to yyyy-mm-dd
		const formattedDob = formatDate(dob);

		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}user/signup`,
				'POST',
				JSON.stringify({
					name: full_name,
					email: email_id,
					dob: formattedDob,
					ip_detail,
					profile_pic: profilePicName,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);
			// const response = {
			// 	success: true,
			// 	data: [],
			// 	message: 'Signup successfully',
			// };

			if (response.success) {
				setQueryUser(true);
				navigate('/');
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			console.error(`Signup API error>>>`, error);
		}
	};

	const onPicClickHandler = (picName, picUrl) => {
		setProfilePicName(picName);
		setDefaultProfilePic(picUrl);
		setOpenProfileSelection(!openProfileSelection);
	};

	return (
		<div className="mt-7 mb-4 px-4 lg:flex lg:justify-center relative">
			<div className="lg:w-1/4 lg:bg-desktopFormBg lg:h-3/5 lg:py-10 lg:px-4  relative">
				{isLoading && <Loader />}
				<div className="mb-5 text-center flex flex-col items-center justify-normal">
					<Heading text={t('sign_up')} additionalClasses="lg:text-3xxl" />
					<h3 className="text-pageSubHead text-base lg:text-xl mb-2">
						{t('enter_detail_text')}
					</h3>
					<div
						className="w-[3.313rem] h-[3.438rem]"
						onClick={() => setOpenProfileSelection(!openProfileSelection)}
					>
						<img src={defaultProfilePic} className="w-full" />
					</div>
				</div>
				<form onSubmit={handleSubmit(submitHandler)}>
					<div className="mb-6">
						<Controller
							name="full_name"
							control={control}
							defaultValue={''}
							render={({ field: { ref, ...rest } }) => (
								<Input
									{...rest}
									isValid={!errors?.full_name}
									placeholder={`${t('name')}*`}
								/>
							)}
						/>
						<InputErrorMessage text={errors?.full_name?.message} />
					</div>
					<div className="mb-6">
						<Controller
							name="mobile_number"
							control={control}
							defaultValue={location?.state?.mobile_number || ''}
							render={({ field: { ref, ...rest } }) => (
								<Input
									{...rest}
									isValid={!errors?.mobile_number}
									placeholder={t('mobile_number')}
									disabled={!!location?.state?.mobile_number}
								/>
							)}
						/>
						<InputErrorMessage text={errors?.mobile_number?.message} />
					</div>
					<div className="mb-6">
						<Controller
							name="email_id"
							control={control}
							defaultValue={''}
							render={({ field: { ref, ...rest } }) => (
								<Input
									{...rest}
									isValid={!errors?.email_id}
									placeholder={t('email_id')}
								/>
							)}
						/>
						<InputErrorMessage text={errors?.email_id?.message} />
					</div>
					<div className="mb-6">
						<Controller
							name="dob"
							control={control}
							defaultValue={''}
							render={({ field: { ref, value, onChange, ...rest } }) => (
								<DatePicker
									{...rest}
									isValid={!errors?.dob}
									placeholder={t('dob')}
									selected={value || null}
									onChange={(date) => {
										onChange(date);
									}}
									type="date"
								/>
							)}
						/>
						<InputErrorMessage text={errors?.dob?.message} />
					</div>
					<div className="flex items-center mb-4 gap-2">
						<Controller
							name="agree_terms"
							control={control}
							defaultValue={false}
							render={({ field: { ref, ...rest } }) => (
								<Checkbox {...rest} id="agreeTerms" />
							)}
						/>

						<label
							htmlFor="agreeTerms"
							className="text-black text-xs lg:text-base lg:leading-5"
						>
							{t('terms_text')}
						</label>
					</div>
					<InputErrorMessage text={errors?.agree_terms?.message} />
					{apiError && (
						<InputErrorMessage text="Something went wrong! Please try again later." />
					)}
					<Button text={t('btn_continue')} customClasses="lg:text-3xxl p-3" />
				</form>
			</div>
			{openProfileSelection && (
				<div className="absolute top-32 left-1/2 transform -translate-x-1/2">
					<ProfilePicUpload items={avatars} onClick={onPicClickHandler} />
				</div>
			)}
		</div>
	);
};

export default Signup;
