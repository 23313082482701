import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox';
import EditIcon from '../../public/edit_icon.svg';
import DeleteIcon from '../../public/delete_icon.svg';

const AddressItem = ({
	item,
	onEdit,
	onDelete,
	addressId,
	setAsDefault,
	onDefault,
}) => {
	const { t } = useTranslation();
	const {
		address1,
		address2,
		landmark,
		alt_mobile_no,
		pin_code,
		city,
		state,
		country,
		is_default,
	} = item;

	const [isDefaultAddr, setIsDefaultAddr] = useState(!!is_default);

	let addressStr;
	addressStr = `${address1}, `;
	addressStr += address2 && `${address2}, `;
	addressStr += landmark && `${landmark}, `;
	addressStr += alt_mobile_no && `${alt_mobile_no}, `;
	addressStr += `${city}, `;
	addressStr += `${state}, `;
	addressStr += `${country} - ${pin_code}`;

	return (
		<div className="bg-inputBg pt-3 pb-5 pl-4 pr-2">
			<div className="flex justify-between items-start">
				<div>
					<p className="text-base text-black font-normal leading-6 mb-2 w-4/5 lg:text-3xl lg:leading-9">
						{addressStr}
					</p>
				</div>
				<div
					className="w-3 h-3.5 lg:w-[1.125rem] lg:h-[1.125rem] cursor-pointer"
					onClick={() => onEdit(item)}
				>
					<img src={EditIcon} />
				</div>
			</div>
			<div className="flex justify-between items-end">
				<div className="flex items-center gap-2">
					<Checkbox
						id="setAsDefault"
						checked={setAsDefault}
						onChange={() => onDefault(addressId)}
						disabled={setAsDefault}
					/>
					<label
						htmlFor="setAsDefault"
						className="text-black text-xs lg:text-2xxl lg:leading-8"
					>
						{t('set_as_default')}
					</label>
				</div>
				<div
					className="w-3 h-3.5 lg:w-[1.125rem] lg:h-[1.125rem] cursor-pointer"
					onClick={() => onDelete(addressId)}
				>
					<img src={DeleteIcon} />
				</div>
			</div>
		</div>
	);
};

export default AddressItem;
