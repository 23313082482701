{
	"win_1": {
		"headingText": "You’ve made it to the Toofani Fan Club!",
		"subHeadingText": "You won a seat Aboard Toofan to the ICC 2024 Games in the West Indies",
		"text": "",
		"icon": "flight_won_icon",
		"btnText": "आता क्लेम करा",
		"btnSubText": "Claim within:",
		"timerDisplay": " {{datetime}}"
	},
	"win_2": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Cricket Jersey worth ₹999 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "आता रिडीम करा",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_3": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Toofani merch worth ₹499 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "आता रिडीम करा",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_check": {
		"headingText": "फक्त आणखी एक तपासणी!",
		"subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 5 seconds, on this site as well as through SMS or WhatsApp. Sit tight!",
		"text": "",
		"icon": "flight_win_check"
	},
	"win_check_waiting": {
		"headingText": "फक्त आणखी एक तपासणी!",
		"subHeadingText": "",
		"text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 3 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
		"icon": "flight_win_check"
	},
	"valid_code": {
		"headingText": "व्वा, तुफान!",
		"subHeadingText": "तुम्ही {{points}} किमी संग्रह केलेला आहेत. तूफानवर बसून तुम्ही तुमच्या संधीपासून फक्त {{remaining_km}} किमी दूर आहात!",
		"text": "",
		"icon": "points_win_flight_icon.svg"
	},
	"total_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "You have entered multiple invalid codes today. Come back tomorrow to try again.",
		"text": "",
		"icon": "warn_icon"
	},
	"already_win": {
		"headingText": "आधीच जिंकले आहे!",
		"subHeadingText": "तुम्ही लकी ड्रॉमध्ये आधीच जिंकला आहात",
		"text": "",
		"icon": "warn_icon"
	},
	"valid_code_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "",
		"text": "You have entered multiple invalid codes today. Come back tomorrow to try again."
	},
	"claimed": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your ticket aboard Toofan Air with our fan squad",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"after_claimed": {
		"headingText": "फक्त आणखी एक तपासणी!",
		"subHeadingText": "तुम्ही सबमिट केलेल्या तपशीलांची आम्ही पडताळणी करत आहोत आणि 10 व्यावसायिक दिवसांच्या आत आम्ही तुफानातील तुमच्या सफरीच्या पात्रतेबद्दल तुम्हाला या साईटवर वा त्याचबरोबर एसएमएस किंवा व्हॉट्सअपच्या माध्यमातून सूचीत करू. प्रतीक्षा करा!",
		"text": "",
		"btnText": "",
		"icon": "flight_win_check"
	},
	"claimed_reward": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your Toofani Reward",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"claim_now": {
		"headingText": "वेळ निघून जात आहे!",
		"subHeadingText": "तुफानावरील तुमचे सीट क्लेम करण्याची चौकट {{timer}} रोजी कालबाह्य होईल",
		"text": "",
		"btnText": "आता क्लेम करा",
		"icon": "timer_icon"
	},
	"claim_expired": {
		"headingText": "तुम्ही धावबाद झाला आहात!",
		"subHeadingText": "तुमचा रिवॉर्डवरील क्लेम कालबाह्य झाला आहे.",
		"text": "Don’t lose hope, you are still in the game.\nCome back again tomorrow to check if you have won the next seat on Toofan!",
		"icon": "said_icon"
	},
	"not_win_for_the_day": {
		"headingText": "ही एक मेडन ओव्हर आहे!",
		"subHeadingText": "आशा हरू देऊ नका, तुम्ही अजूनही गेममध्ये आहात. तुफानावरील पुढचे सीट तुम्ही जिंकला आहात का हे तपासण्यासाठी उद्या परत या!",
		"text": "",
		"icon": "said_icon"
	},
	"exit_claim_popup": {
		"headingText": "तुमच्या मेडलवर क्लेम करा",
		"subHeadingText": "{{hours}} तासांमध्ये तुमच्या बक्षिसावरील तुमचा क्लेम कालबाह्य होईल.",
		"text": "तुम्हाला बंद करण्याची खात्री वाटते का?",
		"icon": "claim_expired_icon",
		"btnTextYes": "होय",
		"btnTextNo": "नाही",
		"confirm": true
	},
	"exit_next_win_for_new": {
		"headingText": "थांबा!",
		"subHeadingText": "तुम्ही आजचे पुढील विजेते असू शकता!",
		"text": "तुम्हाला बंद करण्याची खात्री वाटते का?",
		"icon": "raise_hand_icon",
		"btnTextYes": "होय",
		"btnTextNo": "नाही",
		"confirm": true
	},
	"exit_next_win": {
		"headingText": "तुम्ही विनिंग स्कोअर हिट करण्याच्या जवळपास आहात!",
		"subHeadingText": "दुसरा युनिक कोड एंटर करा आणि जिंकण्याची एक संधी मिळवा!",
		"text": "तुम्हाला बंद करण्याची खात्री वाटते का?",
		"icon": "raise_hand_icon",
		"btnTextYes": "होय",
		"btnTextNo": "नाही",
		"confirm": true
	},
	"affiliate_signup_success": {
		"headingText": "वेल डन स्कीपर",
		"subHeadingText": "",
		"text": "तुम्ही 100किमीज मिळविले आहेत",
		"icon": "right_bat"
	},
	"default": {
		"headingText": "Error!",
		"subHeadingText": "Not found!",
		"text": ""
	},
	"qualified_for_lucky_draw": {
		"headingText": "Already qualified!",
		"subHeadingText": "You have already qualified for the lucky draw",
		"text": ""
	}
}
