import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import HomeIcon from '../../public/home.svg';
import WinnerIcon from '../../public/winner.svg';
import EnterCodeIcon from '../../public/enter_code.svg';
import ProfileIcon from '../../public/profile.svg';
import FooterIcon from './FooterIcon';
import FacebookIcon from '../../public/facebook_white.svg';
import InstagramIcon from '../../public/instagram_white.svg';
import TwitterIcon from '../../public/twitter_white.svg';
import YoutubeIcon from '../../public/youtube_white.svg';
import WhatsappIcon from '../../public/whatsapp_white.svg';
import Logo from '../../public/thumbs_up.svg';
import VegIcon from '../../public/veg_icon.svg';
import { useCampaignContext } from '../context/campaign-context';
import UniqueCodeDialog from './UniqueCodeDialog';
import LoginJourneyDialog from './LoginJourneyDialog';
import MessageDialog from './MessageDialog';
import Typography from './Typography';
import AffiliateJourneyDialog from './AffiliateJourneyDialog';
import { useAuthContext } from '../context/auth-context';
import { useCDP } from '../utils/useCDP';
import UniqueCodeBannerDialog from './UniqueCodeBannerDialog';
import UniqueCodeWinnerDialog from './UniqueCodeWinnerDialog';
import { useUserContext } from '../context/user-context';
import { insertFloodlightTag } from '../utils/common';

const Footer = () => {
	const { t, i18n } = useTranslation();

	const [open, setOpen] = useState(false);

	const { isLoggedIn } = useAuthContext();

	const navigate = useNavigate();

	const sendCDPData = useCDP();

	const {
		openUniqueCodeForm,
		setOpenUniqueCodeForm,
		openUniqueCodeBanner,
		setOpenUniqueCodeBanner,
		openLoginForm,
		setOpenLoginForm,
		openAffiliateForm,
		setOpenAffiliateForm,
		userInfoPopup,
		setUserInfoPopup,
		otherStates,
		userUniqueCodeWinPopup,
		setUserUniqueCodeWinPopup,
		isAffiliateForm,
		totalPoints,
		popupInfo,
		winPopupInfo,
	} = useCampaignContext();
	const { historyBackOption, setHistoryBackOption } = useUserContext();
	const participateHandler = () => {
		if (isLoggedIn) {
			setOpenUniqueCodeForm(!openUniqueCodeForm);
		} else {
			setOpenLoginForm(!openLoginForm);
			setHistoryBackOption({
				...historyBackOption,
				isDialogOpen: true,
				redirectLink: () => {
					setOpenUniqueCodeForm(!openUniqueCodeForm);
				},
			});
		}
	};

	return (
		<>
			<footer className="flex flex-col justify-normal items-center gap-2 w-full lg:hidden sticky bottom-0 z-[99] bg-colorE3E3E3">
				{/* <div className="flex items-center justify-between bg-footerBg w-full px-8 pt-2 pb-1.5 lg:hidden">
					<NavLink
						to="/"
						className={({ isActive }) =>
							[isActive ? 'border-b-2 border-white' : ''].join(' ')
						}
					>
						<FooterIcon
							imgSrc={HomeIcon}
							text={t('home')}
							lang={i18n.language}
						/>
					</NavLink>

					{isLoggedIn ? (
						<NavLink
							to="/winner-list"
							className={({ isActive }) =>
								[isActive ? 'border-b-2 border-white' : ''].join(' ')
							}
						>
							<FooterIcon
								imgSrc={WinnerIcon}
								text={t('toofani_champions')}
								lang={i18n.language}
							/>
						</NavLink>
					) : (
						<div
							onClick={() => {
								if (isLoggedIn) {
									navigate('/winner-list');
								} else {
									setOpenLoginForm(!openLoginForm);
								}
							}}
						>
							<FooterIcon
								imgSrc={WinnerIcon}
								text={t('toofani_champions')}
								lang={i18n.language}
							/>
						</div>
					)}
					<FooterIcon
						imgSrc={EnterCodeIcon}
						text={t('enter_code')}
						onClick={participateHandler}
						lang={i18n.language}
					/>
					{isLoggedIn ? (
						<NavLink
							to="/profile"
							className={({ isActive }) =>
								[isActive ? 'border-b-2 border-white' : ''].join(' ')
							}
						>
							<FooterIcon
								imgSrc={ProfileIcon}
								text={t('profile')}
								lang={i18n.language}
							/>
						</NavLink>
					) : (
						<div
							onClick={() => {
								if (isLoggedIn) {
									navigate('/profile');
								} else {
									setOpenLoginForm(!openLoginForm);
									setHistoryBackOption({
										...historyBackOption,
										isDialogOpen: false,
										isPageOpen: true,
										redirectLink: () => {
											navigate('/profile');
										},
									});
								}
							}}
						>
							<FooterIcon
								imgSrc={ProfileIcon}
								text={t('profile')}
								lang={i18n.language}
							/>
						</div>
					)}
				</div> */}
				<div className="flex justify-between items-center w-full px-4">
					{/* <Typography variant="h4" type="text12" text="T&C | Privacy Policy" /> */}
					<h4
						className={`${
							i18n.language === 'en'
								? 'text-xs'
								: i18n.language == 'en'
								? 'text-[0.625rem]'
								: 'text-[0.375rem]'
						} font-bold leading-4 text-black font-thumbsUp`}
					>
						<Link
							to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/tnc/ICC24-TnCs.pdf"
							target="_blank"
						>
							{t('T&C')}
						</Link>{' '}
						|{' '}
						<Link
							to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
							target="_blank"
						>
							{t('privacy_policy')}
						</Link>
					</h4>
					<div className="flex justify-between items-center">
						<Typography
							variant="h4"
							type={`${i18n.language === 'en' ? 'text10' : 'text6'}`}
							text={t('trademark_text')}
							customClasses="text-black"
						/>
						<div className="w-2.5">
							<img src={VegIcon} className="w-full" />
						</div>
					</div>
				</div>
			</footer>
			<footer className="hidden items-center justify-between bg-headerBg px-36 pt-11 pb-11  lg:flex ">
				<div className="flex flex-col justify-start gap-6 items-center w-full ">
					{/* <div className="w-20 mb-8">
						<img src={Logo} alt="LOGO" width="76" height="76" />
					</div> */}
					<div className="">
						<h4 className="text-2xxl font-light leading-normal text-white">
							<Link
								to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/tnc/ICC24-TnCs.pdf"
								target="_blank"
							>
								{t('terms_conditions')}
							</Link>{' '}
							|{' '}
							<Link
								to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
								target="_blank"
							>
								{t('privacy_policy')}
							</Link>{' '}
							{/* | <Link to="/faqs">{t('faqs')}</Link> */}
						</h4>
					</div>
					<h4 className="text-2xl text-white leading-10">
						{t('copyright_text')}
					</h4>
				</div>
				{/* <div>
					<h4 className="text-2xl text-white leading-7 text-right mb-4">
						{t('follow_us_now')}
					</h4>
					<div className="flex justify-end gap-7 mb-0.5">
						<div
							onClick={() => {
								sendCDPData('Click', 'Follow_FB');
								// Floodlight tags

								insertFloodlightTag({
									activityName: 'ThumsUp_ICC24_Facebook_Counter',
									imageTag: 'thums045', // Specific image tag for HomePage
								});
							}}
						>
							<Link
								to="https://www.facebook.com/thumsupofficial"
								target="_blank"
							>
								<img src={FacebookIcon} alt="Facebook" />
							</Link>
						</div>
						<div
							onClick={() => {
								sendCDPData('Click', 'Follow_Insta');
								// Floodlight tags

								insertFloodlightTag({
									activityName: 'ThumsUp_ICC24_Instagram_Counter',
									imageTag: 'thums046', // Specific image tag for HomePage
								});
							}}
						>
							<Link
								to="https://www.instagram.com/thumsupofficial"
								target="_blank"
							>
								<img src={InstagramIcon} alt="Instagram" />
							</Link>
						</div>
						<div
							onClick={() => {
								// Floodlight tags

								insertFloodlightTag({
									activityName: 'ThumsUp_ICC24_YouTube_Counter',
									imageTag: 'thums047', // Specific image tag for HomePage
								});
							}}
						>
							<Link
								to="https://www.youtube.com/user/ThumsUpOfficial"
								target="_blank"
							>
								<img src={YoutubeIcon} alt="Youtube" />
							</Link>
						</div>
						
					</div>
				</div> */}
			</footer>
			<UniqueCodeDialog
				open={openUniqueCodeForm}
				handleOpen={() => setOpenUniqueCodeForm(!openUniqueCodeForm)}
			/>
			<UniqueCodeBannerDialog
				open={openUniqueCodeBanner}
				handleOpen={() => setOpenUniqueCodeBanner(!openUniqueCodeBanner)}
			/>

			<LoginJourneyDialog
				open={openLoginForm}
				handleOpen={() => {
					setOpenLoginForm(!openLoginForm);
				}}
			/>
			<AffiliateJourneyDialog
				open={openAffiliateForm}
				formType={isAffiliateForm}
				handleOpen={() => setOpenAffiliateForm(!openAffiliateForm)}
			/>

			<MessageDialog
				handleOpen={() => setUserInfoPopup(!userInfoPopup)}
				open={userInfoPopup}
				popupInfo={popupInfo}
			/>
			<UniqueCodeWinnerDialog
				handleOpen={() => setUserUniqueCodeWinPopup(!userUniqueCodeWinPopup)}
				open={userUniqueCodeWinPopup}
				winPopupInfo={winPopupInfo}
			/>
			{/* <UserMessageDialog /> */}
		</>
	);
};

export default Footer;
