import { useCallback, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../components/Button';
import Input from '../components/Input';
import InputErrorMessage from '../components/InputErrorMessage';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/LoaderV1';
import RightHandBatterIcon from '../../public/right_bat.svg';
import { AuthContext, useAuthContext } from '../context/auth-context';
import { useCampaignContext } from '../context/campaign-context';
import { gaEvents } from '../utils/gaEvents';
import { useUserContext } from '../context/user-context';

const AffiliateForm = ({ handleOpen }) => {
	const navigate = useNavigate();
	const { t } = useTranslation('affiliate');
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const { userData } = useUserContext();
	const { login, setQueryUser } = useContext(AuthContext);
	const { token, logout, isLoggedIn } = useAuthContext();
	const { setPopupInfo, setUserInfoPopup } = useCampaignContext();
	useEffect(() => {
		if (isLoggedIn && !userData?.name) {
			handleOpen && handleOpen();
			logout();
		}
	}, [isLoggedIn]);
	const yupSchema = useCallback(
		yup.object({
			invite_code: yup.string().required(t('Please enter a valid code')),
		}),
		['invite_code']
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	const submitHandler = async (data) => {
		const { invite_code } = data;

		// Get IP details

		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}affiliate-registration`,
				'POST',
				JSON.stringify({
					invite_code,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				// GA Event
				window.dataLayer.push(gaEvents['TUICC24_EnterInviteCode_Submit']);
				handleOpen && handleOpen();
				setUserInfoPopup(true);
				setPopupInfo({
					type: 'affiliate_signup_success',
					imgSrc: RightHandBatterIcon,
					imgSrcClasses: ' mb-2',
					staticMessage: true,
					handleOpenHandler,
				});
				setQueryUser(true);
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			console.error(`Affiliate API error>>>`, error);
		}
	};
	const handleOpenHandler = () => {
		setUserInfoPopup(false);
		navigate('/profile');
	};
	return (
		<div className="flex flex-col justify-between items-center font-thumbsUp">
			{isLoading && <Loader />}
			<div className="items-center mb-3">
				<img src={RightHandBatterIcon} alt="icon" />
			</div>
			<div className="mb-4 text-center ">
				<h2 className="text-white text-center text-2xl font-medium leading-8">
					{t('enter_invite_code')}
				</h2>

				<h3 className="text-base text-white font-normal leading-7 mb-1 text-center">
					{t('affiliate_signup_h3')}
				</h3>
			</div>
			<form onSubmit={handleSubmit(submitHandler)} className="w-full">
				<div className="mb-6">
					<Controller
						name="invite_code"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.invite_code}
								placeholder={t('enter_invite_code')}
							/>
						)}
					/>

					<InputErrorMessage text={errors?.invite_code?.message} />
				</div>
				{apiError && <InputErrorMessage text={t(apiError)} />}
				<Button
					text={t('affiliate_signup_btn')}
					customClasses="text-white lg:text-3xxl p-3"
				/>
			</form>
			<div className="w-2/2  text-center mt-3 text-white">
				<Link
					to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
					target="_blank"
				>
					<button
						onClick={() => {
							handleOpen();
						}}
					>
						{t('privacy_policy')}
					</button>
				</Link>
			</div>
		</div>
	);
};

export default AffiliateForm;
