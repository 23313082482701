{
	"winner_list_h1": "తూఫానీ ఛాంపియన్స్",
	"avatar": "అవతార్",
	"name": "పేరు",
	"phone_number": "ఫోను నంబరు",
	"prize": "బహుమతి",
	"select_date": "తేదీ ను ఎంచుకోండి",
	"record_not_found": "Record not found",
	"win_1": "నేను వెస్టిండీస్‌ లో జరిగిన ఐ సి సి పురుషుల టి 20 ప్రపంచ కప్ 2024 ఆటలలో తూఫాన్‌ సీటు ను గెలుచుకున్నాను*",
	"win_img_1": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/flight.jpg",
	"win_2": "నేను ఇప్పుడే Thums up ® ఐ సి సి పురుషుల టి 20 ప్రపంచ కప్ 2024 ప్రమోషన్‌లో ఫ్యాన్‌కోడ్ నుండి క్రికెట్ జెర్సీని గెలుచుకున్నాను.",
	"win_img_2": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/jercy.jpg",
	"win_3": "నేను ఇప్పుడే Thumps up ® ఐ సి సి పురుషుల టి 20 ప్రపంచ కప్ 2024 ప్రమోషన్‌లో ఫ్యాన్‌ కోడ్ నుండి క్రికెట్ మెర్చ్‌ని గెలుచుకున్నాను.",
	"win_img_3": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/voucher.jpg",
	"one_more_check": "మరొక్కసారి పరీక్షించుకోండి!",
	"one_more_check_text": "మీరు సమర్పించిన సమాచారాన్ని మేము ధృవీకరిస్తున్నాము & మేము 10 వ్యాపార రోజుల్లో మీ అర్హత గురించి మీకు ఈ సైట్ లో ఎస్ ఏం ఎస్ ద్వారా లేదా వాట్సప్ ద్వారా తెలియజేస్తాము. నిబ్బరంగా ఉండండి",
	"win_heading": "Congratulations!",
	"win_1_success_verified": "తూఫాన్ కి వెళ్ళే మీ సీటు ఖరారు అయ్యింది మరియు మీరు వెస్ట్ ఇండీస్ కి వెళ్తున్నారు ! ఎస్ ఏం ఎస్ / వాట్సాప్ లేదా ఫోన్ కాల్ ద్వారా మేము తరువాత అంచెల కోసం మిమ్మల్ని చేరుకుంటాము. మీ గొప్ప విజయం గురించి మీ సహేతులకి చెప్పండి!",
	"win_1_success": "తూఫాన్ కి వెళ్ళే మీ సీటు ఖరారు అయ్యింది మరియు మీరు వెస్ట్ ఇండీస్ కి వెళ్తున్నారు ! ఎస్ ఏం ఎస్ / వాట్సాప్ లేదా ఫోన్ కాల్ ద్వారా మేము తరువాత అంచెల కోసం మిమ్మల్ని చేరుకుంటాము. మీ గొప్ప విజయం గురించి మీ సహేతులకి చెప్పండి!",
	"win_2_success": "You’ve claimed your Cricket Jersey! We will communicate further details via email & SMS shortly.",
	"win_3_success": "You’ve claimed your Toofani Merch! We will communicate further details via email & SMS shortly."
}
