{
	"heading_h2": "“পার্টনারশিপ থাকলে তো এমনই হওয়া উচিত!",
	"heading_h3": "অংশগ্রহণের জন্য আপনার বন্ধুদের ইনভাইট করুন",
	"invite_code_text": "যখন আপনার বন্ধু সাইন আপ করবে এবং আপনার ইনভাইট কোড ব্যবহার করবে তখন আপনি 100 KM পাবেন",
	"friend_mobile_number": "আপনার বন্ধুর মোবাইল নম্বর লিখুন",
	"friend_email": "আপনার বন্ধুর ইমেল লিখুন (ঐচ্ছিক)",
	"send_invite_btn": "জমা",
	"privacy_policy": "গোপনীয়তা নীতি",
	"icon": "right_bat",
	"mobile_required": "অনুগ্রহ করে একটি বৈধ নম্বর লিখুন৷",
	"email_optional": "একটি বৈধ ইমেইল প্রবেশ করুন",
	"Number already in use, Please refer another": "নম্বরটি ইতিমধ্যেই ব্যবহার করা হচ্ছে, অনুগ্রহ করে অন্যটি পড়ুন",
	"thank_you": "ধন্যবাদ!",
	"thank_you_h3": "কোড সহ আপনার বন্ধুকে একটি ইনভাইট পাঠানো হয়েছে",
	"thank_you_info_text": "যখন আপনার বন্ধু সাইন আপ করে এবং তাদের ইউনিক কোড ব্যবহার করে, আপনি 100 কিলোমিটার পাবেন",
	"enter_invite_code": "ইনভাইট কোড লিখুন",
	"affiliate_signup_h3": "আপনার বন্ধুর 6 সংখ্যার ইনভাইট কোড লিখুন এবং 100 কিলোমিটার সংগ্রহ করুন",
	"affiliate_signup_btn": "জমা করুন",
	"Please enter a valid code": "অনুগ্রহ করে একটি বৈধ কোড লিখুন",
	"my_referrals": "আমার রেফারেল",
	"accepted": "গৃহীত",
	"pending": "লম্বিত",
	"users": "ইউজার",
	"status": "অবস্থা",
	"send_reminder": "রিমাইন্ডার পাঠাও",
	"refer_another": "অন্য একটি রেফার করুন",
	"Referral not found": "রেফারেল পাওয়া যায়নি"
}
