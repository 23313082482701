import { Tooltip } from '@material-tailwind/react';
import Input from './Input';
import TooltipIcon from '../../public/tooltip_icon.svg';

const InputWithLabel = ({ label, tooltip, ...rest }) => {
	return (
		<>
			<div className="flex justify-start items-baseline">
				<label className="text-black text-xl font-normal leading-6 mb-1.5">
					{label}{' '}
				</label>
				{tooltip && (
					<Tooltip
						placement="bottom"
						className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
						content={
							<div className="w-80">
								<p className="text-voucherSubText text-xs font-bold leading-4">
									We require your PAN Card information for gift tax purposes*.
									This information will not be used beyond the stated purpose
									and will be deleted from our records post 31Dec 2024.
								</p>
							</div>
						}
					>
						<div className="w-3 h-3">
							<img src={TooltipIcon} className="w-full" />
						</div>
					</Tooltip>
				)}
			</div>
			<Input {...rest} />
		</>
	);
};

export default InputWithLabel;
