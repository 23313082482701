export const gaEvents = {
	TUICC24_SignUp: {
		event: 'TUICC24_SignUp',
		action: 'click',
		label: 'Successful Signup/Registration',
	},
	TUICC24_OTP_Submit: {
		event: 'TUICC24_OTP_Submit',
		action: 'click',
		label: 'Successful OTP Submission',
	},
	TUICC24_InviteFriends_Submit: {
		event: 'TUICC24_InviteFriends_Submit',
		action: 'click',
		label: 'Invite your friends',
	},
	TUICC24_UniqueCode_Submit: {
		event: 'TUICC24_UniqueCode_Submit',
		action: 'click',
		label: 'Successful Unique Codes Submission',
	},
	TUICC24_CompletedProfile: {
		event: 'TUICC24_CompletedProfile',
		action: 'click',
		label: 'Successful Completed Profile',
	},
	TUICC24_SeatToofaniAir_ClaimFormSubmit: {
		event: 'TUICC24_SeatToofaniAir_ClaimFormSubmit',
		action: 'click',
		label: 'Successful Claim Form Submission for Air ticket',
	},
	TUICC24_ToofaniMerch_RedeemFormSubmit: {
		event: 'TUICC24_ToofaniMerch_RedeemFormSubmit',
		action: 'click',
		label: 'Successful Redeermtion of Fancode Voucher',
	},
	TUICC24_Jersery_RedeemFormSubmit: {
		event: 'TUICC24_Jersery_RedeemFormSubmit',
		action: 'click',
		label: 'Successful Redeermtion of Fancode Voucher',
	},
};
