export const addressObj = {
	success: true,
	data: {
		id: 4,
		user_id: 5,
		address1: 'B-289, Noid 20',
		address2: 'Second Floor',
		landmark: 'Near Hanuman Mandir',
		pin_code: '201301',
		alt_mobile_no: '9656566667',
		country: 'India',
		state: 'Uttar Pradesh',
		city: 'Noida',
		created_at: '2024-01-31T08:57:29.000000Z',
		updated_at: '2024-01-31T08:57:29.000000Z',
	},
	message: 'Address added successfully',
};
