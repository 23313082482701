import { useTranslation } from 'react-i18next';

import Typography from './Typography';
import WinIcon from '../../public/win_icon.svg';
import Button from './Button';
import { gaEvents } from '../utils/gaEvents';
import { useCDP } from '../utils/useCDP';
import SocialShareButton from './share/SocialShareButton';

const ClaimRedeemSuccess = ({ shareEvent, winCode }) => {
	const { t } = useTranslation('winner');

	const sendCDPData = useCDP();

	const shareClickHandler = () => {
		window.dataLayer.push(gaEvents[shareEvent]);

		if (winCode === 1) {
			sendCDPData('Click', 'Share_ICCToofaniTourWin');
		}
	};

	return (
		<>
			<div className="flex flex-col justify-start items-center text-center">
				<div className="w-9 h-9 mb-2 lg:w-20 lg:h-20 lg:mb-5">
					<img src={WinIcon} className="w-full h-full" />
				</div>
				<Typography
					variant="h2"
					type="text24"
					text={t('win_heading')}
					customClasses="mb-4 text-center  lg:text-[4.25rem] lg:leading-normal"
				/>
				<h3 className="text-center text-sm  leading-[1.125rem] font-thumbsUp  lg:text-[2.375rem] lg:leading-normal">
					{t('win_' + winCode + '_success')}
				</h3>
				<SocialShareButton
					btnText="Share"
					classesName="border-2 bg-btnbg mt-3 font-medium text-1xl border-btnbg text-white  p-2 font-thumbsUp w-2/4 mb-5 lg:text-4xxl lg:p-7"
					onClickHandler={shareClickHandler}
					text={t('win_' + winCode + '_success')}
				/>
			</div>
		</>
	);
};

export default ClaimRedeemSuccess;
