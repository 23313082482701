{
	"winner_list_h1": "તૂફાની ચેમ્પિયન્સ",
	"avatar": "અવતાર",
	"name": "નામ",
	"phone_number": "ફોન નંબર",
	"prize": "ઇનામ",
	"select_date": "તારીખ પસંદ કરો",
	"record_not_found": "Record not found",
	"win_1": "મેં હમણાં જ વેસ્ટ ઈન્ડિઝમાં ICC મેન્સ T20 વર્લ્ડ કપ 2024 ગેમ્સ માટે તૂફાનમાં સીટ જીતી છે* તમે પણ જીતી શકો છો!",
	"win_img_1": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/flight.jpg",
	"win_2": "મેં હમણાં જ થમ્સ અપ® ICC મેન્સ T20 વર્લ્ડ કપ 2024 પ્રમોશનમાં ફેનકોડમાંથી ક્રિકેટ જર્સી જીતી છે.",
	"win_img_2": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/jercy.jpg",
	"win_3": "મેં હમણાં જ થમ્સ અપ® ICC મેન્સ T20 વર્લ્ડ કપ 2024 પ્રમોશનમાં ફેનકોડમાંથી ક્રિકેટ સામાન જીત્યો છે",
	"win_img_3": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/voucher.jpg",
	"one_more_check": "બસ એક વધુ તપાસ!",
	"one_more_check_text": "અમે તમે સબમિટ કરેલી વિગતો ચકાસી  રહ્યાં છીએ અને 10 કામકાજી દિવસોમાં, આ સાઇટ પર તેમજ SMS અથવા WhatsApp દ્વારા તમને તૂફાન પર બોર્ડ કરવાની તમારી યોગ્યતા વિશે સૂચિત કરીશું. ધીરજ રાખો!",
	"win_heading": "Congratulations!",
	"win_1_success_verified": "તૂફાન પર તમારી સીટ કન્ફર્મ થઈ ગઈ છે અને તમે વેસ્ટ ઈન્ડિઝ જઈ રહ્યાં છો! અમે આગામી પગલાઓ સાથે ટૂંક સમયમાં SMS/ WhatsApp અથવા ફોન કૉલ દ્વારા તમારો સંપર્ક કરીશું. તમારી અકલ્પિત જીત વિશે તમારા મિત્રોને કહો!",
	"win_1_success": "તૂફાન પર તમારી સીટ કન્ફર્મ થઈ ગઈ છે અને તમે વેસ્ટ ઈન્ડિઝ જઈ રહ્યાં છો! અમે આગામી પગલાઓ સાથે ટૂંક સમયમાં SMS/ WhatsApp અથવા ફોન કૉલ દ્વારા તમારો સંપર્ક કરીશું. તમારી અકલ્પિત જીત વિશે તમારા મિત્રોને કહો!",
	"win_2_success": "You’ve claimed your Cricket Jersey! We will communicate further details via email & SMS shortly.",
	"win_3_success": "You’ve claimed your Toofani Merch! We will communicate further details via email & SMS shortly."
}
