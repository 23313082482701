import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import GiftIcon1 from '../../public/gift_icon_1.svg';
import GiftIcon2 from '../../public/gift_icon_2.svg';

const ContestOver = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="flex flex-col justify-start items-center h-full bg-contest-over-bg lg:bg-contest-over-bg-dk bg-cover bg-no-repeat bg-center pb-5 px-4 pt-[8rem] lg:pt-[17rem] overflow-hidden">
			{/* <h1 className="text-white text-3xl font-normal leading-7 mb-1">
				{t('contest_over')}
			</h1> */}
			<p className="text-white text-2xl font-medium leading-6 w-[60%] text-center mb-4 lg:w-[34%] lg:text-[3rem] lg:leading-[3.25rem]">
				<span>{t('The ICC Toofani Tour Promo has now ended.')}</span>
				<br />
				<span>{t('For any queries, email us at')}{' '}
					<a href="mailto:indiahelpline@coca-cola.com" className="underline">
					indiahelpline@coca-cola.com
					</a></span>
			</p>

		</div>
	);
};

export default ContestOver;
