import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Heading from '../components/Heading';
import OTPInput from '../components/OTPInput';
import SubHeading from '../components/SubHeading';
import Button from '../components/Button';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import InputErrorMessage from '../components/InputErrorMessage';
import { AuthContext } from '../context/auth-context';
import { useSendOtp } from '../utils/useSendOtp';
import Typography from './Typography';
import { useCampaignContext } from '../context/campaign-context';
import Input from './Input';
import { gaEvents } from '../utils/gaEvents';
import { insertFloodlightTag } from '../utils/common';

const OtpVerificationForm = ({ setLoginStep, mobileNumber }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { openLoginForm, setOpenLoginForm } = useCampaignContext();
	const [otp, setOtp] = useState(0);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [timer, setTimer] = useState(60);
	const [isResendDisabled, setIsResendDisabled] = useState(true);
	const [resendOtpSuccessMsg, setResendOtpSuccessMsg] = useState(false);
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const {
		sendOtpRequest,
		isLoading: sendOtpLoading,
		apiError: sendOtpError,
	} = useSendOtp();

	const { login, setQueryUser, isLoggedIn } = useContext(AuthContext);

	const yupSchema = useCallback(
		yup.object({
			otp: yup.string().matches(/^\d{6}$/, t('Please enter a valid OTP')),
		}),
		[]
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		watch,
		setValue,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	useEffect(() => {
		setValue('otp', watch('otp')?.replace(/\D/g, '').slice(0, 6));
	}, [watch('otp')]);

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/');
		}
	}, [isLoggedIn]);

	useEffect(() => {
		let timer;

		if (resendOtpSuccessMsg) {
			timer = setTimeout(() => setResendOtpSuccessMsg(false), 3000);
		}

		return () => {
			timer && clearTimeout(timer);
		};
	}, [resendOtpSuccessMsg]);

	useEffect(() => {
		let interval;

		if (timer > 0 && isResendDisabled) {
			interval = setInterval(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);
		} else {
			setIsResendDisabled(false);
		}

		return () => clearInterval(interval);
	}, [timer, isResendDisabled]);

	const handleResend = async () => {
		setTimer(60);
		setIsResendDisabled(true);
		try {
			await sendOtpRequest(mobileNumber);
			setResendOtpSuccessMsg(true);
			setValue('otp', '');
		} catch (error) {
			console.error(`resend otp error>>>`, error);
		}
	};

	// const otpChangHandler = (value) => {
	// 	setOtp(value);
	// 	if (value.length === 4) {
	// 		setBtnDisabled(false);
	// 	}
	// };

	const submitHandler = async ({ otp }) => {
		// Floodlight tags

		insertFloodlightTag({
			activityName: 'ThumsUp_ICC24_VerifyOTP_Counter',
			imageTag: 'thums03v', // Specific image tag for HomePage
		});
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}login/verify-otp`,
				'POST',
				JSON.stringify({
					mobile_no: +mobileNumber,
					isd_code: 91,
					otp,
				}),
				{
					'Content-Type': 'application/json',
					'x-auth-key': process.env.AUTH_KEY,
				}
			);

			login(response?.data?.token);
			// Track the signup event with GTM
			window.dataLayer.push(gaEvents['TUICC24_OTP_Submit']);
			if (response?.data?.isFirstLogin) {
				setLoginStep(3);
			} else {
				setOpenLoginForm(!openLoginForm);
				navigate('/');
			}
		} catch (error) {
			console.error(`Login API error>>>`, error);
		}
	};

	return (
		<div className="flex flex-col justify-between items-center font-thumbsUp">
			{(isLoading || sendOtpLoading) && <Loader />}
			<div className="mb-4 text-center">
				<Heading
					text={t('otp_verification')}
					additionalClasses="lg:text-3xxl lg:mb-2"
				/>
				<SubHeading
					text={t('otp_verification_sub_text', { mobile_number: mobileNumber })}
					customClasses="lg:text-xl"
				/>
				{/* <Typography variant="h2" type="text24" text={t('otp_verification')} />
				<Typography
					variant="h3"
					type="text16"
					text={t('otp_verification_sub_text', { mobile_number: mobileNumber })}
				/> */}
			</div>
			<form onSubmit={handleSubmit(submitHandler)} className="w-full">
				<div className="mb-2.5">
					<Controller
						name="otp"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.otp}
								// placeholder={`${t('mobile_number_label')}*`}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.otp?.message} />
				</div>
				<div className="mb-2 text-center">
					{isResendDisabled && (
						<SubHeading
							text={t('try_again_text', { time_remaining: `00:${timer}` })}
							customClasses="lg:text-xl"
						/>
					)}
					{!isResendDisabled && (
						<button
							onClick={(e) => {
								e.stopPropagation();
								handleResend();
							}}
							disabled={isResendDisabled}
							className="text-pageSubHead text-base leading-5 lg:text-xl lg:leading-6 TUICC24ResendOTP"
							id="TUICC24ResendOTP"
						>
							{t('resendOTP')}
						</button>
					)}
				</div>
				{resendOtpSuccessMsg && (
					<p className="text-center text-sm text-green-900 font-normal lg:text-lg">
						{t('OTP resent successfully')}
					</p>
				)}
				{apiError && Object.keys(errors).length === 0 && (
					<InputErrorMessage text={t(apiError)} />
				)}
				<Button text={t('btn_continue')} customClasses="lg:text-3xxl p-3" />
			</form>

		</div>
	);
};

export default OtpVerificationForm;
