import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';

const NotFound = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="flex flex-col justify-center items-center h-screen">
			<h1 className="text-8xl text-black mb-3 leading-[4rem]">404</h1>
			<p className="text-xl text-black font-light mb-3">{t('no_page_found')}</p>
			<div className="">
				<Button
					text={t('go_to_home')}
					customClasses="text-xl font-normal p-2"
					onClick={() => navigate('/')}
				/>
			</div>
		</div>
	);
};

export default NotFound;
