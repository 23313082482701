import { useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../components/Button';
import Input from '../components/Input';
import Heading from '../components/Heading';
import InputErrorMessage from '../components/InputErrorMessage';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import { useAuthContext } from '../context/auth-context';

const Login = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isLoggedIn } = useAuthContext();

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/');
		}
	}, [isLoggedIn]);

	const { isLoading, error: apiError, sendRequest } = useApiCall();

	const yupSchema = useCallback(
		yup.object({
			mobile_number: yup
				.string()
				.matches(/^\d{10}$/, t('mobile_error_message'))
				.min(10, t('mobile_error_message')),
		}),
		[]
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	const submitHandler = async (data) => {
		const { mobile_number } = data;
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}login/send-otp`,
				'POST',
				JSON.stringify({
					mobile_no: +data.mobile_number,
					isd_code: 91,
				}),
				{
					'Content-Type': 'application/json',
					'x-auth-key': process.env.AUTH_KEY,
				}
			);
			navigate('/otp-verification', { state: { mobile_number } });
		} catch (error) {
			console.error(`Login API error>>>`, error);
		}
	};

	return (
		<>
			<div className="mt-7 mb-4 px-4 lg:pt-14 lg:flex lg:justify-center">
				<div className="lg:w-1/4 lg:bg-desktopFormBg lg:h-3/5 lg:py-10 lg:px-4  relative">
					{isLoading && <Loader />}
					<div className="mb-4 text-center ">
						<Heading text="login" additionalClasses="lg:text-3xxl" />
					</div>
					<form onSubmit={handleSubmit(submitHandler)}>
						<div className="mb-6">
							<Controller
								name="mobile_number"
								control={control}
								defaultValue={''}
								render={({ field: { ref, ...rest } }) => (
									<Input
										{...rest}
										isValid={!errors?.mobile_number}
										placeholder={`${t('mobile_number_label')}*`}
									/>
								)}
							/>
							<InputErrorMessage text={errors?.mobile_number?.message} />
						</div>
						{apiError && (
							<InputErrorMessage text="Something went wrong! Please try again later." />
						)}
						<Button text={t('btn_continue')} customClasses="lg:text-3xxl p-3" />
					</form>
				</div>
			</div>
		</>
	);
};

export default Login;
