{
	"home": "होम",
	"login": "लॉगिन करा",
	"mobile_number": "मोबाईल नंबर",
	"mobile_number_label": "मोबाईल नंबर एंटर करा",
	"get_otp": "Get OTP",
	"mobile_error_message": "कृपया एक वैध नंबर एंटर करा",
	"resendOTP": "ओटीपी पुन्हा पाठवा",
	"otp_verification": "ओटीपी पडताळणी",
	"otp_verification_sub_text": "आम्ही +91 {{mobile_number}} ला पाठविलेला 6-अंकी कोड एंटर",
	"try_again_text": "{{time_remaining}} सेकंदांत ओटीपी पुन्हा पाठवा",
	"btn_submit": "सबमिट करा",
	"sign_up": "You are almost there!",
	"enter_detail_text": "खेळण्याच्या चमूमध्ये सामील होण्यासाठी साईन अप करा!",
	"full_name": "पूर्ण नाव",
	"name": "नाव",
	"email_id": "ईमेल आयडी",
	"terms_text": "मी असे प्रमाणित करतो की, मी किमान 18 वर्षे वयाचा आहे आणि मी <tnc>नियम व अटी</tnc> आणि <pp>गोपनीयता धोरण</pp> वाचले आहे.",
	"participate_win": "Participate & Win",
	"winners_list": "Winners List",
	"faqs": "वारंवार विचारले जाणारे प्रश्न",
	"terms_conditions": "अद्ययावत नियम आणि अटी",
	"privacy_policy": "गोपनीयता धोरण",
	"logout": "साइन आउट",
	"participate_now": "आता सहभागी व्हा",
	"winner_board": "Winner Board",
	"enter_unique_code": "युनिक कोड प्रविष्ट करा",
	"winners": "Winners",
	"enter_code": "कोड एंटर करा",
	"profile": "प्रोफाईल",
	"get_aboard_text": "तुफानवर आपली सीट पकडा",
	"btn_continue": "सुरू ठेवा",
	"get_aboard_sub_text": "या आयुष्यात-एकदाच घडणाऱ्या प्रवासात आमच्यासोबत सामील व्हा – जहाजावरील एक असा लक्झरी अनुभव ज्यामध्ये वेस्ट इंडीजमध्ये होणार्या  आयसीसी टी 20 वर्ल्ड कप गेममध्ये आमच्या सर्व तुफानी फॅन्सना हवाईमार्गे नेण्यासाठी एक विशेष विमान सुसज्ज केले आहे.",
	"win_mileage_text": "ॲक्शनच्या जवळ जा",
	"win_mileage_sub_text": "प्रत्येक थम्स अप® सोबत",
	"my_kms": "माय किमीज",
	"follow_us": "आमचे अनुसरण करा",
	"copyright_text": "© 2024 द कोका-कोला कंपनी. सर्व हक्क राखीव.",
	"Please enter a valid unique code": "कृपया एक वैध कोड एंटर करा",
	"your_profile": "माझी प्रोफाईल",
	"profile_page_desc": "विजेत्यांशी संपर्क साधण्यासाठी आम्ही खालील तपशीलाचा वापर करू. कृपया पुरवलेली सर्व माहिती सत्य असल्याची खात्री करा",
	"dob": "जन्म दिनांक",
	"gender": "लिंग",
	"set_as_default": "डिफॉल्ट म्हणून सेट करा",
	"congratulations": "अभिनंदन",
	"flight_ticket_claimed_text": "You’ve claimed your ticket to the West Indies! We will communicate further details via email & SMS shortly.",
	"share": "शेअर करा",
	"claim_form_desc": "तुमचे रिवॉर्ड्स क्लेम करण्यासाठी कृपया तुमचे तपशील एंटर करा",
	"full_name_claim_form": "पूर्ण नाव (शासकीय आयडीनुसार)",
	"pan_card": "पॅन कार्ड",
	"passport_no": "पासपोर्ट नंबर",
	"passport_expiry_date": "पासपोर्ट कालबाह्यतेची तारीख",
	"Male": "पुरूष",
	"Female": "स्त्री",
	"transgender": "Transgender",
	"Other": "अन्य",
	"gift_voucher_claimed_text": "You’ve claimed your gift voucher! We will communicate further details via email & SMS shortly.",
	"claim_now": "आता क्लेम करा",
	"paytm_1000_won_text": "You’ve won yourself a ₹1,000 Paytm voucher!",
	"paytm_1000_win_text": "Win a ₹1000 Paytm Voucher!",
	"paytm_100_won_text": "You’ve won yourself a ₹1,00 Paytm voucher!",
	"paytm_100_win_text": "Win a ₹100 Paytm Voucher!",
	"win_ticket": "Win a Ticket",
	"enter_unique_code_reach_kms": "Enter unique code to reach 14K KMs",
	"enter_name": "कृपया एक वैध नाव एंटर करा",
	"enter_mobile_number": "Please enter mobile number",
	"enter_email": "कृपया एक वैध ईमेल आयडी एंटर करा",
	"enter_pan": "कृपया वैध पॅन कार्ड क्रमांक एंटर करा",
	"no_page_found": "कोणतेही पृष्ठ सापडले नाही",
	"go_to_home": "होमपेजवर जा",
	"eligible_1000_paytm_voucher": "You are now eligible for ₹1000 Paytm Gift Voucher",
	"win_prize_lucky_draw_daily": "Win Prizes in a lucky Draw Daily",
	"ticket_to_west_indies": "Ticket to West Indies",
	"ticket_to_west_indies_v1": "तुम्ही तुफानी फॅन क्लबपर्यंत पोहोचला आहात!",
	"ticket_to_west_indies_subtext_v1": "तुम्ही तुफान जहाजामध्ये सीट जिंकले आहे! वेस्ट इंडीजमध्ये स्टाइलमध्ये उड्डाण करण्यास सज्ज व्हा",
	"fan_jersy_heading": "प्रो प्रमाणे सूट परिधान करा",
	"fan_jersy_heading_text": "999 रुपयांची तुमची क्रिकेट जर्सी क्लेम करण्यासाठीचे फॅनकोड व्हाऊचर येथे आहे",
	"how_enter_lucky_draw": "How to Enter the Lucky Draw",
	"step_1": "पायरी 1",
	"step_2": "पायरी 2",
	"step_3": "पायरी 3",
	"step_4": "पायरी 4",
	"scan_qr_code_bottle": "Scan the QR code from your ThumbsUp bottle",
	"enter_code_kms_achieve_three_code": "Enter the unique code to accrue 14,000kms with 3 codes max",
	"enter_lucky_draw_win_prize": "Enter the lucky draw to win prizes daily",
	"paytm_gift_voucher": "Paytm gift Voucher",
	"faq_heading": "सतत विचारले जाणारे प्रश्न",
	"faq_subtext": "थम्सअपबद्दल आपल्याला माहित असणे आवश्यक असलेले सर्व काही येथे आहे",
	"terms_heading": "Terms & Conditions",
	"contest_over": "Contest Over",
	"thank_you_visit_page": "Thank you for visiting our page! This contest is now closed.",
	"participate_other_giveaways": "Please visit (link to ThumbsUp’s Contests page) to participate in other giveaways",
	"winner_list": "विजेता यादी",
	"full_name_error": "Please enter full name",
	"name_error": "कृपया एक वैध नाव एंटर करा",
	"dob_error": "तुमचे वय किमान १८ वर्षे असणे आवश्यक आहे, कृपया योग्य जन्मतारीख प्रविष्ट करा.",
	"passport_expiry_date_error_required": "Please enter a valid passport expiry date",
	"passport_expiry_date_error": "Passport expiry date field must be a date after today.",
	"passport_no_error": "Please enter a valid Passport number",
	"terms_agree_error": "कृपया नियम आणि अटीना सहमती द्या",
	"email_error": "कृपया एक वैध ईमेल आयडी एंटर करा",
	"how_to_participate": "सहभागी कसे व्हायचे",
	"toofani_champions": "तुफानी चॅंपियन्स",
	"refer_friend": "फ्रेंडला रेफर करा",
	"have_invite_code": "आमंत्रण कोड आहे?",
	"enter_collect_kms": "एंटर करा आणि 100kms कलेक्ट करा",
	"contact_us": "आमच्याशी संपर्क करा",
	"prize_grab": "ही बक्षिसे जिंका",
	"seat_aboard_toofan": "तुफान वर बसा \nदररोज 1 विजेता*",
	"cricket_jersey_999_fancode": "फॅनकोडकडून ₹999 ची क्रिकेट जर्सी \nदर तासाला 1 विजेता*",
	"toofani_999_fancode": "फॅनकोडकडून ₹499 चे तुफानी मर्च \nदर तासाला 15 विजेते*",
	"find_unique_code_pack": "पॅकवर युनिक कोड शोधा",
	"enter_code_collect_kms": "किमीज गोळा करण्यासाठी कोड एंटर करा",
	"complete_journey_destination": "गंतव्यस्थानचा प्रवास पूर्ण करा",
	"get_chance_win": "जिंकण्यासाठी एक संधी मिळवा*!",
	"refer_friend_collect_kms": "अतिरिकीट किमीज* गोळा करण्यासाठी फ्रेंडला रेफर करा",
	"get_100km_mate_signup": "यशस्वीरित्या साईन आप करून तुमच्या आमंत्रण कोडचा उपयोग करणार्यार प्रत्येक मित्रासाठी 100 किमीज मिळवा.",
	"refer_now": "आता रेफर करा",
	"tnc_apply": "नियमवअटी लागू",
	"available": "उपलब्ध आहे",
	"booked": "बुक केले",
	"going_soon": "लवकरच जात आहे",
	"you_made_toofani_fan_club": "तुम्ही तुफानी फॅन क्लबपर्यंत पोहोचला आहात!",
	"won_seat_get_ready": "तुम्ही तुफान जहाजामध्ये सीट जिंकले आहे! वेस्ट इंडीजमध्ये स्टाइलमध्ये उड्डाण करण्यास सज्ज व्हा",
	"jersey_won_text": "प्रो प्रमाणे सूट परिधान करा",
	"jersey_won_subtext": "999 रुपयांची तुमची क्रिकेट जर्सी क्लेम करण्यासाठीचे फॅनकोड व्हाऊचर येथे आहे",
	"redeem_now": "आता रिडीम करा",
	"voucher_won_text": "थोडी संघभावना जागृत होऊ द्या",
	"voucher_won_subtext": "499 रुपयांचे तुमचे क्रिकेट मर्च. क्लेम करण्यासाठीचे फॅनकोड व्हाऊचर येथे आहे",
	"my_wins": "माझे विजय",
	"optional": "पर्यायी",
	"sign_up_cta": "साईन अप करा",
	"Please enter a valid OTP": "कृपया वैध ओटीपी प्रविष्ट करा",
	"enter_collect_100kms": "एंटर करा आणि 100kms कलेक्ट करा",
	"unique_code_entry_text": "वेस्ट इंडीजमधील आयसीसी मेन्स टी20 वर्ल्ड कप 2024 गेम्ससाठीच्या तुफानी जहाजातून उड्डाण करण्याची संधी मिळवा",
	"unique_code_entry_subtext": "थम्स अप® बॉटलच्या लेबलच्या मागील युनिक कोड शोधा",
	"mobile_number_short": "मोबाईल नंबर",
	"welcome_player": "वेलकम, प्लेयर!",
	"login_start_winning": "जिंकणे सुरू करण्यासाठी लॉगिन करा!",
	"trademark_text": "कार्बोनेटेड वॉटर. ट्रेडमार्कचे मालक: द कोका-कोला कंपनी.",
	"address": "पत्ता",
	"address_line_1": "पत्ता ओळ 1",
	"address_line_2": "पत्ता ओळ 2",
	"nearest_landmark": "जवळची खूण",
	"alternative_mobile_number": "पर्यायी मोबाईल नंबर",
	"pincode": "पिनकोड",
	"state": "राज्य",
	"city": "शहर",
	"state_error": "कृपया राज्य निवडा",
	"city_error": "कृपया शहर निवडा",
	"pincode_error": "कृपया वैध पिनकोड एंटर करा",
	"address_error": "कृपया वैध पत्ता एंटर करा",
	"pan_error": "कृपया वैध पॅन कार्ड क्रमांक एंटर करा",
	"copy_code": "कोड कॉपी करा",
	"last_date_redeem": "रिडीम करण्याची अंतिम तारीख",
	"last_date_to_claim": "क्लेम करण्याची अंतिम तारीख",
	"share_with_friends": "मित्रांसोबत शेअर करा",
	"no_internet_message": "कनेक्ट करू शकत नाही. कृपया तुमचे नेटवर्क कनेक्शन तपसा आणि पुन्हा प्रयत्न करा!",
	"email_us": "आम्हाला ईमेल करा",
	"call_toll_free": "यावर आम्हाला टोल-फ्री फोन करा",
	"mon_sat": "सोम.-शनि",
	"sunday": "रवि",
	"follow_us_now": "आता आम्हाला फॉलो करा",
	"otp_expired_enter_new": "तुमचा ओटीपी कालबाह्य झाला आहे. कृपया एक नवीन वापरा.",
	"email_address": "ईमेल पत्ता",
	"Code has already been used, please enter a new code": "कोड आधीच वापरण्यात आला आहे, कृपया एक नवीन कोड एंटर करा.",
	"first": "पहिले",
	"last": "शेवटचे",
	"add": "जोडा",
	"refresh_page": "पेज रिफ्रेश करा",
	"Seat on Toofan": "तुफानावर स्वर व्हा",
	"Toofani Merch": "तुफानी मर्च",
	"Cricket Jersey": "क्रिकेट जर्सी",
	"You are here": "तुम्ही इथे पोहोचला आहात",
	"OTP resent successfully": "ओटीपी यशस्वीरित्या पुन्हा पाठवला.",
	"No winners till now": "आतापर्यंत कोणतेही विजेते नाहीत",
	"COPIED": "कॉपी केले",
	"T&C": "अद्ययावत नियम आणि अटी",
	"Email is already in use": "इमेल आधीच वापरात आहे",
	"Clear": "साफ करा",
	"West Indies": "वेस्ट इंडिज",
	"India": "भारत",
	"Reminder send successfully": "यशस्वीरित्या पाठविलेले स्मरणपत्र",
	"KM": "किमी",
	"KMs": "किमी",
	"Edit Address": "Edit Address",
	"Please enter a valid code": "कृपया वैध कोड प्रविष्ट करा",
	"final_day_offer": "ऑफरचा शेवटचा दिवस 30 एप्रिल 2024 ते 15 मे 2024 पर्यंत वाढवण्यात आला आहे. <tnc>नियम आणि अटी लागू</tnc>"
}
