import {floodLightTags} from "./floodLightTags";

export const subtractYears = (date, years) => {
	date.setFullYear(date.getFullYear() - years);
	return date;
};

function isOver18YearsAgo(givenDate) {
	const currentDate = new Date();
	const eighteenYearsAgo = new Date();
	eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);

	const inputDate = new Date(givenDate);

	if (isNaN(inputDate.getTime())) {
		// If the given date is not a valid date
		return false;
	}

	return inputDate <= eighteenYearsAgo;
}

export const formatDate = (date = new Date()) => {
	const year = date.toLocaleString('default', { year: 'numeric' });
	const month = date.toLocaleString('default', {
		month: '2-digit',
	});
	const day = date.toLocaleString('default', { day: '2-digit' });

	return [year, month, day].join('-');
};
export const formattedDate = (date = new Date()) => {
	const year = date.toLocaleString('default', { year: 'numeric' });
	const month = date.toLocaleString('default', {
		month: '2-digit',
	});
	const day = date.toLocaleString('default', { day: '2-digit' });

	return [day, month, year].join('-');
};

export const triggerTrackingPixel = (pixelUrl) => {
	try {
		// Query all existing pixels with the same URL and remove them
		const existingPixels = document.querySelectorAll(`img[src="${pixelUrl}"]`);
		existingPixels.forEach((pixel) => pixel.parentNode.removeChild(pixel));

		// Create a new tracking pixel
		const img = new Image(1, 1); // Creates an image of 1x1 pixels
		img.src = pixelUrl;
		img.alt = '';
		img.style.borderStyle = 'none';
		img.style.position = 'absolute';
		img.style.opacity = '0';

		// Insert the new tracking pixel at the top of the body
		if (document.body.firstChild) {
			document.body.insertBefore(img, document.body.firstChild);
		} else {
			document.body.appendChild(img);
		}
	} catch (error) {
		// Handle errors that may occur during the tracking pixel setup
		console.error('Error adding tracking pixel:', error);
	}
};

export const insertFloodlightTag = ({ activityName, imageTag }) => {
	try {
		const scriptData = floodLightTags[activityName];
		console.log("Floodlight tags>>",scriptData)
		if (window.gtag) {
			window.gtag('event', 'conversion', {
				'allow_custom_scripts': true,
				'send_to': `${scriptData.send_to}`
			});
		}else{
			console.log("Floodlight>>", scriptData.send_to)
		}
	} catch (error) {
		console.log(`Floodlight tag error>>>`, error);
	}
};
