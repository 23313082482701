import Button from './Button';

const Voucher = ({ imgSrc, text, subText, btnText, onClick }) => {
	return (
		<div className="rounded-lg bg-inputBg">
			<div className="rounded-lg bg-voucherBg  flex justify-center mb-3 w-full h-[6rem] lg:h-[21.25rem]">
				<img src={imgSrc} className="rounded-t-lg" />
			</div>
			<div className="flex flex-col justify-normal items-center px-1.5 pb-4 lg:pb-7">
				<p className="text-pageTitleColor text-sm font-normal leading-5 mb-1 lg:text-5xl lg:leading-none lg:mb-3">
					{text}
				</p>
				<p className="text-voucherSubText text-xs font-normal mb-1 lg:text-4xl lg:leading-10 w-4/5 lg:mb-3">
					{subText}
				</p>
				<div className=" mb-3  ">
					<Button
						text={btnText}
						customClasses="text-sm font-normal p-2 lg:text-4xxl lg:p-7"
						onClick={onClick}
					/>
				</div>
			</div>
		</div>
	);
};

export default Voucher;
