import { useState, useEffect, useRef } from 'react';

import { Carousel } from '@material-tailwind/react';
import HomePageBanner from './HomepageBanner';

const HomePageCarousel = ({ participateHandler, referClickHandler }) => {
	const touchStartX = useRef(null);
	const [swipeRight, setSwipeRight] = useState(false);
	const [swipeLeft, setSwipeLeft] = useState(false);

	useEffect(() => {
		try {
			const btnArr = Array.from(
				document.querySelectorAll('#homeCarousel > button')
			);

			const leftButton = btnArr[0];
			const rightButton = btnArr[1];

			if (leftButton && rightButton) {
				leftButton.style.visibility = 'hidden';
				rightButton.style.visibility = 'hidden';
			}

			if (swipeRight) {
				leftButton.click();
			} else if (swipeLeft) {
				rightButton.click();
			}
		} catch (error) {
			console.log(`Swipe error>>>`, error);
		}
	}, [swipeRight, swipeLeft]);

	const handleTouchStart = (e) => {
		touchStartX.current = e.touches[0].clientX;
		setSwipeRight(false);
		setSwipeLeft(false);
	};

	const handleTouchMove = (e) => {
		if (!touchStartX.current) return;

		const currentX = e.touches[0].clientX;
		const deltaX = currentX - touchStartX.current;

		// Set a threshold to determine a valid swipe
		const threshold = 50;

		if (deltaX > threshold) {
			setSwipeRight(true);
		} else if (deltaX < -threshold) {
			setSwipeLeft(true);
		}
	};

	const handleTouchEnd = () => {
		touchStartX.current = null;
	};

	return (
		<Carousel
			className="homeCarousel rounded-lg mb-2 lg:mb-16"
			id="homeCarousel"
			autoplay={true}
			// loop={true}
			// prevArrow={() => {}}
			// nextArrow={() => {}}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
		>
			<HomePageBanner bannerNum={1} />
			<HomePageBanner bannerNum={2} />
		</Carousel>
	);
};

export default HomePageCarousel;
