import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from './Heading';
import AddIcon from '../../public/add_icon.svg';

import AddressItem from './AddressItem';
import { useAuthContext } from '../context/auth-context';
import { useApiCall } from '../utils/useApiCall';
// import AddAddressDialog from './AddAddressDialog';
import { useUserContext } from '../context/user-context';

const AddressBox = ({
	address,
	onAdd,
	onEdit,
	onDelete,
	btnClasses = 'text-btnTextColor',
}) => {
	const { t } = useTranslation();
	// const [openAddressForm, setOpenAddressForm] = useState(false);
	const { token, isLoggedIn } = useAuthContext();
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	const [addresses, setAddresses] = useState([]);
	const [defaultAddrId, setDefaultAddrId] = useState(0);

	const { userData, setUserData } = useUserContext();

	useEffect(() => {
		if (address) {
			setAddresses(address);
		}
	}, [address]);

	useEffect(() => {
		let defaultAddrId;
		if (addresses.length > 0) {
			defaultAddrId = addresses.find((item) => item.is_default === 1)?.id;
		}
		defaultAddrId && setDefaultAddrId(defaultAddrId);
	}, [addresses]);
	const deleteAddressHandler = async (userAddressId) => {
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}address/delete`,
				'POST',
				JSON.stringify({id:userAddressId}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				let updatedAddress = [...userData?.address];
				updatedAddress = updatedAddress.filter(
					(userAddress) => userAddress.id !== userAddressId
				);
				setUserData({
					...userData,
					address: [...updatedAddress],
				});
			}
		} catch (error) {
			console.log('delete address err=>>', error);
		}
	};

	const defaultAddressHandler = async (addrId) => {
		setDefaultAddrId(addrId);

		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}address/default`,
				'POST',
				{id:addrId},
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				setUserData({
					...userData,
					address: [...response?.data],
				});
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			console.error(`Add address API error>>>`, error);
		}
	};

	let addressList;
	if (addresses?.length === 0) {
		addressList = (
			<></>
			/*<p className="text-base text-black font-normal leading-6 mb-2 w-4/5 lg:text-2xl">
				No Address Found.
			</p>*/
		);
	} else {
		addressList = addresses.map((addressItem) => (
			<AddressItem
				item={addressItem}
				addressId={addressItem.id}
				onEdit={onEdit}
				onDelete={deleteAddressHandler}
				setAsDefault={defaultAddrId === addressItem.id}
				onDefault={defaultAddressHandler}
				key={addressItem.id}
			/>
		));
	}
	return (
		<>
			<div className="flex justify-between items-start mb-2">
				<Heading text={t('address')} />
				<div className="" onClick={onAdd}>
					{/* <img src={AddIcon} /> */}
					<span
						className={`text-xl leading-6  font-bold lg:text-4xxl ${btnClasses}`}
					>
						+ {t('add')}
					</span>
				</div>
			</div>
			{addressList}
			{/* <AddAddressDialog
				open={openAddressForm}
				handleOpen={() => setOpenAddressForm(!openAddressForm)}
			/> */}
		</>
	);
};

export default AddressBox;
