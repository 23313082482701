{
	"winner_list_h1": "Toofani Champions",
	"avatar": "Avatar",
	"name": "Name",
	"phone_number": "Phone Number",
	"prize": "Prize",
	"select_date": "Select Date",
	"record_not_found": "Record not found",
	"win_1": "I just won a seat aboard Toofan for the ICC Men’s T20 World Cup 2024 Games in the West Indies*",
	"win_img_1": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/flight.jpg",
	"win_2": "I just won a cricket jersey from FanCode in the Thums Up® ICC Men’s T20 World Cup 2024 promotion",
	"win_img_2": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/jercy.jpg",
	"win_3": "I just won cricket merch from FanCode in theThums Up® ICC Men’s T20 World Cu-p 2024 promotion",
	"win_img_3": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/voucher.jpg",
	"one_more_check": "Just One More Check!",
	"one_more_check_text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 10 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
	"win_heading": "Congratulations!",
	"win_1_success_verified": "Your seat aboard Toofan is confirmed & you’re going to the West Indies! We will reach out to you with next steps shortly via SMS/ WhatsApp or a phone call. Tell your buddies about your fabulous win!",
	"win_1_success": "Your seat aboard Toofan is confirmed & you’re going to the West Indies! We will reach out to you with next steps shortly via SMS/ WhatsApp or a phone call. Tell your buddies about your fabulous win!",
	"win_2_success": "You’ve claimed your Cricket Jersey! We will communicate further details via email & SMS shortly.",
	"win_3_success": "You’ve claimed your Toofani Merch! We will communicate further details via email & SMS shortly."
}
