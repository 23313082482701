const InputErrorMessage = ({ text, customClasses }) => {
	return (
		<p
			className={`text-sm text-errorText font-normal lg:text-lg pl-3 font-thumbsUp ${customClasses}`}
		>
			{text}
		</p>
	);
};

export default InputErrorMessage;
