{
  "win_1": {
    "headingText": "You’ve made it to the Toofani Fan Club!",
    "subHeadingText": "You won a seat Aboard Toofan to the ICC 2024 Games in the West Indies",
    "text": "",
    "icon": "flight_won_icon",
    "btnText": "Claim Now",
    "btnSubText": "Claim within:",
    "timerDisplay": " {{datetime}}"
  },
  "win_2": {
    "headingText": "Congratulations!",
    "subHeadingText": "You have won Cricket Jersey worth ₹999 from FanCode!",
    "text": "",
    "icon": "win_icon",
    "btnText": "Redeem Now",
    "btnSubText": "Last date to redeem:",
    "timerDisplay": " {{datetime}}"
  },
  "win_3": {
    "headingText": "Congratulations!",
    "subHeadingText": "You have won Toofani merch worth ₹499 from FanCode!",
    "text": "",
    "icon": "win_icon",
    "btnText": "Redeem Now",
    "btnSubText": "Last date to redeem:",
    "timerDisplay": " {{datetime}}"
  },
  "win_check": {
    "headingText": "Just One More Check!",
    "subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 5 seconds, on this site as well as through SMS or WhatsApp. Sit tight!",
    "text": "",
    "icon": "flight_win_check"
  },
  "win_check_waiting": {
    "headingText": "Just One More Check!",
    "subHeadingText": "",
    "text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 3 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
    "icon": "flight_win_check"
  },
  "valid_code": {
    "headingText": "Wah, Toofan!",
    "subHeadingText": "You’ve collected {{points}} kms. You’re just {{remaining_km}} kms away from your chance Aboard Toofan!",
    "text": "",
    "icon": "points_win_flight_icon.svg"
  },
  "total_limit_exceeded": {
    "headingText": "It’s the Bench for You!",
    "subHeadingText": "You have entered multiple invalid codes today. Come back tomorrow to try again.",
    "text": "",
    "icon": "warn_icon"
  },
  "already_win": {
    "headingText": "Already won!",
    "subHeadingText": "You have already won in the lucky draw",
    "text": "",
    "icon": "warn_icon"
  },
  "valid_code_limit_exceeded": {
    "headingText": "It’s the Bench for You!",
    "subHeadingText": "",
    "text": "You have entered multiple invalid codes today. Come back tomorrow to try again."
  },
  "claimed": {
    "headingText": "Congratulations!",
    "subHeadingText": "You’ve successfully claimed your ticket aboard Toofan Air with our fan squad",
    "text": "",
    "btnText": "Share",
    "icon": "claimed_icon"
  },
  "after_claimed": {
    "headingText": "Just One More Check!",
    "subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 10 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
    "text": "",
    "btnText": "",
    "icon": "flight_win_check"
  },
  "claimed_reward": {
    "headingText": "Congratulations!",
    "subHeadingText": "You’ve successfully claimed your Toofani Reward",
    "text": "",
    "btnText": "Share",
    "icon": "claimed_icon"
  },
  "claim_now": {
    "headingText": "Time is Running Out!",
    "subHeadingText": "The window to claim your seat on Toofan is expiring in {{timer}}",
    "text": "",
    "btnText": "Claim Now",
    "icon": "timer_icon"
  },
  "claim_expired": {
    "headingText": "You’ve Been Run Out!",
    "subHeadingText": "Your claim over your reward has expired.",
    "text": "Don’t lose hope, you are still in the game.\nCome back again tomorrow to check if you have won the next seat on Toofan!",
    "icon": "said_icon"
  },
  "not_win_for_the_day": {
    "headingText": "That’s a Maiden Over!",
    "subHeadingText": "Don’t lose hope, you are still in the game. Come back again tomorrow to check if you have won the next seat on Toofan!",
    "text": "",
    "icon": "said_icon"
  },
  "exit_claim_popup": {
    "headingText": "Claim Your Medal",
    "subHeadingText": "Your claim over your prize expires in {{hours}} hours.",
    "text": "Are you sure you want to exit?",
    "icon": "claim_expired_icon",
    "btnTextYes": "Yes",
    "btnTextNo": "No",
    "confirm": true
  },
  "exit_next_win_for_new": {
    "headingText": "Stop!",
    "subHeadingText": "You could be the next winner today!",
    "text": "Are you sure you want to exit?",
    "icon": "raise_hand_icon",
    "btnTextYes": "Yes",
    "btnTextNo": "No",
    "confirm": true
  },
  "exit_next_win": {
    "headingText": "You’re About To Hit The Winning Score!",
    "subHeadingText": "Enter another unique code & get a chance to win!",
    "text": "Are you sure you want to exit?",
    "icon": "raise_hand_icon",
    "btnTextYes": "Yes",
    "btnTextNo": "No",
    "confirm": true
  },
  "affiliate_signup_success": {
    "headingText": "Well Done Skipper",
    "subHeadingText": "",
    "text": "You have collected 100kms",
    "icon":"right_bat"
  },
  "default": {
    "headingText": "Error!",
    "subHeadingText": "Not found!",
    "text": ""
  },
  "qualified_for_lucky_draw": {
    "headingText": "Already qualified!",
    "subHeadingText": "You have already qualified for the lucky draw",
    "text": ""
  }
}
