{
	"heading_h2": "पार्टनरशिप हो तो ऐसी!",
	"heading_h3": "अपने दोस्तों को भाग लेने के लिए आमंत्रित करें।",
	"invite_code_text": "जब आपका दोस्त साइन अप कर आपके आमंत्रण कोड का उपयोग करेंगे तो आपको 100 किलोमीटर प्राप्त होंगे ",
	"friend_mobile_number": "अपने दोस्त का मोबाइल नंबर दर्ज करें ",
	"friend_email": "अपने दोस्त का ईमेल (वैकल्पिक) दर्ज करें ",
	"send_invite_btn": "सबमिट करें",
	"privacy_policy": "गोपनीयता नीति ",
	"icon": "right_bat",
	"mobile_required": "कृपया एक मान्य नंबर दर्ज करें",
	"email_optional": "कृपया एक मान्य ईमेल दर्ज करें ",
	"Number already in use, Please refer another": "नंबर पहले से उपयोग में है, कृपया दूसरा रेफर करें",
	"thank_you": "धन्यवाद! ",
	"thank_you_h3": "आपके दोस्त को कोड {{code}} के साथ एक  आमंत्रण भेजा गया",
	"thank_you_info_text": "जब आपके दोस्त साइन अप कर अपने यूनिक कोड का उपयोग करेंगे तो आपको 100 किलोमीटर प्राप्त होंगे ",
	"enter_invite_code": "इनवाइट कोड दर्ज करें ",
	"affiliate_signup_h3": "अपने दोस्त का 6 अंकों वाला इनवाइट कोड दर्ज करें और 100 किलोमीटर एकत्र करें ",
	"affiliate_signup_btn": "जमा करें",
	"Please enter a valid code": "कृपया एक मान्य कोड दर्ज करें ",
	"my_referrals": "मेरे रेफरल ",
	"accepted": "स्वीकृत",
	"pending": "लंबित",
	"users": "उपयोगकर्ता",
	"status": "स्टेटस",
	"send_reminder": "रिमाइंडर भेजें ",
	"refer_another": "दूसरे को रेफर करें",
	"Referral not found": "रेफरल नहीं मिला"
}
