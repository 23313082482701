{
	"winner_list_h1": "তুফানি চ্যাম্পিয়ন",
	"avatar": "অবতার",
	"name": "নাম",
	"phone_number": "ফোন নম্বর",
	"prize": "প্রাইজ",
	"select_date": "তারিখ নির্বাচন করুন",
	"record_not_found": "Record not found",
	"win_1": "ওয়েস্ট ইন্ডিজে আইসিসি পুরুষদের টি-টোয়েন্টি বিশ্বকাপ 2024-এ তুফান আনতে জন্য আমি একটি সিট জিতেছি*",
	"win_img_1": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/flight.jpg",
	"win_2": "আমি ওয়েস্ট ইন্ডিজে ICC পুরুষদের T20 বিশ্বকাপ 2024 প্রচারের জন্য ফ্যানকোড থেকে একটি ক্রিকেট জার্সি জিতেছি*",
	"win_img_2": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/jercy.jpg",
	"win_3": "আমি ওয়েস্ট ইন্ডিজে ICC পুরুষদের T20 বিশ্বকাপ 2024 প্রচারের জন্য ফ্যানকোড থেকে একটি ক্রিকেট মার্চ জিতেছি*",
	"win_img_3": "https://017533-content-dev.s3.ap-south-1.amazonaws.com/win/voucher.jpg",
	"one_more_check": "শুধু একটি চেক!",
	"one_more_check_text": "আমরা আপনার জমা দেওয়া বিশদ বিবরণ যাচাই করছি এবং এই সাইটের পাশাপাশি এসএমএস বা হোয়াটসঅ্যাপের মাধ্যমে 10 কর্মদিবসের মধ্যে তুফানে যোগদানের জন্য আপনার যোগ্যতা সম্পর্কে আপনাকে অবহিত করব। প্রস্তুত হন!",
	"win_heading": "অভিনন্দন!",
	"win_1_success_verified": "বিদেশে আপনার তুফান সিট নিশ্চিত হয়েছে এবং আপনি ওয়েস্ট ইন্ডিজে যাত্রা করতে চলেছেন! আমরা শীঘ্রই পরবর্তী ধাপে SMS/Whatsapp বা ফোন কলের মাধ্যমে আপনার সাথে যোগাযোগ করব। আপনার দুর্দান্ত জয় সম্পর্কে আপনার বন্ধুদের বলুন!",
	"win_1_success": "বিদেশে আপনার তুফান সিট নিশ্চিত হয়েছে এবং আপনি ওয়েস্ট ইন্ডিজে যাত্রা করতে চলেছেন! আমরা শীঘ্রই পরবর্তী ধাপে SMS/Whatsapp বা ফোন কলের মাধ্যমে আপনার সাথে যোগাযোগ করব। আপনার দুর্দান্ত জয় সম্পর্কে আপনার বন্ধুদের বলুন!",
	"win_2_success": "You’ve claimed your Cricket Jersey! We will communicate further details via email & SMS shortly.",
	"win_3_success": "You’ve claimed your Toofani Merch! We will communicate further details via email & SMS shortly."
}
