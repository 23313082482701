import { useState, useEffect } from 'react';

export const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			// Check if the device width is less than a certain threshold (e.g., 768 pixels)
			const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;
			setIsMobile(isMobileDevice);
		};

		// Initial check
		handleResize();

		// Listen for window resize events
		window.addEventListener('resize', handleResize);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isMobile;
};
